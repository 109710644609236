
// REACT STANDARD LIBRARY CALLS
import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import Modal from 'react-modal';

/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';

/* IMPORT LIBRARY  */
import { DATE_CONFIRMER, NIGERIAN_NUMBER_FORMATTER, CAPITALIZE_FIRST_LETTERS, CAPITALIZE, getCookie, getData, TRIM, EXPLODE, 
    countWords, scrollToTop, OBJECT_SEARCHER } from '../../../Library/Library.js';


/* IMPORT COMPONENTS. */
import { KalyaanNigerianStates } from '../../../Library/KalyaanNigerianStates';
import { KalyaanNigerianStateLGA } from '../../../Library/KalyaanNigerianStateLGA';
import { KalyaanNigerianStateLGABranch } from '../../../Library/KalyaanNigerianStateLGABranch';
import { KalyaanBranchUnits } from '../../../Library/KalyaanBranchUnits';




// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };
const licenseCustomStyles = { content: { top: '52%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






export default function MEMBER_PROFILE({MenuController, DEFAULT_MENU_FEEDBACK, PAGE_TITLE_DATAFEEDBACK, DEFAULT_SUB_MENU_FEEDBACK}) {


    // SET THE PAGE TITLE FOR THIS DASHBOAD SEMI-PAGE
    PAGE_TITLE_DATAFEEDBACK("Member Data | "+configuration.COMPANY_NAME);
    // GET FROM URL THE ID OF THE MEMBER WE WANT TO EDIT HIS/HER DATA
    const { id } = useParams();
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }
    

    // CREATE STATE TO BE USED TO STORE IMAGE
    const [imgUrl, setImgUrl] = useState("/DATA/Spin-Preloader-2.gif");
    
    
    // construct api url to load up image
    const imageUrl = configuration.MEMBER_BINARY_IMAGE_FETCHER_API+id;
    // FUNCTION TO LOAD IMAGE FROM BACKEND AS BINARY, PROCESS AND CONVERT IT TO IMAGE
    const getImg = async () => {
        const response = await fetch(imageUrl, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Accept': 'image/png',
                'Authorization': `Bearer ${session}`,
                'Content-Type': 'image/png'     // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }).catch(error => {
            // history.push(configuration.LOGIN);
            openModal("Network Error, when attempting to save data. Please ensure you have a good internet connection.");
        });

        // ensure that we have an image data response and that fetch operation has not failed as a result of network failure before proceeding to this part
        if(response){
            const imageBlob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(imageBlob);
            reader.onloadend = () => {
                const base64data = reader.result;
                setImgUrl(base64data);
            };
        }
    };





    // STATE TO BE USED TO STORE DATA'S OF THE MEMBER, WE WANT TO PRINT ID-CARD FOR
    const [MEMBER_DATA, setMEMBER_DATA] = useState({});
    const [NEXT_OF_KIN_DATA, setNEXT_OF_KIN_DATA] = useState([]);
    const [MEMBER_BENEFICIARY_DATA, setMEMBER_BENEFICIARY_DATA] = useState([]);

    //
    useEffect(() => {
        // ensure page is scrolled to the top by default
        scrollToTop();

        // ONLY ALLOW API TO RUN IF THE "ALL_MEMBER_DATA" STATE IS EMPTY.
        if(TRIM(id) !== ""){
            /* call the api function responsible for user password reset operation. */
            const url = configuration.SORT_MEMBER_BY_ID_API+id;
            
            /* Call the "postData" function to help us handle submission. */
            getData(url, session).then(response => {
                // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
                if(response.mbrno){
                    // CONTROL IN HERE MEANS REQUEST WAS A SUCCESS, HENCE WE FETCH DATA AND SEND TO PARENT COMPONENT
                    setMEMBER_DATA(response);
                    // UPDATE MEMBER NEXT_OF_KIN STATE, SO THAT WE CAN HAVE DATA TO PRINT INSIDE THE NEXT_OF_KIN TABLE.
                    setNEXT_OF_KIN_DATA(response.nextOfKinEntity);
                    // UPDATE MEMBER BENEFICIARY STATE, SO THAT WE CAN HAVE DATA TO PRINT INSIDE THE BENEFICIARY TABLE.
                    setMEMBER_BENEFICIARY_DATA(response.beneficiaryEntity);
                    // LOAD IMAGE FROM BACKEND AS BINARY, PROCESS AND CONVERT IT TO IMAGE
                    getImg();
                }else{
                    // display server error message
                    openModal("System encountered an internal error while trying to load data.");
                }
            }).catch(error => {
                // At this junction we are certain that request submission has failed because of a network error, therefore we send out network 
                // failure message
                openModal("Network Error, when attempting to save data. Please ensure you have a good internet connection.");
            });
        }

    }, []);




    let decoded_state = "";
    let decoded_lga = "";
    let decoded_branch = "";
    let decoded_unit = "";
    // TRANSCODE DATA'S HERE
    decoded_state = (MEMBER_DATA.mbr_STATE) ? KalyaanNigerianStates[MEMBER_DATA.mbr_STATE] : "";
    if(decoded_state){
        decoded_lga = (MEMBER_DATA.mbr_LGA) ? OBJECT_SEARCHER(KalyaanNigerianStateLGA[MEMBER_DATA.mbr_STATE], MEMBER_DATA.mbr_LGA)['key'] : "";
        decoded_branch = (MEMBER_DATA.mbr_BRANCH) ? KalyaanNigerianStateLGABranch[decoded_lga][MEMBER_DATA.mbr_BRANCH] : "";
        // const extracted_unit_code = (countWords(MEMBER_DATA.hometown, "@#@") > 1) ? EXPLODE("@#@", MEMBER_DATA.hometown)[1] : "";
        // decoded_unit = (countWords(MEMBER_DATA.hometown, "@#@") > 1) ? OBJECT_SEARCHER(KalyaanBranchUnits[decoded_branch], extracted_unit_code)['key'] : "";
        const extracted_unit_code = MEMBER_DATA.unit;
        decoded_unit = OBJECT_SEARCHER(KalyaanBranchUnits[decoded_branch], extracted_unit_code)['key'];
    }
    // const decoded_state = (MEMBER_DATA.mbr_STATE) ? KalyaanNigerianStates[MEMBER_DATA.mbr_STATE] : "";
    // const decoded_lga = (MEMBER_DATA.mbr_LGA) ? OBJECT_SEARCHER(KalyaanNigerianStateLGA[MEMBER_DATA.mbr_STATE], MEMBER_DATA.mbr_LGA)['key'] : "";
    // const decoded_branch = "luckman";
    // const decoded_unit = "looser";

    // WE UPDATE THE PARENT COMPONENT TO REMIND IT THAT THE "ID_CARD" MENU IS WHAT IS CURRENTLY ACTIVE, WE DO THIS JUST SO THAT THE SIDEBAR
    // ACTIVE-MENU-SELECTOR DOES NOT GO OUT OF PLACE EACH TIME THE FULL PAGE IS RELOADED.
    DEFAULT_MENU_FEEDBACK("MEMBER_RECORDS");
    DEFAULT_SUB_MENU_FEEDBACK("MEMBER_LIST");

    return (
        
        <>
            {/* <div className="div-block-35" style={{ marginBottom: "0%", marginTop: "2%" }}>
                <h1></h1>
                <Link to={configuration.AGENT_RECORDS_LINK} className="button_outline filled goldenrod id new w-button">Back</Link>
            </div> */}

            <div className="div-block-35" style={{ marginBottom: "0%", marginTop: "2%" }}>
                <h1></h1>
                <Link to={configuration.ACTIVE_MEMBERSHIPS_LINK} className="realbuttons w-button" style={{ marginBottom: "0" }}>Back</Link>
                {/* <Link to={configuration.ACTIVE_MEMBERSHIPS_LINK} className="button_outline filled goldenrod id new w-button">Back</Link> */}
            </div>

            <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
            </Modal>
            
            {/* height: "990px" */}
            <div className="register_agent" style={{ marginBottom: "0px", height: "3200px" }}>
                <br />
                <h3 className="profile_pic_container" style={{ justifyContent: "center" }}>MEMBER DATA</h3>
                <br />

                <div className="profile_pic_container" style={{ justifyContent: "center", marginBottom: "2%" }}>
                    <img src={imgUrl} loading="lazy" width="200" height="200" alt="Member Display Picture" style={{ borderRadius: "0%" }} />
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "-1.5%" }}>
                    <button className="realbuttons w-button" style={{ marginRight: "0%", background: "#90ee90", width: "200px", border: "0px solid" }}></button>
                </div>


                <div className="profile_pic_container" style={{ justifyContent: "center", marginTop: "3%" }}>
                    <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-8">

                        <p style={{ color: "#777", textAlign: "right", width: "270px", margin: "0 0 0 0", fontSize: "18px" }}> <b>Surname</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", margin: "0 0 0 0", textTransform: "uppercase", fontSize: "18px" }}> 
                        {MEMBER_DATA.mbrsname} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-15%", fontSize: "18px" }}> <b>First Name</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-15%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {(MEMBER_DATA.mbrfname) ? CAPITALIZE_FIRST_LETTERS(EXPLODE(" ", MEMBER_DATA.mbrfname)[0]) : ""} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-30%", fontSize: "18px" }}> <b>Middle Name</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-30%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {(countWords(MEMBER_DATA.mbrfname) > 1) ? CAPITALIZE_FIRST_LETTERS(EXPLODE(" ", MEMBER_DATA.mbrfname)[1]) : ""} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-45%", fontSize: "18px" }}> <b>Gender</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-45%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {MEMBER_DATA.mbr_GENDER} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-60%", fontSize: "18px" }}> <b>Date of Birth</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-60%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {DATE_CONFIRMER(MEMBER_DATA.mbr_DOB)} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-75%", fontSize: "18px" }}> <b>Phone 1</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-75%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {NIGERIAN_NUMBER_FORMATTER(MEMBER_DATA.mbr_TEL_NO1)} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-90%", fontSize: "18px" }}> <b>Phone 2</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-90%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {NIGERIAN_NUMBER_FORMATTER(MEMBER_DATA.mbr_TEL_NO2)} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-105%", fontSize: "18px" }}> <b>Address Line 1</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-105%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {MEMBER_DATA.mbr_ADDR_LN1} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-120%", fontSize: "18px" }}> <b>Address Line 2</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-120%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {MEMBER_DATA.mbr_ADDR_LN2} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-135%", fontSize: "18px" }}> <b>City</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-135%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {MEMBER_DATA.mbr_CITY} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-150%", fontSize: "18px" }}> <b>State</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-150%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {decoded_state} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-150%", fontSize: "18px" }}> <b>LGA</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-150%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {decoded_lga} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-150%", fontSize: "18px" }}> <b>Branch</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-150%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {decoded_branch} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-150%", fontSize: "18px" }}> <b>Unit</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-150%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {decoded_unit} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-150%", fontSize: "18px" }}> <b>State of Origin</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-150%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {MEMBER_DATA.stateOfOrigin}</p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-150%", fontSize: "18px" }}> <b>LGA of Origin</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-150%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {MEMBER_DATA.localGovtOfOrigin}</p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-150%", fontSize: "18px" }}> <b>Hometown</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-150%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {CAPITALIZE_FIRST_LETTERS(EXPLODE("@#@", MEMBER_DATA.hometown)[0])}</p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-150%", fontSize: "18px" }}> <b>Vehicle Number</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-150%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {MEMBER_DATA.mbr_VEHICLE_NO} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-150%", fontSize: "18px" }}> <b>Riders Number</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-150%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {MEMBER_DATA.riders_CARD_NO} </p>

                        {/* <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-150%", fontSize: "18px" }}> <b>Benefits</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-150%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {(MEMBER_DATA.mbr_BENEFIT_PYMT === "O" ? "One-time pay-out" : "Variable pay-out")} </p> */}
                    </div>
                </div>




                {(NEXT_OF_KIN_DATA) ? (
                    <>
                        <div class="div-block-40" style={{ display: "flex", textAlign: "center", justifyContent: "center", marginTop: "-21%" }}>
                            <h1 class="title_header">NEXT OF KIN</h1>
                        </div>

                        <div className="profile_pic_container" style={{ justifyContent: "center", marginTop: "1%" }}>
                            <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-8">
                                <p style={{ color: "#777", textAlign: "right", width: "270px", margin: "0 0 0 0", fontSize: "18px" }}> <b>Surname</b> </p>
                                <p style={{ color: "#777", textAlign: "left", width: "270px", margin: "0 0 0 0", textTransform: "uppercase", fontSize: "18px" }}> 
                                {NEXT_OF_KIN_DATA.mbr_NEXT_KIN_SNAME} </p>

                                <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-15%", fontSize: "18px" }}> <b>First Name</b> </p>
                                <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-15%", textTransform: "capitalize", fontSize: "18px" }}> 
                                {CAPITALIZE_FIRST_LETTERS(NEXT_OF_KIN_DATA.mbr_NEXT_KIN_FNAME)} </p>

                                <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-30%", fontSize: "18px" }}> <b>Relationship</b> </p>
                                <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-30%", textTransform: "capitalize", fontSize: "18px" }}> 
                                {NEXT_OF_KIN_DATA.mbr_NEXT_KIN_REL} </p>

                                <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-45%", fontSize: "18px" }}> <b>Phone 1</b> </p>
                                <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-45%", textTransform: "capitalize", fontSize: "18px" }}> 
                                {NIGERIAN_NUMBER_FORMATTER(NEXT_OF_KIN_DATA.mbr_NEXT_KIN_TEL_NO1)} </p>

                                <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-60%", fontSize: "18px" }}> <b>Phone 2</b> </p>
                                <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-60%", textTransform: "capitalize", fontSize: "18px" }}> 
                                {NIGERIAN_NUMBER_FORMATTER(NEXT_OF_KIN_DATA.mbr_NEXT_KIN_TEL_NO2)} </p>

                                <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-75%", fontSize: "18px" }}> <b>Address</b> </p>
                                <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-75%", textTransform: "capitalize", fontSize: "18px" }}> 
                                {NEXT_OF_KIN_DATA.mbr_NEXT_KIN_ADDR} </p>

                                <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-90%", fontSize: "18px" }}> <b>Benefits</b> </p>
                                <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-90%", textTransform: "capitalize", fontSize: "18px" }}> 
                                {(MEMBER_DATA.mbr_BENEFIT_PYMT === "O" ? "One-time pay-out" : "Variable pay-out")} </p>
                            </div>
                        </div>
                    </>
                ) : ( 
                        <div class="div-block-40" style={{ visibility: "hidden", textAlign: "center", justifyContent: "center", 
                            marginTop: "-21%", marginBottom: "28%" }}>
                            <h1 class="title_header">NEXT OF KIN</h1>
                        </div>
                )}




                {(MEMBER_BENEFICIARY_DATA[0]) ? (
                    <div class="div-block-40" style={{ display: "flex", textAlign: "center", justifyContent: "center", marginTop: "-30%" }}>
                        <h1 class="title_header">BENEFICIARIES</h1>
                    </div>
                ) : ""}

                {(MEMBER_BENEFICIARY_DATA[0]) ? (
                    <div className="profile_pic_container" style={{ justifyContent: "center", marginTop: "1%" }}>
                        <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-8">

                            <p style={{ color: "#777", textAlign: "right", width: "250px", margin: "0 0 0 0", fontSize: "18px" }}> <b>Surname</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", margin: "0 0 0 0", textTransform: "uppercase", fontSize: "18px" }}> 
                            {MEMBER_BENEFICIARY_DATA[0].ben_SNAME} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-15%", fontSize: "18px" }}> <b>First Name</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-15%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {CAPITALIZE_FIRST_LETTERS(MEMBER_BENEFICIARY_DATA[0].ben_FNAME)} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-30%", fontSize: "18px" }}> <b>Date of Birth</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-30%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {DATE_CONFIRMER(MEMBER_BENEFICIARY_DATA[0].ben_DOB)} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-45%", fontSize: "18px" }}> <b>Gender</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-45%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {MEMBER_BENEFICIARY_DATA[0].ben_GENDER} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-60%", fontSize: "18px" }}> <b>Relationship</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-60%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {MEMBER_BENEFICIARY_DATA[0].ben_REL} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-75%", fontSize: "18px" }}> <b>Phone 1</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-75%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {NIGERIAN_NUMBER_FORMATTER(MEMBER_BENEFICIARY_DATA[0].ben_TEL_NO1)} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-90%", fontSize: "18px" }}> <b>Phone 2</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-90%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {NIGERIAN_NUMBER_FORMATTER(MEMBER_BENEFICIARY_DATA[0].ben_TEL_NO2)} </p>
                        </div>
                    </div>
                ) : ""}
                
                {/* <hr style={{ borderBottom: "2px solid #999" }} ></hr> */}
                {(MEMBER_BENEFICIARY_DATA[1]) ? (
                    <div className="profile_pic_container" style={{ justifyContent: "center", marginTop: "-29%" }}>
                        <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-8">
                            <p style={{ color: "#777", textAlign: "right", width: "250px", margin: "0 0 0 0", fontSize: "18px" }}> <b>Surname</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", margin: "0 0 0 0", textTransform: "uppercase", fontSize: "18px" }}> 
                            {MEMBER_BENEFICIARY_DATA[1].ben_SNAME} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-15%", fontSize: "18px" }}> <b>First Name</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-15%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {CAPITALIZE_FIRST_LETTERS(MEMBER_BENEFICIARY_DATA[1].ben_FNAME)} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-30%", fontSize: "18px" }}> <b>Date of Birth</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-30%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {DATE_CONFIRMER(MEMBER_BENEFICIARY_DATA[1].ben_DOB)} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-45%", fontSize: "18px" }}> <b>Gender</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-45%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {MEMBER_BENEFICIARY_DATA[1].ben_GENDER} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-60%", fontSize: "18px" }}> <b>Relationship</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-60%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {MEMBER_BENEFICIARY_DATA[1].ben_REL} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-75%", fontSize: "18px" }}> <b>Phone 1</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-75%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {NIGERIAN_NUMBER_FORMATTER(MEMBER_BENEFICIARY_DATA[1].ben_TEL_NO1)} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-90%", fontSize: "18px" }}> <b>Phone 2</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-90%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {NIGERIAN_NUMBER_FORMATTER(MEMBER_BENEFICIARY_DATA[1].ben_TEL_NO2)} </p>
                        </div>
                    </div>
                ) : ""}
                {(MEMBER_BENEFICIARY_DATA[2]) ? (
                    <div className="profile_pic_container" style={{ justifyContent: "center", marginTop: "-29%" }}>
                        <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-8">
                            <p style={{ color: "#777", textAlign: "right", width: "250px", margin: "0 0 0 0", fontSize: "18px" }}> <b>Surname</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", margin: "0 0 0 0", textTransform: "uppercase", fontSize: "18px" }}> 
                            {MEMBER_BENEFICIARY_DATA[2].ben_SNAME} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-15%", fontSize: "18px" }}> <b>First Name</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-15%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {CAPITALIZE_FIRST_LETTERS(MEMBER_BENEFICIARY_DATA[2].ben_FNAME)} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-30%", fontSize: "18px" }}> <b>Date of Birth</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-30%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {DATE_CONFIRMER(MEMBER_BENEFICIARY_DATA[2].ben_DOB)} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-45%", fontSize: "18px" }}> <b>Gender</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-45%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {MEMBER_BENEFICIARY_DATA[2].ben_GENDER} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-60%", fontSize: "18px" }}> <b>Relationship</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-60%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {MEMBER_BENEFICIARY_DATA[2].ben_REL} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-75%", fontSize: "18px" }}> <b>Phone 1</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-75%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {NIGERIAN_NUMBER_FORMATTER(MEMBER_BENEFICIARY_DATA[2].ben_TEL_NO1)} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-90%", fontSize: "18px" }}> <b>Phone 2</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-90%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {NIGERIAN_NUMBER_FORMATTER(MEMBER_BENEFICIARY_DATA[2].ben_TEL_NO2)} </p>
                        </div>
                    </div>
                ) : ""}
                {(MEMBER_BENEFICIARY_DATA[3]) ? (
                    <div className="profile_pic_container" style={{ justifyContent: "center", marginTop: "-29%" }}>
                        <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-8">
                            <p style={{ color: "#777", textAlign: "right", width: "250px", margin: "0 0 0 0", fontSize: "18px" }}> <b>Surname</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", margin: "0 0 0 0", textTransform: "uppercase", fontSize: "18px" }}> 
                            {MEMBER_BENEFICIARY_DATA[3].ben_SNAME} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-15%", fontSize: "18px" }}> <b>First Name</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-15%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {CAPITALIZE_FIRST_LETTERS(MEMBER_BENEFICIARY_DATA[3].ben_FNAME)} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-30%", fontSize: "18px" }}> <b>Date of Birth</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-30%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {DATE_CONFIRMER(MEMBER_BENEFICIARY_DATA[3].ben_DOB)} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-45%", fontSize: "18px" }}> <b>Gender</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-45%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {MEMBER_BENEFICIARY_DATA[3].ben_GENDER} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-60%", fontSize: "18px" }}> <b>Relationship</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-60%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {MEMBER_BENEFICIARY_DATA[3].ben_REL} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-75%", fontSize: "18px" }}> <b>Phone 1</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-75%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {NIGERIAN_NUMBER_FORMATTER(MEMBER_BENEFICIARY_DATA[3].ben_TEL_NO1)} </p>

                            <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-90%", fontSize: "18px" }}> <b>Phone 2</b> </p>
                            <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-90%", textTransform: "capitalize", fontSize: "18px" }}> 
                            {NIGERIAN_NUMBER_FORMATTER(MEMBER_BENEFICIARY_DATA[3].ben_TEL_NO2)} </p>
                        </div>
                    </div>
                ) : ""}

                <div className="botton-holder" style={{ marginTop: "-28%" }}>
                    <a href={configuration.GWS_DASHBOARD_LINK} className="w-button" style={{ visibility: "hidden" }}></a>
                    <Link to={configuration.EDIT_MEMBER_RECORDS_LINK+id} className="realbuttons filled w-button">Edit</Link>
                </div>
            </div>

        </>
    );


}

