
export const KalyaanNigerianStateLGABranch = {

    "Abaji": {
    "ABJ": "Abaji",
    },

    "Amac": {
    "APG": "Apo - Galadimawa",
    "AKJ": "Apo - Kabusa Junction",
    "AKV": "Apo - Kabusa Village",
    "ASO": "Asokoro",
    "JKW": "Jikwoyi",
    "KMO": "Karmo",
    "KRU": "Karu",
    "LUA": "Lugbe Across",
    "LUF": "Lugbe F.H.A 9",
    "NYY": "Nyanya",
    "GWR": "Gwarimpa"
    },

    "Bwari": {
    "BWR": "Bwari",
    "KUB": "Kubwa",
    "MPP": "Mpape"
    },

    "Gwagwalada": {
    "GWA": "Gwagwalada",
    },

    "Kwali": {
    "KWL": "Kwali",
    },

    "Kuje": {
    "KUJ": "Kuje",
    },

    "Demsa": {
    "DSA": "Demsa",
    },

    "Fufore": {
    "FUR": "Fufore",
    },
    "Ganye": {
    "GAN": "Ganye",
    },
    "Girei": {
    "GRE": "Girei",
    },
    "Gombi": {
    "GMB": "Gombi",
    },
    "Guyuk": {
    "GUY": "Guyuk",
    },
    "Hong": {
    "HNG": "Hong",
    },
    "Jada": {
    "JAD": "Jada",
    },
    "Lamurde": {
    "LMR": "Lamurde",
    },
    "Madagali": {
    "MDG": "Madagali",
    },
    "Maiha": {
    "MAH": "Maiha",
    },
    "Mayo-Belwa": {
    "MWA": "Mayo-Belwa",
    },
    "Michika": {
    "MCH": "Michika",
    },
    "Mubi North": {
    "MUB": "Mubi North",
    },
    "Mubi South": {
    "GYL": "Mubi South",
    },
    "Numan": {
    "NUM": "Numan",
    },
    "Shelleng": {
    "SHG": "Shelleng",
    },
    "Song": {
    "SNG": "Song",
    },
    "Toungo": {
    "TNG": "Toungo",
    },
    "Yola North": {
    "YLA": "Yola North",
    },
    "Yola South": {
    "JMT": "Yola South",
    },
    "Alkaleri": {
    "ALK": "Alkaleri",
    },
    "Bauchi": {
    "BAU": "Bauchi",
    },
    "Bogoro": {
    "BGR": "Bogoro",
    },
    "Damban": {
    "DBM": "Damban",
    },
    "Darazo": {
    "DRZ": "Darazo",
    },
    "Dass": {
    "DAS": "Dass",
    },
    "Gamawa": {
    "GAM": "Gamawa",
    },
    "Ganjuwa": {
    "GJW": "Ganjuwa",
    },
    "Giade": {
    "GYD": "Giade",
    },
    "Itas/Gadau": {
    "TSG": "Itas/Gadau",
    },
    "Jama Are": {
    "JMA": "Jama Are",
    },
    "Katagum": {
    "KTG": "Katagum",
    },
    "Kirfi": {
    "KRF": "Kirfi",
    },
    "Misau": {
    "MSA": "Misau",
    },
    "Ningi": {
    "NNG": "Ningi",
    },
    "Shira": {
    "SHR": "Shira",
    },
    "Tafawa-Balewa": {
    "TFB": "Tafawa-Balewa",
    },
    "Toro": {
    "TRR": "Toro",
    },
    "Warji": {
    "WRJ": "Warji",
    },
    "Zaki": {
    "ZAK": "Zaki",
    },
    "Ado": {
    "GMU": "Ado",
    },
    "Agatu": {
    "GTU": "Agatu",
    },
    "Apa": {
    "GKP": "Apa",
    },
    "Buruku": {
    "BKB": "Buruku",
    },
    "Gboko": {
    "GBK": "Gboko",
    },
    "Guma": {
    "YGJ": "Guma",
    },
    "Gwer East": {
    "ALD": "Gwer East",
    },
    "Gwer West": {
    "NAK": "Gwer West",
    },
    "Katsina-Ala": {
    "KAL": "Katsina-Ala",
    },
    "Konshisha": {
    "TSE": "Konshisha",
    },
    "Kwande": {
    "WDP": "Kwande",
    },
    "Logo": {
    "GBG": "Logo",
    },
    "Makurdi": {
    "MKD": "Makurdi",
    },
    "Obi": {
    "BRT": "Obi",
    },
    "Ogbadibo": {
    "BGT": "Ogbadibo",
    },
    "Ohimini": {
    "DKP": "Ohimini",
    },
    "Oju": {
    "JUX": "Oju",
    },
    "Okpokwu": {
    "PKG": "Okpokwu",
    },
    "Otukpo": {
    "TKP": "Otukpo",
    },
    "Tarka": {
    "WNN": "Tarka",
    },
    "Ukum": {
    "UKM": "Ukum",
    },
    "Ushongo": {
    "SEL": "Ushongo",
    },
    "Vandeikya": {
    "VDY": "Vandeikya",
    },
    "Abadam": {
    "ADM": "Abadam",
    },
    "Askira/Uba": {
    "ASU": "Askira/Uba",
    },
    "Bama": {
    "BAM": "Bama",
    },
    "Bayo": {
    "BAY": "Bayo",
    },
    "Biu": {
    "BBU": "Biu",
    },
    "Chibok": {
    "CBK": "Chibok",
    },
    "Damboa": {
    "DAM": "Damboa",
    },
    "Dikwa": {
    "DKW": "Dikwa",
    },
    "Gubio": {
    "GUB": "Gubio",
    },
    "Guzamala": {
    "GZM": "Guzamala",
    },
    "Gwoza": {
    "GZA": "Gwoza",
    },
    "Hawul": {
    "HWL": "Hawul",
    },
    "Jere": {
    "JRE": "Jere",
    },
    "Kaga": {
    "KGG": "Kaga",
    },
    "Kala/Balge": {
    "KBG": "Kala/Balge",
    },
    "Konduga": {
    "KDG": "Konduga",
    },
    "Kukawa": {
    "KWA": "Kukawa",
    },
    "Kwaya Kusar": {
    "KWY": "Kwaya Kusar",
    },
    "Mafa": {
    "MAF": "Mafa",
    },
    "Magumeri": {
    "MGM": "Magumeri",
    },
    "Maiduguri": {
    "MAG": "Maiduguri",
    },
    "Marte": {
    "MAR": "Marte",
    },
    "Mobbar": {
    "MBR": "Mobbar",
    },
    "Monguno": {
    "MNG": "Monguno",
    },
    "Ngala": {
    "NGL": "Ngala",
    },
    "Nganzai": {
    "NGZ": "Nganzai",
    },
    "Shani": {
    "SHN": "Shani",
    },
    "Akko": {
    "AKK": "Akko",
    },
    "Balanga": {
    "BLG": "Balanga",
    },
    "Billiri": {
    "BLR": "Billiri",
    },
    "Dukku": {
    "DKU": "Dukku",
    },
    "Funakaye": {
    "FKY": "Funakaye",
    },
    "Gombe": {
    "GME": "Gombe",
    },
    "Kaltungo": {
    "KLT": "Kaltungo",
    },
    "Kwami": {
    "KWM": "Kwami",
    },
    "Nafada/Bajoga": {
    "NFD": "Nafada/Bajoga",
    },
    "Shomgom": {
    "SHM": "Shomgom",
    },
    "Yamaltu/Deba": {
    "YD3": "Yamaltu/Deba",
    },
    "Auyo": {
    "AUY": "Auyo",
    },
    "Babura": {
    "BBR": "Babura",
    },
    "Biriniwa": {
    "BNW": "Biriniwa",
    },
    "Birnin Kudu": {
    "BKD": "Birnin Kudu",
    },
    "Buji": {
    "BUJ": "Buji",
    },
    "Dutse": {
    "DUT": "Dutse",
    },
    "Gagarawa": {
    "GGW": "Gagarawa",
    },
    "Garki": {
    "GRK": "Garki",
    },
    "Gumel": {
    "GML": "Gumel",
    },
    "Guri": {
    "GRR": "Guri",
    },
    "Gwaram": {
    "GRM": "Gwaram",
    },
    "Gwiwa": {
    "GWW": "Gwiwa",
    },
    "Hadejia": {
    "HJA": "Hadejia",
    },
    "Jahun": {
    "JHN": "Jahun",
    },
    "Kafin Hausa": {
    "KHS": "Kafin Hausa",
    },
    "Kaugama": {
    "KGM": "Kaugama",
    },
    "Kazaure": {
    "KZR": "Kazaure",
    },
    "Kiri Kasamma": {
    "KKM": "Kiri Kasamma",
    },
    "Kiyawa": {
    "KYW": "Kiyawa",
    },
    "Maigatari": {
    "MGR": "Maigatari",
    },
    "Malam Madori": {
    "MMR": "Malam Madori",
    },
    "Miga": {
    "MGA": "Miga",
    },
    "Ringim": {
    "RNG": "Ringim",
    },
    "Roni": {
    "RRN": "Roni",
    },
    "Sule-Tankarkar": {
    "STK": "Sule-Tankarkar",
    },
    "Taura": {
    "TAR": "Taura",
    },
    "Yankwashi": {
    "YKS": "Yankwashi",
    },
    "Birnin-Gwari": {
    "BNG": "Birnin-Gwari",
    },
    "Chikun": {
    "STS": "S. Tasha",
    },
    "Giwa": {
    "GKW": "Giwa",
    },
    "Igabi": {
    "BKR": "Bakin Ruwa",
    },
    "Ikara": {
    "KAR": "Ikara",
    },
    "Jaba": {
    "KWI": "Kwoi",
    },
    "Jema'a": {
    "KFC": "Kafanchan",
    },
    "Kachia": {
    "KCH": "Kachia",
    },
    "Kaduna North": {
    "MGK": "Magajin Kara",
    },
    "Kaduna South": {
    "BNW": "Barnawa",
    },
    "Kagarko": {
    "JRE": "Jere",
    },
    "Kajuru": {
    "KJR": "Kajuru",
    },
    "Kaura": {
    "KGR": "Kagoro",
    },
    "Kauru": {
    "PBG": "Pambeguwa B",
    },
    "Kubau": {
    "ANC": "Anchau",
    },
    "Kudan": {
    "HKY": "Hunkuyi",
    },
    "Lere": {
    "UWB": "Ungwan Bawa",
    },
    "Makarfi": {
    "MKR": "Makarfi",
    },
    "Sabon-Gari": {
    "GDI": "Gidan Iya",
    },
    "Sanga": {
    "GWT": "Gwantu",
    },
    "Soba": {
    "MGN": "Maigana",
    },
    "Zango-Kataf": {
    "ZKW": "Zonkwa",
    },
    "Zaria": {
    "TDW": "Tudun Wada",
    },
    "Ajingi": {
    "AJG": "Ajingi",
    },
    "Albasu": {
    "BCH": "Bichi",
    },
    "Bagwai I": {
    "DMI": "Durumin Iya",
    },
    "Bagwai II": {
    "MTW": "Matawalle",
    },
    "Bebeji": {
    "RMI": "Rimi",
    },
    "Bichi": {
    "BCH": "Bichi",
    },
    "Bunkure": {
    "BNK": "Bunkure",
    },
    "Dala I": {
    "DAL": "Dala",
    },
    "Dala II": {
    "GMB": "Gambari",
    },
    "Dambatta": {
    "DMG": "Danmagaji",
    },
    "Dawaki Kudu": {
    "DKD": "Dawaki Kudu",
    },
    "Dawaki Tofa I": {
    "DMG": "Danmagaji",
    },
    "Dawaki Tofa II": {
    "MDK": "Madaki",
    },
    "Doguwa": {
    "DGW": "Doguwa",
    },
    "Fagge I": {
    "FGE": "Fagge",
    },
    "Fagge II": {
    "YNK": "Yankaba",
    },
    "Gabasawa I": {
    "RMI": "Rimi",
    },
    "Gabasawa II": {
    "SYK": "S. Yakasai",
    },
    "Garko": {
    "GAK": "Garko",
    },
    "Garum Mallam": {
    "RYZ": "Rijiyar Zaki",
    },
    "Gaya": {
    "TYW": "Tsanyawa",
    },
    "Gezawa I": {
    "MMR": "Marmara",
    },
    "Gezawa II": {
    "RYZ": "Rijiyar Zaki",
    },
    "Gwale I": {
    "GWL": "Gwale",
    },
    "Gwale II": {
    "KSM": "Kasuwar Madaki",
    },
    "Gwarzo": {
    "DLA": "Dala",
    },
    "Kabo": {
    "KBK": "Kabo",
    },
    "Kano Municipal": {
    "KMC": "Kano Municipal",
    },
    "Karaye": {
    "GWL": "Gwale",
    },
    "Kibiya": {
    "KBY": "Kibiya",
    },
    "Kiru": {
    "MGS": "Magashe",
    },
    "Kumbotso I": {
    "KDA": "Kofar Dan Agundi",
    },
    "Kumbotso II": {
    "MDC": "Madaci",
    },
    "Kunchi I": {
    "KNC": "Kunchi",
    },
    "Kunchi II": {
    "MGR": "Mangari",
    },
    "Kura": {
    "DMI": "Durimin Iya",
    },
    "Madobi": {
    "MDB": "Madobi",
    },
    "Makoda I": {
    "MKD": "Makoda",
    },
    "Makoda II": {
    "TDF": "Tudun Fulani",
    },
    "Minjibir": {
    "MGS": "Magashe",
    },
    "Nasarawa I": {
    "AAI": "Abdul'Aziz Isa",
    },
    "Nasarawa II": {
    "SHR": "Sharafi",
    },
    "Rano": {
    "RAN": "Rano",
    },
    "Rimin Gado": {
    "RMI": "Rimi",
    },
    "Rogo": {
    "RGG": "Rogo",
    },
    "Shanono": {
    "FGE": "Fagge",
    },
    "Sumaila": {
    "SML": "Sumaila",
    },
    "Takai": {
    "MNH": "Munir Hasan",
    },
    "Tarauni I": {
    "KBW": "Kabawa",
    },
    "Tarauni II": {
    "TRN": "Tarauni",
    },
    "Tofa I": {
    "DMG": "Danmagaji",
    },
    "Tofa II": {
    "MTW": "Matawalle",
    },
    "Tsanyawa": {
    "TYW": "Tsanyawa",
    },
    "Tudun Wada": {
    "NAI": "Na'Isa",
    },
    "Ungogo I": {
    "JRM": "Jarmai",
    },
    "Ungogo II": {
    "UGG": "Ungogo",
    },
    "Warawa": {
    "WRA": "Warawa",
    },
    "Wudil": {
    "WDL": "Wudil",
    },
    "Bakori": {
    "BKR": "Bakori",
    },
    "Batagarawa": {
    "BAT": "Batagarawa",
    },
    "Batsari": {
    "BTR": "Batsari",
    },
    "Baure": {
    "BRE": "Baure",
    },
    "Bindawa": {
    "BDW": "Bindawa",
    },
    "Charanchi": {
    "CRC": "Charanchi",
    },
    "Dan Musa": {
    "DMS": "Dan Musa",
    },
    "Dandume": {
    "DDM": "Dandume",
    },
    "Danja": {
    "DJA": "Danja",
    },
    "Daura": {
    "DRA": "Daura",
    },
    "Dutsi": {
    "DTS": "Dutsi",
    },
    "Dutsin-Ma": {
    "DTM": "Dutsin-Ma",
    },
    "Faskari": {
    "FSK": "Faskari",
    },
    "Funtua": {
    "FTA": "Funtua",
    },
    "Ingawa": {
    "NGW": "Ingawa",
    },
    "Jibia": {
    "JBY": "Jibia",
    },
    "Kafur": {
    "KFR": "Kafur",
    },
    "Kaita": {
    "KAT": "Kaita",
    },
    "Kankara": {
    "KKR": "Kankara",
    },
    "Kankia": {
    "KNK": "Kankia",
    },
    "Katsina": {
    "KTN": "Katsina",
    },
    "Kurfi": {
    "KUF": "Kurfi",
    },
    "Kusada": {
    "KSD": "Kusada",
    },
    "Mai-Adua": {
    "MDW": "Mai-Adua",
    },
    "Malumfashi": {
    "MNF": "Malumfashi",
    },
    "Mani": {
    "MAN": "Mani",
    },
    "Mashi": {
    "MSH": "Mashi",
    },
    "Matazu": {
    "MTZ": "Matazu",
    },
    "Musawa": {
    "MSW": "Musawa",
    },
    "Rimi": {
    "RMY": "Rimi",
    },
    "Sabuwa": {
    "SBA": "Sabuwa",
    },
    "Safana": {
    "SFN": "Safana",
    },
    "Sandamu": {
    "SDM": "Sandamu",
    },
    "Zango": {
    "ZNG": "Zango",
    },
    "Aleiro": {
    "ALR": "Aleiro",
    },
    "Arewa Dandi": {
    "KGW": "Arewa Dandi",
    },
    "Argungu": {
    "ARG": "Argungu",
    },
    "Augie": {
    "AUG": "Augie",
    },
    "Bagudo": {
    "BGD": "Bagudo",
    },
    "Birnin Kebbi": {
    "BRK": "Birnin Kebbi",
    },
    "Bunza": {
    "BNZ": "Bunza",
    },
    "Dandi": {
    "KMB": "Dandi",
    },
    "Danko/Wasagu": {
    "RBH": "Danko/Wasagu",
    },
    "Fakai": {
    "MHT": "Fakai",
    },
    "Gwandu": {
    "GWN": "Gwandu",
    },
    "Jega": {
    "JEG": "Jega",
    },
    "Kalgo": {
    "KLG": "Kalgo",
    },
    "Koko/Besse": {
    "BES": "Koko/Besse",
    },
    "Maiyama": {
    "MYM": "Maiyama",
    },
    "Ngaski": {
    "WRR": "Ngaski",
    },
    "Sakaba": {
    "DRD": "Sakaba",
    },
    "Shanga": {
    "SNA": "Shanga",
    },
    "Suru": {
    "DKG": "Suru",
    },
    "Yauri": {
    "YLW": "Yauri",
    },
    "Zuru": {
    "ZUR": "Zuru",
    },
    "Adavi": {
    "DAV": "Adavi",
    },
    "Ajaokuta": {
    "AJA": "Ajaokuta",
    },
    "Ankpa": {
    "KPA": "Ankpa",
    },
    "Bassa": {
    "BAS": "Bassa",
    },
    "Dekina": {
    "KNA": "Dekina",
    },
    "Ibaji": {
    "NDG": "Ibaji",
    },
    "Idah": {
    "DAH": "Idah",
    },
    "Igbalamela-Odolu": {
    "AJK": "Igbalamela-Odolu",
    },
    "Ijumu": {
    "JMU": "Ijumu",
    },
    "Kabba/Bunu": {
    "KAB": "Kabba/Bunu",
    },
    "Kogi": {
    "KKF": "Kogi",
    },
    "Lokoja": {
    "LKJ": "Lokoja",
    },
    "Mopa-Muro": {
    "MPA": "Mopa-Muro",
    },
    "Ofu": {
    "KFU": "Ofu",
    },
    "Ogori/Magongo": {
    "KPF": "Ogori/Magongo",
    },
    "Okehi": {
    "KKH": "Okehi",
    },
    "Okene": {
    "KNE": "Okene",
    },
    "Olamabolo": {
    "LAM": "Olamabolo",
    },
    "Omala": {
    "BJK": "Omala",
    },
    "Yagba East": {
    "ERE": "Yagba East",
    },
    "Yagba West": {
    "SAN": "Yagba West",
    },
    "Asa": {
    "IRD": "Irede",
    },
    "Baruteen": {
    "OKT": "Okuta",
    },
    "Edu": {
    "LFA": "Lafia",
    },
    "Ekiti": {
    "EKT": "Ekiti",
    },
    "Ifelodun": {
    "IGB": "Igbomina",
    },
    "Ilorin East": {
    "ORR": "Orire",
    },
    "Ilorin South": {
    "EM2": "Emirate 2",
    },
    "Ilorin West": {
    "EM1": "Emirate 1",
    },
    "Irepodun": {
    "OS1": "Osi 1",
    },
    "Isin": {
    "IGB": "Igbomina",
    },
    "Kaiama": {
    "KMA": "Kaiama",
    },
    "Moro": {
    "LFA": "Lafia",
    },
    "Offa": {
    "IRR": "Irorude",
    },
    "Oke-Ero": {
    "OKR": "Oke-Ero",
    },
    "Oyun": {
    "ERL": "Erinle",
    },
    "Patigi": {
    "PTG": "Patigi",
    },
    "Agaie": {
    "AGA": "Agaie",
    },
    "Agwara": {
    "AGR": "Agwara",
    },
    "Bida": {
    "BDA": "Bida",
    },
    "Borgu": {
    "NBS": "Borgu",
    },
    "Bosso": {
    "MAK": "Bosso",
    },
    "Chanchaga": {
    "MNA": "Chanchaga",
    },
    "Edati": {
    "ENG": "Edati",
    },
    "Gbako": {
    "LMU": "Gbako",
    },
    "Gurara": {
    "GWU": "Gurara",
    },
    "Katcha": {
    "KHA": "Katcha",
    },
    "Kontagora": {
    "KNT": "Kontagora",
    },
    "Lapai": {
    "LAP": "Lapai",
    },
    "Lavun": {
    "KUG": "Lavun",
    },
    "Magama": {
    "NAS": "Magama",
    },
    "Mariga": {
    "BMG": "Mariga",
    },
    "Mashegu": {
    "MSG": "Mashegu",
    },
    "Mokwa": {
    "MKW": "Mokwa",
    },
    "Muya": {
    "SRP": "Muya",
    },
    "Paikoro": {
    "PAK": "Paikoro",
    },
    "Rafi": {
    "KAG": "Rafi",
    },
    "Rijau": {
    "RJA": "Rijau",
    },
    "Shiroro": {
    "KUT": "Shiroro",
    },
    "Suleja": {
    "SUL": "Suleja",
    },
    "Tafa": {
    "WSE": "Tafa",
    },
    "Wushishi": {
    "WSU": "Wushishi",
    },
    "Barkin Ladi": {
    "BLD": "Barkin Ladi",
    },
    "Bassa": {
    "BSA": "Bassa",
    },
    "Bokkos": {
    "BKK": "Bokkos",
    },
    "Jos East": {
    "ANW": "Jos East",
    },
    "Jos North": {
    "JJN": "Jos North",
    },
    "Jos South": {
    "BUU": "Jos South",
    },
    "Kanam": {
    "DNG": "Kanam",
    },
    "Kanke": {
    "KWK": "Kanke",
    },
    "Langtang North": {
    "LGT": "Langtang North",
    },
    "Langtang South": {
    "MBD": "Langtang South",
    },
    "Mangu": {
    "MGU": "Mangu",
    },
    "Mikang": {
    "TNK": "Mikang",
    },
    "Pankshin": {
    "PKN": "Pankshin",
    },
    "Qua'an Pan": {
    "QAP": "Qua'an Pan",
    },
    "Riyom": {
    "RYM": "Riyom",
    },
    "Shendam": {
    "SHD": "Shendam",
    },
    "Wase": {
    "WAS": "Wase",
    },
    "Binji": {
    "BJN": "Binji",
    },
    "Bodinga": {
    "DBN": "Bodinga",
    },
    "Dange-Shuni": {
    "DGS": "Dange-Shuni",
    },
    "Gada": {
    "GAD": "Gada",
    },
    "Goronyo": {
    "GRY": "Goronyo",
    },
    "Gudu": {
    "BLE": "Gudu",
    },
    "Gwadabawa": {
    "GWD": "Gwadabawa",
    },
    "Illela": {
    "LLA": "Illela",
    },
    "Isa": {
    "SAA": "Isa",
    },
    "Kebbe": {
    "KBE": "Kebbe",
    },
    "Kware": {
    "KWR": "Kware",
    },
    "Rabah": {
    "RBA": "Rabah",
    },
    "Sabon Birni": {
    "SBN": "Sabon Birni",
    },
    "Shagari": {
    "SGR": "Shagari",
    },
    "Silame": {
    "SLM": "Silame",
    },
    "Sokoto North": {
    "SKK": "Sokoto North",
    },
    "Sokoto South": {
    "SRZ": "Sokoto South",
    },
    "Tambuwal": {
    "TBW": "Tambuwal",
    },
    "Tangaza": {
    "TGZ": "Tangaza",
    },
    "Tureta": {
    "TRT": "Tureta",
    },
    "Wamako": {
    "WMK": "Wamako",
    },
    "Wurno": {
    "WRN": "Wurno",
    },
    "Yabo": {
    "YYB": "Yabo",
    },

    "Ardo-Kola I": {
    "IWR": "Iware",
    },
    "Ardo-Kola II": {
    "SUN": "Sunkani",
    },
    "Bali": {
    "BAL": "Bali",
    },
    "Donga": {
    "DGA": "Donga",
    },
    "Gashaka": {
    "GKA": "Gashaka",
    },
    "Gassol": {
    "GAS": "Gassol",
    },
    "Ibi": {
    "BBB": "Ibi",
    },
    "Jalingo": {
    "JAL": "Jalingo",
    },
    "Karim-Lamido": {
    "KLD": "Karim-Lamido",
    },
    "Kurmi": {
    "KRM": "Kurmi",
    },
    "Lau": {
    "LAU": "Lau",
    },
    "Sardauna": {
    "GBU": "Gembu",
    },
    "Takum": {
    "TTM": "Takum",
    },
    "Ussa": {
    "USS": "Ussa",
    },
    "Wukari": {
    "WKR": "Wukari",
    },
    "Yorro": {
    "YRR": "Yorro",
    },
    "Zing": {
    "TZG": "Zing",
    },
    "Bade": {
    "GSH": "Bade",
    },
    "Bursari": {
    "DPH": "Bursari",
    },
    "Damaturu": {
    "DTR": "Damaturu",
    },
    "Fika": {
    "FKA": "Fika",
    },
    "Fune": {
    "FUN": "Fune",
    },
    "Geidam": {
    "GDN": "Geidam",
    },
    "Gujba": {
    "GJB": "Gujba",
    },
    "Gulani": {
    "GLN": "Gulani",
    },
    "Jakusko": {
    "JAK": "Jakusko",
    },
    "Karasuwa": {
    "KRS": "Karasuwa",
    },
    "Machina": {
    "MCN": "Machina",
    },
    "Nangere": {
    "NNR": "Nangere",
    },
    "Nguru": {
    "NGU": "Nguru",
    },
    "Potiskum": {
    "PKM": "Potiskum",
    },
    "Tarmuwa": {
    "TMW": "Tarmuwa",
    },
    "Yunusari": {
    "YUN": "Yunusari",
    },
    "Yusufari": {
    "YSF": "Yusufari",
    },
    "Anka": {
    "ANK": "Anka",
    },
    "Bakura": {
    "BKA": "Bakura",
    },
    "Birnin Magaji": {
    "BMJ": "Birnin Magaji",
    },
    "Bukkuyum": {
    "BKM": "Bukkuyum",
    },
    "Bungudu": {
    "BUG": "Bungudu",
    },
    "Gummi": {
    "GMM": "Gummi",
    },
    "Gusau": {
    "GUS": "Gusau",
    },
    "Kaura Namoda": {
    "KRN": "Kaura Namoda",
    },
    "Maradun": {
    "MRD": "Maradun",
    },
    "Maru": {
    "MRR": "Maru",
    },
    "Shinkafi": {
    "SKF": "Shinkafi",
    },
    "Talata Mafara": {
    "TMA": "Talata Mafara",
    },
    "Tsafe": {
    "TSF": "Tsafe",
    },
    "Zurmi": {
    "ZRM": "Zurmi",
    }

};
