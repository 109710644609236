

/*
DEPLOYING REACT APP ON APACHE AND SHARED HOST: https://codeburst.io/deploying-and-hosting-a-react-app-on-an-apache-server-b7121dffb07a
DEPLOYING REACT APP ON VPS AND APACHE AND MAYBE SHARED HOST: https://stackoverflow.com/questions/42461279/how-to-deploy-a-react-app-on-apache-web-server


ARTICLES FOR NESTED REACT ROUTING WITH URL PARAMETER PASSING
https://medium.com/swlh/defining-nested-routes-with-react-router-8f140e87b360

BEST REACT-BASED GALLERY
DOC & DOWNLOAD => https://github.com/xiaolin/react-image-gallery
DEMO => https://www.linxtion.com/demo/react-image-gallery/

BEST REACT-BASED CAROUSELS
DOC & DOWNLOAD => https://github.com/FormidableLabs/nuka-carousel
DEMO => https://nuka-carousel-next.vercel.app/?slides=6&params=%7B%22wrapAround%22:true,%22slidesToShow%22:3%7D
LEARN TO BUILD CAROUSELS => https://www.youtube.com/watch?v=eBKcGAhkZUI


fff9f3 = warning = #fde2c3, f1f8ff = primary = #b8daff, f6f8ff = info = #d1ddfe, f1fcfa = success = #b8f1e6, fff4f4 = danger = #ffcaca
*/






    /* The domain name to the server that is hosting app related datas, along side images, scripts and stylesheets for this app front-end.  */
    // var APP_DATA_RESOURCE_DOMAIN = "https://ifeanyiokeakwalam.com/kalyaan/DATA/WEBSITE_ASSETS/";
    // var APP_DATA_RESOURCE_DOMAIN = "http://54.158.67.206:8090/HARLEY-CDN/DATA/WEBSITE_ASSETS/";
    // var APP_DATA_RESOURCE_DOMAIN = "http://demo.natomorasagent.com/HARLEY-CDN/DATA/WEBSITE_ASSETS/";
    // var APP_DATA_RESOURCE_DOMAIN = "https://cdn.kalyaanoffice.com/HARLEY-CDN/DATA/WEBSITE_ASSETS/";
    var APP_DATA_RESOURCE_DOMAIN = "https://firstcarekalyaan.com/HARLEY-CDN/DATA/WEBSITE_ASSETS/";
    // var APP_DATA_RESOURCE_DOMAIN = "http://localhost/HARLEY-CDN/DATA/WEBSITE_ASSETS/";
    /* The domain name to the server that is hosting app users uploaded datas, such as profile image, property images etc, for this app front-end.  */
    // var USER_DATA_RESOURCE_DOMAIN = "https://ifeanyiokeakwalam.com/kalyaan/DATA/UPLOADS/";
    // var USER_DATA_RESOURCE_DOMAIN = "http://54.158.67.206:8090/HARLEY-CDN/DATA/UPLOADS/";
    // var USER_DATA_RESOURCE_DOMAIN = "http://demo.natomorasagent.com/HARLEY-CDN/DATA/UPLOADS/";
    // var USER_DATA_RESOURCE_DOMAIN = "https://cdn.kalyaanoffice.com/HARLEY-CDN/DATA/UPLOADS/";
    var USER_DATA_RESOURCE_DOMAIN = "https://firstcarekalyaan.com/HARLEY-CDN/DATA/UPLOADS/";
    // var USER_DATA_RESOURCE_DOMAIN = "http://localhost/HARLEY-CDN/DATA/UPLOADS/";
    // Link address to QR scanned for Verification
    // var MEMBER_VERIFICATION_LINK = "http://natomorasagent.com/verify/member/";
    var MEMBER_VERIFICATION_LINK = "http://auth.kalyaanverify.co.uk/verify/member/";
    /* root link to the server where apis are hosted, among others. */
    var SERVER_DOMAIN = "http://54.158.67.206:8090"; // index.php/
    /* flatshare hotline slice. */
    var NUMBER = "8122072106";
    // set dashboard constant route part
    const dash = "/dashboard";


    export const configuration = { 
        
        /* BASIC SITE DATAS.  */
        COMPANY_NAME: "Kalyaan",
        COMPANY_EMAIL: "support@kalyaan.ng",
        ABUSE_EMAIL: "abuse@kalyaan.ng",
        ADDRESS: "",
        HOTLINE: "+234"+NUMBER,
        SESSION_EXPIRY: 180,
        ITEM_TO_CALL_AT_ONCE: 50,
        ITEM_TO_CALL_AT_ONCE_SHORT_FORM: 5,
        IMAGE_UPLOAD_SIZE_ALLOWED: 1, // INPUTES HERE ARE IN MEGABYTES (MB)
        WHATSAPP_LINK: "https://wa.me/0"+NUMBER,
        SERVER_DOMAIN: SERVER_DOMAIN,
        APP_DATA_RESOURCE: APP_DATA_RESOURCE_DOMAIN,
        USER_DATA_RESOURCE: USER_DATA_RESOURCE_DOMAIN,
        
        /* SITE URL */
        ADD_VETERAN_LINK: "/new/veteran/registra",
        ADD_AGENT_LINK: "/new/agent/registra",

        
        LOGIN: "/",
        MEMBER_VERIFICATION_LINK: MEMBER_VERIFICATION_LINK,
        DASHBOARD: dash,
        TRACKING: dash+"/member/tracking",
        AGENT_PROFILE_LINK: dash+"/agent/profile/",
        AGENT_RECORDS_LINK: dash+"/agent/records",
        // ADD_AGENT_LINK: dash+"/add/new/agent",
        EDIT_AGENT_RECORDS_LINK: dash+"/edit/agent/",
        
        OUTSTANDING_ID_CARDS: dash+"/id-card/outstanding-id-cards",
        ID_CARD_FOR_STAFF: dash+"/id-card/registered-staffs",
        ID_CARD_FOR_AGENT: dash+"/id-card/registered-agents",
        ID_CARD_FOR_MEMBERS: dash+"/id-card/registered-member-lists",

        ACTIVE_MEMBERSHIPS_LINK: dash+"/active/members/list",
        SUSPENDED_MEMBERSHIPS_LINK: dash+"/suspended/members/list",
        EXPELLED_MEMBERSHIPS_LINK: dash+"/expelled/members/list",
        DECEASED_MEMBERSHIPS_LINK: dash+"/deceased/members/list",
        MEMBER_PROFILE_LINK: dash+"/members/profile/",
        EDIT_MEMBER_NEXT_OF_KIN_LINK: dash+"/edit/nok/",
        EDIT_MEMBER_RECORDS_LINK: dash+"/edit/members/",
        ADD_MEMBER_BENEFICIARY_LINK: dash+"/create/member/dependant/",
        EDIT_MEMBER_BENEFICIARY_LINK: dash+"/update/member/dependant/",
        CUSTOMER_CARE_ACTIVE_MEMBERSHIPS_LINK: dash+"/customercare/members/list",
        CUSTOMER_CARE_MEMBER_PROFILE_LINK: dash+"/customercare/members/profile/",
        BATCH_PRINT_MEMBERS_ID_CARDS: "/batch/print/members/id-card",
        PRINT_MEMBER_DASHBOARD: "/print/member/id-card",
        PRINT_AGENT_DASHBOARD: "/print/agent/id-card",
        
        GWS_DASHBOARD_LINK: dash+"/gws/dashboard",
        COMPANY_REG_LINK: dash+"/register/new/company",
        G_W_S_APPLICATION_LINK: dash+"/gws/application",
        G_W_S_BATCH_APPLICATION_LINK: dash+"/gws/batch/application",
        APPROVAL_AND_POLICY_LINK: dash+"/gws/approval-policy",
        CLAIMS_PROCESSING_LINK: dash+"/gws/claims-processing",
        G_W_S_CLAIMS_REGISTRY_LINK: dash+"/gws/claims-registering/",
        
        /* OPERATIONS API's LIST  */
        SORT_MEMBER_BY_ID_API: SERVER_DOMAIN+"/v1/harley-welfare/members/",
        LOGIN_API: SERVER_DOMAIN+"/v1/harley-welfare/user/authenticate",
        MEMBER_LIST_API: SERVER_DOMAIN+"/v1/harley-welfare/members/list/",
        MEMBER_DATA_UPDATE_API: SERVER_DOMAIN+"/v1/harley-welfare/members/update/",
        MEMBER_DATA_DELETE_API: SERVER_DOMAIN+"/v1/harley-welfare/members/delete/",
        MEMBER_IMAGE_FETCHER_API: SERVER_DOMAIN+"/v1/harley-welfare/image/member/view/",
        MEMBER_BINARY_IMAGE_FETCHER_API: SERVER_DOMAIN+"/v1/harley-welfare/image/member/",
        GET_MEMBER_NEXT_OF_KIN_API: SERVER_DOMAIN+"/v1/harley-welfare/nok/",
        GET_MEMBER_BENEFICIARY_API: SERVER_DOMAIN+"/v1/harley-welfare/ben/list/",
        CREATE_BENEFICIARY_API: SERVER_DOMAIN+"/v1/harley-welfare/ben/save/",
        NEXT_OF_KIN_UPDATE_API: SERVER_DOMAIN+"/v1/harley-welfare/nok/update/",
        BENEFICIARY_UPDATE_API: SERVER_DOMAIN+"/v1/harley-welfare/ben/update/",
        MEMBER_BENEFICIARY_DELETE_API: SERVER_DOMAIN+"/v1/harley-welfare/ben/delete/",
        AGENTS_LIST_API: SERVER_DOMAIN+"/v1/harley-welfare/agent/list/",
        ADD_AGENT_API: SERVER_DOMAIN+"/v1/harley-welfare/agent/save",
        UPDATE_AGENT_API: SERVER_DOMAIN+"/v1/harley-welfare/agent/",
        SORT_AGENT_BY_ID_API: SERVER_DOMAIN+"/v1/harley-welfare/agent/one/",
        AGENT_BINARY_IMAGE_FETCHER_API: SERVER_DOMAIN+"/v1/harley-welfare/image/agent/",
        REGISTER_COMPANY_API: SERVER_DOMAIN+"",
        
        /* API KEY */
        KEY: "pk_test_46de0aa3c6601c75151d204271488c7c53adcd42",
        
        /* GET THE CURRENT YEAR*/
        CURRENT_YEAR: new Date().getFullYear()
    };


