
import React from 'react';
// import { Link } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';



/* IMPORT LIBRARY  */
import { NIGERIAN_NUMBER_FORMATTER, ALL_LETTERS_CAPITALIZED, CAPITALIZE_FIRST_LETTERS } from '../Library/Library.js';




export default function BeneficiaryList({MEMBER_BENEFICIARY_DATA, id}){

    
    return (

        <div className="dependant-holder" style={{ marginBottom: "10%" }}>
            <h1 className="title_header" style={{ marginBottom: "1%" }}>Member&#x27;s Beneficiary List</h1>
            <div className="div-block-22 dependant">
                <a href="#" className="table_data_holder dependant w-inline-block">
                    <div className="table_data header">Full Name</div>
                    <div className="table_data header">Relationship</div>
                    <div className="table_data header">Phone Number</div>
                    <div className="table_data header">Gender</div>
                </a>
                <div className="table_data header">Status</div>
            </div>

            {MEMBER_BENEFICIARY_DATA.map((infos, index) => {
                // print out our view
                return ( 
                    <div className="data_row dependant">
                        <a href={configuration.DASHBOARD+"/update/member/dependant/"+id+"/"+infos.id} 
                            className="table_data_holder dependant w-inline-block">
                            <div className="table_data">{ALL_LETTERS_CAPITALIZED(infos.ben_SNAME)+" "+CAPITALIZE_FIRST_LETTERS(infos.ben_FNAME)}</div>
                            <div className="table_data" style={{ textTransform: "capitalize"  }}>{infos.ben_REL}</div>
                            <div className="table_data">{NIGERIAN_NUMBER_FORMATTER(infos.ben_TEL_NO1)}</div>
                            <div className="table_data" style={{ textTransform: "capitalize"  }}>{infos.ben_GENDER}</div>
                        </a>
                        <div className="div-block-25 dependant">
                            <p className={"status table"+((infos.ben_STATUS === "alive") ? " active" : "")}>
                                {CAPITALIZE_FIRST_LETTERS( ((infos.ben_STATUS === "alive") ? "Active" : "Inactive") )}
                            </p>
                        </div>
                    </div>
                );
            })}
        </div>

    );

}

