
// REACT STANDARD LIBRARY CALLS
import React, {useState, useEffect, useRef} from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';

/* IMPORT COMPONENTS. */
// import NIGERIA_STATES_DROPDOWN from '../../../Components/Nigerian_States';
import { KalyaanNigerianStates } from '../../../Library/KalyaanNigerianStates';
import { KalyaanNigerianStateLGA } from '../../../Library/KalyaanNigerianStateLGA';
import { KalyaanNigerianStateLGABranch } from '../../../Library/KalyaanNigerianStateLGABranch';
import { KalyaanBranchUnits } from '../../../Library/KalyaanBranchUnits';

/* IMPORT LIBRARY  */
import { NIGERIAN_NUMBER_FORMATTER, ALL_LETTERS_CAPITALIZED, getData, FORMDATA_POSTDATA, deleteData, TRIM, getCookie, CAPITALIZE_FIRST_LETTERS, 
    mathRandom, EXPLODE, REACT_DATEPICKER_DATE_FORMATTER, DATE_CONFIRMER, OBJECT_TO_ARRAY, RELATIONSHIP_TO_GENDER_PARSER, 
    CAPITALIZE, scrollToTop, OBJECT_SEARCHER, STRING_CHARS_COUNT } 
    from '../../../Library/Library.js';
// import { NIGERIA_STATES_LOCAL_GOVERNMENT_AREA } from '../../../Library/STATES_LGA.js';
// import { NIGERIA_STATES_LOCAL_GOVERNMENT_AREA_BRANCHES } from '../../../Library/LGA_WARD_OR_BRANCH_SCRIPT.js';
import { MEMBER_REGISTRATION_DATA_AUTHENTICATOR } from '../../../Library/REG_LIBRARY.js';






// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






export default function MEMBER_EDIT({MenuController, DEFAULT_MENU_FEEDBACK, PAGE_TITLE_DATAFEEDBACK, DEFAULT_SUB_MENU, DEFAULT_SUB_MENU_FEEDBACK}) {

    // SET THE PAGE TITLE FOR THIS DASHBOAD SEMI-PAGE
    PAGE_TITLE_DATAFEEDBACK("Member Edit | "+configuration.COMPANY_NAME);
    // GET FROM URL THE ID OF THE MEMBER WE WANT TO EDIT HIS/HER DATA
    const { id } = useParams();
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }


    // CREATE STATE TO BE USED TO STORE IMAGE
    const [imgUrl, setImgUrl] = useState("/DATA/Spin-Preloader-"+mathRandom(1, 2)+".gif");
    // CREATE STATE TO BE USED TO CONTROL BUTTON LOADING ANIMATION, FOR THIS COMPONENT.
    const [BUTTON_LOADING_ANIM, setBUTTON_LOADING_ANIM] = useState(false);
    const [BUTTON_LOADING_ANIM2, setBUTTON_LOADING_ANIM2] = useState(false);
    // CREATE FORM STATES HERE
    const [FIRSTNAME, setFIRSTNAME] = useState("");
    const [MIDDLENAME, setMIDDLENAME] = useState("");
    const [LASTNAME, setLASTNAME] = useState("");
    const [STATUS, setSTATUS] = useState("");
    const [PHONE1, setPHONE1] = useState("");
    const [PHONE2, setPHONE2] = useState("");
    const [ADDRESS, setADDRESS] = useState("");
    const [ADDRESS2, setADDRESS2] = useState("");
    // const [MEMBER_BENEFITS, setMEMBER_BENEFITS] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    // CREATE STATES TO BE USED TO MANIPULATE AND STORE UP, STATES, LOCAL GOVERNEMNET AREA AND BRANCH DATAS
    const [MAIN_RESIDENCE_STATE, setMAIN_RESIDENCE_STATE] = useState("");
    // const [MAIN_RESIDENCE_STATE, setMAIN_RESIDENCE_STATE] = useState([]);
    const [MAIN_LGA, setMAIN_LGA] = useState([]);
    const [MAIN_LGA_BRANCHES, setMAIN_LGA_BRANCHES] = useState([]);
    const [UNITS, setUNITS] = useState([{ value: "", label: "" }]);
    const [STATE_OF_ORIGIN, setSTATE_OF_ORIGIN] = useState("");
    const [LGA_OF_ORIGIN, setLGA_OF_ORIGIN] = useState([]);
    const [TOWN, setTOWN] = useState("");
    const [DATE_OF_BIRTH, setDATE_OF_BIRTH] = useState(null);

    const FIRSTNAME_onChange = (event) => {         setFIRSTNAME(event.target.value);         };
    const MIDDLENAME_onChange = (event) => {         setMIDDLENAME(event.target.value);         };
    const LASTNAME_onChange = (event) => {         setLASTNAME(event.target.value);         };
    const STATUS_onChange = (event) => {         setSTATUS(event.target.value);         };
    const PHONE1_onChange = (event) => {         setPHONE1(event.target.value);         };
    const PHONE2_onChange = (event) => {         setPHONE2(event.target.value);         };
    const ADDRESS_onChange = (event) => {         setADDRESS(event.target.value);         };
    const ADDRESS2_onChange = (event) => {         setADDRESS2(event.target.value);         };
    const DATE_OF_BIRTH_onChange = (date) => {         setDATE_OF_BIRTH(date);         };
    // const MEMBER_BENEFITS_onChange = (event) => {         setMEMBER_BENEFITS(event.target.value);         };

    // const [decoy_FIRSTNAME, setdecoy_FIRSTNAME] = useState("");
    // const [decoy_MIDDLENAME, setdecoy_MIDDLENAME] = useState("");
    // const decoy_FIRSTNAME_onChange = (event) => {         setdecoy_FIRSTNAME(event.target.value);          setFIRSTNAME(decoy_FIRSTNAME+" "+decoy_MIDDLENAME);         };
    // const decoy_MIDDLENAME_onChange = (event) => {        setdecoy_MIDDLENAME(event.target.value);         setFIRSTNAME(decoy_FIRSTNAME+" "+decoy_MIDDLENAME);         };

    const selectedFile_onChange = (e) => {  
        // extract selected image file and store as an objcet
        const FILE_OBJCET = e.target.files[0];  
        // update the "selectedFile" state, which will inform our form submission that, user has selected an image. 
        setSelectedFile(FILE_OBJCET);
        // Also convert the selected image to base64 formate so we can display it on the preview, just immediately after image selection.
        setImgUrl(URL.createObjectURL(FILE_OBJCET));
    };

    // CREATE THE ONCHANGE METHOD, FOR THE STATE DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM STATE DROPDOWN
    const MAIN_RESIDENCE_STATE_onChange = (event) => {
        /* Here we collect the selected state data and store it inside the "new_home_state" variable.  */
        let new_home_state = event.target.value;
        // console.log(new_home_state);
        /* We update the "MAIN_RESIDENCE_STATE" of the new selection. */
        setMAIN_RESIDENCE_STATE( new_home_state ); 
        /* AFTER GETTING THE STATE SELECTED FROM THE STATE DROPDOWN MENU, HERE WE NOW CALL ON THE "STATE_LOCAL_GOVERMENT_OPERATOR" FUNCTION TO HELP 
         * US GET ALL THE LOCAL GOVERNMENTS UNDER THIS STATE AND THEN POPULATE OUR LGA DROPDOWN WITH CORRESPONDING DATAS EACH TIME A NEW STATE IS 
         * SELECTED FROM THE STATE DROPDOWN LIST.  */
        STATE_LOCAL_GOVERMENT_OPERATOR(new_home_state);
    };

    // CREATE THE ONCHANGE METHOD, FOR THE LGA DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM LGA DROPDOWN
    const MAIN_LGA_onChange = (event) => {
        /* Here we collect the selected lga data and store it inside the "new_lga" variable.  */
        let new_lga = event.target.value;
        // extract the label of the selected lga from the dropdown
        let lga_label = event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text
        // We update the "MAIN_LGA" state of the new selection.
        setMAIN_LGA( [{ value: new_lga, label: lga_label }] );

        /* AFTER GETTING THE LGA SELECTED FROM THE LGA DROPDOWN MENU, HERE WE NOW CALL ON THE "LGA_BRANCH_OPERATOR" FUNCTION TO HELP 
         * US GET ALL THE BRANCHES UNDER THIS LGA AND THEN POPULATE OUR BRANCH DROPDOWN WITH CORRESPONDING DATAS EACH TIME A NEW LGA IS 
         * SELECTED FROM THE LGA DROPDOWN LIST.  */
        LGA_BRANCH_OPERATOR(lga_label);
    };

    // CREATE THE ONCHANGE METHOD, FOR THE BRANCH DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM BRANCH DROPDOWN
    const MAIN_LGA_BRANCHES_onChange = (event) => {   
        /* Here we collect the selected BRANCH data and store it inside the "BRANCH" variable.  */
        let BRANCH_data = event.target.value;  
        // extract the label of the selected branch from the dropdown
        let branch_label = event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text
        // UPDATE BRANCH STATE. 
        setMAIN_LGA_BRANCHES( [{ value: BRANCH_data, label: branch_label }] );

        /* AFTER GETTING THE BRANCH SELECTED FROM THE BRANCH DROPDOWN MENU, HERE WE NOW CALL ON THE "BRANCH_TO_UNIT_OPERATOR" FUNCTION TO HELP 
         * US GET ALL THE UNITS UNDER THIS BRANCH AND THEN POPULATE OUR UNIT DROPDOWN WITH CORRESPONDING DATAS EACH TIME A NEW BRANCH IS 
         * SELECTED FROM THE BRANCH DROPDOWN LIST.  */
        BRANCH_TO_UNIT_OPERATOR(branch_label);
    };

    // CREATE THE ONCHANGE METHOD, FOR THE BRANCH DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM BRANCH DROPDOWN
    const UNITS_onChange = (event) => {   
        /* Here we collect the selected UNIT data and store it inside the "UNIT_data" variable.  */
        let UNIT_data = event.target.value;  
        // extract the label of the selected unit from the dropdown
        let unit_label = event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text
        // UPDATE UNIT. 
        setUNITS( [{ value: UNIT_data, label: unit_label }] );
    };
    


    /* WE USE TO FUNCTION TO CONTROL WHAT SHOULD HAPPEN WHEN USER SELECTS A STATE. THIS FUNCTION TAKES IN A PARAMETER WHICH IS THE STATE THAT WAS 
     * SELECTED TO HELP IT FETCH THE CORRESPONDING LOCAL GOVERNMENT AREAS FOR THAT STATE */
    const STATE_LOCAL_GOVERMENT_OPERATOR = (SECLECTED_STATE) => {
        /* Now we create a temporary initially empty arrays, the aim of creating this array is so that we get to use it as temporary container to 
         * store the local government areas of the selected state.  */
        let dataset = [];
        // set the default value for our LGA dropdown each time the state dropdown is tampered with.
        dataset[0] = { value: "", label: "Select LGA" };
        /* Also since we shall be storing datas inside array soon using a loop, its important that we create an integer variable to be used to 
         * properly formate the indexes or keys of our new array containers as we store datas in them, right inside the loop.  */
        let countez = 1;
        /* Here we use the "KalyaanNigerianStateLGA" function to create and fetch an object of all the states in nigeria and their 
         * respective local government areas, then we store them inside the "lgaList" variable. */
        const KalyaanLgaList = KalyaanNigerianStateLGA;

        // get the Code of the selected state and use it against the object of local government area, in a bid to get the corresponding lga's of 
        // current selected state.
        Object.entries(KalyaanLgaList[SECLECTED_STATE]).forEach(entries => {
            /* Now we extract the local government area data, and use it to create an array that we shall be storing in our temporary arrays, we use the "countez" variable to 
             * align the indexes of the temporary arrays.  */
            dataset[countez] = { value: entries[1], label: entries[0] };
            /* At the end of each loop cycle of the second loop, its important we increment this "countez" variable so that the job of properly formatting the indexes of our
             * two temporary arrays can be smooth. */
            countez++;
        });

        /* now that we have the local government area data of user selected state, its time we store this "compiled in array" datas inside a react state, but first we empty this react
         * state first at this junction.  */
        setMAIN_LGA([]);
        // Since this page is an update page, meaning default data will be preloaded into forms and dropdowns, and also if this function is activated
        // it only signifies that state dropdown has been tampered with, it only makes sense to set to default empty state, the branch dropdown.
        setMAIN_LGA_BRANCHES([{ value: "", label: "Select Branch" }]);
        setUNITS([{ value: "", label: "Select Unit" }]);
        /* after emptying the react state responsible for holding the list datas of user first and second prefered locations, we now store the array dataset innside the "dataset" 
         * vaiable in them so they can take a live effect of the location dropdown. Note that the state carrying the selected data of this two location dropdown form is different from
         * this state we updating here, because this state only store user first and second location dropdown datas.  */
        setMAIN_LGA(dataset);
    };



    /* WE USE TO FUNCTION TO CONTROL WHAT SHOULD HAPPEN WHEN USER SELECTS LGA. THIS FUNCTION TAKES IN A PARAMETER WHICH IS THE LGA THAT WAS 
     * SELECTED TO HELP IT FETCH THE CORRESPONDING BRANCHES FOR THAT LGA */
    const LGA_BRANCH_OPERATOR = (SECLECTED_LGA) => {
        /* Now we create a temporary initially empty arrays, the aim of creating this array is so that we get to use it as temporary container to 
         * store the branches of the selected lga.  */
        let dataset = [];
        // set the default value for our Branch dropdown each time the LGA dropdown is tampered with.
        dataset[0] = { value: "", label: "Select Branch" };
        /* Also since we shall be storing datas inside array soon using a loop, its important that we create an integer variable to be used to 
         * properly formate the indexes or keys of our new array containers as we store datas in them, right inside the loop.  */
        let countez = 1;
        /* Here we use the "KalyaanNigerianStateLGABranch" function to create and fetch an object of all the lga's in nigeria and 
         * their respective branche codes, then we store them inside the "branchList" variable. */
        const branchList = KalyaanNigerianStateLGABranch;

        // just incase some datas in the branch object does not correlate with that of user selected lga, we run this clause to avert future errors.
        if(branchList[SECLECTED_LGA] !== undefined){
            // get the Code of the selected state and use it against the object of local government area, in a bid to get the corresponding lga's of 
            // current selected state.
            Object.entries(branchList[SECLECTED_LGA]).forEach(entries => {
                /* Now we extract the local government area data, and use it to create an array that we shall be storing in our temporary arrays, we use the "countez" variable to 
                * align the indexes of the temporary arrays.  */
                dataset[countez] = { value: entries[0], label: entries[1] };
                /* At the end of each loop cycle of the second loop, its important we increment this "countez" variable so that the job of properly formatting the indexes of our
                * two temporary arrays can be smooth. */
                countez++;
            });
        }

        /* now that we have the branch data of user selected lga, its time we store this "compiled in array" datas inside a react state, but first we 
         * empty this react state first at this junction.  */
        setMAIN_LGA_BRANCHES([]);
        // Since this page is an update page, meaning default data will be preloaded into forms and dropdowns, and also if this function is activated
        // it only signifies that state dropdown has been tampered with, it only makes sense to set to default empty state, the unit dropdown.
        setUNITS([{ value: "", label: "Select Unit" }]);
        /* after emptying the react state responsible for holding the list datas, we now store the array dataset innside the "dataset" vaiable in them 
         * so they can take a live effect on the dropdown.  */
        setMAIN_LGA_BRANCHES(dataset);
    };






    /* WE USE TO FUNCTION TO CONTROL WHAT SHOULD HAPPEN WHEN USER SELECTS BRANCH. THIS FUNCTION TAKES IN A PARAMETER WHICH IS THE BRANCH THAT WAS 
     * SELECTED TO HELP IT FETCH THE CORRESPONDING UNITS FOR THAT BRANCH */
    const BRANCH_TO_UNIT_OPERATOR = (SECLECTED_BRANCH) => {
        // Now we create a temporary initially empty arrays, the aim of creating this array is so that we get to use it as temporary container to 
        // store the branches of the selected lga.  
        let dataset = [];
        // set the default value for our Branch dropdown each time the LGA dropdown is tampered with.
        dataset[0] = { value: "", label: "Select Unit" };
        // Also since we shall be storing datas inside array soon using a loop, its important that we create an integer variable to be used to 
        // properly formate the indexes or keys of our new array containers as we store datas in them, right inside the loop.
        let countez = 1;
        // Here we use the "KalyaanBranchUnits" function to create and fetch an object of all the Unit's in nigeria and 
        // their respective Unit Codes, then we store them inside the "branchList" variable.
        const unitLists = KalyaanBranchUnits;

        // just incase some datas in the branch object does not correlate with that of user selected lga, we run this clause to avert future errors.
        if(unitLists[SECLECTED_BRANCH] !== undefined){
            // get the Code of the selected state and use it against the object of local government area, in a bid to get the corresponding lga's of 
            // current selected state.
            Object.entries(unitLists[SECLECTED_BRANCH]).forEach(entries => {
                // Now we extract the local government area data, and use it to create an array that we shall be storing in our temporary arrays, we use the "countez" variable to 
                // align the indexes of the temporary arrays.
                dataset[countez] = { value: entries[1], label: entries[0] };
                // At the end of each loop cycle of the second loop, its important we increment this "countez" variable so that the job of properly 
                // formatting the indexes of our two temporary arrays can be smooth. 
                countez++;
            });
        }

        /* now that we have the branch data of user selected lga, its time we store this "compiled in array" datas inside a react state, but first we 
         * empty this react state first at this junction.  */
        setUNITS([]);
        /* after emptying the react state responsible for holding the list datas, we now store the array dataset innside the "dataset" vaiable in them 
         * so they can take a live effect on the dropdown.  */
        setUNITS(dataset);
    };







    const TOWN_onChange = (event) => {         setTOWN(event.target.value);         };

    // CREATE THE ONCHANGE METHOD, FOR THE STATE DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM STATE DROPDOWN
    const STATE_OF_ORIGIN_onChange = (event) => {
        /* Here we collect the selected state data and store it inside the "new_home_state" variable.  */
        let new_home_state = event.target.value;
        /* We update the "STATE" of the new selection. */
        setSTATE_OF_ORIGIN( new_home_state ); 
        /* AFTER GETTING THE STATE SELECTED FROM THE STATE DROPDOWN MENU, HERE WE NOW CALL ON THE "STATE_LOCAL_GOVERMENT_OPERATOR_____2" FUNCTION TO HELP 
         * US GET ALL THE LOCAL GOVERNMENTS UNDER THIS STATE AND THEN POPULATE OUR LGA DROPDOWN WITH CORRESPONDING DATAS EACH TIME A NEW STATE IS 
         * SELECTED FROM THE STATE DROPDOWN LIST.  */
        STATE_LOCAL_GOVERMENT_OPERATOR_____2(new_home_state);
    };

    // CREATE THE ONCHANGE METHOD, FOR THE LGA DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM LGA DROPDOWN
    const LGA_OF_ORIGIN_onChange = (event) => {
        /* Here we collect the selected lga data and store it inside the "new_lga" variable.  */
        let new_lga = event.target.value;
        // extract the label of the selected lga from the dropdown
        let lga_label = event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text
        /* We update the "LGA" state of the new selection. We do this update in such a way that any data already in this state stays. */
        // if(LGA.length > 1) setLGA( [{ value: new_lga, label: new_lga }].concat(LGA) );
        // We update the "LGA" state of the new selection.
        setLGA_OF_ORIGIN( [{ value: new_lga, label: lga_label }] );
    };

    /* WE USE TO FUNCTION TO CONTROL WHAT SHOULD HAPPEN WHEN USER SELECTS A STATE. THIS FUNCTION TAKES IN A PARAMETER WHICH IS THE STATE THAT WAS 
     * SELECTED TO HELP IT FETCH THE CORRESPONDING LOCAL GOVERNMENT AREAS FOR THAT STATE */
    const STATE_LOCAL_GOVERMENT_OPERATOR_____2 = (SECLECTED_STATE) => {
        /* Now we create a temporary initially empty arrays, the aim of creating this array is so that we get to use it as temporary container to 
         * store the local government areas of the selected state.  */
        let dataset = [];
        // set the default value for our LGA dropdown each time the state dropdown is tampered with.
        dataset[0] = { value: "", label: "Select LGA of Origin" };
        /* Also since we shall be storing datas inside array soon using a loop, its important that we create an integer variable to be used to 
         * properly formate the indexes or keys of our new array containers as we store datas in them, right inside the loop.  */
        let countez = 1;
        /* Here we use the "KalyaanNigerianStateLGA" function to create and fetch an object of all the states in nigeria and their 
         * respective local government areas, then we store them inside the "lgaList" variable. */
        const KalyaanLgaList = KalyaanNigerianStateLGA;

        // get the Code of the selected state and use it against the object of local government area, in a bid to get the corresponding lga's of 
        // current selected state.
        Object.entries(KalyaanLgaList[SECLECTED_STATE]).forEach(entries => {
            /* Now we extract the local government area data, and use it to create an array that we shall be storing in our temporary arrays, we use the "countez" variable to 
             * align the indexes of the temporary arrays.  */
            dataset[countez] = { value: entries[1], label: entries[0] };
            /* At the end of each loop cycle of the second loop, its important we increment this "countez" variable so that the job of properly formatting the indexes of our
             * two temporary arrays can be smooth. */
            countez++;
        });

        /* now that we have the local government area data of user selected state, its time we store this "compiled in array" datas inside a react state, but first we empty this react
         * state first at this junction.  */
        setLGA_OF_ORIGIN([]);
        /* after emptying the react state responsible for holding the list datas of user first and second prefered locations, we now store the array dataset innside the "dataset" 
         * vaiable in them so they can take a live effect of the location dropdown. Note that the state carrying the selected data of this two location dropdown form is different from
         * this state we updating here, because this state only store user first and second location dropdown datas.  */
        setLGA_OF_ORIGIN(dataset);
    };






    
    // construct api url to load up image
    const imageUrl = configuration.MEMBER_BINARY_IMAGE_FETCHER_API+id;
    // FUNCTION TO LOAD IMAGE FROM BACKEND AS BINARY, PROCESS AND CONVERT IT TO IMAGE
    const getImg = async () => {
        const response = await fetch(imageUrl, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Accept': 'image/png',
                'Authorization': `Bearer ${session}`,
                'Content-Type': 'image/png'     // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }).catch(error => {
            // history.push(configuration.LOGIN);
            openModal("Network Error, when attempting to save data. Please ensure you have a good internet connection.");
        });

        // ensure that we have an image data response and that fetch operation has not failed as a result of network failure before proceeding to this part
        if(response){
            const imageBlob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(imageBlob);
            reader.onloadend = () => {
                const base64data = reader.result;
                setImgUrl(base64data);
            };
        }
    };


    // STATE TO BE USED TO STORE DATA'S OF THE MEMBER, WE WANT TO PRINT ID-CARD FOR
    const [MEMBER_DATA, setMEMBER_DATA] = useState({});
    const [MEMBER_NEXT_OF_KIN_DATA, setMEMBER_NEXT_OF_KIN_DATA] = useState([]);
    const [MEMBER_BENEFICIARY_DATA, setMEMBER_BENEFICIARY_DATA] = useState([]);

    //
    useEffect(() => {
        // ensure page is scrolled to the top by default
        scrollToTop();

        // ONLY ALLOW API TO RUN IF THE "ALL_MEMBER_DATA" STATE IS EMPTY.
        if(TRIM(id) !== ""){
            /* call the api function responsible for user password reset operation. */
            const url_1 = configuration.SORT_MEMBER_BY_ID_API+id;
            
            /* Call the "postData" function to help us handle submission. */
            getData(url_1, session).then(response => {
                // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
                if(response.mbrno){
                    // CONTROL IN HERE MEANS REQUEST WAS A SUCCESS, HENCE WE FETCH DATA AND SEND TO PARENT COMPONENT
                    setMEMBER_DATA(response);
                    // UPDATE MEMBER NEXT-OF-KIN STATE, SO THAT WE CAN HAVE DATA TO PRINT INSIDE THE BENEFICIARY TABLE.
                    setMEMBER_NEXT_OF_KIN_DATA(response.nextOfKinEntity);
                    // UPDATE MEMBER BENEFICIARY STATE, SO THAT WE CAN HAVE DATA TO PRINT INSIDE THE BENEFICIARY TABLE.
                    setMEMBER_BENEFICIARY_DATA(response.beneficiaryEntity);
                    // set states storing user personal datas
                    // setFIRSTNAME(response.mbrfname);
                    setFIRSTNAME(EXPLODE(" ", response.mbrfname)[0]);
                    setMIDDLENAME(EXPLODE(" ", response.mbrfname)[1]);
                    setLASTNAME(response.mbrsname);
                    setSTATUS(response.mbr_STATUS);
                    setPHONE1(response.mbr_TEL_NO1);
                    setPHONE2(response.mbr_TEL_NO2);
                    setADDRESS(response.mbr_ADDR_LN1);
                    setADDRESS2(response.mbr_ADDR_LN2);
                    // console.log(DATE_CONFIRMER(response.mbr_DOB));
                    // console.log(new Date(DATE_CONFIRMER(response.mbr_DOB)));
                    // console.log(new Date(DATE_CONFIRMER(response.mbr_DOB)).toLocaleString('en-US', { timeZone: 'Europe/London' }));
                    setDATE_OF_BIRTH(new Date(DATE_CONFIRMER(response.mbr_DOB)));
                    setSTATE_OF_ORIGIN(response.stateOfOrigin);
                    setTOWN(EXPLODE("@#@", response.hometown)[0]);
                    setLGA_OF_ORIGIN([{ value: response.localGovtOfOrigin, label: response.localGovtOfOrigin }]);
                    // System of storing state, lga and branch is quite different so we perform that here  INARRAY
                    // setMAIN_RESIDENCE_STATE(response.mbr_STATE);
                    // setMAIN_RESIDENCE_STATE(KalyaanNigerianStates[CAPITALIZE(response.mbr_STATE)]);
                    // const encoded_state = OBJECT_SEARCHER(KalyaanNigerianStates, response.mbr_STATE)['key'];
                    setMAIN_RESIDENCE_STATE(KalyaanNigerianStates[response.mbr_STATE]);
                    // const decoded_state_code = KalyaanNigerianStates[response.mbr_STATE];
                    // setMAIN_RESIDENCE_STATE([{ value: response.mbr_STATE, label: decoded_state_code }]);
                    // setMAIN_LGA([{ value: response.mbr_LGA, label: response.mbr_LGA }]);
                    const decoded_lga = OBJECT_SEARCHER(KalyaanNigerianStateLGA[response.mbr_STATE], CAPITALIZE(response.mbr_LGA))['key'];
                    setMAIN_LGA([{ value: response.mbr_LGA, label: decoded_lga }]);
                    const decoded_branch = KalyaanNigerianStateLGABranch[decoded_lga][CAPITALIZE(response.mbr_BRANCH)];
                    setMAIN_LGA_BRANCHES([{ value: response.mbr_BRANCH, label: decoded_branch }]);
                    // const extracted_unit_code = (countWords(response.hometown, "@#@") > 1) ? EXPLODE("@#@", response.hometown)[1] : "";
                    // const decoded_unit_name = OBJECT_SEARCHER(KalyaanBranchUnits[decoded_branch], extracted_unit_code)['key'];
                    const extracted_unit_code = response.unit;
                    const decoded_unit_name = OBJECT_SEARCHER(KalyaanBranchUnits[decoded_branch], extracted_unit_code)['key'];
                    // const decoded_unit_name = (countWords(response.hometown, "@#@") > 1) ? EXPLODE("@#@", response.hometown)[1] : "";
                    // const extracted_unit_code = KalyaanBranchUnits[decoded_branch][decoded_unit_name];
                    // console.log(decoded_unit_name+" "+extracted_unit_code+" gud");
                    setUNITS([{ value: extracted_unit_code, label: decoded_unit_name }]);

                    // setdecoy_FIRSTNAME(EXPLODE(" ", response.mbrfname)[0]);
                    // setdecoy_MIDDLENAME(EXPLODE(" ", response.mbrfname)[1]);
                    // LOAD IMAGE FROM BACKEND AS BINARY, PROCESS AND CONVERT IT TO IMAGE
                    getImg();
                }else{
                    // display server error message
                    openModal("System encountered an internal error while trying to load data.");
                }
            }).catch(error => {
                // At this junction we are certain that request submission has failed because of a network error, therefore we send out network 
                // failure message
                openModal("Network Error, when attempting to save data. Please ensure you have a good internet connection.");
            });
        }

    }, []);





    // MEMBER_DATA
    const Submit_Updates = (e) => {
        /* line of code to ensure that html form default submit process is disabled, so that form submission can be handled by javascript in the REACT way.  */
        e.preventDefault();

        // process the inpute date of brith data, into a reliable format
        const processed_date = REACT_DATEPICKER_DATE_FORMATTER(DATE_OF_BIRTH);
        /* collect all data from their official states storage and compile them into an object, in a bid to prepare the datas for authentication 
         * before we can proceed to submitting data. */
        const RAW_DATA = { 
            MBR_WELFARE_PKG: MEMBER_DATA.mbr_WELFARE_PKG, MBR_DOB: processed_date, MBR_GENDER: MEMBER_DATA.mbr_GENDER, 
            MBR_CITY: MEMBER_DATA.mbr_CITY, MBR_VEHICLE_NO: MEMBER_DATA.mbr_VEHICLE_NO, 
            MBR_VEHICLE_NO2: MEMBER_DATA.mbr_VEHICLE_NO2, RIDERS_CARD_NO: MEMBER_DATA.riders_CARD_NO, MBR_NUM_BEN: MEMBER_DATA.mbr_NUM_BEN,
            
            MBRFNAME: FIRSTNAME+" "+MIDDLENAME, MBRSNAME: LASTNAME, MBR_STATUS: STATUS, MBR_TEL_NO1: PHONE1, 
            MBR_TEL_NO2: PHONE2, MBR_ADDR_LN1: ADDRESS, MBR_ADDR_LN2: ADDRESS2, MBR_STATE: MAIN_RESIDENCE_STATE, 
            MBR_LGA: MAIN_LGA[0].value, MBR_BRANCH: MAIN_LGA_BRANCHES[0].value, IMAGE_FILE_OBJECT: selectedFile
        };
        /* Here we launch the "RAW_DATA_AUTHENTICATOR" function to help us validate the authenticity of all the datas user has provided. */
        const AUTHENTICATOR_DATA = MEMBER_REGISTRATION_DATA_AUTHENTICATOR(RAW_DATA);
        /* Before any major registration operations can be permitted to begin, firstly the "REGISTRATION_DATA_AUTHENTICATOR_2" function must first give clear indications that the data
         * provided by user is valid. So at this junction we test to see if we have clearance to register this user.  */
        if(AUTHENTICATOR_DATA === true){
            /* Here we signal the button state change react-state, to indicate that button is clicked and request sent. */
            setBUTTON_LOADING_ANIM(true);
            // Build formData object.
            let formData = new FormData();
            /* collect all data from their official states storage and compile them into an object, in a bid to prepare the datas for form submission. */
            formData.append('MBR_WELFARE_PKG', MEMBER_DATA.mbr_WELFARE_PKG);
            // formData.append('MBR_DOB', MEMBER_DATA.mbr_DOB);
            formData.append('MBR_DOB', processed_date);
            formData.append('MBR_GENDER', MEMBER_DATA.mbr_GENDER);
            // formData.append('MBR_ADDR_LN2', MEMBER_DATA.mbr_ADDR_LN2);
            formData.append('MBR_CITY', MEMBER_DATA.mbr_CITY);
            formData.append('MBR_VEHICLE_NO', MEMBER_DATA.mbr_VEHICLE_NO);
            formData.append('MBR_VEHICLE_NO2', MEMBER_DATA.mbr_VEHICLE_NO2);
            formData.append('RIDERS_CARD_NO', MEMBER_DATA.riders_CARD_NO);
            // formData.append('MBR_BENEFIT_PYMT', MEMBER_BENEFITS);
            formData.append('MBR_NUM_BEN', MEMBER_DATA.mbr_NUM_BEN);
            formData.append('MBRFNAME', FIRSTNAME+" "+MIDDLENAME);
            formData.append('MBRSNAME', LASTNAME);
            formData.append('MBR_STATUS', STATUS);
            formData.append('MBR_TEL_NO1', PHONE1);
            formData.append('MBR_TEL_NO2', PHONE2);
            formData.append('MBR_ADDR_LN1', ADDRESS);
            formData.append('MBR_ADDR_LN2', ADDRESS2);
            // formData.append('MBR_STATE', MAIN_RESIDENCE_STATE);
            // formData.append('MBR_LGA', MAIN_LGA[0].value);
            // formData.append('MBR_BRANCH', MAIN_LGA_BRANCHES[0].value);

            // formData.append('MBR_STATE', FIRST_FEW_CHARS_IN_STRING_FETCHER(MAIN_RESIDENCE_STATE, 2));
            // formData.append('MBR_LGA', FIRST_FEW_CHARS_IN_STRING_FETCHER(MAIN_LGA[0].value, 3));
            // formData.append('MBR_BRANCH', MAIN_LGA_BRANCHES[0].value);

            // formData.append('stateOfOrigin', FIRST_FEW_CHARS_IN_STRING_FETCHER(STATE_OF_ORIGIN, 3));
            // formData.append('localGovtOfOrigin', FIRST_FEW_CHARS_IN_STRING_FETCHER(LGA_OF_ORIGIN[0].value, 3));
            // formData.append('hometown', TOWN);

            // const encoded_state = OBJECT_SEARCHER(KalyaanNigerianStates, MAIN_RESIDENCE_STATE)['key'];
            // console.log(encoded_state+" === "+MAIN_RESIDENCE_STATE);
            formData.append('MBR_STATE', (STRING_CHARS_COUNT(MAIN_RESIDENCE_STATE) > 2) ? OBJECT_SEARCHER(KalyaanNigerianStates, MAIN_RESIDENCE_STATE)['key'] : MAIN_RESIDENCE_STATE);
            formData.append('MBR_LGA', MAIN_LGA[0].value);
            formData.append('MBR_BRANCH', MAIN_LGA_BRANCHES[0].value);
            formData.append('unit', UNITS[0].value);
            // console.log(UNITS[0].value);
            formData.append('stateOfOrigin', STATE_OF_ORIGIN);
            formData.append('localGovtOfOrigin', LGA_OF_ORIGIN[0].value);
            formData.append('hometown', TOWN);
            // formData.append("MBR_PHOTO", selectedFile);
            /* call the api function responsible for handling the profile updating job. */
            const url = configuration.MEMBER_DATA_UPDATE_API+id;
            /* Call the "FORMDATA_POSTDATA" function to help us handle submission. */
            FORMDATA_POSTDATA(url, formData, session).then(response => {
                // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
                if(response.status === 200){
                    // display success message
                    openModal("Member datas updated successfully.");
                }else{
                    // display server error message
                    openModal("System encountered an internal error while trying to submit your data.");
                }

                // Here we signal the button state change react-state, to indicate that request has been completed. 
                setBUTTON_LOADING_ANIM(false);
            }).catch(error => {
                // At this junction we are certain that request submission has failed because of a network error, therefore we send out network 
                // failure message
                openModal("Network Error, when attempting to save data. Please ensure you have a good internet connection.");
                // Here we signal the button state change react-state, to indicate that request has been completed. 
                setBUTTON_LOADING_ANIM(false);
            });
            
        }else{
            // control getting in here signifies that the authentication of user supplied profile data has failed for one reason or the 
            // other, further meaning user did not fill one or more form field well, therefore we initialize our alert container to tell user 
            // what he or she is doing wrong. 
            openModal(AUTHENTICATOR_DATA);
        }
    };









    const Delete_Account = (e) => {
        /* line of code to ensure that html form default submit process is disabled, so that form submission can be handled by javascript in the REACT way.  */
        e.preventDefault();

        /* Here we signal the button state change react-state, to indicate that button is clicked and request sent. */
        setBUTTON_LOADING_ANIM2(true);
        /* call the api function responsible for handling the profile updating job. */
        const url = configuration.MEMBER_DATA_DELETE_API+id;
        /* Call the "deleteData" function to help us handle submission. */
        deleteData(url, session);
        // display success message
        alert("Member account deleted successfully.");
        // revert user back to member page
        history.push(configuration.MEMBER_RECORDS_LINK);
    }






    // WE UPDATE THE PARENT COMPONENT TO REMIND IT THAT THE "ID_CARD" MENU IS WHAT IS CURRENTLY ACTIVE, WE DO THIS JUST SO THAT THE SIDEBAR
    // ACTIVE-MENU-SELECTOR DOES NOT GO OUT OF PLACE EACH TIME THE FULL PAGE IS RELOADED.
    DEFAULT_MENU_FEEDBACK("MEMBER_RECORDS");
    DEFAULT_SUB_MENU_FEEDBACK("");
    // console.log(MAIN_RESIDENCE_STATE);
    // if(MenuController === "MEMBER_RECORDS"){

        return (
            
            <>
                <div className="div-block-35" style={{ marginBottom: "0%", marginTop: "2%" }}>
                    <h1></h1>
                    <Link to={configuration.ACTIVE_MEMBERSHIPS_LINK} className="realbuttons w-button" style={{ marginBottom: "0" }}>Back</Link>
                    {/* <Link to={configuration.ACTIVE_MEMBERSHIPS_LINK} className="button_outline filled goldenrod id new w-button">Back</Link> */}
                </div>

                <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                    <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
                </Modal>
                
                <form onSubmit={Submit_Updates} method="put">
                    <div className="register_staff_container" style={{ height: "870px" }}>
                        <div className="div-block-20" style={{ marginTop: "4.5%" }}>
                            <div className="profile_pic_container">
                                <img src={imgUrl} loading="lazy" width="250" height="250" onClick={() => document.getElementById('memberImageFile').click()} 
                                    alt={(FIRSTNAME+" "+LASTNAME)+" image"} />
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                                <input type="button" onClick={() => document.getElementById('memberImageFile').click()} 
                                    className="realbuttons w-button" value="Upload Image" />
                                <input type='file' id="memberImageFile" onChange={selectedFile_onChange} style={{ display: "none" }} />
                                {/* <button onClick={() => document.getElementById('memberImageFile').click()} className="realbuttons w-button">Uploade Image</button> */}
                            </div>
                        </div>

                        <div className="div-block-20" style={{ marginLeft: "10%" }}></div>

                        <div className="div-block-18">
                            <div className="div-block-11" style={{ marginBottom: "0%" }}>
                                <div className="data_container">
                                    <div className="div-block-21">
                                        {/* <h1 className="title_header" style={{ textTransform: "uppercase"  }}>{((MEMBER_DATA.mbrfname) ? MEMBER_DATA.mbrfname+" "+MEMBER_DATA.mbrsname : "")}</h1> */}
                                        <h1 className="title_header" style={{ textTransform: "uppercase", fontSize: "25px", marginBottom: "3%"  }}>EDIT MEMBER DATA</h1>
                                        {/* <DECEASED_TAG STATUS={MEMBER_DATA.mbr_STATUS} /> */}
                                    </div>
                                    <div name="email-form-3" data-name="Email Form 3">
                                        <div className="w-form">
                                            <div id="email-form-3" className="form-4">
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> First Name </small> 
                                                <input onChange={FIRSTNAME_onChange} type="text" className="data_capture w-input" minlength="3" maxlength="40" name="name-2" 
                                                    data-name="Name 2" placeholder="First Name" id="name-2" value={FIRSTNAME} required /></label>
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> Middle Name </small> 
                                                <input onChange={MIDDLENAME_onChange} type="text" className="data_capture w-input" minlength="3" maxlength="40" name="name-2" 
                                                    data-name="Name 2" placeholder="Middle Name" id="name-2" value={MIDDLENAME} /></label>
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> Surname </small> 
                                                <input onChange={LASTNAME_onChange} type="text" className="data_capture w-input" minlength="3" maxlength="40" name="email-2" 
                                                    data-name="Email 2" placeholder="Last Name" id="email-2" value={LASTNAME} required /></label>
                                            </div>
                                        </div>
                                        <div className="w-form">
                                            <div id="email-form-3" className="form-4">
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> Status </small> 
                                                    <select onChange={STATUS_onChange} id="field-3" name="field-3" data-name="Field 3" className="data_capture w-select" required>
                                                        <option value={STATUS}>{(STATUS === "alive") ? "Active" : STATUS}</option>
                                                        <option value="alive">Active</option>
                                                        <option value="Suspended">Suspended</option>
                                                        <option value="Expelled">Expelled</option>
                                                        <option value="Deceased">Deceased</option>
                                                    </select>
                                                </label>
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> Phone 1 </small> 
                                                <input onChange={PHONE1_onChange} type="tel" pattern="[0-9]{11}" placeholder="0000-000-0000" className="data_capture w-input" 
                                                    minlength="11" maxlength="15" name="name-2" data-name="Name 2" id="tel1" value={PHONE1} required /></label>
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> Phone 2 </small> 
                                                <input onChange={PHONE2_onChange} type="tel" pattern="[0-9]{11}" placeholder="0000-000-0000" className="data_capture w-input" 
                                                    minlength="11" maxlength="15" name="email-2" data-name="Email 2" id="tel2" value={PHONE2} /></label>
                                            </div>
                                        </div>
                                        <div className="w-form" style={{ marginBottom: "0", marginLeft: "5px" }}>
                                            <label style={{ color: "#777" }}> <small> Date of Birth </small> </label>
                                            <DatePicker className="my-text-field w-input" selected={DATE_OF_BIRTH} dateFormat="dd-MMM-yyyy" 
                                                onChange={DATE_OF_BIRTH_onChange} />
                                        </div>
                                        <div className="w-form" style={{ marginTop: "0" }}>
                                            <div id="email-form-3" className="form-4">
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> Address Line 1 </small> 
                                                <input onChange={ADDRESS_onChange} type="text" className="data_capture w-input" minlength="3" maxlength="40" name="name-2" 
                                                    data-name="Name 2" placeholder="No 9 Gbala st Maitama, Abuja." id="address1" value={ADDRESS} style={{ width: "620px" }} 
                                                    required  /></label>
                                            </div>
                                        </div>
                                        <div className="w-form">
                                            <div id="email-form-3" className="form-4">
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> Address Line 2 </small> 
                                                <input onChange={ADDRESS2_onChange} type="text" className="data_capture w-input" minlength="3" maxlength="40" name="name-2" 
                                                    data-name="Name 2" placeholder="No 9 Gbala st Maitama, Abuja." id="address1" value={ADDRESS2} style={{ width: "620px" }} 
                                                /></label>
                                            </div>
                                        </div>
                                        <div className="w-form">
                                            <div id="email-form-3" className="form-4">
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> State </small> 
                                                    <select id="field-3" name="field-3" data-name="Field 3" className="data_capture w-select" 
                                                        onChange={MAIN_RESIDENCE_STATE_onChange} required>
                                                        <option value={MAIN_RESIDENCE_STATE}>{MAIN_RESIDENCE_STATE}</option>
                                                        {OBJECT_TO_ARRAY(KalyaanNigerianStates, "BOTH").map((infos, index) => {
                                                            return <option value={infos[0]}>{infos[1]}</option>;
                                                        })}
                                                    </select>
                                                </label>
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> LGA </small> 
                                                    <select id="field-3" name="field-3" data-name="Field 3" className="data_capture w-select" onChange={MAIN_LGA_onChange} required>
                                                        {MAIN_LGA.map((infos, index) => {
                                                            return <option value={infos.value}>{(infos.label)}</option>;
                                                        })}
                                                    </select>
                                                </label>
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> Branch </small> 
                                                    <select id="field-3" name="field-3" data-name="Field 3" className="data_capture w-select" onChange={MAIN_LGA_BRANCHES_onChange} required>
                                                        {MAIN_LGA_BRANCHES.map((infos, index) => {
                                                            return <option value={infos.value}>{(infos.label)}</option>;
                                                        })}
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w-form">
                                            <div id="email-form-3" className="form-4">
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> Unit </small>
                                                    <select id="field-3" name="field-3" data-name="Field 3" className="data_capture w-select" 
                                                        onChange={UNITS_onChange} required>
                                                        {UNITS.map((infos, index) => {
                                                            return <option value={infos.value}>{(infos.label)}</option>;
                                                        })}
                                                    </select>
                                                    {/* <select id="field-3" name="field-3" data-name="Field 3" className="data_capture w-select" required>
                                                        <option>{"UNITS"}</option>
                                                        {UNITS.map((infos, index) => {
                                                            return <option value={infos.value}>{CAPITALIZE_FIRST_LETTERS(infos.label)}</option>;
                                                        })}
                                                    </select> */}
                                                </label>
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> State of Origin </small> 
                                                    <select id="field-3" name="field-3" data-name="Field 3" className="data_capture w-select" onChange={STATE_OF_ORIGIN_onChange} required>
                                                        <option value={STATE_OF_ORIGIN}>{STATE_OF_ORIGIN}</option>
                                                        {OBJECT_TO_ARRAY(KalyaanNigerianStates, "BOTH").map((infos, index) => {
                                                            return <option value={infos[0]}>{infos[1]}</option>;
                                                        })}
                                                    </select>
                                                </label>
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> LGA of Origin </small> 
                                                    <select id="field-3" name="field-3" data-name="Field 3" className="data_capture w-select" onChange={LGA_OF_ORIGIN_onChange} required>
                                                        {LGA_OF_ORIGIN.map((infos, index) => {
                                                            return <option value={infos.value}>{CAPITALIZE_FIRST_LETTERS(infos.label)}</option>;
                                                        })}
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w-form">
                                            <div id="email-form-3" className="form-4">
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> Hometown </small> 
                                                <input onChange={TOWN_onChange} type="text" className="data_capture w-input" minlength="3" maxlength="40" name="name-2" 
                                                    data-name="Name 2" placeholder="Hometown" id="address1" value={TOWN} style={{ width: "620px" }} 
                                                    required  /></label>
                                            </div>
                                        </div>
                                        <div className="w-form" style={{ visibility: "hidden" }}>
                                            <div id="email-form-3" className="form-4">
                                                <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> Benefits </small> 
                                                    {/* <select onChange={MEMBER_BENEFITS_onChange} id="field-3" name="field-3" data-name="Field 3" 
                                                        className="my-text-field short w-select" style={{ marginLeft: "5px" }} >
                                                        <option value={MEMBER_BENEFITS}>{(MEMBER_BENEFITS === "O" ? "One-time pay-out" : "Variable pay-out")}</option>
                                                        <option value="O">One-time pay-out</option>
                                                        <option value="s">Variable pay-out</option>
                                                    </select> */}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="div-block-15" style={{ marginTop: "-3%", marginLeft: "11%", width: "78%" }}>
                                {(BUTTON_LOADING_ANIM === true) ? ( <button type="submit" className="button_outline w-button">Updating.......</button> ) : (
                                    <button type="submit" className="button_outline filled w-button">Update Member</button> )}
                                {(BUTTON_LOADING_ANIM2 === true) ? ( <button onClick={Delete_Account} className="button_outline delete w-button">Deleting......</button>
                                ) : ( <button onClick={Delete_Account} className="button_outline filled delete w-button">Delete Member</button> )}
                                <Link to={configuration.ADD_MEMBER_BENEFICIARY_LINK+id} className="button_outline filled add w-button">Add Beneficiary</Link>
                            </div>
                        </div>
                    </div>
                </form>










                
                <div className="dependant-holder">
                    <h1 className="title_header" style={{ marginBottom: "1%" }}>Member&#x27;s Next of Kin</h1>
                    <div className="div-block-22 dependant">
                        <a href="#" className="table_data_holder dependant w-inline-block">
                            <div className="table_data header">Full Name</div>
                            <div className="table_data header">Relationship</div>
                            <div className="table_data header">Phone Number</div>
                            <div className="table_data header">Gender</div>
                        </a>
                    </div>

                    <div className="data_row dependant">
                        <Link to={configuration.EDIT_MEMBER_NEXT_OF_KIN_LINK+id} className="table_data_holder dependant w-inline-block">
                            <div className="table_data">{ALL_LETTERS_CAPITALIZED(MEMBER_NEXT_OF_KIN_DATA.mbr_NEXT_KIN_SNAME)+" "+CAPITALIZE_FIRST_LETTERS(MEMBER_NEXT_OF_KIN_DATA.mbr_NEXT_KIN_FNAME)}</div>
                            <div className="table_data" style={{ textTransform: "capitalize"  }}>{MEMBER_NEXT_OF_KIN_DATA.mbr_NEXT_KIN_REL}</div>
                            <div className="table_data">{NIGERIAN_NUMBER_FORMATTER(MEMBER_NEXT_OF_KIN_DATA.mbr_NEXT_KIN_TEL_NO1)}</div>
                            <div className="table_data" style={{ textTransform: "capitalize"  }}>{RELATIONSHIP_TO_GENDER_PARSER(MEMBER_NEXT_OF_KIN_DATA.mbr_NEXT_KIN_REL)}</div>
                        </Link>
                    </div>
                </div>












                {/* THERE IS A COMPONENT TO PRINT US BENEFICIARY TABLE, BUT ON THIS PAGE WE OPT NOT TO USE IT. WE USING THIS DIRECTLY SO THAT WHEN
                USER ON THIS PAGE CLICKS A BENEFICIARY, REACT ROUTER WILL SEND THE TO THE BENEFICIARY EDIT PAGE WITHOUT RELOADING THIS PAGE. BUT ONCE
                USER IS ON EITHER BENEFICIARY EDIT PAGE OR BENEFICIARY ADD PAGE, WE LOAD THAT EXTERNAL COMPONENT INSTEAD. ON THE EXTERNAL COMPONENT, 
                WHEN A BENEFICIARY IS CLICKED, THE WHOLE PAGE RELOADS AND LOADS IN THE EDITOR FOR THE NEWLY SELECTED BENEFICIARY. THIS IS WHY WE SET 
                THINGS UP THIS WAY, BECAUSE IF WE USE ONE COMPONENT THROUGHOUT AND RELY ON REACT ROUTER, THE USEEFFECT FUNCTION FOR THE OTHER PAGE WONT
                TRIGGER, HENCE THE PAGE DEFAULT CONTENTS WONT LOAD. */}
                <div className="dependant-holder" style={{ marginBottom: "10%" }}>
                    <h1 className="title_header" style={{ marginBottom: "1%" }}>Member&#x27;s Beneficiaries List</h1>
                    <div className="div-block-22 dependant">
                        <a href="#" className="table_data_holder dependant w-inline-block">
                            <div className="table_data header">Full Name</div>
                            <div className="table_data header">Relationship</div>
                            <div className="table_data header">Phone Number</div>
                            <div className="table_data header">Gender</div>
                        </a>
                        <div className="table_data header">Status</div>
                    </div>

                    {MEMBER_BENEFICIARY_DATA.map((infos, index) => {
                        // print out view
                        return ( 
                            <div className="data_row dependant">
                                <Link to={configuration.EDIT_MEMBER_BENEFICIARY_LINK+id+"/"+infos.id} className="table_data_holder dependant w-inline-block">
                                    <div className="table_data">{ALL_LETTERS_CAPITALIZED(infos.ben_SNAME)+" "+CAPITALIZE_FIRST_LETTERS(infos.ben_FNAME)}</div>
                                    <div className="table_data" style={{ textTransform: "capitalize"  }}>{infos.ben_REL}</div>
                                    <div className="table_data">{NIGERIAN_NUMBER_FORMATTER(infos.ben_TEL_NO1)}</div>
                                    <div className="table_data" style={{ textTransform: "capitalize"  }}>{infos.ben_GENDER}</div>
                                </Link>
                                <div className="div-block-25 dependant">
                                    <p className={"status table"+((infos.ben_STATUS === "alive") ? " active" : "")}>
                                        {CAPITALIZE_FIRST_LETTERS( ((infos.ben_STATUS === "alive") ? "Active" : "Inactive") )}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>

            </>
        );

    // }else{
    //     return null;
    // }

}











const DECEASED_TAG = ({STATUS}) => {
    if(STATUS !== "alive"){
        return <p className="status" style={{ display: "block" }}>Inactive</p>;
    }else{
        return null;
    }
}



