
// REACT STANDARD LIBRARY CALLS
import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import Modal from 'react-modal';

/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';

/* IMPORT COMPONENTS. */
import BeneficiaryList from '../../../Components/BeneficiaryList';

/* IMPORT LIBRARY  */
import { putData, getData, deleteData, getCookie, TRIM, scrollToTop } from '../../../Library/Library.js';
import { BENEFICIARY_REGISTRATION_DATA_AUTHENTICATOR } from '../../../Library/REG_LIBRARY.js';





// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






// export default function UPDATE_BENEFICIARY({MenuController, DEFAULT_MENU_FEEDBACK}) {
export default function UPDATE_BENEFICIARY({MenuController, DEFAULT_MENU_FEEDBACK, PAGE_TITLE_DATAFEEDBACK, DEFAULT_SUB_MENU, DEFAULT_SUB_MENU_FEEDBACK}) {

    // SET THE PAGE TITLE FOR THIS DASHBOAD SEMI-PAGE
    PAGE_TITLE_DATAFEEDBACK("Edit Beneficiary | "+configuration.COMPANY_NAME);
    // GET FROM URL THE ID OF THE MEMBER WE WANT TO EDIT HIS/HER DATA
    const { id, ben_id } = useParams();
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);}
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");           }
    function closeModalRedirect(CALLBACK){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");     CALLBACK();          }


    // CREATE STATE TO BE USED TO CONTROL BUTTON LOADING ANIMATION, FOR THIS COMPONENT.
    const [BUTTON_LOADING_ANIM, setBUTTON_LOADING_ANIM] = useState(false);
    const [BUTTON_LOADING_ANIM2, setBUTTON_LOADING_ANIM2] = useState(false);
    // CREATE FORM STATES HERE
    const [FIRSTNAME, setFIRSTNAME] = useState("");
    const [LASTNAME, setLASTNAME] = useState("");
    const [PHONE1, setPHONE1] = useState("");
    const [PHONE2, setPHONE2] = useState("");
    const [GENDER, setGENDER] = useState("");
    const [RELATIONSHIP, setRELATIONSHIP] = useState("");

    // UNCHANGED FUNCTIONS FOR THE STATES ABOVE
    const FIRSTNAME_onChange = (event) => {         setFIRSTNAME(event.target.value);         };
    const LASTNAME_onChange = (event) => {         setLASTNAME(event.target.value);         };
    const PHONE1_onChange = (event) => {         setPHONE1(event.target.value);         };
    const PHONE2_onChange = (event) => {         setPHONE2(event.target.value);         };
    const GENDER_onChange = (event) => {         setGENDER(event.target.value);         };
    const RELATIONSHIP_onChange = (event) => {         setRELATIONSHIP(event.target.value);         };





    // STATE TO BE USED TO STORE DATA'S OF THE MEMBER, WE WANT TO PRINT ID-CARD FOR
    const [MEMBER_BENEFICIARY_DATA, setMEMBER_BENEFICIARY_DATA] = useState([]);

    // ON COMPONENT LOAD, RUN SOME FUNCTIONS TO LOAD UP DEFAULT PROPERTIES AND DATAS NEEDED BY THIS COMPONENT TO BE FULLY FUNCTIONAL
    useEffect(() => {
        // ensure page is scrolled to the top by default
        scrollToTop();

        // ONLY ALLOW API TO RUN IF THE "ALL_MEMBER_DATA" STATE IS EMPTY.
        if(TRIM(id) !== ""){
            /* call the api function responsible for user password reset operation. */
            const url = configuration.GET_MEMBER_BENEFICIARY_API+id;
            
            /* Call the "postData" function to help us handle submission. */
            getData(url, session).then(response => {
                // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
                if(response.length > 0){
                    // UPDATE MEMBER BENEFICIARY STATE, SO THAT WE CAN HAVE DATA TO PRINT INSIDE THE BENEFICIARY TABLE.
                    setMEMBER_BENEFICIARY_DATA(response);
                    // MAP THROUGH MEMBER BENEFICIARY, DETECT THE CURRENT BENEFICIARY WE ARE EDITING AND PRELOAD ITS DATA INTO FORM INPUTES.
                    response.map((infos, index) => {
                        if(infos.id == ben_id){
                            // ALSO GET DATA INDIVIDUALLY, SO WE CAN UPDATE RELEVANT STATES
                            setFIRSTNAME(infos.ben_FNAME);
                            setLASTNAME(infos.ben_SNAME);
                            setPHONE1(infos.ben_TEL_NO1);
                            setPHONE2(infos.ben_TEL_NO2);
                            setGENDER(infos.ben_GENDER);
                            setRELATIONSHIP(infos.ben_REL);
                        }
                    });
                }else{
                    // display server error message
                    openModal("System encountered an internal error while trying to load data.");
                }
            }).catch(error => {
                // At this junction we are certain that request submission has failed because of a network error, therefore we send out network 
                // failure message
                openModal("Network Error, when attempting to save data. Please ensure you have a good internet connection.");
            });
        }
    }, []);






    // MEMBER_DATA
    const UPDATE_DEPENDANT = (e) => {
        /* line of code to ensure that html form default submit process is disabled, so that form submission can be handled by javascript in the REACT way.  */
        e.preventDefault();

        /* collect all data from their official states storage and compile them into an object, in a bid to prepare the datas for authentication before we can proceed to submitting 
         * data. */
        const RAW_DATA = { 
            BEN_FNAME: FIRSTNAME, BEN_SNAME: LASTNAME, BEN_DOB: "2022-07-11", BEN_STATUS: "alive", BEN_TEL_NO1: PHONE1, 
            BEN_TEL_NO2: PHONE2, BEN_GENDER: GENDER, BEN_REL: RELATIONSHIP
        };
        // console.log(RAW_DATA);
        /* Here we launch the "RAW_DATA_AUTHENTICATOR" function to help us validate the authenticity of all the datas user has provided. */
        const AUTHENTICATOR_DATA = BENEFICIARY_REGISTRATION_DATA_AUTHENTICATOR(RAW_DATA);
        /* Before any major registration operations can be permitted to begin, firstly the "REGISTRATION_DATA_AUTHENTICATOR_2" function must first give clear indications that the data
         * provided by user is valid. So at this junction we test to see if we have clearance to register this user.  */
        if(AUTHENTICATOR_DATA === true){
            /* Here we signal the button state change react-state, to indicate that button is clicked and request sent. */
            setBUTTON_LOADING_ANIM(true);
            /* call the api function responsible for handling the profile updating job. */
            const url = configuration.BENEFICIARY_UPDATE_API+id+"/"+ben_id;
            /* Call the "putData" function to help us handle submission. */
            putData(url, RAW_DATA, session).then(response => {
                // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
                if(response.id){
                    // display success message
                    openModal("Member beneficiary datas, updated successfully.");
                    // revert user back to member edit page
                    //history.push(configuration.DASHBOARD+"/edit/members/"+id);
                }else{
                    // display server error message
                    openModal("System encountered an internal error while trying to update data.");
                }

                // Here we signal the button state change react-state, to indicate that request has been completed. 
                setBUTTON_LOADING_ANIM(false);
            }).catch(error => {
                // At this junction we are certain that request submission has failed because of a network error, therefore we send out network 
                // failure message
                openModal("Network Error, when attempting to save data. Please ensure you have a good internet connection.");
                // Here we signal the button state change react-state, to indicate that request has been completed. 
                setBUTTON_LOADING_ANIM(false);
            });
            
        }else{
            // control getting in here signifies that the authentication of user supplied profile data has failed for one reason or the other, 
            // further meaning user did not fill one or more form field well, therefore we initialize our alert container to tell user what he or 
            // she is doing wrong. 
            openModal(AUTHENTICATOR_DATA);
        }
    };







    const Delete_Dependant = (e) => {
        /* line of code to ensure that html form default submit process is disabled, so that form submission can be handled by javascript in the REACT way.  */
        e.preventDefault();

        /* Here we signal the button state change react-state, to indicate that button is clicked and request sent. */
        setBUTTON_LOADING_ANIM2(true);
        /* call the api function responsible for handling the profile updating job. */
        const url = configuration.MEMBER_BENEFICIARY_DELETE_API+id+"/"+ben_id;
        /* Call the "deleteData" function to help us handle submission. */
        deleteData(url, session);
        // display success message
        openModal("Member Beneficiary deleted successfully.");
    }








    // WE UPDATE THE PARENT COMPONENT TO REMIND IT THAT THE "ID_CARD" MENU IS WHAT IS CURRENTLY ACTIVE, WE DO THIS JUST SO THAT THE SIDEBAR
    // ACTIVE-MENU-SELECTOR DOES NOT GO OUT OF PLACE EACH TIME THE FULL PAGE IS RELOADED.
    DEFAULT_MENU_FEEDBACK("MEMBER_RECORDS");
    DEFAULT_SUB_MENU_FEEDBACK("");
    
    // if(MenuController === "MEMBER_RECORDS"){

        return (
            
            <>
                <div className="div-block-35" style={{ marginBottom: "0%", marginTop: "2%" }}>
                    <h1></h1>
                    <Link to={configuration.DASHBOARD+"/edit/members/"+id} className="realbuttons w-button" style={{ marginBottom: "0" }}>Back</Link>
                </div>

                <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                    <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={() => closeModalRedirect(() => history.push(configuration.DASHBOARD+"/edit/members/"+id))} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
                </Modal>

                <div className="edit-dependant-holder">
                    <div className="div-block-31">
                        <form onSubmit={UPDATE_DEPENDANT} method="put">
                            <div className="div-block-11 dependant">
                                <div className="data_container">
                                    <div className="div-block-21">
                                        <h1 className="title_header dependent" style={{ marginBottom: "30px", fontSize: "25px" }}>EDIT BENEFICIARY</h1>
                                    </div>

                                    <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-7">
                                        <div className="w-form">
                                            <div className="form-4">
                                                <label> <b style={{ marginLeft: "2%", color: "white" }}> Surname </b> 
                                                    <input onChange={LASTNAME_onChange} type="text" value={LASTNAME} className="data_capture white w-input" minlength="3" maxlength="40" 
                                                        placeholder="Beneficiary Surname" required />
                                                </label>

                                                <label> <b style={{ marginLeft: "2%", color: "white" }}> First Name </b> 
                                                    <input onChange={FIRSTNAME_onChange} type="text" value={FIRSTNAME} className="data_capture white w-input" minlength="3" maxlength="40" 
                                                        placeholder="Beneficiary First Name" required />
                                                </label>

                                                <label> <b style={{ marginLeft: "2%", color: "white" }}> Gender </b> 
                                                    <select onChange={GENDER_onChange} id="field-3" className="data_capture white w-select" required>
                                                        <option value={GENDER}>{GENDER}</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w-form">
                                            <div className="form-4">
                                                <label> <b style={{ marginLeft: "2%", color: "white" }}> Relationship </b> 
                                                    <select onChange={RELATIONSHIP_onChange} id="field-3" className="data_capture white w-select" required>
                                                        <option value={RELATIONSHIP}>{RELATIONSHIP}</option>
                                                        <option value="Father">Father</option>
                                                        <option value="Mother">Mother</option>
                                                        <option value="Husband">Husband</option>
                                                        <option value="Wife">Wife</option>
                                                        <option value="Grandfather">Grandfather</option>
                                                        <option value="Grandmother">Grandmother</option>
                                                        <option value="Uncle">Uncle</option>
                                                        <option value="Auntie">Auntie</option>
                                                        <option value="Son">Son</option>
                                                        <option value="Daughter">Daughter</option>
                                                        <option value="Nephew">Nephew</option>
                                                        <option value="Niece">Niece</option>
                                                        <option value="Friend">Friend</option>
                                                        <option value="Brother">Brother</option>
                                                        <option value="Sister">Sister</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </label>

                                                <label> <b style={{ marginLeft: "2%", color: "white" }}> Phone 1 </b> 
                                                    <input onChange={PHONE1_onChange} type="tel" pattern="[0-9]{11}" minlength="10" maxlength="15" placeholder="0000-000-0000" 
                                                        value={PHONE1} className="data_capture white w-input" required />
                                                </label>

                                                <label> <b style={{ marginLeft: "2%", color: "white" }}> Phone 2 </b> 
                                                    <input onChange={PHONE2_onChange} type="tel" pattern="[0-9]{11}" minlength="10" maxlength="15" placeholder="0000-000-0000" 
                                                        value={PHONE2} className="data_capture white w-input" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="div-block-15 dependant">
                                {(BUTTON_LOADING_ANIM === true) ? ( <button type="submit" className="button_outline goldenrod w-button">Updating.....</button> ) : (
                                    <button type="submit" className="button_outline filled goldenrod w-button">Update</button> )}
                                {(BUTTON_LOADING_ANIM2 === true) ? ( <button onClick={Delete_Dependant} className="button_outline delete w-button">Deleting.....</button> ) : (
                                    <button onClick={Delete_Dependant} className="button_outline filled delete w-button">Remove Beneficiary</button> )}
                            </div>
                        </form>
                    </div>
                    <p className="paragraph-8">Caution: A Beneficiary record can no longer be edited once it has been removed.</p>
                </div>
                
                
                <BeneficiaryList MEMBER_BENEFICIARY_DATA={MEMBER_BENEFICIARY_DATA} id={id} />
            </>
        );

    // }else{
    //     return null;
    // }

}


