
// REACT STANDARD LIBRARY CALLS
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import QRCode from 'qrcode';
import ReactToPrint from "react-to-print";
import '../../../../CSS/id_card_stylesheet.css';


/* WEB APP CONFIG FILE. */
import { configuration } from '../../../../Config/Config';



/* IMPORT LIBRARY  */
import { getData, TRIM, __String_To_Chars_Splitter, EXPLODE, STRING_CHARS_COUNT, getCookie, mathRandom } from '../../../../Library/Library.js';




export default function Print_Agent_ID_cards() {

    // lets set the page title. 
    document.getElementById("title").innerHTML = "Print ID Card | "+configuration.COMPANY_NAME;

    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);
    // GET FROM URL THE ID OF THE MEMBER WE WANT TO GENERATE ID CARD FOR
    const { id } = useParams();
    // CREATE AN OBJECT OF REACT USEREF HOOK, TO BE USED IN LATER STAGE
    let componentRef = useRef();

    // USE THIS HOOK TO INVOKE WEBFLOWS SCRIPTS AND ANIMATIONS 
    useEffect(() => {
        const html = document.getElementsByTagName('html')[0];
        html.setAttribute('data-wf-page', '62ab23d8eae8f044a26113f2');
        html.setAttribute('data-wf-site', '62a356d924ac21c4e115b2a0');

        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2').init();
        document.dispatchEvent(new Event('readystatechange'))
    }, []);

    

    // STATE TO BE USED TO STORE DATA'S OF THE MEMBER, WE WANT TO PRINT ID-CARD FOR
    const [AGENT_DATA, setAGENT_DATA] = useState({ dcagmt_FNAME: "", dcagmt_SNAME: "", dcamgtno: "", dcagmt_STATE: "", dcagmt_LGA: "", dcagmt_BRANCH: "" });

    const imageUrl = configuration.AGENT_BINARY_IMAGE_FETCHER_API+id;
    const [imgUrl, setImgUrl] = useState("/DATA/Spin-Preloader-"+mathRandom(1, 2)+".gif");

    // FUNCTION TO FETCH MEMBER IMAGE IN BINARY AND TRANSCODE INTO READABLE IMAGE FORMATE
    const getImg = async () => {
        const response = await fetch(imageUrl, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Accept': 'image/png',
                'Authorization': `Bearer ${session}`,
                'Content-Type': 'image/png'     // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            /*body: JSON.stringify(data)*/ // body data type must match "Content-Type" header
        }).catch(error => {
            history.push(configuration.LOGIN);
        });

        // ensure that we have an image data response and that fetch operation has not failed as a result of network failure before proceeding to this part
        if(response){
            const imageBlob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(imageBlob);
            reader.onloadend = () => {
            const base64data = reader.result;
            setImgUrl(base64data);
            };
        }
    };
    
    


    // USE THIS HOOK TO FETCH MEMBERS DETAILS AND INVOKE THE FUNCTION THAT WILL FETCH US MEMBER IMAGE
    useEffect(() => {
        // ONLY ALLOW API TO RUN IF THE "ALL_AGENT_DATA" STATE IS EMPTY.
        if(TRIM(id) !== ""){
            // 
            const token = session;
            /* call the api function responsible for user password reset operation. */
            const url_1 = configuration.SORT_AGENT_BY_ID_API+id;
            /* Call the "postData" function to help us handle submission. */
            getData(url_1, token).then(response => {
                // CONTROL IN HERE MEANS REQUEST WAS A SUCCESS, HENCE WE FETCH DATA AND SEND TO PARENT COMPONENT
                setAGENT_DATA(response);
            }).catch(error => {
                history.push(configuration.LOGIN);
            });

            // LOAD IMAGE FROM BACKEND AS BINARY, PROCESS AND CONVERT IT TO IMAGE
            getImg();
        }
    }, []);


    // create state be used to store qr images temporal
    const [src, setSrc] = useState('');
    // extract the section of the member id that has numbers, then split those numbers into chars
    const CODEX = (AGENT_DATA.dcamgtno) ? __String_To_Chars_Splitter(EXPLODE("-", AGENT_DATA.dcamgtno)[2]) : ""; 
    // extract member id from api response only if id is in its correct format, else we generate new formated id
    const Member_ID = (CODEX.length == 8) ? CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7] : EXPLODE("-", AGENT_DATA.dcamgtno)[2];
    // generate qr codes
    QRCode.toDataURL("Fullname: "+(AGENT_DATA.dcagmt_FNAME+" "+AGENT_DATA.dcagmt_SNAME)+", Agent Number: "+Member_ID)
    .then(url => {
        setSrc(url);
    });
       

    return (
        <div className='dash_board_body'>

            <div className="table_bg" style={{ marginLeft: "auto", marginRight: "auto", marginTop: "2%", width: "1215px", minWidth: "1215px", 
                maxWidth: "1215px" }}>

                
                    {/* <a href="#" className="button_outline filled goldenrod id new w-button">Return</a> */}
                    {/* button to trigger printing of target component */}
                    <ReactToPrint
                    trigger={() => {
                        return ( 
                            <div className="div-block-35" style={{ marginBottom: "0%", marginTop: "0%" }}>
                                <h1></h1>
                                <a href="#" className="button_outline filled goldenrod id new w-button">Print ID Card</a> 
                            </div>
                        );
                    }}
                    content={() => componentRef}
                    />
                


                <h1 style={{ textAlign: "center" }}>PRINT ID CARD</h1>
                <ComponentToPrint ref={(el) => (componentRef = el)} AGENT_DATA={AGENT_DATA} IMAGE={imgUrl} src={src} />
                <ComponentNotToPrint AGENT_DATA={AGENT_DATA} IMAGE={imgUrl} src={src} />

            </div>

        </div>
    );

}









const ComponentNotToPrint = ({AGENT_DATA, IMAGE, src}) => {
  
    // construct to ensure that, nothing is displayed when api response is yet to load data
    const Location_ID = (AGENT_DATA.dcagmt_STATE) ? (AGENT_DATA.dcagmt_STATE+"/"+AGENT_DATA.dcagmt_LGA) : "";
    // extract the section of the member id that has numbers, then split those numbers into chars
    const CODEX = (AGENT_DATA.dcamgtno) ? __String_To_Chars_Splitter(EXPLODE("-", AGENT_DATA.dcamgtno)[2]) : ""; 
    // extract member id from api response only if id is in its correct format, else we generate new formated id
    // const Member_ID = (CODEX.length == 8) ? CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7] : ((Math.floor(1000 + Math.random() * 9000)) +" "+ (Math.floor(1000 + Math.random() * 9000)));
    const Member_ID = CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7];
    // CONSTRUCT THE FULL NAME OF THE MEMBER
    const MEMBER_NAME = (AGENT_DATA.dcagmt_FNAME+" "+AGENT_DATA.dcagmt_SNAME);
    


    return (
        <div className="example" style={{ display: "block" }}>

            <img className="background-image-1" src={configuration.APP_DATA_RESOURCE+"ID-CARD-MOCK/FRONT.png"} />

            <div className="overlay">
                <div className="image" style={{ top: "135px", left: "135px" }}>
                    <img id="newImg" src={IMAGE} alt=""
                    loading="lazy" width="360" height="325" className="task_nav_button_icon" style={{ filter: "grayscale(100%)" }} /> 
                </div>

                <USER_NAME_RESOLUTOR MEMBER_NAME={MEMBER_NAME} />

                <h2 className="location_ID" style={{ top: "626px", left: "260px", transform: "translate(-18%, -50%)" }}>
                    {Location_ID}
                </h2>

                <h2 className="membership_ID" style={{ top: "626px", left: "900px", letterSpacing: "7px", transform: "translate(-75%, -50%)" }}>
                    {Member_ID}
                </h2>
            </div>

            <img className="background-image-2" src={configuration.APP_DATA_RESOURCE+"ID-CARD-MOCK/BACK.png"} />

            <div className="overlay2">   
                <div className="image2" style={{ top: "1305px", left: "880px" }}>
                    <img id="newImg2" src={src} alt="Passport" loading="lazy" width="238" height="220" className="task_nav_button_icon" /> 
                </div>
            </div>

        </div>  
    );
    
};











const USER_NAME_RESOLUTOR = ({MEMBER_NAME}) => {
    if(STRING_CHARS_COUNT(MEMBER_NAME) > 18){
        return( <h3 className="name" style={{ top: "480px", left: "135px", transform: "translate(-0%, -50%)" }}>{MEMBER_NAME}</h3> );
    }else{
        return( <h2 className="name" style={{ top: "480px", left: "135px", transform: "translate(-0%, -50%)" }}>{MEMBER_NAME}</h2> );
    }
}











class ComponentToPrint extends React.Component {

    render() {


        // construct to ensure that, nothing is displayed when api response is yet to load data
        const Location_ID = (this.props.AGENT_DATA.dcagmt_STATE) ? (this.props.AGENT_DATA.dcagmt_STATE+"/"+this.props.AGENT_DATA.dcagmt_LGA) : "";
        // extract the section of the member id that has numbers, then split those numbers into chars
        const CODEX = (this.props.AGENT_DATA.dcamgtno) ? __String_To_Chars_Splitter(EXPLODE("-", this.props.AGENT_DATA.dcamgtno)[2]) : ""; 
        // extract member id from api response only if id is in its correct format, else we generate new formated id
        // const Member_ID = (CODEX.length == 8) ? CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7] : ((Math.floor(1000 + Math.random() * 9000)) +" "+ (Math.floor(1000 + Math.random() * 9000)));
        const Member_ID = CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7];
        // CONSTRUCT THE FULL NAME OF THE MEMBER
        const MEMBER_NAME = (this.props.AGENT_DATA.dcagmt_FNAME+" "+this.props.AGENT_DATA.dcagmt_SNAME);

        return (

            <div className="example">

                <img className="background-image-1" width="500" height="210" src={configuration.APP_DATA_RESOURCE+"ID-CARD-MOCK/FRONT.png"} />

                <div className="overlay">
                    <div className="image">
                        <img id="newImg" src={this.props.IMAGE} alt=""
                        loading="lazy" width="110" height="95" className="task_nav_button_icon" style={{ filter: "grayscale(100%)" }} /> 
                    </div>

                    {/* <USER_NAME_RESOLUTOR MEMBER_NAME={MEMBER_NAME} /> */}
                    <h6 className="name" style={{ left: "35px" }}>
                        {(this.props.AGENT_DATA.dcagmt_FNAME+" "+this.props.AGENT_DATA.dcagmt_SNAME)}
                    </h6>

                    <h6 className="location_ID" style={{ left: "68px" }}>
                        {Location_ID}
                    </h6>

                    <h6 className="membership_ID" style={{ left: "253px" }}>
                        {Member_ID}
                    </h6>
                </div>


                <img className="background-image-2" width="500" height="210" src={configuration.APP_DATA_RESOURCE+"ID-CARD-MOCK/BACK.png"} />

                <div className="overlay2">
                    <div className="image2">
                        <img id="newImg2" src={this.props.src} alt="Passport" loading="lazy" width="63" height="62" className="task_nav_button_icon" /> 
                    </div>
                </div>

            </div>   
        );
    }
};








