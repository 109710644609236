// REACT STANDARD LIBRARY CALLS
import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Modal from 'react-modal';


/* WEB APP CONFIG FILE. */
import { configuration } from '../../Config/Config';

/* IMPORT LIBRARY  */
import { getData, getCookie, formatMoney } from '../../Library/Library.js';





// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






export default function DASHBOARD({MenuController, DEFAULT_MENU_FEEDBACK, PAGE_TITLE_DATAFEEDBACK, DEFAULT_SUB_MENU_FEEDBACK}) {


    // SET THE PAGE TITLE FOR THIS DASHBOAD SEMI-PAGE
    PAGE_TITLE_DATAFEEDBACK("Personnel Statistics | "+configuration.COMPANY_NAME);
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }


    // STATE TO BE USED TO STORE DATA'S OF ALL AGENTS
    const [TOTAL_AGENT, setTOTAL_AGENT] = useState("");
    // STATE TO BE USED TO STORE DATA'S OF ALL MEMBERS
    const [TOTAL_MEMBER, setTOTAL_MEMBER] = useState("");



    // LOAD AGENT DATAS AS COMPONENT LOADS UP
    useEffect(() => {
        /* call the api function responsible for user password reset operation. */
        const url_1 = configuration.MEMBER_LIST_API+"1?pageSize="+configuration.ITEM_TO_CALL_AT_ONCE_SHORT_FORM+"&sortField=id&sortDir=desc&name=";
        /* Call the "postData" function to help us handle submission. */
        getData(url_1, session).then(response => {
            // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
            if(response.content){
                // CONTROL IN HERE MEANS REQUEST WAS A SUCCESS, HENCE WE FETCH DATA AND SEND TO PARENT COMPONENT
                setTOTAL_MEMBER(response.totalElements);
            }else{
                // display server error message
                openModal("System encountered an internal error while trying to load data.");
            }
        }).catch(error => {
            history.push(configuration.LOGIN);
        });

        /* call the api function responsible for user password reset operation. */
        const url_2 = configuration.AGENTS_LIST_API+"1?pageSize="+configuration.ITEM_TO_CALL_AT_ONCE_SHORT_FORM+"&sortField=id&sortDir=desc&name=";
        /* Call the "postData" function to help us handle submission. */
        getData(url_2, session).then(response => {
            // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
            if(response.content){
                // CONTROL IN HERE MEANS REQUEST WAS A SUCCESS, HENCE WE FETCH DATA AND SEND TO PARENT COMPONENT
                setTOTAL_AGENT(response.totalElements);
            }else{
                // display server error message
                openModal("System encountered an internal error while trying to load data.");
            }
        }).catch(error => {
            history.push(configuration.LOGIN);
        });
    }, []);


    // WE UPDATE THE PARENT COMPONENT TO REMIND IT THAT THE "MAIN_DASHBOARD" MENU IS WHAT IS CURRENTLY ACTIVE, WE DO THIS JUST SO THAT THE SIDEBAR
    // ACTIVE-MENU-SELECTOR DOES NOT GO OUT OF PLACE EACH TIME THE FULL PAGE IS RELOADED.
    DEFAULT_MENU_FEEDBACK("MAIN_DASHBOARD");
    DEFAULT_SUB_MENU_FEEDBACK("PERSONNEL_STATISTICS");
    
    // if(MenuController === "ID_CARD"){

        return (
            
            <>

                <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                    <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
                </Modal>

                <div className="dashboard_statistics" style={{ marginBottom: "30%", background: "white" }}>
                    <h2 className="total_text" style={{ marginTop: "5%" }}> KALYAAN WELFARE SCHEME </h2>
                    <div className="info_box_container id">
                        <div className="splitter_box id gws">
                            <div className="dashboard_statistics info_box" style={{ marginRight: "8%", marginBottom: "4%", borderRadius: "0px", border: "2px solid #999" }}>
                                <div className="total_text"><h4>Collection Agents</h4></div>
                                <div className="big_text">{formatMoney(16)}</div>
                                <div className="total_text"></div>
                                <div className="total_text"></div>
                            </div>
                            <div className="dashboard_statistics info_box" style={{ borderRadius: "0px", border: "2px solid #999" }}>
                                <div className="total_text"><h4>Registered Members</h4></div>
                                <div className="big_text">{formatMoney(TOTAL_MEMBER)}</div>
                                <div className="total_text"></div>
                                <div className="total_text"></div>
                            </div>
                        </div>
                    </div>
                    <div className="info_box_container id">
                        <div className="splitter_box id gws">
                            <div className="dashboard_statistics info_box" style={{ marginRight: "8%", marginBottom: "10%", borderRadius: "0px", border: "2px solid #999" }}>
                                <div className="total_text"><h4>Data Capture Agents</h4></div>
                                <div className="big_text">{formatMoney(TOTAL_AGENT)}</div>
                                <div className="total_text"></div>
                                <div className="total_text"></div>
                            </div>
                            <div className="dashboard_statistics info_box" style={{ borderRadius: "0px", border: "2px solid #999" }}>
                                <div className="total_text"><h4>Personnel</h4></div>
                                <div className="big_text">{formatMoney(10)}</div>
                                <div className="total_text"></div>
                                <div className="total_text"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    // }else{
    //     return null;
    // }

}


