
// REACT STANDARD LIBRARY CALLS
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';



/* IMPORT LIBRARY  */
import { postData, createCookie, getCookie } from '../Library/Library.js';


// LOAD AGENT MODULE FOR AGENTS REGISTRATION
import ADD_VETERAN from './DashboardPages/Veterans/ADD_VETERAN';




export default function VeteransRegistration() {

    // lets set the page title. 
    document.getElementById("title").innerHTML = "Veterans Registration | "+configuration.COMPANY_NAME;
    /* we create "useHistory" object to be used for redirecting. */
    // let history = useHistory();   

    // A FUNCTION TO ENSURE YOU DONT SEE THE LOGIN PAGE AS LONG AS YOUR COOKIE IS YET TO EXPIRE
    // if(getCookie("token"))history.push(configuration.DASHBOARD);


    useEffect(() => {
        const html = document.getElementsByTagName('html')[0];
        html.setAttribute('data-wf-page', '62ab23d8eae8f044a26113f2');
        html.setAttribute('data-wf-site', '62a356d924ac21c4e115b2a0');

        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2').init();
        document.dispatchEvent(new Event('readystatechange'))
    }, []);
    




    return (
        <div className='dash_board_body'>
            <div className="new-side-menu">
                <div className="task_nav">
                    <Link to="#">
                        <img src={configuration.APP_DATA_RESOURCE+"images/our_logo.png"} loading="lazy" width="128" 
                            alt={configuration.COMPANY_NAME+" Official Logo"} className="image-4" />
                    </Link>
                    
                    <Link to="#" className={"task_nav_button w-inline-block w--current"}>
                        <img src={configuration.APP_DATA_RESOURCE+"images/reg-agent-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                        <div className="text-block">Veterans Registration</div>
                    </Link>
                </div> 
            </div>


            <div className="main-body">


                <ADD_VETERAN />
                
                
            </div>

        </div>
    );

}
