


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';
/* IMPORT LIBRARY  */
import { PERMIT_ONLY_LETTERS, PERMIT_ONLY_NUMBERS, TRIM } from './Library.js';





 

export function MEMBER_REGISTRATION_DATA_AUTHENTICATOR(DATA){
    // Get the maximum allowable size of image, that can be uploaded
    const MAX_IMAGE_SIZE = configuration.IMAGE_UPLOAD_SIZE_ALLOWED;
    // we check if this form fields default value is what was selected.
    // if(!DATA.IMAGE_FILE_OBJECT){
    //     // if control gets here it means that user has not uploaded an image
    //     return "Error, please upload an image before submitting this form.";
    // }
    // we check if the email data field is in the right formate or not. 
    if( (DATA.IMAGE_FILE_OBJECT) && ((DATA.IMAGE_FILE_OBJECT.size / 1024 / 1024) > MAX_IMAGE_SIZE) ){
        // if control gets here it means the email entered has failed to meet the proper standards.
        return "Error, the image uploaded has exceeded the maximum size of "+MAX_IMAGE_SIZE+"MB, Please ensure to upload an"+
        " image with a size below "+MAX_IMAGE_SIZE+"MB.";
    }
    // permit only letters for this entry.
    else if((!PERMIT_ONLY_LETTERS(TRIM(DATA.MBRSNAME))) || (!PERMIT_ONLY_LETTERS(TRIM(DATA.MBRFNAME)))){
        // if control gets here it means the data entered by user has maybe a number or character in it, and its not letters only.
        return "Special characters or numbers are not allowed inside the name fields.";
    }
    // Since first phone field is compulsary while the second isn't, we set up this perfect clause to run test on both fields and ensure both fields
    // Supplied inputes are numbers throughout.
    else if((!PERMIT_ONLY_NUMBERS(TRIM(DATA.MBR_TEL_NO1))) || ((TRIM(DATA.MBR_TEL_NO2) !== "") && (!PERMIT_ONLY_NUMBERS(TRIM(DATA.MBR_TEL_NO2))))){
        // if control gets here it means the data entered has a mix of other characters.
        return "Error, check both phone number fields and ensure your inputs are numbers only.";
    }
    // this is the default operation that is if none of the clauses of this construct interupts, then this function would give it seal of approval
    // for furher operations to take place.
    else{
        return true;
    }
};










export function AGENT_REGISTRATION_DATA_AUTHENTICATOR(DATA, CALLER = "UPDATE"){
    // Get the maximum allowable size of image, that can be uploaded
    const MAX_IMAGE_SIZE = configuration.IMAGE_UPLOAD_SIZE_ALLOWED;

    
    // check to ensure image uploaded is not empty during registration.
    if((!DATA.IMAGE_FILE_OBJECT) && (CALLER === "INSERT")){
        return "Error, please upload an image before submitting this form---------.";
    }
    // we check to see if image uploaded has not suppased the allowable file size limit for this entry and during registration.
    else if( ((DATA.IMAGE_FILE_OBJECT.size / 1024 / 1024) > MAX_IMAGE_SIZE) && (CALLER === "INSERT") ){
        return "Error, the image uploaded has exceeded the maximum size of "+MAX_IMAGE_SIZE+"MB, Please ensure to upload an"+
        " image with a size below "+MAX_IMAGE_SIZE+"MB.";
    }
    // check to ensure document uploaded is not empty during registration.
    else if((!DATA.DOCUMENT_FILE_OBJECT) && (CALLER === "INSERT")){
        return "Error, please upload a screenshot of your original government approved ID card.";
    }
    // we check to see if document uploaded has not suppased the allowable file size limit for this entry and during registration.
    else if( ((DATA.DOCUMENT_FILE_OBJECT.size / 1024 / 1024) > MAX_IMAGE_SIZE) && (CALLER === "INSERT") ){
        return "Error, the document uploaded has exceeded the maximum size of "+MAX_IMAGE_SIZE+"MB, Please ensure to upload an"+
        " image with a size below "+MAX_IMAGE_SIZE+"MB.";
    }
    else if(DATA.DCAGMTPASSWRD !== DATA.RETYPED_PASSWORD){
        return "Retyped password is not thesame with password.";
    }else
    // ensure that password has more than 8 characters
    if(DATA.DCAGMTPASSWRD.length < 8){
        return "Password should be minimum of 8 characters.";
    }else
    // permit only letters for this entry.
    if((!PERMIT_ONLY_LETTERS(TRIM(DATA.DCAGMT_SNAME))) || (!PERMIT_ONLY_LETTERS(TRIM(DATA.DCAGMT_FNAME)))){
        // if control gets here it means the data entered by user has maybe a number or character in it, and its not letters only.
        return "Special characters or numbers are not allowed inside the name fields.";
    }
    // Since first phone field is compulsary while the second isn't, we set up this perfect clause to run test on both fields and ensure both fields
    // Supplied inputes are numbers throughout.
    else if((!PERMIT_ONLY_NUMBERS(TRIM(DATA.DCAGMT_TEL_NO1))) || ((TRIM(DATA.DCAGMT_TEL_NO2) !== "") && (!PERMIT_ONLY_NUMBERS(TRIM(DATA.DCAGMT_TEL_NO2))))){
        // if control gets here it means the data entered has a mix of other characters.
        return "Error, check both phone number fields and ensure your inputs are numbers only.";
    }
    // this is the default operation that is if none of the clauses of this construct interupts, then this function would give it seal of approval
    // for furher operations to take place.
    else{
        return true;
    }
};





export function AGENT_REGISTRATION_DATA_AUTHENTICATOR_2(DATA, CALLER = "UPDATE"){
    // Get the maximum allowable size of image, that can be uploaded
    const MAX_IMAGE_SIZE = configuration.IMAGE_UPLOAD_SIZE_ALLOWED;

    
    // check to ensure image uploaded is not empty during registration.
    if(DATA.DCAGMTPASSWRD !== DATA.RETYPED_PASSWORD){
        return "Retyped password is not thesame with password.";
    }else
    // ensure that password has more than 8 characters
    if(DATA.DCAGMTPASSWRD.length < 8){
        return "Password should be minimum of 8 characters.";
    }else
    // permit only letters for this entry.
    if((!PERMIT_ONLY_LETTERS(TRIM(DATA.DCAGMT_SNAME))) || (!PERMIT_ONLY_LETTERS(TRIM(DATA.DCAGMT_FNAME)))){
        // if control gets here it means the data entered by user has maybe a number or character in it, and its not letters only.
        return "Special characters or numbers are not allowed inside the name fields.";
    }
    // Since first phone field is compulsary while the second isn't, we set up this perfect clause to run test on both fields and ensure both fields
    // Supplied inputes are numbers throughout.
    else if((!PERMIT_ONLY_NUMBERS(TRIM(DATA.DCAGMT_TEL_NO1))) || ((TRIM(DATA.DCAGMT_TEL_NO2) !== "") && (!PERMIT_ONLY_NUMBERS(TRIM(DATA.DCAGMT_TEL_NO2))))){
        // if control gets here it means the data entered has a mix of other characters.
        return "Error, check both phone number fields and ensure your inputs are numbers only.";
    }
    // this is the default operation that is if none of the clauses of this construct interupts, then this function would give it seal of approval
    // for furher operations to take place.
    else{
        return true;
    }
};









 

export function BENEFICIARY_REGISTRATION_DATA_AUTHENTICATOR(DATA){
    // permit only letters for this entry.
    //if((!PERMIT_ONLY_LETTERS(TRIM(DATA.BEN_SNAME))) || (!PERMIT_ONLY_LETTERS(TRIM(DATA.BEN_FNAME)))){
        // if control gets here it means the data entered by user has maybe a number or character in it, and its not letters only.
    //    return "Special characters or numbers are not allowed inside the name fields.";
    //}
    // Since first phone field is compulsary while the second isn't, we set up this perfect clause to run test on both fields and ensure both fields
    // Supplied inputes are numbers throughout.
    if((!PERMIT_ONLY_NUMBERS(TRIM(DATA.BEN_TEL_NO1))) || ((TRIM(DATA.BEN_TEL_NO2) !== "") && (!PERMIT_ONLY_NUMBERS(TRIM(DATA.BEN_TEL_NO2))))){
        // if control gets here it means the data entered has a mix of other characters.
        return "Error, check both phone number fields and ensure your inputs are numbers only.";
    }
    // this is the default operation that is if none of the clauses of this construct interupts, then this function would give it seal of approval
    // for furher operations to take place.
    else{
        return true;
    }
};









 

export function NEXT_OF_KIN_UPDATE_DATA_AUTHENTICATOR(DATA){
    // permit only letters for this entry.
    if((!PERMIT_ONLY_LETTERS(TRIM(DATA.MBR_NEXT_KIN_SNAME))) || (!PERMIT_ONLY_LETTERS(TRIM(DATA.MBR_NEXT_KIN_FNAME)))){
        // if control gets here it means the data entered by user has maybe a number or character in it, and its not letters only.
        return "Special characters or numbers are not allowed inside the name fields.";
    }
    // Since first phone field is compulsary while the second isn't, we set up this perfect clause to run test on both fields and ensure both fields
    // Supplied inputes are numbers throughout.
    else if((!PERMIT_ONLY_NUMBERS(TRIM(DATA.MBR_NEXT_KIN_TEL_NO1))) || ((TRIM(DATA.MBR_NEXT_KIN_TEL_NO2) !== "") && (!PERMIT_ONLY_NUMBERS(TRIM(DATA.MBR_NEXT_KIN_TEL_NO2))))){
        // if control gets here it means the data entered has a mix of other characters.
        return "Error, check both phone number fields and ensure your inputs are numbers only.";
    }
    // this is the default operation that is if none of the clauses of this construct interupts, then this function would give it seal of approval
    // for furher operations to take place.
    else{
        return true;
    }
};







