
// REACT STANDARD LIBRARY CALLS
import React, { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
// import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';



// IMPORT PAGE COMPONENTS
import SideBar from '../Components/SideBar';
import NavBar from '../Components/NavBar';

// LOAD DASHBOARD MODULE PAGE
import DASHBOARD from './DashboardPages/DASHBOARD';
// LOAD ID-CARD MODULE PAGES
import TRACKING from './DashboardPages/TRACKING';
import OUTSTANDING_ID_CARDS from './DashboardPages/ID_Card_Module/OUTSTANDING_ID_CARDS';
import REGISTERED_STAFFS from './DashboardPages/ID_Card_Module/REGISTERED_STAFFS';
import ISSUED_ID_CARDS from './DashboardPages/ID_Card_Module/ISSUED_ID_CARDS';
import REGISTERED_AGENTS from './DashboardPages/ID_Card_Module/REGISTERED_AGENTS';
// LOAD MEMBER MODULE PAGES
import ACTIVE_MEMBERS from './DashboardPages/Member_Module/ACTIVE_MEMBERS';
import SUSPENDED_MEMBERS from './DashboardPages/Member_Module/SUSPENDED_MEMBERS';
import DECEASED_MEMBERS from './DashboardPages/Member_Module/DECEASED_MEMBERS';
import EXPELLED_MEMBERS from './DashboardPages/Member_Module/EXPELLED_MEMBERS';
import EDIT_NEXT_OF_KIN from './DashboardPages/Member_Module/EDIT_NEXT_OF_KIN';
import MEMBER_EDIT from './DashboardPages/Member_Module/MEMBER_EDIT';
import CREATE_BENEFICIARY from './DashboardPages/Member_Module/ADD_BENEFICIARY';
import UPDATE_BENEFICIARY from './DashboardPages/Member_Module/UPDATE_BENEFICIARY';
import MEMBER_PROFILE from './DashboardPages/Member_Module/MEMBER_PROFILE';
// LOAD AGENT MODULE PAGES
import AGENT_RECORDS from './DashboardPages/Agent_Module/AGENT_RECORDS';
import AGENT_PROFILE from './DashboardPages/Agent_Module/AGENT_PROFILE';
// import ADD_AGENT from './DashboardPages/Agent_Module/ADD_AGENT';
import EDIT_AGENT from './DashboardPages/Agent_Module/EDIT_AGENT';
// LOAD "GROUP WELFARE SCHEME" MODULE PAGES
import GROUP_WELFARE_SCHEME from './DashboardPages/GWS_Module/GROUP_WELFARE_SCHEME';
import REGISTER_COMPANY from './DashboardPages/GWS_Module/REGISTER_COMPANY';
import GWS_APPLICATION from './DashboardPages/GWS_Module/GWS_APPLICATION';
import GWS_BATCH_APPLICATION from './DashboardPages/GWS_Module/GWS_BATCH_APPLICATION';
import APPROVAL_AND_POLICY from './DashboardPages/GWS_Module/APPROVAL_AND_POLICY';
import CLAIMS_PROCESSING from './DashboardPages/GWS_Module/CLAIMS_PROCESSING';
import CLAIMS_REGISTERING from './DashboardPages/GWS_Module/CLAIMS_REGISTERING';
// LOAD CUSTOMER CARE MODULE PAGES
import CC_ACTIVE_MEMBERS from './DashboardPages/Customer_Care_Module/CC_ACTIVE_MEMBERS';
import CC_MEMBER_PROFILE from './DashboardPages/Customer_Care_Module/CC_MEMBER_PROFILE';


/* IMPORT LIBRARY  */
import { getCookie } from '../Library/Library.js';




export default function Dashboard() {

    // Create a state to be used by dashboard sub Pages to control page title, and create its feedback function
    const [PAGE_TITLE, setPAGE_TITLE] = useState("Dashboard | "+configuration.COMPANY_NAME);
    const PAGE_TITLE_DATAFEEDBACK = (data) => {       setPAGE_TITLE(data);         } 
    // lets set the page title. 
    document.getElementById("title").innerHTML = PAGE_TITLE;

    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    /* Here we create the mechanism we shall be using to control the dashboard routes which happens to be a nested route of this entire react application.  */
    let { path } = useRouteMatch();


    // useEffect(() => {
    //     const html = document.getElementsByTagName('html')[0];
    //     html.setAttribute('data-wf-page', '62ab23d8eae8f044a26113f2');
    //     html.setAttribute('data-wf-site', '62a356d924ac21c4e115b2a0');

    //     window.Webflow && window.Webflow.destroy();
    //     window.Webflow && window.Webflow.ready();
    //     window.Webflow && window.Webflow.require('ix2').init();
    //     document.dispatchEvent(new Event('readystatechange'))
    // }, []);

    

    // STATE TO BE USED TO CONTROL THE MENU CLICKING FROM INSIDE THE SIDE-NAV CHILD COMPONENT, AND EFFECT THE RESPECTIVE CHANGES ITS BRINGS TO 
    // OTHER PARTS. THE DEAFULT DATAS OF THIS STATE DEFINES THE DEFAULT MENU USER SHALL BE SEEING UPON LOGGING-IN
    const [MenuController, setMenuController] = useState("MAIN_DASHBOARD");
    const MenuControllerFEEDBACK = (data) => {       setMenuController(data);         } 
    const [SubMenuController, setSubMenuController] = useState("PERSONNEL_STATISTICS");
    const SubMenuControllerFEEDBACK = (data) => {       setSubMenuController(data);         } 

    // STATE AND STATE-FEEDBACK FUNCTION, FOR STORING NAVIGATION SEARCH BAR, SEARCHED USER INFORMATIONS
    const [MEMBER_DATA, setMEMBER_DATA] = useState([]);
    const MEMBER_DATAFEEDBACK = (data) => {       setMEMBER_DATA(data);         } 

    // alert(getCookie("token"));
    // console.log(MenuController);
    

    return (
        <>
            {/* <GENERAL_MODAL modal_toggler={showGeneralModal} modal_closer={generalModal_CloserFunction} modal_display_DATA={generalModalDisplayData} 
                modal_footer_display_DATA={generalModalFooterDisplayData} modal_dismis_button_style={generalModal_dismis_button_style} 
                CONTROLLER={generalModal_controller} /> */}
                
            <div className='dash_board_body'>
                    
                {/* <div data-w-id="d7c5cd46-67aa-900c-99d4-0f7edb580ef5" style={{ opacity: "1" }} className="notification-modal">
                    <div className="chat-bg">
                        <div className="div-block-29">
                            <h1 className="title_header">Your massages</h1>
                            <a data-w-id="0c056528-d8b4-5ebf-0427-0de14e9efc1d" href="#" className="w-inline-block">
                                <img src={configuration.APP_DATA_RESOURCE+"images/close-icon.svg"} loading="lazy" alt="" />
                            </a>
                        </div>
                        <div className="chatbody">&quot;you should come online more often, your break no dey finish?&quot; 
                            <em>-Adefola Adams. 1pm. June 9, 2022.</em>
                        </div>
                        <div className="chatbody">&quot;We&#x27;ll be running system update by 10am today, you should be back to work by noon.&quot; 
                            <em>-Adefola Adams. 1pm. June 9, 2022.</em>
                        </div>
                        <div className="chatbody">&quot;Pls reset your password on first login, thanks.&quot; 
                            <em>-Adefola Adams. 1pm. June 9, 2022.</em>
                        </div>
                    </div>
                </div> */}
                
                <SideBar DEFAULT_MENU={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} />

                <div className="main-body">
                    <div className="chat_modal">
                        <div className="w-form">
                            <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" className="form-3">
                                <a data-w-id="74f688fb-b6a5-1de4-2952-3806a940402b" href="#" className="close">X</a>
                                <input type="text" className="text-field-2 w-input" maxlength="256" name="field-2" data-name="Field 2" 
                                    placeholder="Type message here" id="field-2" required="" />
                                <input type="submit" value="send message" data-wait="Please wait..." className="button w-button" />
                            </form>
                            {/* <div className="w-form-done"><div>Thank you! Your submission has been received!</div></div>
                            <div className="w-form-fail"><div>Oops! Something went wrong while submitting the form.</div></div> */}
                        </div>
                    </div>




                    <NavBar MEMBER_DATAFEEDBACK={MEMBER_DATAFEEDBACK} />


                    
                    <Switch>
                        <Route exact path={path}>
                            <DASHBOARD MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} 
                            DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/member/tracking"}>
                            <TRACKING MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} 
                            DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/id-card/outstanding-id-cards"}>
                            <OUTSTANDING_ID_CARDS MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} SEARCHED_MEMBER_DATA={MEMBER_DATA} 
                            DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/id-card/registered-staffs"}>
                            <REGISTERED_STAFFS MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/id-card/registered-member-lists"}>
                            <ISSUED_ID_CARDS MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} SEARCHED_MEMBER_DATA={MEMBER_DATA} 
                            DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/id-card/registered-agents"}>
                            <REGISTERED_AGENTS MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} SEARCHED_MEMBER_DATA={MEMBER_DATA} DEFAULT_SUB_MENU={SubMenuController} 
                            DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/active/members/list"}>
                            <ACTIVE_MEMBERS MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} SEARCHED_MEMBER_DATA={MEMBER_DATA} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/suspended/members/list"}>
                            <SUSPENDED_MEMBERS MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} SEARCHED_MEMBER_DATA={MEMBER_DATA} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/deceased/members/list"}>
                            <DECEASED_MEMBERS MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} SEARCHED_MEMBER_DATA={MEMBER_DATA} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/expelled/members/list"}>
                            <EXPELLED_MEMBERS MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} SEARCHED_MEMBER_DATA={MEMBER_DATA} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/members/profile/:id"}>
                            <MEMBER_PROFILE MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/edit/members/:id"}>
                            <MEMBER_EDIT MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/edit/nok/:id"}>
                            <EDIT_NEXT_OF_KIN MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} 
                            DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/create/member/dependant/:id"}>
                            <CREATE_BENEFICIARY MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/update/member/dependant/:id/:ben_id"}>
                            <UPDATE_BENEFICIARY MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/customercare/members/list"}>
                            <CC_ACTIVE_MEMBERS MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} SEARCHED_MEMBER_DATA={MEMBER_DATA} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/customercare/members/profile/:id"}>
                            <CC_MEMBER_PROFILE MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/agent/records"}>
                            <AGENT_RECORDS MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} SEARCHED_MEMBER_DATA={MEMBER_DATA} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/agent/profile/:agent_id"}>
                            <AGENT_PROFILE MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        {/* <Route path={configuration.DASHBOARD+"/add/new/agent"}>
                            <ADD_AGENT MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route> */}

                        <Route path={configuration.DASHBOARD+"/edit/agent/:agent_id"}>
                            <EDIT_AGENT MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/gws/dashboard"}>
                            <GROUP_WELFARE_SCHEME MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/register/new/company"}>
                            <REGISTER_COMPANY MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/gws/application"}>
                            <GWS_APPLICATION MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/gws/batch/application"}>
                            <GWS_BATCH_APPLICATION MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/gws/approval-policy"}>
                            <APPROVAL_AND_POLICY MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/gws/claims-processing"}>
                            <CLAIMS_PROCESSING MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>

                        <Route path={configuration.DASHBOARD+"/gws/claims-registering/:id"}>
                            <CLAIMS_REGISTERING MenuController={MenuController} DEFAULT_MENU_FEEDBACK={MenuControllerFEEDBACK} 
                            PAGE_TITLE_DATAFEEDBACK={PAGE_TITLE_DATAFEEDBACK} DEFAULT_SUB_MENU={SubMenuController} DEFAULT_SUB_MENU_FEEDBACK={SubMenuControllerFEEDBACK} />
                        </Route>
                    </Switch>






                    {/* <div style={{ opacity: "0" }} className="menu-modal">
                        <div className="new-side-menu popup">

                            <div className="task_nav">
                                <div className="div-block-9">
                                    <img src={configuration.APP_DATA_RESOURCE+"images/full-logo.svg"} loading="lazy" width="128" alt="" className="image-4" />
                                    <a data-w-id="2b4483cb-c57a-2957-4682-8986c392e625" href="#" className="w-inline-block">
                                        <img src={configuration.APP_DATA_RESOURCE+"images/close-icon.svg"} loading="lazy" width="94" alt="" />
                                    </a>
                                </div>

                                <a href="#" className="task_nav_button w-inline-block">
                                    <img src={configuration.APP_DATA_RESOURCE+"images/button-icon-1.svg"} loading="lazy" width="8" alt="" 
                                        className="task_nav_button_icon" />
                                    <div className="text-block">Dashboard</div>
                                </a>

                                <a href="#" className="task_nav_button inactive w-inline-block">
                                    <img src={configuration.APP_DATA_RESOURCE+"images/reg-agent-icon.svg"} loading="lazy" alt="" className="task_nav_button_icon" />
                                    <div className="text-block">Reg. Staffs</div>
                                </a>

                                <a href="#" className="task_nav_button inactive w-inline-block">
                                    <img src={configuration.APP_DATA_RESOURCE+"images/reg-agent-icon.svg"} loading="lazy" alt="" className="task_nav_button_icon" />
                                    <div className="text-block">Reg. Members</div>
                                </a>

                                <a href="#" className="task_nav_button inactive w-inline-block">
                                    <img src={configuration.APP_DATA_RESOURCE+"images/reg-agent-icon.svg"} loading="lazy" alt="" className="task_nav_button_icon" />
                                    <div className="text-block">Reg. Agents</div>
                                </a>
                            </div>

                            <div className="module_nav">
                                <a href="#" className="button_menu w-button">Finance</a>
                                <a href="#" className="button_menu white w-button">Member Record</a>
                                <a href="#" className="button_menu white w-button">Agent Registration</a>
                                <a href="#" className="button_menu w-button">Group Welfare Scheme</a>
                                <a href="#" aria-current="page" className="button_menu white selected w-button w--current">ID Card Issue</a>
                                <a href="#" className="button_menu w-button">H.M.O</a>
                                <a href="#" className="button_menu w-button">Admin</a>
                                </div>
                                <div className="logout_nav">
                                <a href="#" className="logout w-inline-block">
                                    <img src={configuration.APP_DATA_RESOURCE+"images/logout-logo.svg"} loading="lazy" alt="" className="image-6" />
                                    <div className="text-block-3">Sign-Out</div>
                                </a>
                            </div>

                        </div>
                    </div> */}

                </div>
            </div>
        </>
    );

}
