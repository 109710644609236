
// REACT STANDARD LIBRARY CALLS
import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';



/* IMPORT LIBRARY  */
import { postData, createCookie, getCookie, MOBILE_SCREEN_DETECTOR } from '../Library/Library.js';






export default function Login() {

    // lets set the page title. 
    document.getElementById("title").innerHTML = "Sign in | "+configuration.COMPANY_NAME;
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();   

    // A FUNCTION TO ENSURE YOU DONT SEE THE LOGIN PAGE AS LONG AS YOUR COOKIE IS YET TO EXPIRE
    if(getCookie("token"))history.push(configuration.DASHBOARD);


    /* state to be used to store user email data. */
    const [EMAIL, setEMAIL] = useState("");
    const [PASSWORD, setPASSWORD] = useState("");
    const EMAIL_onChange = (event) => {       setEMAIL(event.target.value);       };  
    const PASSWORD_onChange = (event) => {       setPASSWORD(event.target.value);       };

    // GET THE SCREEN COMPATIBILITY OF THE DEVICE VIEWING THIS APP
    // const screenCompatibility = MOBILE_SCREEN_DETECTOR(1394);
    const screenCompatibility = MOBILE_SCREEN_DETECTOR(1000);
    if(screenCompatibility === true)alert("Please change to a more compatible screen size to open this portal.");

    /*       P A G E       U N S U B M I T      F U N C T I O N       */
    /* function to be called when our form inputes are ready to be submitted. */
    const onSubmit = (e) => {
        /* line of code to ensure that html form default submit process is disabled, so that form submission can be handled by javascript in the REACT way.  */
        e.preventDefault();
        
        /* collect the user supplied email from the state variable, and prepare it for transport. */
        const data = { username: EMAIL, password: PASSWORD }
        /* call the api function responsible for user password reset operation. */
        const url = configuration.LOGIN_API;
        /* Call the "postData" function to help us handle api call.  */
        postData(url, data).then(response => {
            /* CREATE THE SESSION COOKIE, AND USE THE TOKEN DATA COLLECTED FROM THE SERVER TO SET THE SESSION VALUE. ALSO SET COOKIE EXPIRING TO 
             * WHAT EVER WE HAVE IN OUR CONFIG FILE ("createCookie" TAKES COOKIE EXPIRY IN MINUTES). */
            createCookie("token", response.token, configuration.SESSION_EXPIRY);
            /* REDIRECT USER TO PROTECTED PAGE, THAT IS THE USER DASHBOARD. */
            history.push(configuration.DASHBOARD);
        }).catch(error => {
            /* Since we are certain that request submission has failed because of a network error, therefore we communicate this info to user. */
            alert("Network Error, when attempting to sign you in. Please ensure you have a good internet connection.");
        });
    }


    // black

    return (
        <div className='body-2'>
            <div className="section wf-section" style={{  backgroundColor: "transparent", display: "flex", justifyContent: "center", alignItems: "center"  }}>
                <img src={configuration.APP_DATA_RESOURCE+"images/logo_home.png"} loading="lazy" height="60" width="80" alt="Firstcare Kalyan Logo" />
            </div>

            <div className="section-2 wf-section">
                <div className="div-block-2">
                    <img src={configuration.APP_DATA_RESOURCE+"images/logo-purple.png"} loading="lazy" height="40" width="41" alt="Firstcare Kalyan Logo" className="image-3" />
                </div>

                <div>
                    <h1 className="heading">Welcome!</h1>
                    <p className="paragraph">Please enter your login details to proceed</p>
                    <div className="w-form">
                        {(screenCompatibility === false) ? (
                            <form onSubmit={onSubmit} className="form" novalidate>
                                <input onChange={EMAIL_onChange} type="text" className="my-text-field w-input" maxlength="256" required />
                                <input onChange={PASSWORD_onChange} type="password" className="my-text-field w-input" maxlength="256" required />
                                <div>
                                    <p className="paragraph-2">I forgot my password</p>
                                </div>
                                <button className="log-in-button w-button" type="submit">Sign in</button>
                            </form>
                        ) : (
                            <></>
                        )}
                        
                        {/* <div className="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );

}
