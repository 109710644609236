
// REACT STANDARD LIBRARY CALLS
import React, {useState} from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import Modal from 'react-modal';


/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';

/* IMPORT LIBRARY  */
import { getData, getCookie, CAPITALIZE_FIRST_LETTERS, mathRandom } from '../../../Library/Library.js';





// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






export default function CLAIMS_REGISTERING({MenuController, DEFAULT_MENU_FEEDBACK, PAGE_TITLE_DATAFEEDBACK, DEFAULT_SUB_MENU, DEFAULT_SUB_MENU_FEEDBACK}) {

    // SET THE PAGE TITLE FOR THIS DASHBOAD SEMI-PAGE
    PAGE_TITLE_DATAFEEDBACK("G.W.S. Claims Registery | "+configuration.COMPANY_NAME);
    // GET FROM URL THE ID OF THE MEMBER WE WANT TO EDIT HIS/HER DATA
    const { id } = useParams();
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }







    // WE UPDATE THE PARENT COMPONENT TO REMIND IT THAT THE "GROUP_WELFARE" MENU IS WHAT IS CURRENTLY ACTIVE, WE DO THIS JUST SO THAT THE SIDEBAR
    // ACTIVE-MENU-SELECTOR DOES NOT GO OUT OF PLACE EACH TIME THE FULL PAGE IS RELOADED.
    DEFAULT_MENU_FEEDBACK("GROUP_WELFARE");
    DEFAULT_SUB_MENU_FEEDBACK("CLAIMS_PROCESSING");
    
    // if(MenuController === "ID_CARD"){

        return (
            
            <>

                <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                    <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
                </Modal>

                <div className="div-block-35" style={{ marginBottom: "0%", marginTop: "2%" }}>
                    <h1></h1>
                    <Link to={configuration.CLAIMS_PROCESSING_LINK} className="realbuttons w-button" style={{ marginBottom: "0" }}>Back</Link>
                </div>
                    
                <div className="my-container">
                    <div className="form-section">
                        <div className="div-block-39">
                            <h1 className="title_header">Member Info</h1>
                            <img src={configuration.APP_DATA_RESOURCE+"images/image-6.jpg"} loading="lazy" width="103" alt="Member_image" />
                        </div>
                        <div>
                            <div className="form-block-6 w-form">
                                <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="text v2">
                                    <input type="text" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="Last Name" id="name-2" />
                                    <input type="text" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="Middle Name" id="name-2" />
                                    <input type="text" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="First Name" id="name-2" />
                                </form>
                            </div>
                        </div>
                        <div>
                            <div className="form-block-6 w-form">
                                <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="text v2">
                                    <input type="text" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="Registration Date" id="name-2" />
                                    <input type="text" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="Due Status" id="name-2" />
                                    <input type="text" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="Type of Benefit" id="name-2" />
                                </form>
                            </div>
                        </div>
                        <div>
                            <div className="form-block-6 w-form">
                                <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="text v2">
                                    <select id="field-6" name="field-6" data-name="Field 6" className="text-fields w-select">
                                        <option value="">Gender</option>
                                        <option value="First">Male</option>
                                        <option value="Second">Female</option>
                                        <option value="Third">Confused</option>
                                    </select>
                                    <input type="tel" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="Phone Number 1" id="name-2" />
                                    <input type="tel" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="Phone Number 2" id="name-2" />
                                </form>
                            </div>
                        </div>
                        <div>
                            <div className="form-block-6 w-form">
                                <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="text v2">
                                    <input type="text" className="text-fields extra-long w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="Address" id="name-2" />
                                </form>
                            </div>
                        </div>
                        <div>
                            <div className="form-block-6 w-form">
                                <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="text v2">
                                    <input type="text" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="Branch" id="name-2" />
                                    <input type="text" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="City" id="name-2" />
                                    <input type="text" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="LGA" id="name-2" />
                                </form>
                            </div>
                        </div>

                        <div className="div-block-40"><h1 className="title_header">Next of Kin</h1></div>

                        <div>
                            <div className="form-block-6 w-form">
                                <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="text v2">
                                    <input type="text" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="Last Name" id="name-2" />
                                    <input type="text" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="Middle Name" id="name-2" />
                                    <input type="text" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="First Name" id="name-2" />
                                </form>
                            </div>
                        </div>
                        <div>
                            <div className="form-block-6 w-form">
                                <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="text v2">
                                    <select id="field-4" name="field-4" data-name="Field 4" className="text-fields w-select">
                                        <option value="">Relationship</option>
                                        <option value="First">Father</option>
                                        <option value="Second">Mother</option>
                                        <option value="Third">Son</option>
                                        <option value="fourth">Daughter</option>
                                        <option value="fifth">Brother</option>
                                        <option value="sixth">Sister</option>
                                    </select>
                                    <input type="tel" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="Phone Number 1" id="name-2" />
                                    <input type="tel" className="text-fields w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="Phone Number 2" id="name-2" />
                                </form>
                            </div>
                        </div>
                        <div>
                            <div className="form-block-6 w-form">
                                <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="text v2">
                                    <input type="text" className="text-fields extra-long w-input" maxlength="256" name="name-2" data-name="Name 2" 
                                        placeholder="Address" id="name-2" />
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="form-section second">
                        <div>
                            <div className="form-block-6 w-form">
                                <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="text v2">
                                    <select id="field-3" name="field-3" data-name="Field 3" className="text-fields extra-long w-select">
                                        <option value="">Account Type...</option>
                                        <option value="First">Account 1</option>
                                        <option value="Second">Account 2</option>
                                        <option value="Third">Account 3</option>
                                    </select>
                                </form>
                            </div>
                        </div>
                        <div className="div-block-41">
                            <div className="form-block-6 w-form">
                                <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="text v2">
                                    <textarea placeholder="Describe Accident" maxlength="5000" id="field-5" name="field-5" data-name="field" 
                                        className="textarea-3 w-input"></textarea>
                                </form>
                            </div>
                        </div>
                        
                        <div className="image-holder-form">
                            <img src={configuration.APP_DATA_RESOURCE+"images/Rectangle-5573.jpg"} loading="lazy" alt="Accident_image" />
                            <img src={configuration.APP_DATA_RESOURCE+"images/Rectangle-5572.jpg"} loading="lazy" alt="Accident_image" />
                            <img src={configuration.APP_DATA_RESOURCE+"images/Rectangle-5574.jpg"} loading="lazy" alt="Accident_image" />
                            <img src={configuration.APP_DATA_RESOURCE+"images/Rectangle-5575.png"} loading="lazy" alt="Accident_image" />
                        </div>

                        <div><p className="paragraph-11">Please upload only images related to incident.</p></div>

                        <div className="botton-holder">
                            <Link to={configuration.CLAIMS_PROCESSING_LINK} className="realbuttons w-button">Cancel</Link>
                            <button type='submit' className="realbuttons filled w-button">Make Claim</button>
                        </div>
                    </div>
                </div>

            </>
        );

    // }else{
    //     return null;
    // }

}
