
// REACT STANDARD LIBRARY CALLS
import React, {useState} from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import Modal from 'react-modal';


/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';

/* IMPORT LIBRARY  */
import { NIGERIAN_NUMBER_FORMATTER, ALL_LETTERS_CAPITALIZED, CAPITALIZE_FIRST_LETTERS, getCookie } from '../../../Library/Library.js';





// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






export default function REGISTERED_STAFFS({MenuController, DEFAULT_MENU_FEEDBACK, PAGE_TITLE_DATAFEEDBACK, DEFAULT_SUB_MENU, DEFAULT_SUB_MENU_FEEDBACK}) {

    // SET THE PAGE TITLE FOR THIS DASHBOAD SEMI-PAGE
    PAGE_TITLE_DATAFEEDBACK("Registered Staffs | "+configuration.COMPANY_NAME);
    // GET FROM URL THE ID OF THE MEMBER WE WANT TO EDIT HIS/HER DATA
    const { id } = useParams();
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }







    // WE UPDATE THE PARENT COMPONENT TO REMIND IT THAT THE "ID_CARD" MENU IS WHAT IS CURRENTLY ACTIVE, WE DO THIS JUST SO THAT THE SIDEBAR
    // ACTIVE-MENU-SELECTOR DOES NOT GO OUT OF PLACE EACH TIME THE FULL PAGE IS RELOADED.
    DEFAULT_MENU_FEEDBACK("ID_CARD");
    DEFAULT_SUB_MENU_FEEDBACK("REG_STAFF");
    
    // if(MenuController === "ID_CARD"){

        return (
            
            <>

                <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                    <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
                </Modal>
                    
                <div className="table_bg" style={{ marginBottom: "100px" }}>
                    <div className="div-block-35" style={{ display: "flex", justifyContent: "center", marginBottom: "15px" }}>
                        <h1 className="title_header"><b>REGISTERED STAFFS</b></h1>
                    </div>
                    <div className="data_row title">
                        <a href="#" className="member-data-holder w-inline-block">
                            <div className="table_data record title">Department</div>
                            <div className="table_data mini title">Staff Name</div>
                            <div className="table_data phone record title">Phone Number</div>
                            <div className="table_data title">Gender</div>
                        </a>
                        <div className="div-block-27"><p className="status table title">Action</p></div>
                    </div>
                    <div className="data_row">
                        <a href="#" className="member-data-holder w-inline-block">
                            <div className="table_data record">Accounting</div>
                            <div className="id-name-holder">
                                <img src={configuration.APP_DATA_RESOURCE+"images/image-2.jpg"} loading="lazy" width="39" height="40" alt="" className="id-image-tag" />
                                <div className="table_data mini id_tag">JAMES Afam</div>
                            </div>
                            <div className="table_data phone record">0704-365-5757</div>
                            <div className="table_data">Male</div>
                        </a>
                        <div className="div-block-27"><p className="status table active">ID CARD</p></div>
                    </div>
                    <div className="data_row">
                        <a href="#" className="member-data-holder w-inline-block">
                            <div className="table_data record">G.L.A.</div>
                            <div className="id-name-holder">
                                <img src={configuration.APP_DATA_RESOURCE+"images/small-photo-1.jpg"} loading="lazy" width="39" height="40" alt="" className="id-image-tag" />
                                <div className="table_data mini id_tag">ENA Eniola</div>
                            </div>
                            <div className="table_data phone record">0704-365-5757</div>
                            <div className="table_data">Female</div>
                        </a>
                        <div className="div-block-27"><p className="status table active">ID CARD</p></div>
                    </div>
                    <div className="data_row">
                        <a href="#" className="member-data-holder w-inline-block">
                            <div className="table_data record">Insurance</div>
                            <div className="id-name-holder">
                                <img src={configuration.APP_DATA_RESOURCE+"images/image-1.jpg"} loading="lazy" width="39" height="40" alt="" className="id-image-tag" />
                                <div className="table_data mini id_tag">WIKE Umo</div>
                            </div>
                            <div className="table_data phone record">0704-365-5757</div>
                            <div className="table_data">Male</div>
                        </a>
                        <div className="div-block-27"><p className="status table active">ID CARD</p></div>
                    </div>
                    <div className="data_row">
                        <a href="#" className="member-data-holder w-inline-block">
                            <div className="table_data record">H.M.O.</div>
                            <div className="id-name-holder">
                                <img src={configuration.APP_DATA_RESOURCE+"images/image-4.jpg"} loading="lazy" width="39" height="40" alt="" className="id-image-tag" />
                                <div className="table_data mini id_tag">DREW Igor</div>
                            </div>
                            <div className="table_data phone record">0704-365-5757</div>
                            <div className="table_data">Male</div>
                        </a>
                        <div className="div-block-27"><p className="status table active">ID CARD</p></div>
                    </div>

                    <div className="pagenation-container" style={{ marginTop: "50px", marginBottom: "20px" }}>
                        <div className="pagination-holder">
                            <button onClick={""}><p className="pagination">&lt;</p></button>
                        </div>
                        <div className="pagination-holder">
                            <p className="pagination">Page {"1"} of {"11"}</p>
                        </div>
                        <div className="pagination-holder">
                            <button onClick={""}><p className="pagination">&gt;</p></button>
                        </div>
                    </div>
                </div>

            </>
        );

    // }else{
    //     return null;
    // }

}
