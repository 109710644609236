// REACT STANDARD LIBRARY CALLS
import React, {useState, useRef, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Modal from 'react-modal';

// import { GoogleMap, LoadScript, StandaloneSearchBox } from '@react-google-maps/api';


/* WEB APP CONFIG FILE. */
import { configuration } from '../../Config/Config';

/* IMPORT LIBRARY  */
import { getData, getCookie, formatMoney } from '../../Library/Library.js';





// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






export default function TRACKING({MenuController, DEFAULT_MENU_FEEDBACK, PAGE_TITLE_DATAFEEDBACK, DEFAULT_SUB_MENU_FEEDBACK}) {


    // SET THE PAGE TITLE FOR THIS DASHBOAD SEMI-PAGE
    PAGE_TITLE_DATAFEEDBACK("Tracking | "+configuration.COMPANY_NAME);
    // we create "useHistory" object to be used for redirecting. 
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }


    // // STATE TO BE USED TO STORE DATA'S OF ALL AGENTS
    // const [TOTAL_AGENT, setTOTAL_AGENT] = useState("");
    // // STATE TO BE USED TO STORE DATA'S OF ALL MEMBERS
    // const [TOTAL_MEMBER, setTOTAL_MEMBER] = useState("");

    // const onLoad = (ref) => {
    // // Handle map load
    // };

    // const onPlacesChanged = () => {
    // // Handle places changed
    // };


    // // LOAD AGENT DATAS AS COMPONENT LOADS UP
    // useEffect(() => {
    //     
    // }, []);


    // WE UPDATE THE PARENT COMPONENT TO REMIND IT THAT THE "MAIN_DASHBOARD" MENU IS WHAT IS CURRENTLY ACTIVE, WE DO THIS JUST SO THAT THE SIDEBAR
    // ACTIVE-MENU-SELECTOR DOES NOT GO OUT OF PLACE EACH TIME THE FULL PAGE IS RELOADED.
    DEFAULT_MENU_FEEDBACK("TRACKING");
    DEFAULT_SUB_MENU_FEEDBACK("ADDRESS_TRACKING");
    
    // if(MenuController === "ID_CARD"){

        return (
            
            <>

                <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                    <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
                </Modal>

                <div className="dashboard_statistics" style={{ marginBottom: "30%", background: "white" }}>
                    <h2 className="total_text" style={{ marginTop: "5%" }}> TRIANGULATE ADDRESS </h2>

                    {/* <input id="pac-input" className="controls" type="text" placeholder="Search Box" />
                    <div id="map"></div>
                    <a onClick={() => fuck()} href=''>Hey!</a> */}

                    {/* <LoadScript googleMapsApiKey="AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg"> */}
                    {/* <LoadScript googleMapsApiKey="AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg" libraries={['places']}>
                        <GoogleMap
                            id="map"
                            center={{ lat: -33.8688, lng: 151.2195 }}
                            zoom={13}
                            onLoad={onLoad}
                        >
                            <StandaloneSearchBox
                            onLoad={onLoad}
                            onPlacesChanged={onPlacesChanged}
                            >
                            <input
                                type="text"
                                placeholder="Search places..."
                                id="pac-input"
                                style={{
                                boxSizing: 'border-box',
                                border: '1px solid transparent',
                                width: '240px',
                                height: '32px',
                                padding: '0 12px',
                                borderRadius: '3px',
                                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                fontSize: '14px',
                                outline: 'none',
                                textOverflow: 'ellipses',
                                position: 'absolute',
                                left: '50%',
                                marginLeft: '-120px',
                                }}
                            />
                            </StandaloneSearchBox>
                        </GoogleMap>
                    </LoadScript> */}

                    {/* Your map container */}
                    {/* <div id="map"></div> */}

                    {/* Your input element */}
                    {/* <input id="pac-input" type="text" placeholder="Search for a place" /> */}

                    
                </div>

            </>
        );
    // }else{
    //     return null;
    // }

}


