
// REACT STANDARD LIBRARY CALLS
import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import Modal from 'react-modal';

/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';

/* IMPORT COMPONENTS. */
import NIGERIA_STATES_DROPDOWN from '../../../Components/Nigerian_States';

/* IMPORT LIBRARY  */
import { FORMDATA_POSTDATA, FIRST_FEW_CHARS_IN_STRING_FETCHER, getCookie, FORM_DATA_PRINTER, getImg } from '../../../Library/Library.js';
import { AGENT_REGISTRATION_DATA_AUTHENTICATOR } from '../../../Library/REG_LIBRARY.js';






// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






export default function REGISTER_COMPANY({MenuController, DEFAULT_MENU_FEEDBACK, PAGE_TITLE_DATAFEEDBACK, DEFAULT_SUB_MENU, DEFAULT_SUB_MENU_FEEDBACK}) {

    // SET THE PAGE TITLE FOR THIS DASHBOAD SEMI-PAGE
    PAGE_TITLE_DATAFEEDBACK("Company Registration | "+configuration.COMPANY_NAME);
    // GET FROM URL THE ID OF THE MEMBER WE WANT TO EDIT HIS/HER DATA
    const { id } = useParams();
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }
    


    // CREATE STATE TO BE USED TO CONTROL BUTTON LOADING ANIMATION, FOR THIS COMPONENT.
    const [BUTTON_LOADING_ANIM, setBUTTON_LOADING_ANIM] = useState(false);
    // CREATE FORM STATES HERE
    const [COMPANY_NAME, setCOMPANY_NAME] = useState("");
    const [COMPANY_EMAIL, setCOMPANY_EMAIL] = useState("");
    const [COMPANY_ADDRESS_1, setCOMPANY_ADDRESS_1] = useState("");
    const [COMPANY_ADDRESS_2, setCOMPANY_ADDRESS_2] = useState("");
    const [COMPANY_PHONE_1, setCOMPANY_PHONE_1] = useState("");
    const [COMPANY_PHONE_2, setCOMPANY_PHONE_2] = useState("");
    const [COMPANY_CITY, setCOMPANY_CITY] = useState("");
    const [COMPANY_STATE, setCOMPANY_STATE] = useState("");

    // UNCHANGED FUNCTIONS FOR THE STATES ABOVE
    const COMPANY_NAME_onChange = (event) => {         setCOMPANY_NAME(event.target.value);         };
    const COMPANY_EMAIL_onChange = (event) => {         setCOMPANY_EMAIL(event.target.value);         };
    const COMPANY_ADDRESS_1_onChange = (event) => {         setCOMPANY_ADDRESS_1(event.target.value);         };
    const COMPANY_ADDRESS_2_onChange = (event) => {         setCOMPANY_ADDRESS_2(event.target.value);         };
    const COMPANY_PHONE_1_onChange = (event) => {         setCOMPANY_PHONE_1(event.target.value);         };
    const COMPANY_PHONE_2_onChange = (event) => {         setCOMPANY_PHONE_2(event.target.value);         };
    const COMPANY_CITY_onChange = (event) => {         setCOMPANY_CITY(event.target.value);         };
    const COMPANY_STATE_onChange = (event) => {         setCOMPANY_STATE(event.target.value);         };

    
    
    // FUNCTION TO REGISTER NEW COMPANY TO THE SYSTEM
    const REGISTER_COMPANY = (e) => {
        /* line of code to ensure that html form default submit process is disabled, so that form submission can be handled by javascript in the REACT way.  */
        e.preventDefault();

        /* collect all data from their official states storage and compile them into an object, in a bid to prepare the datas for validation 
         * before we can proceed to submitting data. */
        const RAW_DATA = {};
        /* Here we launch the "RAW_DATA_AUTHENTICATOR" function to help us validate the authenticity of all the datas user has provided. */
        const AUTHENTICATOR_DATA = true; //AGENT_REGISTRATION_DATA_AUTHENTICATOR(RAW_DATA);

        /* Before any major registration operations can be permitted to begin, firstly the "REGISTRATION_DATA_AUTHENTICATOR_2" function must first give clear indications that the data
         * provided by user is valid. So at this junction we test to see if we have clearance to register this user.  */
        if(AUTHENTICATOR_DATA === true){
            /* Here we signal the button state change react-state, to indicate that button is clicked and request sent. */
            setBUTTON_LOADING_ANIM(true);
            
            /* call the api function responsible for handling the profile updating job. */
            const url = configuration.REGISTER_COMPANY_API;
            /* Call the "putData" function to help us handle submission. */
            FORMDATA_POSTDATA(url, RAW_DATA, session, 'POST').then(response => {
                // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
                if(response.status === 200){
                    // display success message
                    //openModal("Agent added successfully.");
                    // revert user back to member page
                    //history.push(configuration.AGENT_RECORDS_LINK);
                }else{
                    // display server error message
                    openModal("System encountered an internal error while trying to process your data.");
                }

                // Here we signal the button state change react-state, to indicate that request has been completed. 
                setBUTTON_LOADING_ANIM(false);
            }).catch(error => {
                // Here we signal the button state change react-state, to indicate that request has been completed. 
                setBUTTON_LOADING_ANIM(false);
                // At this junction we are certain that request submission has failed because of a network error, therefore we send out network 
                // failure message
                openModal("Network Error, when attempting to save data. Please ensure you have a good internet connection.");
            });
            
        }else{
            /* control getting in here signifies that the authentication of user supplied profile data has failed for one reason or the other, further meaning user did 
             * not fill one or more form field well, therefore we initialize our alert container to tell user what he or she is doing wrong. */
            openModal(AUTHENTICATOR_DATA);
        }
    };







    
    

    // WE UPDATE THE PARENT COMPONENT TO REMIND IT THAT THE "GROUP_WELFARE" MENU IS WHAT IS CURRENTLY ACTIVE, WE DO THIS JUST SO THAT THE SIDEBAR
    // ACTIVE-MENU-SELECTOR DOES NOT GO OUT OF PLACE EACH TIME THE FULL PAGE IS RELOADED.
    DEFAULT_MENU_FEEDBACK("GROUP_WELFARE");
    DEFAULT_SUB_MENU_FEEDBACK("COMPANY_REG");
    
    // if(MenuController === "AGENT_REG"){

        return (
            
            <>

                <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                    <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
                </Modal>
                
                <div className="div-block-38">
                    <div className="title-holder">
                        <h1 className="title_header">Insurance Companies</h1>
                    </div>
                    <form onSubmit={REGISTER_COMPANY} method="get">
                        <div className="form-holder" style={{ marginBottom: "1%" }}>
                            <div className="reg-form-block w-form">
                                <div className="text">
                                    <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> Surname </small> 
                                    <input type="text" className="text-fields w-input" minlength="3" maxlength="40" name="name-2" data-name="Name 2" 
                                        placeholder="Company Name" id="name-2" onChange={COMPANY_NAME_onChange} required /></label>
                                    <label style={{ color: "#777" }}> <small style={{ marginLeft: "2%" }}> Surname </small> 
                                    <input type="email" className="text-fields w-input" minlength="3" maxlength="40" name="email" data-name="Email" 
                                        placeholder="Company Email" id="email" onChange={COMPANY_EMAIL_onChange} required /></label>
                                </div>
                            </div>
                        </div>
                        <div className="form-holder" style={{ marginBottom: "1%" }}>
                            <div className="reg-form-block w-form">
                                <div className="text">
                                    <input type="text" className="text-fields long w-input" minlength="3" maxlength="40" name="name-2" data-name="Name 2" 
                                        placeholder="Company Address" id="name-2" onChange={COMPANY_ADDRESS_1_onChange} required />
                                </div>
                            </div>
                        </div>
                        <div className="form-holder" style={{ marginBottom: "1%" }}>
                            <div className="reg-form-block w-form">
                                <div className="text">
                                    <input type="text" className="text-fields long w-input" minlength="3" maxlength="40" name="name-2" data-name="Name 2" 
                                    placeholder="Company Address 2" id="name-2" onChange={COMPANY_ADDRESS_2_onChange} />
                                </div>
                            </div>
                        </div>
                        <div className="form-holder" style={{ marginBottom: "1%" }}>
                            <div className="reg-form-block w-form">
                                <div className="text">
                                    <input type="text" className="text-fields w-input" minlength="3" maxlength="40" name="name-2" data-name="Name 2" 
                                        placeholder="City" id="name-2" onChange={COMPANY_CITY_onChange} required />
                                    <select className="text-fields w-input" minlength="3" maxlength="40" name="email-2" data-name="Email 2"
                                        id="email-2" onChange={COMPANY_STATE_onChange} required >
                                        <option value="">Select State</option>
                                        <NIGERIA_STATES_DROPDOWN />
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="form-holder" style={{ marginBottom: "1%" }}>
                            <div className="reg-form-block w-form">
                                <div className="text">
                                    <input type="tel" className="text-fields w-input" minlength="3" maxlength="13" name="name-2" data-name="Name 2" 
                                        placeholder="0000-000-0000" id="name-2" onChange={COMPANY_PHONE_1_onChange} required />
                                    <input type="tel" className="text-fields w-input" minlength="3" maxlength="13" name="email-2" data-name="Email 2" 
                                        placeholder="0000-000-0000" id="email-2" onChange={COMPANY_PHONE_2_onChange} />
                                </div>
                            </div>
                        </div>
                        <div className="botton-holder">
                            <a href={configuration.GWS_DASHBOARD_LINK} className="realbuttons w-button">Back</a>
                            {(BUTTON_LOADING_ANIM === true) ? (
                                <button type="button" className="realbuttons w-button">Registering........</button>
                            ) : (
                                <button type="submit" className="realbuttons filled w-button">Register</button>
                            )}
                        </div>
                    </form>
                </div>

            </>
        );

    // }else{
    //     return null;
    // }

}





