



// MOST ACCURATE AND UP TO DATE LOCAL GOVERNMENT AREA AND BRANCHES AND STATE EXTRACTED FROM 
// https://www.inecnigeria.org/elections/polling-units/
// USING A SCRAPPER TOOL CREATED FOR THIS PURPOSE AND STORED SOMEWHERE IN THIS PROJECT

/* JAVASCRIPT FUNCTION TO HELP US COMPILE AND RETURN A JAVASCRIPT OBJECT LIST OF ALL STATES IN NIGERIA AND THEIR RESPECTIVE LOCAL GOVERNMENT AREAS.  */
export function NIGERIA_STATES_LOCAL_GOVERNMENT_AREA(){   
    
  /* compile as a javascript object, a list of all states in nigeria and their local government areas. */
  const lgaList = {
    ABIA: [
    "ABA NORTH",
    "ABA SOUTH",
    "AROCHUKWU",
    "BENDE",
    "IKWUANO",
    "ISIALA NGWA NORTH",
    "ISIALA NGWA SOUTH",
    "ISUIKWUATO",
    "OBINGWA",
    "OHAFIA",
    "OSISIOMA",
    "UGWUNAGBO",
    "UKWA EAST",
    "UKWA WEST",
    "UMUAHIA NORTH",
    "UMUAHIA SOUTH",
    "UMU-NNEOCHI"
    ],
    ADAMAWA: [
    "DEMSA",
    "FUFORE",
    "GANYE",
    "GIRE 1",
    "GOMBI",
    "GUYUK",
    "HONG",
    "JADA",
    "LAMURDE",
    "MADAGALI",
    "MAIHA",
    "MAYO-BELWA",
    "MICHIKA",
    "MUBI NORTH",
    "MUBI SOUTH",
    "NUMAN",
    "SHELLENG",
    "SONG",
    "TOUNGO",
    "YOLA NORTH",
    "YOLA SOUTH"
    ],
    "AKWA IBOM": [
    "ABAK",
    "EASTERN OBOLO",
    "EKET",
    "ESIT EKET (UQUO)",
    "ESSIEN UDIM",
    "ETIM EKPO",
    "ETINAN",
    "IBENO",
    "IBESIKPO ASUTAN",
    "IBIONO IBOM",
    "IKA",
    "IKONO",
    "IKOT ABASI",
    "IKOT EKPENE",
    "INI",
    "ITU",
    "MBO",
    "MKPAT ENIN",
    "NSIT ATAI",
    "NSIT IBOM",
    "NSIT UBIUM",
    "OBOT AKARA",
    "OKOBO",
    "ONNA",
    "ORON",
    "ORUK ANAM",
    "UDUNG UKO",
    "UKANAFUN",
    "URUAN",
    "URUE OFFONG/ORUKO",
    "UYO"
    ],
    ANAMBRA: [
    "AGUATA",
    "AYAMELUM",
    "ANAMBRA EAST",
    "ANAMBRA WEST",
    "ANAOCHA",
    "AWKA NORTH",
    "AWKA SOUTH",
    "DUNUKOFIA",
    "EKWUSIGO",
    "IDEMILI NORTH",
    "IDEMILI SOUTH",
    "IHALA",
    "NJIKOKA",
    "NNEWI NORTH",
    "NNEWI SOUTH",
    "OGBARU",
    "ONITSHA-NORTH",
    "ONITSHA-SOUTH",
    "ORUMBA NORTH",
    "ORUMBA SOUTH",
    "OYI"
    ],
    BAUCHI: [
    "ALKALERI",
    "BAUCHI",
    "BOGORO",
    "DAMBAN",
    "DARAZO",
    "DASS",
    "GAMAWA",
    "GANJUWA",
    "GIADE",
    "ITAS/GADAU",
    "JAMA'ARE",
    "KATAGUM",
    "KIRFI",
    "MISAU",
    "NINGI",
    "SHIRA",
    "TAFAWA BALEWA",
    "TORO",
    "WARJI",
    "ZAKI"
    ],
    BAYELSA: [
    "BRASS",
    "EKEREMOR",
    "KOLOKUMA/OPOKUMA",
    "NEMBE",
    "OGBIA",
    "SAGBAMA",
    "SOUTHERN IJAW",
    "YENAGOA"
    ],
    BENUE: [
    "ADO",
    "AGATU",
    "APA",
    "BURUKU",
    "GBOKO",
    "GUMA",
    "GWER EAST",
    "GWER WEST",
    "KATSINA-ALA",
    "KONSHISHA",
    "KWANDE",
    "LOGO",
    "MAKURDI",
    "OBI",
    "OGBADIBO",
    "OJU",
    "OHIMINI",
    "OKPOKWU",
    "OTUKPO",
    "TARKA",
    "UKUM",
    "USHONGO",
    "VANDEIKYA"
    ],
    BORNO: [
    "ABADAM",
    "ASKIRA/UBA",
    "BAMA",
    "BAYO",
    "BIU",
    "CHIBOK",
    "DAMBOA",
    "DIKWA",
    "GUBIO",
    "GUZAMALA",
    "GWOZA",
    "HAWUL",
    "JERE",
    "KAGA",
    "KALA BALGE",
    "KONDUGA",
    "KUKAWA",
    "KWAYA/KUSAR",
    "MAFA",
    "MAGUMERI",
    "MAIDUGURI M.C.",
    "MARTE",
    "MOBBAR",
    "MONGUNO",
    "NGALA",
    "NGANZAI",
    "SHANI"
    ],
    "CROSS RIVER": [
    "ABI",
    "AKAMKPA",
    "AKPABUYO",
    "BAKASSI",
    "BEKWARRA",
    "BIASE",
    "BOKI",
    "CALABAR MUNICIPALITY",
    "CALABAR SOUTH",
    "ETUNG",
    "IKOM",
    "OBANLIKU",
    "OBUBRA",
    "OBUDU",
    "ODUKPANI",
    "OGOJA",
    "YAKURR",
    "YALA"
    ],
    DELTA: [
    "ANIOCHA NORTH",
    "ANIOCHA SOUTH",
    "BOMADI",
    "BURUTU",
    "ETHIOPE EAST",
    "ETHIOPE WEST",
    "IKA NORTH-EAST",
    "IKA SOUTH",
    "ISOKO NORTH",
    "ISOKO SOUTH",
    "NDOKWA EAST",
    "NDOKWA WEST",
    "OKPE",
    "OSHIMILI NORTH",
    "OSHIMILI SOUTH",
    "PATANI",
    "SAPELE",
    "UDU",
    "UGHELLI NORTH",
    "UGHELLI SOUTH",
    "UKWUANI",
    "UVWIE",
    "WARRI NORTH",
    "WARRI SOUTH",
    "WARRI SOUTH-WEST"
    ],
    EBONYI: [
    "ABAKALIKI",
    "AFIKPO NORTH",
    "AFIKPO SOUTH",
    "EBONYI",
    "EZZA NORTH",
    "EZZA SOUTH",
    "IKWO",
    "ISHIELU",
    "IVO",
    "IZZI",
    "OHAOZARA",
    "OHAUKWU",
    "ONICHA"
    ],
    EDO: [
    "AKOKO EDO",
    "EGOR",
    "ESAN CENTRAL",
    "ESAN NORTH-EAST",
    "ESAN SOUTH-EAST",
    "ESAN WEST",
    "ETSAKO CENTRAL",
    "ETSAKO EAST",
    "ETSAKO WEST",
    "IGUEBEN",
    "IKPOBA/OKHA",
    "OREDO",
    "ORHIONMWON",
    "OVIA NORTH-EAST",
    "OVIA SOUTH-WEST",
    "OWAN EAST",
    "OWAN WEST",
    "UHUNMWODE"
    ],
    EKITI: [
    "ADO EKITI",
    "EFON",
    "EKITI EAST",
    "EKITI WEST",
    "EKITI SOUTH-WEST",
    "EMURE",
    "GBONYIN",
    "IDO/OSI",
    "IJERO",
    "IKERE",
    "IKOLE",
    "ILEJEMEJE",
    "IREPODUN/IFELODUN",
    "ISE/ORUN",
    "MOBA",
    "OYE"
    ],
    RIVERS: [
    "ABUA-ODUAL",
    "AHOADA EAST",
    "AHOADA WEST",
    "AKUKU TORU",
    "ANDONI",
    "ASARI-TORU",
    "BONNY",
    "DEGEMA",
    "ELEME",
    "EMOHUA",
    "ETCHE",
    "GOKANA",
    "IKWERRE",
    "KHANA",
    "OBIO/AKPOR",
    "OGBA/EGBEMA/NDONI",
    "OGU/BOLO",
    "OKRIKA",
    "OMUMA",
    "OPOBO/NEKORO",
    "OYIGBO",
    "PORT HARCOURT",
    "TAI"
    ],
    ENUGU: [
    "ANINRI",
    "AWGU",
    "ENUGU EAST",
    "ENUGU NORTH",
    "ENUGU SOUTH",
    "EZEAGU",
    "IGBO ETITI",
    "IGBO EZE NORTH",
    "IGBO EZE SOUTH",
    "ISI UZO",
    "NKANU EAST",
    "NKANU WEST",
    "NSUKKA",
    "OJI-RIVER",
    "UDENU",
    "UDI",
    "UZO-UWANI"
    ],
    ABUJA: [
    "ABAJI",
    "BWARI",
    "GWAGWALADA",
    "KUJE",
    "KWALI",
    "MUNICIPAL"
    ],
    GOMBE: [
    "AKKO",
    "BALANGA",
    "BILLIRI",
    "DUKKU",
    "FUNAKAYE",
    "GOMBE",
    "KALTUNGO",
    "KWAMI",
    "NAFADA",
    "SHONGOM",
    "YALMALTU/DEBA"
    ],
    IMO: [
    "ABOH MBAISE",
    "AHIAZU MBAISE",
    "EHIME MBANO",
    "EZINIHITTE MBAISE",
    "IDEATO NORTH",
    "IDEATO SOUTH",
    "IHITTE/UBOMA (ISINWEKE)",
    "IKEDURU (IHO)",
    "ISIALA MBANO (UMUELEMAI)",
    "ISU (UMUNDUGBA)",
    "MBAITOLI (NWAORIEUBI)",
    "NGOR OKPALA (UMUNEKE)",
    "NJABA (NNENASA)",
    "NKWERRE",
    "NWANGELE (ONU-NWANGELE AMAIGBO)",
    "OBOWO (OTOKO)",
    "OGUTA (OGUTA)",
    "OHAJI/EGBEMA (MMAHU-EGBEMA)",
    "OKIGWE (OKIGWE)",
    "ONUIMO (OKWE)",
    "ORLU",
    "ORSU (AWO IDEMILI)",
    "ORU EAST",
    "ORU WEST (MGBIDI)",
    "OWERRI MUNICIPAL",
    "OWERRI NORTH (ORIE URATTA)",
    "OWERRI WEST (UMUGUMA)"
    ],
    JIGAWA: [
    "AUYO",
    "BABURA",
    "BIRNIN KUDU",
    "BIRNIWA",
    "BUJI",
    "DUTSE",
    "GARKI",
    "GAGARAWA",
    "GUMEL",
    "GURI",
    "GWARAM",
    "GWIWA",
    "HADEJIA",
    "JAHUN",
    "KAFIN HAUSA",
    "KAUGAMA",
    "KAZAURE",
    "KIRIKA SAMMA",
    "KIYAWA",
    "MAIGATARI",
    "MALAM MADORI",
    "MIGA",
    "RINGIM",
    "RONI",
    "SULE-TANKARKAR",
    "TAURA",
    "YANKWASHI"
    ],
    KADUNA: [
    "BIRNIN GWARI",
    "CHIKUN",
    "GIWA",
    "IGABI",
    "IKARA",
    "JABA",
    "JEMA'A",
    "KACHIA",
    "KADUNA NORTH",
    "KADUNA SOUTH",
    "KAGARKO",
    "KAJURU",
    "KAURA",
    "KAURU",
    "KUBAU",
    "KUDAN",
    "LERE",
    "MAKARFI",
    "SABON GARI",
    "SANGA",
    "SOBA",
    "ZANGON KATAF",
    "ZARIA"
    ],
    KANO: [
    "AJINGI",
    "ALBASU",
    "BAGWAI",
    "BEBEJI",
    "BICHI",
    "BUNKURE",
    "DALA",
    "DAMBATA",
    "DAWAKI KUDU",
    "DAWAKI TOFA",
    "DOGUWA",
    "FAGGE",
    "GABASAWA",
    "GARKO",
    "GARUN MALAM",
    "GAYA",
    "GEZAWA",
    "GWALE",
    "GWARZO",
    "KABO",
    "KANO MUNICIPAL",
    "KARAYE",
    "KIBIYA",
    "KIRU",
    "KUMBOTSO",
    "KUNCHI",
    "KURA",
    "MADOBI",
    "MAKODA",
    "MINJIBIR",
    "NASARAWA",
    "RANO",
    "RIMIN GADO",
    "ROGO",
    "SHANONO",
    "SUMAILA",
    "TAKAI",
    "TARAUNI",
    "TOFA",
    "TSANYAWA",
    "TUDUN WADA",
    "UNGOGO",
    "WARAWA",
    "WUDIL"
    ],
    KATSINA: [
    "BAKORI",
    "BATAGARAWA",
    "BATSARI",
    "BAURE",
    "BINDAWA",
    "CHARANCHI",
    "DANDUME",
    "DANJA",
    "DAN MUSA",
    "DAURA",
    "DUTSI",
    "DUTSIN-MA",
    "FASKARI",
    "FUNTUA",
    "INGAWA",
    "JIBIA",
    "KAFUR",
    "KAITA",
    "KANKARA",
    "KANKIA",
    "KATSINA",
    "KURFI",
    "KUSADA",
    "MAI'ADUA",
    "MALUFASHI",
    "MANI",
    "MASHI",
    "MATAZU",
    "MUSAWA",
    "RIMI",
    "SABUWA",
    "SAFANA",
    "SANDAMU",
    "ZANGO"
    ],
    KEBBI: [
    "ALIERO",
    "AREWA",
    "ARGUNGU",
    "AUGIE",
    "BAGUDO",
    "BIRNIN KEBBI",
    "BUNZA",
    "DANDI",
    "FAKAI",
    "GWANDU",
    "JEGA",
    "KALGO",
    "KOKO/BESSE",
    "MAIYAMA",
    "NGASKI",
    "SAKABA",
    "SHANGA",
    "SURU",
    "WASAGU/DANKO",
    "YAURI",
    "ZURU"
    ],
    KOGI: [
    "ADAVI",
    "AJAOKUTA",
    "ANKPA",
    "BASSA",
    "DEKINA",
    "IBAJI",
    "IDAH",
    "IGALAMELA/ODOLU",
    "IJUMU",
    "KABBA/BUNU",
    "KOGI K.K.",
    "LOKOJA",
    "MOPA MORO",
    "OFU",
    "OGORI MANGOGO",
    "OKEHI",
    "OKENE",
    "OLAMABORO",
    "OMALA",
    "YAGBA EAST",
    "YAGBA WEST"
    ],
    KWARA: [
    "ASA",
    "BARUTEN",
    "EDU",
    "EKITI",
    "IFELODUN",
    "ILORIN EAST",
    "ILORIN SOUTH",
    "ILORIN WEST",
    "IREPODUN",
    "ISIN",
    "KAIAMA",
    "MORO",
    "OFFA",
    "OKE-ERO",
    "OYUN",
    "PATIGI"
    ],
    LAGOS: [ 
    "AGEGE",
    "AJEROMI/IFELODUN",
    "ALIMOSHO",
    "AMUWO-ODOFIN",
    "APAPA",
    "BADAGRY",
    "EPE",
    "ETI-OSA",
    "IBEJU/LEKKI",
    "IFAKO-IJAYE",
    "IKEJA",
    "IKORODU",
    "KOSOFE",
    "LAGOS ISLAND",
    "LAGOS MAINLAND",
    "MUSHIN",
    "OJO",
    "OSHODI/ISOLO",
    "SOMOLU",
    "SURULERE"
    ],
    NASARAWA: [
    "AKWANGA",
    "AWE",
    "DOMA",
    "KARU",
    "KEANA",
    "KEFFI",
    "KOKONA",
    "LAFIA",
    "NASARAWA EGGON",
    "TOTO",
    "WAMBA",
    "NASARAWA",
    "OBI"
    ],
    NIGER: [
    "AGAIE",
    "AGWARA",
    "BIDA",
    "BORGU",
    "BOSSO",
    "CHANCHAGA",
    "EDATTI",
    "GBAKO",
    "GURARA",
    "KATCHA",
    "KONTAGORA",
    "LAPAI",
    "LAVUN",
    "MAGAMA",
    "MARIGA",
    "MASHEGU",
    "MOKWA",
    "MUNYA",
    "PAIKORO",
    "RAFI",
    "RIJAU",
    "SHIRORO",
    "SULEJA",
    "TAFA",
    "WUSHISHI"
    ],
    OGUN: [
    "ABEOKUTA NORTH",
    "ABEOKUTA SOUTH",
    "ADO ODO-OTA",
    "EGBADO NORTH",
    "EGBADO SOUTH",
    "EWEKORO",
    "IFO",
    "IJEBU EAST",
    "IJEBU NORTH",
    "IJEBU NORTH-EAST",
    "IJEBU ODE",
    "IKENNE",
    "IMEKO/AFON",
    "IPOKIA",
    "OBAFEMI/OWODE",
    "ODEDA",
    "ODOGBOLU",
    "OGUN WATER SIDE",
    "REMO NORTH",
    "SAGAMU"
    ],
    ONDO: [
    "AKOKO NORTH-EAST",
    "AKOKO NORTH-WEST",
    "AKOKO SOUTH-EAST",
    "AKOKO SOUTH-WEST",
    "AKURE NORTH",
    "AKURE SOUTH",
    "ESE-ODO",
    "IDANRE",
    "IFEDORE",
    "ILAJE",
    "ILEOLUJI/OKEIGBO",
    "IRELE",
    "ODIGBO",
    "OKITIPUPA",
    "ONDO EAST",
    "ONDO WEST",
    "OSE",
    "OWO"
    ],
    OSUN: [
    "ATAKUMOSA EAST",
    "ATAKUMOSA WEST",
    "AYEDAADE",
    "AYEDIRE",
    "BOLUWADURO",
    "BORIPE",
    "EDE NORTH",
    "EDE SOUTH",
    "EGBEDORE",
    "EJIGBO",
    "IFE CENTRAL",
    "IFEDAYO",
    "IFE EAST",
    "IFE NORTH",
    "IFE SOUTH",
    "ILA",
    "ILESA EAST",
    "ILESA WEST",
    "IREWOLE",
    "ISOKAN",
    "IWO",
    "OBOKUN",
    "ODO-OTIN",
    "OLA-OLUWA",
    "OLORUNDA",
    "ORIADE",
    "OROLU",
    "OSOGBO",
    "IREPODUN",
    "IFELODUN"
    ],
    OYO: [
    "AFIJIO",
    "AKINYELE",
    "ATIBA",
    "ATISBO",
    "EGBEDA",
    "IBADAN NORTH",
    "IBADAN NORTH-EAST",
    "IBADAN NORTH-WEST",
    "IBADAN SOUTH-EAST",
    "IBADAN SOUTH-WEST",
    "IBARAPA CENTRAL",
    "IBARAPA EAST",
    "IBARAPA NORTH",
    "IDO",
    "IREPO",
    "ISEYIN",
    "ITESIWAJU",
    "IWAJOWA",
    "KAJOLA",
    "LAGELU",
    "OGBOMOSHO NORTH",
    "OGBOMOSHO SOUTH",
    "OGO-OLUWA",
    "OLORUNSOGO",
    "OLUYOLE",
    "ONA-ARA",
    "OORELOPE",
    "ORI IRE",
    "OYO EAST",
    "OYO WEST",
    "SAKI EAST",
    "SAKI WEST",
    "SURULERE"
    ],
    PLATEAU: [
    "BARIKIN LADI",
    "BOKKOS",
    "JOS EAST",
    "JOS NORTH",
    "JOS SOUTH",
    "KANAM",
    "KANKE",
    "LANGTANG NORTH",
    "LANGTANG SOUTH",
    "MANGU",
    "MIKANG",
    "PANKSHIN",
    "QUA'AN PAN",
    "RIYOM",
    "SHENDAM",
    "WASE",
    "BASSA"
    ],
    SOKOTO: [
    "BINJI",
    "BODINGA",
    "DANGE/SHUNI",
    "GADA",
    "GORONYO",
    "GUDU",
    "GWADABAWA",
    "ILLELA",
    "ISA",
    "KWARE",
    "KEBBE",
    "RABAH",
    "SABON/BIRNI",
    "SHAGARI",
    "SILAME",
    "SOKOTO NORTH",
    "SOKOTO SOUTH",
    "TAMBUWAL",
    "TANGAZA",
    "TURETA",
    "WAMAKKO",
    "WURNO",
    "YABO"
    ],
    TARABA: [
    "ARDO-KOLA",
    "BALI",
    "DONGA",
    "GASHAKA",
    "GASSOL",
    "IBI",
    "JALINGO",
    "KARIM-LAMIDO",
    "KURMI",
    "LAU",
    "SARDAUNA",
    "TAKUM",
    "USSA",
    "WUKARI",
    "YORRO",
    "ZING"
    ],
    YOBE: [
    "BADE",
    "BURSARI",
    "DAMATURU",
    "FIKA",
    "FUNE",
    "GEIDAM",
    "GUJBA",
    "GULANI",
    "JAKUSKO",
    "KARASAWA",
    "MACHINA",
    "NANGERE",
    "NGURU",
    "POTISKUM",
    "TARMUWA",
    "YUNUSARI",
    "YUSUFARI"
    ],
    ZAMFARA: [
    "ANKA",
    "BAKURA",
    "BIRNIN MAGAJI",
    "BUKKUYUM",
    "BUNGUDU",
    "GUMMI",
    "GUSAU",
    "KAURA NAMODA",
    "MARADUN",
    "MARU",
    "SHINKAFI",
    "TALATA MAFARA",
    "TSAFE",
    "ZURMI"
    ]
  };
    
  /* return the list of local government areas in nigeria as a javascript object. */    
  return lgaList; 

};
