
// REACT STANDARD LIBRARY CALLS
import React, {useState, useEffect} from 'react';
import { Link, useHistory } from "react-router-dom";
import Modal from 'react-modal';


/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';

/* IMPORT LIBRARY  */
import { NIGERIAN_NUMBER_FORMATTER, ALL_LETTERS_CAPITALIZED, getData, getCookie, CAPITALIZE_FIRST_LETTERS, scrollToTop, isEmpty } from '../../../Library/Library.js';





// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






export default function REGISTERED_AGENTS({MenuController, DEFAULT_MENU_FEEDBACK, PAGE_TITLE_DATAFEEDBACK, SEARCHED_MEMBER_DATA, DEFAULT_SUB_MENU, DEFAULT_SUB_MENU_FEEDBACK}) {

    // SET THE PAGE TITLE FOR THIS DASHBOAD SEMI-PAGE
    PAGE_TITLE_DATAFEEDBACK("Registered Agents | "+configuration.COMPANY_NAME);
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }





    // STATE TO BE USED TO STORE DATA'S OF ALL AGENTS
    const [ALL_AGENT_DATA, setALL_AGENT_DATA] = useState({});
    const [TOTAL_PAGE_NUMBER, setTOTAL_PAGE_NUMBER] = useState("");
    // CREATE A STATE TO BE USED TO CONTROL PAGINATION PAGES
    const [PAGINATION, setPAGINATION] = useState(1);




    // LOAD AGENT DATAS AS COMPONENT LOADS UP
    useEffect(() => {
        // ONLY ALLOW API TO RUN IF THE "ALL_AGENT_DATA" STATE IS EMPTY.
        if(isEmpty(ALL_AGENT_DATA) === true){
            /* call the api function responsible for user password reset operation. */
            const url = configuration.AGENTS_LIST_API+PAGINATION+"?pageSize="+configuration.ITEM_TO_CALL_AT_ONCE+"&sortField=id&sortDir=desc&name=";
            /* Call the "postData" function to help us handle submission. */
            getData(url, session).then(response => {
                // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
                if(response.content){
                    // CONTROL IN HERE MEANS REQUEST WAS A SUCCESS, HENCE WE FETCH DATA AND SEND TO PARENT COMPONENT
                    setALL_AGENT_DATA(response.content);
                    setTOTAL_PAGE_NUMBER(response.totalPages);
                }else{
                    // display server error message
                    openModal("System encountered an internal error while trying to load data.");
                }
            }).catch(error => {
                history.push(configuration.LOGIN);
            });
        }
    }, []);



    

    // FUNCTION TO PAGINATE FORWARD
    const ADD = () => {
        const newPageNumber = (PAGINATION + 1);
        FLIP_PAGE(newPageNumber);
    }
    // FUNCTION TO PAGINATE BACKWARDS
    const SUBTRACT = () => {
        const newPageNumber = (PAGINATION - 1);
        FLIP_PAGE(newPageNumber);
    }
    // ACTUAL PAGINATION FUNCTION. THIS FUNCTION IS RESPONSIBLE FOR NEW PAGE FLIPING/LOADING
    const FLIP_PAGE = (newPageNumber) => {
        // update the pagination state of new flipping
        setPAGINATION(newPageNumber);
        /* call the api function responsible for user password reset operation. */
        const url = configuration.AGENTS_LIST_API+newPageNumber+"?pageSize="+configuration.ITEM_TO_CALL_AT_ONCE+"&sortField=id&sortDir=desc&name=";
        /* Call the "postData" function to help us handle submission. */
        getData(url, session).then(response => {
            // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
            if(response.content){
                // CONTROL IN HERE MEANS REQUEST WAS A SUCCESS, HENCE WE FETCH DATA AND SEND TO PARENT COMPONENT
                setALL_AGENT_DATA(response.content);
            }else{
                // display server error message
                openModal("System encountered an internal error while trying to load data.");
            }
        }).catch(error => {
            history.push(configuration.LOGIN);
        });

        // SCROLL PAGE TO THE TOP AFTER FETCHING AND UPDATING DATA
        scrollToTop();
    }





    // WE UPDATE THE PARENT COMPONENT TO REMIND IT THAT THE "ID_CARD" MENU IS WHAT IS CURRENTLY ACTIVE, WE DO THIS JUST SO THAT THE SIDEBAR
    // ACTIVE-MENU-SELECTOR DOES NOT GO OUT OF PLACE EACH TIME THE FULL PAGE IS RELOADED.
    DEFAULT_MENU_FEEDBACK("ID_CARD");
    DEFAULT_SUB_MENU_FEEDBACK("REG_AGENT");
    
    // if(MenuController === "ID_CARD"){

        return (
            
            <>

                <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                    <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
                </Modal>


                    
                <div className="table_bg" style={{ marginBottom: "100px" }}>
                    <div className="div-block-35" style={{ display: "flex", justifyContent: "center", marginBottom: "15px" }}>
                        <h1 className="title_header"><b>REGISTERED AGENTS</b></h1>
                    </div>

                    <div className="data_row title">
                        <a href="#" className="member-data-holder w-inline-block">
                            <div className="new-table-content header">Agent No</div>
                            <div className="new-table-content header">Member Name</div>
                            <div className="new-table-content header">Phone Number</div>
                            <div className="new-table-content header">Gender</div>
                        </a>
                        <div className="div-block-27"><p className="status table title">Action</p></div>
                    </div>

                    <TABLE_ITEMS_PRINTER SEARCHED_MEMBER_DATA={SEARCHED_MEMBER_DATA} ALL_AGENT_DATA={ALL_AGENT_DATA} />

                    <div className="pagenation-container" style={{ marginTop: "50px", marginBottom: "20px" }}>
                        {(PAGINATION === 1) ? null : (<div className="pagination-holder">
                            <button onClick={SUBTRACT}><p className="pagination">&lt;</p></button>
                        </div>)}
                        <div className="pagination-holder">
                            <p className="pagination">Page {PAGINATION} of {TOTAL_PAGE_NUMBER}</p>
                        </div>
                        {(PAGINATION === TOTAL_PAGE_NUMBER) ? null : (<div className="pagination-holder">
                            <button onClick={ADD}><p className="pagination">&gt;</p></button>
                        </div>)}
                    </div>
                </div>

            </>
        );

    // }else{
    //     return null;
    // }

}










const TABLE_ITEMS_PRINTER = ({SEARCHED_MEMBER_DATA, ALL_AGENT_DATA}) => {
    //
    if(isEmpty(SEARCHED_MEMBER_DATA) === false){
        //
        return [SEARCHED_MEMBER_DATA].map((infos, index) => {
            // print out our view
            return (
                
                <div className="data_row" style={{ paddingBottom: "17px" }}>
                    <div className="member-data-holder w-inline-block">
                        <div className="new-table-content" style={{ textTransform: "uppercase"  }}> {infos.dcamgtno} </div>
                        <div className="new-table-content">{(ALL_LETTERS_CAPITALIZED(infos.dcagmt_SNAME)+" "+CAPITALIZE_FIRST_LETTERS(infos.dcagmt_FNAME))}</div>
                        <div className="new-table-content">{NIGERIAN_NUMBER_FORMATTER(infos.dcagmt_TEL_NO1)}
                            {(infos.dcagmt_TEL_NO2) ? ", "+NIGERIAN_NUMBER_FORMATTER(infos.dcagmt_TEL_NO2) : ""}</div>
                        <div className="new-table-content">{CAPITALIZE_FIRST_LETTERS(infos.dcagmt_GENDER)}</div>
                    </div>
                    <div className="div-block-27">
                        <a href={configuration.PRINT_AGENT_DASHBOARD+"/"+infos.dcamgtno} target="_blank" style={{ textDecoration: "none"  }}>
                            <div className="div-block-27"><p className="status table active">ID CARD</p></div>
                        </a>
                    </div>
                </div>

            );
        });

    }else if(isEmpty(ALL_AGENT_DATA) === false){
        //
        return ALL_AGENT_DATA.map((infos, index) => {
            // print out our view
            return (
                
                <div className="data_row" style={{ paddingBottom: "17px" }}>
                    <div className="member-data-holder w-inline-block">
                    <div className="new-table-content" style={{ textTransform: "uppercase"  }}> {infos.dcamgtno} </div>
                        <div className="new-table-content">{(ALL_LETTERS_CAPITALIZED(infos.dcagmt_SNAME)+" "+CAPITALIZE_FIRST_LETTERS(infos.dcagmt_FNAME))}</div>
                        <div className="new-table-content">{NIGERIAN_NUMBER_FORMATTER(infos.dcagmt_TEL_NO1)}</div>
                        <div className="new-table-content">{CAPITALIZE_FIRST_LETTERS(infos.dcagmt_GENDER)}</div>
                    </div>
                    <div className="div-block-27">
                        <a href={configuration.PRINT_AGENT_DASHBOARD+"/"+infos.dcamgtno} target="_blank" style={{ textDecoration: "none"  }}>
                            <div className="div-block-27"><p className="status table active">ID CARD</p></div>
                        </a>
                    </div>
                </div>

            );
        });
    }else{
        return (
        
            <div className="data_row">
                <p>No Agent records available.</p>
            </div>

        );
    }
};


