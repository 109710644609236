
// REACT STANDARD LIBRARY CALLS
import React, {useState} from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import Modal from 'react-modal';


/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';

/* IMPORT LIBRARY  */
import { getData, getCookie, CAPITALIZE_FIRST_LETTERS, mathRandom } from '../../../Library/Library.js';





// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






export default function CLAIMS_PROCESSING({MenuController, DEFAULT_MENU_FEEDBACK, PAGE_TITLE_DATAFEEDBACK, DEFAULT_SUB_MENU, DEFAULT_SUB_MENU_FEEDBACK}) {

    // SET THE PAGE TITLE FOR THIS DASHBOAD SEMI-PAGE
    PAGE_TITLE_DATAFEEDBACK("Claims Processing | "+configuration.COMPANY_NAME);
    // GET FROM URL THE ID OF THE MEMBER WE WANT TO EDIT HIS/HER DATA
    const { id } = useParams();
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }







    // WE UPDATE THE PARENT COMPONENT TO REMIND IT THAT THE "GROUP_WELFARE" MENU IS WHAT IS CURRENTLY ACTIVE, WE DO THIS JUST SO THAT THE SIDEBAR
    // ACTIVE-MENU-SELECTOR DOES NOT GO OUT OF PLACE EACH TIME THE FULL PAGE IS RELOADED.
    DEFAULT_MENU_FEEDBACK("GROUP_WELFARE");
    DEFAULT_SUB_MENU_FEEDBACK("CLAIMS_PROCESSING");
    
    // if(MenuController === "ID_CARD"){

        return (
            
            <>

                <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                    <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
                </Modal>
                    
                <div className="div-block-38 search">
                    <div className="title-holder"><h1 className="title_header">Group Welfare Claim</h1></div>

                    <div className="form-holder">
                        <div className="reg-form-block w-form">
                            <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="text">
                                <input type="text" className="text-fields mid w-input" maxlength="256" name="field-4" data-name="Field 4" 
                                    placeholder="Search Member Number" id="field-4" required="" />
                            </form>
                        </div>
                        <Link to={configuration.G_W_S_CLAIMS_REGISTRY_LINK+"BA-DAM-79481648"} className="realbuttons search w-button">Search</Link>
                    </div>
                    <p className="paragraph-10">Only member Group Welfare number can be searched.</p>
                </div>

            </>
        );

    // }else{
    //     return null;
    // }

}
