
// REACT STANDARD LIBRARY CALLS
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import QRCode from 'qrcode';
import ReactToPrint from "react-to-print";
import '../../../../CSS/id_card_stylesheet.css';
// import '../CSS/id_card_stylesheet.css';
import Modal from 'react-modal';


/* WEB APP CONFIG FILE. */
import { configuration } from '../../../../Config/Config';



/* IMPORT LIBRARY  */
import { getData, TRIM, __String_To_Chars_Splitter, EXPLODE, STRING_CHARS_COUNT, getCookie, scrollToTop } from '../../../../Library/Library.js';




// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };







export default function Members_ID_card_batch_printer() {

    // lets set the page title. 
    document.getElementById("title").innerHTML = "Batch Print ID Card | "+configuration.COMPANY_NAME;

    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);
    // GET FROM URL THE ID OF THE MEMBER WE WANT TO GENERATE ID CARD FOR
    const { pagination_index } = useParams();
    // CREATE AN OBJECT OF REACT USEREF HOOK, TO BE USED IN LATER STAGE
    let componentRef = useRef();

    // USE THIS HOOK TO INVOKE WEBFLOWS SCRIPTS AND ANIMATIONS 
    // useEffect(() => {
    //     const html = document.getElementsByTagName('html')[0];
    //     html.setAttribute('data-wf-page', '62ab23d8eae8f044a26113f2');
    //     html.setAttribute('data-wf-site', '62a356d924ac21c4e115b2a0');

    //     window.Webflow && window.Webflow.destroy();
    //     window.Webflow && window.Webflow.ready();
    //     window.Webflow && window.Webflow.require('ix2').init();
    //     document.dispatchEvent(new Event('readystatechange'))
    // }, []);

    

    // STATE TO BE USED TO STORE DATA'S OF THE MEMBER, WE WANT TO PRINT ID-CARD FOR
    // const [MEMBER_DATA, setMEMBER_DATA] = useState({ mbrfname: "", mbrsname: "", mbrno: "", mbr_STATE: "", mbr_LGA: "", mbr_BRANCH: "" });

    // const imageUrl = configuration.MEMBER_BINARY_IMAGE_FETCHER_API+id;
    // const [imgUrl, setImgUrl] = useState("/DATA/Spin-Preloader-2.gif");

    // FUNCTION TO FETCH MEMBER IMAGE IN BINARY AND TRANSCODE INTO READABLE IMAGE FORMATE
    // const getImg = async () => {
    //     const response = await fetch(imageUrl, {
    //         method: 'GET',
    //         mode: 'cors',
    //         cache: 'no-cache',
    //         credentials: 'include',
    //         headers: {
    //             'Accept': 'image/png',
    //             'Authorization': `Bearer ${session}`,
    //             'Content-Type': 'image/png'
    //         },
    //         redirect: 'follow',
    //         referrerPolicy: 'no-referrer',
    //     }).catch(error => {
    //         history.push(configuration.LOGIN);
    //     });

    //     // ensure that we have an image data response and that fetch operation has not failed as a result of network failure before proceeding to this part
    //     if(response){
    //         const imageBlob = await response.blob();
    //         const reader = new FileReader();
    //         reader.readAsDataURL(imageBlob);
    //         reader.onloadend = () => {
    //         const base64data = reader.result;
    //         setImgUrl(base64data);
    //         };
    //     }
    // };



    
    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }


    
    // const [renderer, setRenderer] = useState(false);
    // STATE TO BE USED TO STORE DATA'S OF ALL MEMBERS
    // const [ALL_MEMBER_DATA, setALL_MEMBER_DATA] = useState([]);
    const ALL_MEMBER_DATA = useRef([]);
    function setALL_MEMBER_DATA(memberData) {     ALL_MEMBER_DATA.current = memberData;     }

    // USE THIS HOOK TO FETCH MEMBERS DETAILS AND INVOKE THE FUNCTION THAT WILL FETCH US MEMBER IMAGE
    useEffect(() => {
    // const handleMemberLoad = () => {
        // ONLY ALLOW API TO RUN IF THE "ALL_MEMBER_DATA" STATE IS EMPTY.
        if(TRIM(pagination_index) !== ""){

            /* call the api function responsible for user password reset operation. */
            const url = configuration.MEMBER_LIST_API+pagination_index+"?pageSize="+configuration.ITEM_TO_CALL_AT_ONCE+"&sortField=id&sortDir=desc&name=";
            /* Call the "postData" function to help us handle submission. */
            getData(url, session).then(response => {
                // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
                if(response.content){
                    // CONTROL IN HERE MEANS REQUEST WAS A SUCCESS, HENCE WE FETCH DATA AND SEND TO PARENT COMPONENT
                    setALL_MEMBER_DATA(response.content);
                    // ALL_MEMBER_DATA.current = response.content;
                }else{
                    // display server error message
                    openModal("System encountered an internal error while trying to load data.");
                }
            }).catch(error => {
                history.push(configuration.LOGIN);
            });

            // SCROLL PAGE TO THE TOP AFTER FETCHING AND UPDATING DATA
            scrollToTop();
        }
    // }
    }, []);


    // // create state be used to store qr images temporal
    // const [src, setSrc] = useState('');
    // // extract the section of the member id that has numbers, then split those numbers into chars
    // const CODEX = (MEMBER_DATA.mbrno) ? __String_To_Chars_Splitter(EXPLODE("-", MEMBER_DATA.mbrno)[2]) : ""; 
    // // extract member id from api response only if id is in its correct format, else we generate new formated id
    // const Member_ID = (CODEX.length == 8) ? CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7] : EXPLODE("-", MEMBER_DATA.mbrno)[2];
    // // generate qr codes
    // QRCode.toDataURL("Fullname: "+(MEMBER_DATA.mbrfname+" "+MEMBER_DATA.mbrsname)+", Membership Number: "+Member_ID)
    // .then(url => {
    //     setSrc(url);
    // });

    
       
    // const [MEMBER_DISPLAY_IMAGE, setMEMBER_DISPLAY_IMAGE] = useState([]);
    // function handle_MEMBER_DP_Change(IMAGE_DATA){   
    //     const updatedImage = [...MEMBER_DISPLAY_IMAGE, IMAGE_DATA];   
    //     setMEMBER_DISPLAY_IMAGE(updatedImage);      
    // }

    // const [MEMBER_QR_CODE, setMEMBER_QR_CODE] = useState([]);
    // function handle_QR_CODE_Change(QR_CODE_IMAGE){  
    //     const updatedQrCode = [...MEMBER_QR_CODE, QR_CODE_IMAGE];    
    //     setMEMBER_QR_CODE(updatedQrCode);      
    // }

    // const [counter, setCounter] = useState(0);
    // function import_Counter(counts){      setCounter(counts);      }

    // const data1 = useRef([]);
    const MEMBER_DISPLAY_IMAGE = useRef([]);
    const MEMBER_QR_CODE = useRef([]);
    

    // function handleChange1(newValue) {
    //     // data1.current = newValue // Won't trigger render
    //     const updatedData = [...data1.current, newValue];
    //     data1.current = updatedData;
    // }
    function handle_MEMBER_DP_Change(image_data) {
        const updatedImage = [...MEMBER_DISPLAY_IMAGE.current, image_data];
        MEMBER_DISPLAY_IMAGE.current = updatedImage;
    }
    function handle_QR_CODE_Change(qr_data) {
        const updatedQrCode = [...MEMBER_QR_CODE.current, qr_data];
        MEMBER_QR_CODE.current = updatedQrCode;
    }


    // useEffect(()=> {
    const handleLoad = () => {
        //
        if(ALL_MEMBER_DATA.current.length > 0){
            //
            ALL_MEMBER_DATA.current.map((MEMBER_DATA, index) => {
                // console.log((MEMBER_DATA.mbrfname+" "+MEMBER_DATA.mbrsname));
                const imageUrl = configuration.MEMBER_BINARY_IMAGE_FETCHER_API+MEMBER_DATA.mbrno;
                // FUNCTION TO FETCH MEMBER IMAGE IN BINARY AND TRANSCODE INTO READABLE IMAGE FORMATE
                const getImg = async () => {
                    const response = await fetch(imageUrl, {
                        method: 'GET', mode: 'cors', cache: 'no-cache', credentials: 'include',
                        headers: { 'Accept': 'image/png', 'Authorization': `Bearer ${session}`, 'Content-Type': 'image/png' },
                        redirect: 'follow', referrerPolicy: 'no-referrer',
                    });

                    // ensure that we have an image data response and that fetch operation has not failed as a result of network failure before proceeding to this part
                    if(response){
                        const imageBlob = await response.blob();
                        const reader = new FileReader();
                        reader.readAsDataURL(imageBlob);
                        reader.onloadend = () => {
                            const base64data = reader.result;
                            handle_MEMBER_DP_Change(base64data);
                        };
                    }
                };
                //
                getImg();

                //   B L O C K     O F     C O D E     T O     G E N E R A T E       M E M B E R       Q R      C O D E
                // extract the section of the member id that has numbers, then split those numbers into chars
                const CODEX = (MEMBER_DATA.mbrno) ? __String_To_Chars_Splitter(EXPLODE("-", MEMBER_DATA.mbrno)[2]) : ""; 
                // extract member id from api response only if id is in its correct format, else we generate new formated id
                const Member_ID = CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7];
                // generate qr code here
                QRCode.toDataURL("Fullname: "+(MEMBER_DATA.mbrfname+" "+MEMBER_DATA.mbrsname)+", Membership Number: "+Member_ID)
                .then(url => { handle_QR_CODE_Change(url) });
            });
        }
    }
    // }, [ALL_MEMBER_DATA]);

    const [toggle, setToggle] = React.useState(false);
    console.log(ALL_MEMBER_DATA);
    console.log(MEMBER_DISPLAY_IMAGE);
    console.log(MEMBER_QR_CODE);

    // console.log(MEMBER_DISPLAY_IMAGE.current);
    // console.log(MEMBER_QR_CODE.current);
    // setRenderer(true);

    // const countRef = useRef(0);

    // const handleClick = () => {
    //     countRef.current++;
    //     console.log(`${countRef.current} button clicks`);
    // }

    // console.log("Component rendered");

    return (
        <>
            <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
            </Modal>
            {/* <button onClick={handleMemberLoad}>Load Members</button> */}
            <button onClick={handleLoad}>Load Datas</button>
            <button onClick={() => setToggle(toggle => !toggle)} > Render View </button>

            <div className='dash_board_body'>

                <div className="table_bg" style={{ marginLeft: "auto", marginRight: "auto", marginTop: "2%", width: "1215px", minWidth: "1215px", 
                    maxWidth: "1215px" }}>

                    
                        <ReactToPrint
                        trigger={() => {
                            return ( 
                                <div className="div-block-35" style={{ marginBottom: "0%", marginTop: "0%" }}>
                                    <h1></h1>
                                    <a href="#" className="button_outline filled goldenrod id new w-button">Batch Print</a> 
                                </div>
                            );
                        }}
                        content={() => componentRef}
                        />
                    


                    <h1 style={{ textAlign: "center" }}>PRINT '{configuration.ITEM_TO_CALL_AT_ONCE}' ID CARDS</h1>
                    {/* <ComponentToPrint ref={(el) => (componentRef = el)} MEMBER_DATA={MEMBER_DATA} IMAGE={imgUrl} src={src} /> */}
                    {/* <ComponentNotToPrint MEMBER_DATA={MEMBER_DATA} IMAGE={imgUrl} src={src} /> */}

                    {/* <ComponentNotToPrint DATA={ALL_MEMBER_DATA} session={session} history={history} 
                    QR_CODE={QR_CODE} setQR_CODE={import_QR_CODE} IMAGE={IMAGE} setIMAGE={import_IMAGE} 
                    counter={data1} setCounter={handleChange1} /> */}

                    <ComponentNotToPrint ALL_MEMBER_DATA={ALL_MEMBER_DATA} QR_CODE={MEMBER_QR_CODE} IMAGE={MEMBER_DISPLAY_IMAGE} />

                </div>

            </div>
        </>
    );

}








// const ComponentNotToPrint = ({DATA, IMAGE, src}) => {
const ComponentNotToPrint = ({ALL_MEMBER_DATA, QR_CODE, IMAGE}) => {
  
    if(ALL_MEMBER_DATA){
        // console.log("zuess");
        //
        // return [ALL_MEMBER_DATA][0].map((MEMBER_DATA, index) => {
        return ALL_MEMBER_DATA.current.map((MEMBER_DATA, index) => {

            // setIMAGE("/DATA/Spin-Preloader-2.gif");

            // construct to ensure that, nothing is displayed when api response is yet to load data
            const Location_ID = (MEMBER_DATA.mbr_STATE) ? (MEMBER_DATA.mbr_STATE+"/"+MEMBER_DATA.mbr_LGA) : "";
            // extract the section of the member id that has numbers, then split those numbers into chars
            const CODEX = (MEMBER_DATA.mbrno) ? __String_To_Chars_Splitter(EXPLODE("-", MEMBER_DATA.mbrno)[2]) : ""; 
            // extract member id from api response only if id is in its correct format, else we generate new formated id
            // const Member_ID = (CODEX.length == 8) ? CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7] : ((Math.floor(1000 + Math.random() * 9000)) +" "+ (Math.floor(1000 + Math.random() * 9000)));
            const Member_ID = CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7];
            // CONSTRUCT THE FULL NAME OF THE MEMBER
            const MEMBER_NAME = (MEMBER_DATA.mbrfname+" "+MEMBER_DATA.mbrsname);

            // return null;
            // print out our view
            return (

                // <PREVIEW IMAGE={IMAGE} MEMBER_NAME={MEMBER_NAME} Location_ID={Location_ID} Member_ID={Member_ID} QR_CODE={QR_CODE} />

                <div className="example" style={{ display: "block" }}>
                    <img className="background-image-1" src={configuration.APP_DATA_RESOURCE+"ID-CARD-MOCK/FRONT.png"} />
                    <div className="overlay">
                        <div className="image" style={{ top: "135px", left: "135px" }}>
                            <img id="newImg" src={IMAGE.current[index]} alt=""
                            loading="lazy" width="360" height="325" className="task_nav_button_icon" style={{ filter: "grayscale(100%)" }} /> 
                        </div>

                        <USER_NAME_RESOLUTOR MEMBER_NAME={MEMBER_NAME} />

                        <h2 className="location_ID" style={{ top: "626px", left: "260px", transform: "translate(-18%, -50%)" }}>
                            {Location_ID}
                        </h2>

                        <h2 className="membership_ID" style={{ top: "626px", left: "900px", letterSpacing: "7px", transform: "translate(-75%, -50%)" }}>
                            {Member_ID}
                        </h2>
                    </div>

                    <img className="background-image-2" src={configuration.APP_DATA_RESOURCE+"ID-CARD-MOCK/BACK.png"} />

                    <div className="overlay2">   
                        <div className="image2" style={{ top: "1305px", left: "880px" }}>
                            <img id="newImg2" src={QR_CODE.current[index]} alt="Passport" loading="lazy" width="238" height="220" className="task_nav_button_icon" /> 
                        </div>
                    </div>
                </div>

            );
        });
    }else{
        return null;
    }
};


const PREVIEW = ({IMAGE, MEMBER_NAME, Location_ID, Member_ID, QR_CODE}) => {
    return (
        <div className="example" style={{ display: "block" }}>

            <img className="background-image-1" src={configuration.APP_DATA_RESOURCE+"ID-CARD-MOCK/FRONT.png"} />

            <div className="overlay">
                <div className="image" style={{ top: "135px", left: "135px" }}>
                    <img id="newImg" src={IMAGE} alt=""
                    loading="lazy" width="360" height="325" className="task_nav_button_icon" style={{ filter: "grayscale(100%)" }} /> 
                </div>

                <USER_NAME_RESOLUTOR MEMBER_NAME={MEMBER_NAME} />

                <h2 className="location_ID" style={{ top: "626px", left: "260px", transform: "translate(-18%, -50%)" }}>
                    {Location_ID}
                </h2>

                <h2 className="membership_ID" style={{ top: "626px", left: "900px", letterSpacing: "7px", transform: "translate(-75%, -50%)" }}>
                    {Member_ID}
                </h2>
            </div>

            <img className="background-image-2" src={configuration.APP_DATA_RESOURCE+"ID-CARD-MOCK/BACK.png"} />

            <div className="overlay2">   
                <div className="image2" style={{ top: "1305px", left: "880px" }}>
                    <img id="newImg2" src={QR_CODE} alt="Passport" loading="lazy" width="238" height="220" className="task_nav_button_icon" /> 
                </div>
            </div>

        </div>
    );
}











const USER_NAME_RESOLUTOR = ({MEMBER_NAME}) => {
    if(STRING_CHARS_COUNT(MEMBER_NAME) > 18){
        return( <h3 className="name" style={{ top: "480px", left: "135px", transform: "translate(-0%, -50%)" }}>{MEMBER_NAME}</h3> );
    }else{
        return( <h2 className="name" style={{ top: "480px", left: "135px", transform: "translate(-0%, -50%)" }}>{MEMBER_NAME}</h2> );
    }
}











class ComponentToPrint extends React.Component {

    render() {


        // construct to ensure that, nothing is displayed when api response is yet to load data
        const Location_ID = (this.props.MEMBER_DATA.mbr_STATE) ? (this.props.MEMBER_DATA.mbr_STATE+"/"+this.props.MEMBER_DATA.mbr_LGA) : "";
        // extract the section of the member id that has numbers, then split those numbers into chars
        const CODEX = (this.props.MEMBER_DATA.mbrno) ? __String_To_Chars_Splitter(EXPLODE("-", this.props.MEMBER_DATA.mbrno)[2]) : ""; 
        // extract member id from api response only if id is in its correct format, else we generate new formated id
        // const Member_ID = (CODEX.length == 8) ? CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7] : ((Math.floor(1000 + Math.random() * 9000)) +" "+ (Math.floor(1000 + Math.random() * 9000)));
        const Member_ID = CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7];
        // CONSTRUCT THE FULL NAME OF THE MEMBER
        const MEMBER_NAME = (this.props.MEMBER_DATA.mbrfname+" "+this.props.MEMBER_DATA.mbrsname);

        return (

            <div className="example">

                <img className="background-image-1" width="500" height="210" src={configuration.APP_DATA_RESOURCE+"ID-CARD-MOCK/FRONT.jpg"} />

                <div className="overlay">
                    <div className="image">
                        <img id="newImg" src={this.props.IMAGE} alt=""
                        loading="lazy" width="110" height="95" className="task_nav_button_icon" style={{ filter: "grayscale(100%)" }} /> 
                    </div>

                    {/* <USER_NAME_RESOLUTOR MEMBER_NAME={MEMBER_NAME} /> */}
                    <h6 className="name" style={{ left: "35px" }}>
                        {(this.props.MEMBER_DATA.mbrfname+" "+this.props.MEMBER_DATA.mbrsname)}
                    </h6>

                    <h6 className="location_ID" style={{ left: "68px" }}>
                        {Location_ID}
                    </h6>

                    <h6 className="membership_ID" style={{ left: "253px" }}>
                        {Member_ID}
                    </h6>
                </div>


                <img className="background-image-2" width="500" height="210" src={configuration.APP_DATA_RESOURCE+"ID-CARD-MOCK/BACK.jpg"} />

                <div className="overlay2">
                    <div className="image2">
                        <img id="newImg2" src={this.props.src} alt="Passport" loading="lazy" width="63" height="62" className="task_nav_button_icon" /> 
                    </div>
                </div>

            </div>   
        );
    }
};








