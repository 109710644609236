
// REACT STANDARD LIBRARY CALLS
import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import Modal from 'react-modal';

/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';

/* IMPORT LIBRARY  */
import { DATE_CONFIRMER, NIGERIAN_NUMBER_FORMATTER, CAPITALIZE_FIRST_LETTERS, getCookie, getData, TRIM, EXPLODE } from '../../../Library/Library.js';






// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };
const licenseCustomStyles = { content: { top: '52%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






export default function AGENT_PROFILE({MenuController, DEFAULT_MENU_FEEDBACK, PAGE_TITLE_DATAFEEDBACK, DEFAULT_SUB_MENU_FEEDBACK}) {


    // SET THE PAGE TITLE FOR THIS DASHBOAD SEMI-PAGE
    PAGE_TITLE_DATAFEEDBACK("Agent Data | "+configuration.COMPANY_NAME);
    // GET FROM URL THE ID OF THE MEMBER WE WANT TO EDIT HIS/HER DATA
    const { agent_id } = useParams();
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }
    

    // CREATE STATE TO BE USED TO STORE IMAGE
    const [imgUrl, setImgUrl] = useState("/DATA/Spin-Preloader-2.gif");
    
    
    // construct api url to load up image
    const imageUrl = configuration.AGENT_BINARY_IMAGE_FETCHER_API+agent_id;
    // FUNCTION TO LOAD IMAGE FROM BACKEND AS BINARY, PROCESS AND CONVERT IT TO IMAGE
    const getImg = async () => {
        const response = await fetch(imageUrl, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Accept': 'image/png',
                'Authorization': `Bearer ${session}`,
                'Content-Type': 'image/png'     // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }).catch(error => {
            // history.push(configuration.LOGIN);
            openModal("Network Error, when attempting to save data. Please ensure you have a good internet connection.");
        });

        // ensure that we have an image data response and that fetch operation has not failed as a result of network failure before proceeding to this part
        if(response){
            const imageBlob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(imageBlob);
            reader.onloadend = () => {
                const base64data = reader.result;
                setImgUrl(base64data);
            };
        }
    };





    // STATE TO BE USED TO STORE DATA'S OF THE MEMBER, WE WANT TO PRINT ID-CARD FOR
    const [AGENT_DATA, setAGENT_DATA] = useState({});

    //
    useEffect(() => {
        // ONLY ALLOW API TO RUN IF THE "ALL_AGENT_DATA" STATE IS EMPTY.
        if(TRIM(agent_id) !== ""){
            /* call the api function responsible for user password reset operation. */
            const url = configuration.SORT_AGENT_BY_ID_API+agent_id;
            
            /* Call the "postData" function to help us handle submission. */
            getData(url, session).then(response => {
                // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
                if(response.dcamgtno){
                    // CONTROL IN HERE MEANS REQUEST WAS A SUCCESS, HENCE WE FETCH DATA AND SEND TO PARENT COMPONENT
                    setAGENT_DATA(response);
                    // LOAD IMAGE FROM BACKEND AS BINARY, PROCESS AND CONVERT IT TO IMAGE
                    getImg();
                }else{
                    // display server error message
                    openModal("System encountered an internal error while trying to load data.");
                }
            }).catch(error => {
                // At this junction we are certain that request submission has failed because of a network error, therefore we send out network 
                // failure message
                openModal("Network Error, when attempting to save data. Please ensure you have a good internet connection.");
            });
        }

    }, []);




    
    // WE UPDATE THE PARENT COMPONENT TO REMIND IT THAT THE "AGENT_REG" MENU IS WHAT IS CURRENTLY ACTIVE, WE DO THIS JUST SO THAT THE SIDEBAR
    // ACTIVE-MENU-SELECTOR DOES NOT GO OUT OF PLACE EACH TIME THE FULL PAGE IS RELOADED.
    DEFAULT_MENU_FEEDBACK("AGENT_REG");
    DEFAULT_SUB_MENU_FEEDBACK("AGENT_LIST_DASHBOARD");

    return (
        
        <>
            {/* <div className="div-block-35" style={{ marginBottom: "0%", marginTop: "2%" }}>
                <h1></h1>
                <Link to={configuration.AGENT_RECORDS_LINK} className="button_outline filled goldenrod id new w-button">Back</Link>
            </div> */}

            <div className="div-block-35" style={{ marginBottom: "0%", marginTop: "2%" }}>
                <h1></h1>
                <Link to={configuration.AGENT_RECORDS_LINK} className="realbuttons w-button" style={{ marginBottom: "0" }}>Back</Link>
            </div>

            <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
            </Modal>
            
            {/* height: "990px" */}
            <div className="register_agent" style={{ marginBottom: "0px", height: "1500px" }}>
                <br />
                <h3 className="profile_pic_container" style={{ justifyContent: "center" }}>AGENT DATA</h3>
                <br />

                <div className="profile_pic_container" style={{ justifyContent: "center", marginBottom: "2%" }}>
                    <img src={imgUrl} loading="lazy" width="200" height="200" alt="Agent Display Picture" style={{ borderRadius: "0%" }} />
                </div>
                {/* <div style={{ display: "flex", justifyContent: "center", marginTop: "-1.5%" }}>
                    <button className="realbuttons w-button" style={{ marginRight: "0%", background: "#90ee90", width: "200px", border: "0px solid" }}></button>
                </div> */}


                <div className="profile_pic_container" style={{ justifyContent: "center", marginTop: "3%" }}>
                    <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-8">

                        <p style={{ color: "#777", textAlign: "right", width: "270px", margin: "0 0 0 0", fontSize: "18px" }}> <b>Surname</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", margin: "0 0 0 0", textTransform: "uppercase", fontSize: "18px" }}> 
                        {AGENT_DATA.dcagmt_SNAME} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-15%", fontSize: "18px" }}> <b>First Name</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-15%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {(CAPITALIZE_FIRST_LETTERS(AGENT_DATA.dcagmt_FNAME))} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-30%", fontSize: "18px" }}> <b>Date of Birth</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-30%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {(DATE_CONFIRMER(AGENT_DATA.dcagmt_DOB))} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-45%", fontSize: "18px" }}> <b>Gender</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-45%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {AGENT_DATA.dcagmt_GENDER} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-60%", fontSize: "18px" }}> <b>Phone 1</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-60%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {NIGERIAN_NUMBER_FORMATTER(AGENT_DATA.dcagmt_TEL_NO1)} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-75%", fontSize: "18px" }}> <b>Phone 2</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-75%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {NIGERIAN_NUMBER_FORMATTER(AGENT_DATA.dcagmt_TEL_NO2)} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-87%", fontSize: "18px" }}> <b>Address Line 1</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-87%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {AGENT_DATA.dcagmt_ADDR_LN1} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-95%", fontSize: "18px" }}> <b>Address Line 2</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-95%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {EXPLODE("#@#", AGENT_DATA.dcagmt_ADDR_LN2)[0]} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-105%", fontSize: "18px" }}> <b>Phone Serial</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-105%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {EXPLODE("#@#", AGENT_DATA.dcagmt_ADDR_LN2)[1]} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-118%", fontSize: "18px" }}> <b>City</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-118%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {AGENT_DATA.dcagmt_CITY} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-132%", fontSize: "18px" }}> <b>State</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-132%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {CAPITALIZE_FIRST_LETTERS(AGENT_DATA.dcagmt_STATE)} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-132%", fontSize: "18px" }}> <b>LGA</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-132%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {CAPITALIZE_FIRST_LETTERS(AGENT_DATA.dcagmt_LGA)} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-132%", fontSize: "18px" }}> <b>Branch</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-132%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {CAPITALIZE_FIRST_LETTERS(AGENT_DATA.dcagmt_BRANCH)} </p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-132%", fontSize: "18px" }}> <b>State of Origin</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-132%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {CAPITALIZE_FIRST_LETTERS(AGENT_DATA.stateOfOrigin)}</p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-132%", fontSize: "18px" }}> <b>LGA of Origin</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-132%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {CAPITALIZE_FIRST_LETTERS(AGENT_DATA.localGovtOfOrigin)}</p>

                        <p style={{ color: "#777", textAlign: "right", width: "270px", marginTop: "-132%", fontSize: "18px" }}> <b>Hometown</b> </p>
                        <p style={{ color: "#777", textAlign: "left", width: "270px", marginTop: "-132%", textTransform: "capitalize", fontSize: "18px" }}> 
                        {CAPITALIZE_FIRST_LETTERS(AGENT_DATA.hometown)}</p>
                    </div>
                </div>



                <div class="div-block-40" style={{ display: "flex", textAlign: "center", justifyContent: "center", marginTop: "-18%", 
                    marginBottom: "28%" }}>
                    <h1 class="title_header">ATTESTATION AND UNDERTAKING</h1>
                </div>

                <div className="div-block-40" style={{ display: "flex", marginTop: "-28%", marginLeft: "37%"}}>
                    <div className="w-form" style={{  width: "100%" }}>
                        <div className="form-8">
                            <div style={{  display: "flex", width: "200%", marginTop: "13px", marginLeft: "60%" }}>
                                <input type="checkbox" id="Policy" checked readOnly style={{  marginRight: "1%" }} />
                                <label htmlFor="Policy"> Kalyaan Data Protection Policy </label>
                            </div>
                            <div style={{  display: "flex", width: "100%", marginTop: "-20px" }}></div>
                            <div style={{  display: "flex", width: "230%", marginTop: "-12px", marginLeft: "90%" }}>
                                <input type="checkbox" id="Undertaking" checked readOnly style={{  marginRight: "1%", marginTop: "-7%"}} />
                                <label htmlFor="Undertaking"> Undertaking against acts of criminality and violence </label>
                            </div>
                            {/* <div style={{  display: "flex", width: "100%", marginTop: "-55px" }}></div>
                            <div style={{  display: "flex", width: "130%", marginTop: "-55px", marginLeft: "30%" }}>
                                <input type="checkbox" id="data_policy" checked readOnly style={{  marginRight: "1%", marginTop: "-30%"}} />
                                <label htmlFor="data_policy"> Data Policy </label>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* style={{  display: "flex", textAlign: "center", justifyContent: "center" }} */}

                {/* <div class="div-block-40" style={{ display: "flex", textAlign: "center", justifyContent: "center", marginTop: "-21%" }}>
                    <h1 class="title_header">ATTESTATION AND UNDERTAKING</h1>
                </div> */}

                {/* {(AGENT_DATA) ? (
                    <>
                        <div class="div-block-40" style={{ display: "flex", textAlign: "center", justifyContent: "center", marginTop: "-21%" }}>
                            <h1 class="title_header">NEXT OF KIN</h1>
                        </div>

                        <div className="profile_pic_container" style={{ justifyContent: "center", marginTop: "1%" }}>
                            <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-8">
                                <p style={{ color: "#777", textAlign: "right", width: "250px", margin: "0 0 0 0", fontSize: "18px" }}> <b>Surname</b> </p>
                                <p style={{ color: "#777", textAlign: "left", width: "250px", margin: "0 0 0 0", textTransform: "uppercase", fontSize: "18px" }}> 
                                {AGENT_DATA.dcagmt_NEXT_KIN_SNAME} </p>

                                <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-15%", fontSize: "18px" }}> <b>First Name</b> </p>
                                <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-15%", textTransform: "capitalize", fontSize: "18px" }}> 
                                {CAPITALIZE_FIRST_LETTERS(AGENT_DATA.dcagmt_NEXT_KIN_FNAME)} </p>

                                <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-30%", fontSize: "18px" }}> <b>Relationship</b> </p>
                                <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-30%", textTransform: "capitalize", fontSize: "18px" }}> 
                                {AGENT_DATA.dcagmt_NEXT_KIN_REL} </p>

                                <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-45%", fontSize: "18px" }}> <b>Phone 1</b> </p>
                                <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-45%", textTransform: "capitalize", fontSize: "18px" }}> 
                                {NIGERIAN_NUMBER_FORMATTER(AGENT_DATA.dcagmt_NEXT_KIN_TEL_NO)} </p>

                                <p style={{ color: "#777", textAlign: "right", width: "250px", marginTop: "-60%", fontSize: "18px" }}> <b>Address</b> </p>
                                <p style={{ color: "#777", textAlign: "left", width: "250px", marginTop: "-60%", textTransform: "capitalize", fontSize: "18px" }}> 
                                {AGENT_DATA.dcagmt_NEXT_KIN_ADDR} </p>
                            </div>
                        </div>
                    </>
                ) : ( 
                        <div class="div-block-40" style={{ visibility: "hidden", textAlign: "center", justifyContent: "center", 
                            marginTop: "-21%", marginBottom: "28%" }}>
                            <h1 class="title_header">NEXT OF KIN</h1>
                        </div>
                )} */}

                <div className="botton-holder" style={{ marginTop: "-45%" }}>
                    <a href={configuration.GWS_DASHBOARD_LINK} className="w-button" style={{ visibility: "hidden" }}></a>
                    <Link to={configuration.EDIT_AGENT_RECORDS_LINK+agent_id} className="realbuttons filled w-button">Edit</Link>
                </div>
            </div>

        </>
    );


}

