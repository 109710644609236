



// MOST ACCURATE AND UP TO DATE LOCAL GOVERNMENT AREA AND BRANCHES AND STATE EXTRACTED FROM 
// https://www.inecnigeria.org/elections/polling-units/
// USING A SCRAPPER TOOL CREATED FOR THIS PURPOSE AND STORED SOMEWHERE IN THIS PROJECT

/* JAVASCRIPT FUNCTION TO HELP US COMPILE AND RETURN A JAVASCRIPT OBJECT LIST OF ALL STATES IN NIGERIA AND THEIR RESPECTIVE LOCAL GOVERNMENT AREAS.  */
export function NIGERIA_STATES_LOCAL_GOVERNMENT_AREA_BRANCHES(){   
    
  /* compile as a javascript object, a list of all states in nigeria and their local government areas. */
  const lgaBranchList = {
            
    "ABA NORTH": [
    "EZIAMA",
    "INDUSTRIAL AREA",
    "OSUSU I",
    "OSUSU II",
    "ST.EUGENES BY OKIGWE RD.",
    "URATTA",
    "OLD ABA GRA",
    "UMUOLA",
    "ARIARIA MARKET",
    "OGBOR I",
    "OGBOR II",
    "UMUOGOR"
    ],
    
    "ABA SOUTH": [
    "EZIUKWU",
    "ASA",
    "ENYIMBA",
    "NGWA",
    "OHAZU I",
    "OHAZU II",
    "IGWEBUIKE",
    "EKEOHA",
    "GLOUCESTER",
    "MOSQUE",
    "ABA RIVER",
    "ABA TOWN HALL"
    ],

    "AROCHUKWU": [
    "OVUKWU",
    "OHAEKE",
    "OHAFOR I",
    "OHAFOR II",
    "AROCHUKWU I",
    "AROCHUKWU II",
    "IKWUN IHECHIOWA",
    "ELEOHA IHECHIOWA",
    "UTUTU",
    "ISU",
    "AROCHUKWU III"
    ],

    "BENDE": [
    "AMANKALU/AKOLIUFU",
    "BENDE",
    "UGWUEKE/EZEUKWU",
    "IGBERE 'A'",
    "IGBERE 'B'",
    "ITEM A",
    "ITEM B",
    "ITEM C",
    "ITUMBAUZO",
    "OZUITEM",
    "UMUHU / EZECHI",
    "UMU - IMENYI",
    "UZUAKOLI"
    ],

    "IKWUANO": [
    "OLOKO I",
    "OLOKO II",
    "IBERE I",
    "IBERE II",
    "OBORO I",
    "OBORO II",
    "OBORO III",
    "OBORO IV",
    "ARIAM",
    "USAKA"
    ],

    "ISIALA NGWA NORTH": [
    "AMASAA NSULU",
    "UMUNNA NSULU",
    "ISIALA NSULU",
    "NGWA UKWU I",
    "NGWA UKWU II",
    "IHIE",
    "AMASAA NTIGHA",
    "AMAPU NTIGHA",
    "UMUOHA",
    "MBAWSI / UMUOMAINTA"
    ],

    "ISIALA NGWA SOUTH": [
    "AMAISE / AMAISE ANABA",
    "NGWAOBI",
    "MBUTU UKWU",
    "MBUTU NGWA",
    "EHINA GURU OSOKWA",
    "AKUNEKPU EZIAMA NA OBUBA",
    "OMOBA",
    "OVUNGWU",
    "OVUOKWU",
    "OKPORO AHABA"
    ],

    "ISUIKWUATO": [
    "IMENYI",
    "EZERE",
    "ISIALA AMAWU",
    "ISU AMAWU",
    "OGUNDUASA",
    "UMUNNEKWU",
    "ACHARA / MGBUGWU",
    "IKEAGHA I",
    "IKEAGHA II",
    "UMUANYI / ABSU"
    ],

    "OBINGWA": [
    "ABAYI I",
    "ABAYI II",
    "MGBOKO UMUANUNU",
    "MGBOKO ITUNGWA",
    "AHIABA",
    "MABOKO AMAIRI",
    "ALAUKWU OHANZE",
    "AKUMAIMO",
    "NDIAKATA / AMAIRINABUA",
    "NTIGHAUZO AMAIRI",
    "IBEME"
    ],

    "OHAFIA": [
    "ISIAMA OHAFIA",
    "EBEM OHAFIA",
    "NDI ELU NKPORO",
    "NDI ETITI NKPORO",
    "AMAEKE ABIRIBA",
    "AMAOGUDU ABIRIBA",
    "AGBOJI ABIRIBA",
    "OHAFOR OHAFIA",
    "OKAMU OHAFIA",
    "ANIA OHOAFIA",
    "NDI AGBO NKPORO"
    ],

    "OSISIOMA": [
    "AMAVO",
    "AMAITOLU MBUTU UMUOJIMA",
    "AMASATOR",
    "ARO - NGWA",
    "AMA - ASAA",
    "OSO - OKWA",
    "URTTA",
    "AMATOR",
    "UMUNNEISE",
    "OKPOR - UMUOBO"
    ],

    "UGWUNAGBO": [
    "WARD ONE",
    "WARD TWO",
    "WARD THREE",
    "WARD FOUR",
    "WARD FIVE",
    "WARD SIX",
    "WARD SEVEN",
    "WARD EIGHT",
    "WARD NINE",
    "WARD TEN"
    ],

    "UKWA EAST": [
    "IKWURIATOR EAST",
    "IKWURIATOR WEST",
    "AZUMINI",
    "UMUIGUBE ACHARA",
    "AKWETE",
    "OBOHIA",
    "IKWUEKE EAST",
    "IKWUEKE WEST",
    "NKPOROBE/OHURU",
    "IKWUORIE"
    ],

    "UKWA WEST": [
    "ASA NORTH",
    "OBOKWE",
    "OGWE",
    "ASA SOUTH",
    "OBUZOR",
    "IPU WEST",
    "IPU EAST",
    "IPU SOUTH",
    "OZAA UKWU",
    "OZAA WEST"
    ],

    "UMUAHIA NORTH": [
    "IBEKU EAST I",
    "IBEKU EAST II",
    "NDUME",
    "IBEKU WEST",
    "UMUAHIA URBAN I",
    "UMUAHIA URBAN II",
    "UMUAHIA URBAN III",
    "NKWOACHARA",
    "NKWOEGWU",
    "AFUGIRI",
    "UMUHU",
    "ISINGWU"
    ],

    "UMUAHIA SOUTH": [
    "EZELEKE/OGBODIUKWU",
    "OMAEGWU",
    "OHIAOCHA",
    "AHIAUKWU I",
    "AHIAUKWU II",
    "OLD UMUAHIA",
    "AMAKAMA",
    "UBAKALA 'A'",
    "NSIRIMO",
    "UBAKALA 'B'"
    ],

    "UMU-NNEOCHI": [
    "AMUDA",
    "UMUAKU",
    "MBALA/ACHARA",
    "EZINGODO",
    "NDIAWA/UMUELEM/I",
    "EZIAMA - UGWU",
    "EZIAMA - AGBO",
    "UBAHU/AKAWA/AROKPA",
    "UMUCHIEZE I",
    "UMUCHIEZE II",
    "UMUCHIEZE III",
    "OBINOLU/OBIAGU/LA"
    ],

    "DEMSA": [
    "BILLE",
    "BORRONG",
    "DEMSA",
    "DILLI",
    "DONG",
    "DWAM",
    "GWAMBA",
    "KPASHAM",
    "MBULA KULI",
    "NASSARAWO DEMSA"
    ],

    "FUFORE": [
    "BETI",
    "FARANG",
    "FUFORE",
    "GURIN",
    "KARLAHI",
    "MAYO INE",
    "PARIYA",
    "RIBADU",
    "UKI TUKI",
    "WURO BOKKI",
    "YADIM"
    ],

    "GANYE": [
    "BAKARI GUSO",
    "GAMU",
    "GANYE I",
    "GANYE II",
    "GURUM",
    "JAGGU",
    "SANGASUMI",
    "SUGU",
    "TIMDORE",
    "YEBBI"
    ],

    "GIRE 1": [
    "DAKRI",
    "DAMARE",
    "GEREI I",
    "GIREI II",
    "GERENG",
    "JERA BAKARI",
    "JERA BONYO",
    "MODIRE/ VINIKILANG",
    "TAMBO",
    "WURO DOLE"
    ],

    "GOMBI": [
    "BOGA/ DINGAI",
    "DUWA",
    "GA'ANDA",
    "GABUN",
    "GOMBI NORTH",
    "GOMBI SOUTH",
    "GARKIDA",
    "GUYAKU",
    "TAWA",
    "YANG"
    ],

    "GUYUK": [
    "BANJIRAM",
    "BOBINI",
    "BODENO",
    "CHIKILA",
    "DUKUL",
    "DUMNA",
    "GUYUK",
    "KOLA",
    "LOKORO",
    "PUROKAYO"
    ],

    "HONG": [
    "BANGSHIKA",
    "DAKSIRI",
    "GARAHA",
    "GAYA",
    "HILDI",
    "HONG",
    "HUSHERE ZUM",
    "KWARHI",
    "MAYO LOPE",
    "SHANGUI",
    "THILBANG",
    "UBA"
    ],

    "JADA": [
    "DANABA",
    "JADA I",
    "JADA II",
    "KOMA I",
    "KOMA II",
    "LEKO",
    "MAPEO",
    "MAYOKALAYE",
    "MBULO",
    "NYIBANGO",
    "YELLI"
    ],

    "LAMURDE": [
    "DUBWANGUN",
    "GYAWANA",
    "LAFIYA",
    "LAMURDE",
    "MGBEBONGUN",
    "NGBAKOWO",
    "OPALO",
    "RIGANGE",
    "SUWA",
    "WADUKU"
    ],

    "MADAGALI": [
    "BABEL",
    "DUHU/ SHUWA",
    "GULAK",
    "HYAMBULA",
    "K/WURO NGAYANDI",
    "MADAGALI",
    "PALLAM",
    "SHELMI / SUKUR/ VAPURA",
    "WAGGA",
    "WULA"
    ],

    "MAIHA": [
    "BELEL",
    "HUMBUTUDI",
    "KONKOL",
    "MAIHA GARI",
    "MANJEKIN",
    "MAYONGULI",
    "PAKKA",
    "SORAU 'A'",
    "SORAU 'B'",
    "TAMBAJAM"
    ],

    "MAYO-BELWA": [
    "BAJAMA",
    "BINYERI",
    "GANGFADA",
    "GENGLE",
    "GOROBI",
    "MAYO-BELWA",
    "MAYO FARANG",
    "NASSARAWO JERENG",
    "NDIKONG",
    "RIBADU",
    "TOLA",
    "YOFFO"
    ],

    "MICHIKA": [
    "BAZZA MARGI",
    "FUTUDOU / FUTULES",
    "GARTA / GHUNCHI",
    "JIGALAMBU",
    "MADZI",
    "MICHIKA I",
    "MICHIKA II",
    "MINKISI/ WURO NGIKI",
    "MODA / DLAKA / GHENJUWA",
    "MUNKAVICITA",
    "SINA / KAMALE / KWANDE",
    "THUKUDOU / SUFUKU / ZAH",
    "SUKUMU / TILLIJO",
    "TUMBARA / NGABILI",
    "VI / BOKA",
    "WAMBLIMI / TILLI"
    ],

    "MUBI NORTH": [
    "BAHULI",
    "BETSO",
    "DIGIL",
    "KOLERE",
    "LOKUWA",
    "MAYO BANI",
    "MIJILU",
    "MUCHALLA",
    "SABON LAYI",
    "VIMTIM",
    "YELWA"
    ],

    "MUBI SOUTH": [
    "DIRBISHI/GANDIRA",
    "DUVU/ CHABA/ GIRBURUM",
    "GELLA",
    "GUDE",
    "KWAJA",
    "LAMORDE",
    "MUJARA",
    "MUGULBU/ YADAFA",
    "NASSARAWO",
    "NDUKU"
    ],

    "NUMAN": [
    "BARE",
    "BOLKI",
    "GAMADIO",
    "IMBURU",
    "KODOMTI",
    "NUMAN I",
    "NUMAN II",
    "NUMAN III",
    "SABON PEGI",
    "VULPI"
    ],

    "SHELLENG": [
    "BAKTA",
    "BODWAI",
    "GUNDO",
    "GWAPOPOLOK",
    "JUMBUL",
    "KETEMBERE",
    "KIRI",
    "LIBBO",
    "SHELLENG",
    "TALLUM"
    ],

    "SONG": [
    "DIRMA",
    "DUMNE",
    "GUDU MBOI",
    "KILANGE FUNA",
    "KILANGE HIRNA",
    "SIGIRE",
    "SUKTU",
    "SONG GARI",
    "SONG WAJE",
    "WALTANDI",
    "ZUMO"
    ],

    "TOUNGO": [
    "DAWO I",
    "DAWO II",
    "GUMTI",
    "KIRI I",
    "KIRI II",
    "KONGIN BABA I",
    "KONGIN BABA II",
    "TOUNGO I",
    "TOUNGO II",
    "TOUNGO III"
    ],

    "YOLA NORTH": [
    "ALKALAWA",
    "AJIYA",
    "DOUBELI",
    "GWADABAWA",
    "JAMBUTU",
    "KAREWA",
    "LIMAWA",
    "LUGGERE",
    "NASSARAWO",
    "RUMDE",
    "YELWA"
    ],

    "YOLA SOUTH": [
    "ADARAWO",
    "BAKO",
    "BOLE YOLDE PATE",
    "NAMTARI",
    "NGURORE",
    "MAKAMA 'A'",
    "MAKAMA 'B'",
    "MBAMBA",
    "MBAMOI",
    "TOUNGO",
    "YOLDE KOHI"
    ],

    "ABAK": [
    "ABAK URBAN 1",
    "ABAK URBAN 11",
    "ABAK URBAN 111",
    "ABAK URBAN 1V",
    "AFAHA OBONG 1",
    "AFAHA OBONG 11",
    "MIDIM 1",
    "MIDIM 11",
    "OTORO 1",
    "OTORO 11",
    "OTORO 111"
    ],

    "EASTERN OBOLO": [
    "EASTERN OBOLO 1",
    "EASTERN OBOLO 11",
    "EASTERN OBOLO 111",
    "EASTERN OBOLO 1V",
    "EASTERN OBOLO V",
    "EASTERN OBOLO V1",
    "EASTERN OBOLO V11",
    "EASTERN OBOLO V111",
    "EASTERN OBOLO IX",
    "EASTERN OBOLO X"
    ],

    "EKET": [
    "URBAN 1",
    "URBAN 11",
    "URBAN 111",
    "URBAN 1V",
    "CENTRAL 1",
    "CENTRAL 11",
    "CENTRAL 111",
    "CENTRAL 1V",
    "CENTRAL V",
    "OKON 1",
    "OKON 11"
    ],

    "ESIT EKET (UQUO)": [
    "EKPENE OBO",
    "EDOR",
    "EBE EKPI",
    "IKPA",
    "UQUO",
    "EBIGHI OKPONO",
    "ETEBI IDUNG ASSAN",
    "ETEBI AKWATA",
    "AKPAUTONG",
    "NTAK INYANG"
    ],

    "ESSIEN UDIM": [
    "ADIASIM",
    "AFAHA",
    "EKPEYONG 1",
    "EKPEYONG 11",
    "IKPE ANNANG",
    "ODORO IKOT 1",
    "ODORO IKOT 11",
    "OKON",
    "UKANA EAST",
    "UKANA WEST 1",
    "UKANA WEST 11"
    ],

    "ETIM EKPO": [
    "ETIM EKPO 1",
    "ETIM EKPO 11",
    "ETIM EKPO 111",
    "ETIM EKPO 1V",
    "ETIM EKPO V",
    "ETIM EKPO V1",
    "ETIM EKPO V11",
    "ETIM EKPO V111",
    "ETIM EKPO IX",
    "ETIM EKPO X"
    ],

    "ETINAN": [
    "ETINAN URBAN 1",
    "ETINAN URBAN 11",
    "ETINAN URBAN 111",
    "ETINAN URBAN 1V",
    "ETINAN URBAN V",
    "SOUTHERN IMAN 1",
    "SOUTHERN IMAN 11",
    "SOUTHERN IMAN 111",
    "SOUTHERN IMAN 1V",
    "NORTHERN IMAN 1",
    "NORTHERN IMAN 11"
    ],

    "IBENO": [
    "IBENO 1",
    "IBENO 11",
    "IBENO 111",
    "IBENO 1V",
    "IBENO V",
    "IBENO VI",
    "IBENO V11",
    "IBENO V111",
    "IBENO IX",
    "IBENO X"
    ],

    "IBESIKPO ASUTAN": [
    "IBESIKPO 1",
    "IBESIKPO 11",
    "IBESIKPO 111",
    "IBESIKPO 1V",
    "IBESIKPO V",
    "ASUTAN 1",
    "ASUTAN 11",
    "ASUTAN 111",
    "ASUTAN 1V",
    "ASUTAN V"
    ],

    "IBIONO IBOM": [
    "IBIONO EASTERN 1",
    "IBIONO EASTERN 11",
    "IBIONO WESTERN 1",
    "IBIONO WESTERN 11",
    "IBIONO SOUTHERN 1",
    "IBIONO SOUTHERN 11",
    "IBIONO NORTHERN 1",
    "IBIONO NORTHERN 11",
    "IBIONO CENTRAL 1",
    "IBIONO CENTRAL 11",
    "IKPANYA"
    ],

    "IKA": [
    "URBAN 1",
    "URBAN 11",
    "ACHAN IKA",
    "ACHAN 11",
    "ACHAN 111",
    "ITO 1",
    "ITO 11",
    "ITO 111",
    "ODORO 1",
    "ODORO 11"
    ],

    "IKONO": [
    "IKONO MIDDLE 1",
    "IKONO MIDDLE 11",
    "IKONO MIDDLE 111",
    "IKONO MIDDLE 1V",
    "IKONO SOUTH",
    "NKWOT 1",
    "NKWOT 11",
    "NDIYA/IKOT IDAHA",
    "EDIENE 1",
    "EDIENE 11",
    "1TAK"
    ],

    "IKOT ABASI": [
    "IKPA IBEKWE 1",
    "IKPA IBEKWE 11",
    "UKPUM ETE 1",
    "UKPUM ETE 11",
    "UKPUM OKON",
    "EDEMAYA 1",
    "EDEMAYA 11",
    "EDEMAYA 111",
    "IKPA NUNG ASANG 1",
    "IKPA NUNG ASANG 11"
    ],

    "IKOT EKPENE": [
    "IKOT EKPENE 1",
    "IKOT EKPENE 11",
    "IKOT EKPENE 111",
    "IKOT EKPENE 1V",
    "IKOT EKPENE V",
    "IKOT EKPENE V1",
    "IKOT EKPENE V11",
    "IKOT EKPENE V111",
    "IKOT EKPENE IX",
    "IKOT EKPENE X",
    "IKOT EKPENE X1"
    ],

    "INI": [
    "IKPE 1",
    "IKPE 11",
    "ITU MBONUSO",
    "NKARI",
    "IWERRE",
    "IKONO NORTH 1",
    "IKONO NORTH 11",
    "IKONO NORTH 111",
    "ODORO UKWOK",
    "USUK UKWOK"
    ],

    "ITU": [
    "OKU IBOKU",
    "MBIASE/AYADEHE",
    "EAST ITAM 1",
    "EAST ITAM 11",
    "EAST ITAM 111",
    "EAST ITAM 1V",
    "EAST ITAM V",
    "WEST ITAM 1",
    "WEST ITAM 11",
    "WEST ITAM 111"
    ],

    "MBO": [
    "ENWANG 1",
    "ENWANG 11",
    "EBUGHU 1",
    "EBUGHU 11",
    "UDESI",
    "EFIAT 1",
    "EFIAT 11",
    "IBAKA",
    "UDA 1",
    "UDA 11"
    ],

    "MKPAT ENIN": [
    "UKPUM MINYA 1",
    "UKPUM MINYA 11",
    "UKPUM MINYA 111",
    "UKPUM MINYA 1V",
    "IKPA IBOM 1",
    "IKPA IBOM 11",
    "IKPA IBOM 111",
    "IKPA IBOM 1V",
    "IBIAKU 1",
    "IBIAKU 11",
    "IBIAKU 111",
    "IKPA IKONO 1",
    "IKPA IKONO 11",
    "IKPA IKONO 111"
    ],

    "NSIT ATAI": [
    "EASTERN NSIT 1",
    "EASTERN NSIT 11",
    "EASTERN NSIT 111",
    "EASTERN NSIT 1V",
    "EASTERN NSIT V",
    "EASTERN NSIT V1",
    "EASTERN NSIT V11",
    "EASTERN NSIT V111",
    "EASTERN NSIT IX",
    "EASTERN NSIT X"
    ],

    "NSIT IBOM": [
    "ASANG 1",
    "ASANG 11",
    "ASANG 111",
    "ASANG 1V",
    "ASANG V",
    "MBAISO 1",
    "MBAISO 11",
    "MBAISO 111",
    "MBAISO 1V",
    "MBAISO V"
    ],

    "NSIT UBIUM": [
    "IBIAKPAN/OBOTIM 11",
    "IBIAKPAN OBOTIM 1",
    "ITRETO",
    "NDIYA",
    "UBIUM NORTH 1",
    "UBIUM NORTH 11",
    "UBIUM NORTH 111",
    "UBIUM SOUTH 1",
    "UBIUM SOUTH 11",
    "UBIUM SOUTH 111"
    ],

    "OBOT AKARA": [
    "IKOT ABIA 1",
    "IKOT ABIA 11",
    "IKOT ABIA 111",
    "OBOT AKARA 1",
    "OBOT AKARA 11",
    "OBOT AKARA 111",
    "NTO EDINO 1",
    "NTO EDINO 11",
    "NTO EDINO 111",
    "NTO EDINO 1V"
    ],

    "OKOBO": [
    "OKOPEDI 1",
    "OKOPEDI 11",
    "NUNG ATAI/UBE 1",
    "NUNG ATAI/UBE 11",
    "AKAI/MBUKPO/UDUNG",
    "EWEME 1",
    "EWEME 11",
    "OFFI 1",
    "OFFI 11",
    "EKEYA"
    ],

    "ONNA": [
    "AWA 1",
    "AWA 11",
    "AWA 111",
    "AWA 1V",
    "NUNG IDEM 1",
    "NUNG IDEM 11",
    "ONIONG EAST 1",
    "ONIONG EAST 11",
    "ONIONG EAST 111",
    "ONIONG WEST 1",
    "ONIONG WEST 11",
    "ONIONG WEST 111"
    ],

    "ORON": [
    "ORON URBAN 1",
    "ORON URBAN 11",
    "ORON URBAN 111",
    "ORON URBAN 1V",
    "ORON URBAN V",
    "ORON URBAN V1",
    "ORON URBAN V11",
    "ORON URBAN V111",
    "ORON URBAN IX",
    "ORON URBAN X"
    ],

    "ORUK ANAM": [
    "IKOT IBRITAM 1",
    "IKOT IBRITAM 11",
    "NDOT/IKOT OKORO 1",
    "NDOT/IKOT OKORO 11",
    "NDOT/IKOT OKORO 111",
    "IBESIT",
    "EKPARAKWA",
    "ABAK MIDIM 1",
    "ABAK MIDIM 11",
    "ABAK MIDIM 111",
    "ABAK MIDIM 1V",
    "IBESIT/NUNG IKOT 1",
    "IBESIT/NUNG IKOT 11"
    ],

    "UDUNG UKO": [
    "UDUNG UKO 1",
    "UDUNG UKO 11",
    "UDUNG UKO 111",
    "UDUNG UKO 1V",
    "UDUNG UKO V",
    "UDUNG UKO V1",
    "UDUNG UKO V11",
    "UDUNG UKO V111",
    "UDUNG UKO IX",
    "UDUNG UKO X"
    ],

    "UKANAFUN": [
    "UKANAFUN URBAN",
    "SOUTHERN UKANAFUN 1",
    "SOUTHERN UKANAFUN 11",
    "NORTHERN UKANAFUN 1",
    "NORTHERN UKANAFUN 11",
    "NORTHERN AFAHA 1",
    "NORTHERN AFAHA 11",
    "SOUTHERN AFAHA, ADAT IFANG 1",
    "SOUTHERN AFAHA, ADAT IFANG 11",
    "SOUTHERN AFAHA, ADAT IFANG 111",
    "SOUTHERN AFAHA, ADAT IFANG 1V"
    ],

    "URUAN": [
    "CENTRAL URUAN I",
    "CENTRAL URUAN II",
    "CENTRAL URUAN III",
    "NORTHERN URUAN 1",
    "NORTHERN URUAN 11",
    "SOUTHERN URUAN 1",
    "SOUTHERN URUAN 11",
    "SOUTHERN URUAN 111",
    "SOUTHERN URUAN IV",
    "SOUTHERN URUAN V",
    "SOUTHERN URUAN V1"
    ],

    "URUE OFFONG/ORUKO": [
    "URUE OFFONG 1",
    "URUE OFFONG 11",
    "URUE OFFONG 111",
    "URUE OFFONG 1V",
    "URUE OFFONG V",
    "ORUKO 1",
    "ORUKO 11",
    "ORUKO 111",
    "ORUKO 1V",
    "ORUKO V"
    ],

    "UYO": [
    "UYO URBAN 1",
    "UYO URBAN 11",
    "UYO URBAN 111",
    "ETOI 1",
    "ETOI 11",
    "OFFOT 1",
    "OFFOT 11",
    "IKONO 1",
    "IKONO 11",
    "OKU 1",
    "OKU 11"
    ],

    "AGUATA": [
    "ACHINA I",
    "ACHINA II",
    "AGULUEZE CHUKWU",
    "AKPO",
    "AMESI",
    "EKWULOBIA I",
    "EKWULOBIA II",
    "EZINIFITE I",
    "EZINIFITE II",
    "IGBO-UKWU I",
    "IGBO - UKWU II",
    "IKENGA",
    "ISUOFIA",
    "NKPOLOGWU",
    "ORAERI",
    "UGA I",
    "UGA II",
    "UMUCHU I",
    "UMUCHU II",
    "UMUONA"
    ],

    "AYAMELUM": [
    "ANAKU",
    "IFITE OGWARI I",
    "IFITE OGWARI II",
    "IGBAKWU",
    "OMASI",
    "OMOR I",
    "OMOR II",
    "OMOR III",
    "UMU ELUM",
    "UMUEJE",
    "UMUMBO"
    ],

    "ANAMBRA EAST": [
    "AGULERI",
    "AGULERI II",
    "ENUGWU-OTU",
    "EZIAGULU-OTU",
    "IGBARIAM",
    "NANDO I",
    "NANDO II",
    "NANDO III",
    "NSUGBE I",
    "NSUGBE II",
    "OTUOCHA I",
    "OTUOCHA II",
    "UMURELI I",
    "UMULERI II",
    "UMUOBA-ANAM"
    ],

    "ANAMBRA WEST": [
    "EZI-ANAM",
    "IFITE-ANAM",
    "NZAM",
    "OLUMBANASA - ODE",
    "OLUMBANASA-INOMA",
    "OROMA ETITI-ANAM",
    "UMUENWELUM-ANAM",
    "UMUEZE-ANAM I",
    "UMUEZE-ANAM II",
    "UMUOBA-ANAM"
    ],

    "ANAOCHA": [
    "ADAZI ANI I",
    "ADAZI ANI II",
    "ADAZI ENU I",
    "ADAZI ENU II",
    "ADAZI NNUKWU I",
    "ADAZI NNUKWU II",
    "AGULU I",
    "AGULU II",
    "AGULU III",
    "AGULU IV",
    "AGULU UZOIGBO",
    "AKWAEZE",
    "ICHIDA I",
    "ICHIDA II",
    "NENI I",
    "NENI II",
    "NRI I",
    "NRI II",
    "OBELEDU"
    ],

    "AWKA NORTH": [
    "ACHALLA I",
    "ACHALLA I1",
    "ACHALLA I1I",
    "AMANSEA",
    "AMANUKE",
    "AWBA-OFEMMILI",
    "EBENEBE I",
    "EBENEBE II",
    "EBENEBE III",
    "ISU ANIOCHA",
    "MGBAKWU I (ANEZIKE)",
    "UGBENE",
    "UGBENU",
    "URUM"
    ],

    "AWKA SOUTH": [
    "AGU OKA",
    "AMAWBIA I",
    "AMAWBIA II",
    "AMAWBIA III",
    "AWKA I",
    "AWKA II",
    "AWKA III",
    "AWKA IV",
    "AWKA V",
    "AWKA VI",
    "AWKA VII",
    "EZINATO/ISIAGU",
    "MBA-UKWU",
    "NIBO I",
    "NIBO II",
    "NIBO III",
    "NISE I",
    "NISE II",
    "OKPUNO",
    "UMUAWULU"
    ],

    "DUNUKOFIA": [
    "AKWA",
    "IFITEDUNU I",
    "IFITEDUNU II",
    "NAWGU I",
    "NAWGU II",
    "UKPO I",
    "UKPO II",
    "UKPO III",
    "UKWULU I",
    "UKWULU II",
    "UMUDIOKA I",
    "UMUDIOKA II",
    "UMUNNACHI I",
    "UMUNNACHI II"
    ],

    "EKWUSIGO": [
    "ICHI",
    "IHEMBOSI/ANAUBAHU",
    "ORAIFITE I",
    "ORAIFITE II",
    "ORAIFITE III",
    "OZUBULU I",
    "OZUBULU II",
    "OZUBULU III",
    "OZUBULU IV",
    "OZUBULU V",
    "AMAKWA II",
    "IHITEOHA"
    ],

    "IDEMILI NORTH": [
    "ABACHA",
    "ABATETE",
    "EZIOWELLE",
    "IDEANI",
    "NKPOR I",
    "NKPOR II",
    "OBOSI",
    "OGIDI I",
    "OGIDI II",
    "ORAUKWU",
    "UKE",
    "UMUOJI"
    ],

    "IDEMILI SOUTH": [
    "AKWUKWU",
    "ALOR I",
    "ALOR II",
    "AWKA-ETITI I",
    "AWKA-ETITI II",
    "NNOBI I",
    "NNOBI II",
    "NNOBI III",
    "NNOKWA",
    "OBA I",
    "OBA II",
    "OJOTO"
    ],

    "IHALA": [
    "AMAMU I",
    "AMAMU II",
    "AMORKA",
    "AZIA",
    "IHITE",
    "ISSEKE",
    "OGBOLO",
    "OKIJA I",
    "OKIJA II",
    "OKIJA III",
    "OKIJA IV",
    "OKIJA V",
    "ORSUMOGHU",
    "UBULUISIUZOR",
    "ULI I",
    "ULI II",
    "ULI III",
    "LILU",
    "UZOAKWA",
    "MBOSI"
    ],

    "NJIKOKA": [
    "ABBA I",
    "ABBA II",
    "ABAGANA I",
    "ABAGANA II",
    "ABAGANA III",
    "ABAGANA IV",
    "ENUGWU-AGIDI I",
    "ENUGWU-AGIDI II",
    "ENUGWU UKWU I",
    "ENUGWU UKWU II",
    "ENUGWU UKWU III",
    "ENUGWU UKWU IV",
    "NAWFIA I",
    "NAWFIA II",
    "NIMO I",
    "NIMO II",
    "NIMO III",
    "NIMO IV"
    ],

    "NNEWI NORTH": [
    "NNEWI-ICHI I",
    "NNEWI-ICHI II",
    "OTOLO I",
    "OTOLO II",
    "OTOLO III",
    "UMUDIM I",
    "UMUDIM II",
    "URUAGU I",
    "URUAGU II",
    "URUAGU III"
    ],

    "NNEWI SOUTH": [
    "AKWA-IHEDI",
    "AMICHI I",
    "AMICHI II",
    "AMICHI III",
    "AZUIGBO",
    "EBENATOR",
    "EKWULUMILI",
    "EZINIFITE I",
    "EZINIFITE II",
    "EZINIFITE III",
    "OSUMENYI I",
    "OSUMENYI II",
    "UKPOR I",
    "UKPOR II",
    "UKPOR III",
    "UKPOR IV",
    "UKPOR V",
    "UKPOR VI",
    "UNUBI",
    "UTUH"
    ],

    "OGBARU": [
    "AKILI OGIDI/OBEAGWE",
    "AKILI - OZIZOR",
    "ATANI I",
    "ATANI II",
    "IYIOWA/ODEKPE/OHITA",
    "OCHUCHE-UMUODU/OGBAKUBA/AMIYI",
    "OGWUANIOCHA",
    "OGWU-IKPELE",
    "OKPOKO I",
    "OKPOKO II",
    "OKPOKO III",
    "OKPOKO IV",
    "OKPOKO V",
    "OKPOKO VI",
    "OSSOMALA",
    "UMUNANKWO/MPUTU"
    ],

    "ONITSHA-NORTH": [
    "AMERICAN QUARTERS",
    "G.R.A",
    "INLAND TOWN I",
    "INLAND TOWN II",
    "INLAND TOWN III",
    "INLAND TOWN IV",
    "INLAND TOWN V",
    "INLAND TOWN VI",
    "INLAND TOWN VII",
    "INLAND TOWN VIII`",
    "OGBE UMUONICHA",
    "TRANS NKISI",
    "WATER-SIDE CENTRAL 1",
    "WATER-SIDE CENTRAL II",
    "WOLUWO LAYOUT"
    ],

    "ONITSHA-SOUTH": [
    "BRIDGE HEAD I",
    "BRIDGE HEAD II",
    "BRIDGE HEAD III",
    "FEGGE 1",
    "FEGGE II",
    "FEGGE III",
    "FEGGE 1V",
    "FEGGE V",
    "FEGGE VI",
    "FEGGE VII",
    "ODOAKPU I",
    "ODOAKPU II",
    "ODOAKPU III",
    "ODOAKPU IV",
    "ODOAKPU V",
    "ODOAKPU VI",
    "ODOAKPU VII"
    ],

    "ORUMBA NORTH": [
    "AJALLI I (OBINIKPA AND UMUEVE)",
    "AJALLI II (UMUABIAMA AND AMAGA)",
    "AMAETITI",
    "AMAOKPALA / OMOGHO",
    "AWA",
    "AWGBU I",
    "AWGBU II",
    "NANKA I",
    "NANKA II",
    "NDIKELIONWU",
    "NDIOKOLO/NDIOKPALEKE",
    "NDIOWU",
    "NDIUKWUENU/OKPEZE",
    "OKO I",
    "OKO II",
    "NDI OKPALAEZE",
    "UFUMA I",
    "UFUMA II"
    ],

    "ORUMBA SOUTH": [
    "AGBUDU",
    "AKPU",
    "ENUGU/UMONYIA",
    "EZIAGU",
    "EZIRA",
    "IHITE",
    "ISULO",
    "NAWFIJA",
    "UMUCHUKWU",
    "OGBOJI",
    "OGBUNKA I",
    "OGBUNKA II",
    "OWERRE-EZUKALA I",
    "OWERRE-EZUKALA II",
    "UMUNZE I",
    "UMUNZE II",
    "UMUNZE III",
    "UMUOMAKU"
    ],

    "OYI": [
    "AWKUZU I",
    "AWKUZU II",
    "AWKUZU III",
    "AWKUZU IV",
    "NTEJE I",
    "NTEJE II",
    "NTEJE III",
    "NTEJE IV",
    "NTEJE V",
    "NKWELLE EZUNAKA I",
    "NKWELLE EZUNAKA II",
    "OGBUNIKE I",
    "OGBUNIKE II",
    "UMUNYA I",
    "UMUNYA II"
    ],

    "ALKALERI": [
    "ALKALERI",
    "PALI",
    "GAR",
    "GWARAM",
    "MAIMADI",
    "DAN KUNGIBAR",
    "BIRIN/ GIGARA/ YANKARI",
    "YULI/ LIM",
    "FUTUK",
    "YALO",
    "GWANA / MANSUR"
    ],

    "BAUCHI": [
    "MAJIDADI 'A'",
    "MAJIDADI 'B'",
    "MAKAMA/SARKI BAKI",
    "ZUNGUR/LIMAN KATAGUM",
    "MUN/MUNSAL",
    "DANDANGO/YAMRAT",
    "BIRSHI/MIRI",
    "KUNDUM/DURUM",
    "KANGYARE/TURWUN",
    "GALAMBI/GWASKWARAM",
    "DAN'IYA HARDO",
    "DAWAKI"
    ],

    "BOGORO": [
    "BOGORO A",
    "BOGORO B",
    "BOGORO C",
    "BOGORO D",
    "B O I 'A'",
    "B O I 'B'",
    "B O I 'C'",
    "LUSA 'A'",
    "LUSA 'B'",
    "LUSA 'C'"
    ],

    "DAMBAN": [
    "GARUZA",
    "GURBANA",
    "DAMBAM",
    "YANDA",
    "YAME",
    "DAGAUDA",
    "GARGAWA",
    "ZAURA",
    "JALAM CENTRAL",
    "JALAM EAST"
    ],

    "DARAZO": [
    "DARAZO",
    "TAUYA",
    "GABARIN",
    "KONKIYAL",
    "LAGO",
    "SADE",
    "LANZAI",
    "YAUTARE",
    "GABCIYARI",
    "WAHU",
    "PAPA"
    ],

    "DASS": [
    "BAGEL/BAJAR",
    "BUNDOT",
    "BUNUNU CENTRAL",
    "BUNUNU SOUTH",
    "BARAZA",
    "DOTT",
    "DURR",
    "POLCHI",
    "WANDI",
    "ZUMBUL/LUKSHI"
    ],

    "GAMAWA": [
    "GAMAWA",
    "KAFIN ROMI",
    "GOLOLO",
    "KUBDIYA",
    "ALAGARNO/JADORI",
    "TUMBI",
    "UDUBO",
    "TARMASUWA",
    "RAGA",
    "GADIYA",
    "ZINDI"
    ],

    "GANJUWA": [
    "GANJUWA",
    "GUNGURA",
    "KAFIN MADAKI",
    "KARIYA",
    "KUBI EAST",
    "KUBI WEST",
    "MIYA EAST",
    "MIYA WEST",
    "NASARAWA NORTH",
    "NASARAWA SOUTH",
    "YALI"
    ],

    "GIADE": [
    "CHINKANI",
    "SABON SARA",
    "DOGUWA CENTRAL",
    "DOGUWA SOUTH",
    "GIADE",
    "ISAWA",
    "U. ZUM 'A'",
    "UZUM 'B'",
    "ZABI",
    "ZIRRAMI"
    ],

    "ITAS/GADAU": [
    "ITAS",
    "MASHEMA",
    "GWARAI",
    "ABDALLAWA/MAGARYA",
    "BUZAWA",
    "BILKICHERI",
    "BAMBAL",
    "GADAU",
    "KASHURI",
    "ZUBUKI"
    ],

    "JAMA'ARE": [
    "JAMA'ARE 'A'",
    "JAMA'ARE 'B'",
    "JAMA'ARE 'C'",
    "JAMA'ARE 'D'",
    "DOGON JEJI 'A'",
    "DOGON JEJI 'B'",
    "DOGON JEJI 'C'",
    "HANAFARI",
    "GALDIMARI",
    "JURARA"
    ],

    "KATAGUM": [
    "TSAKUWA KOFAR GABAS/ KOFAR KUKA",
    "NASARAWA BAKIN KASUWA",
    "MADANGALA",
    "MADARA",
    "BUSKURI",
    "RAGWAM/MAGONSHI",
    "GAMBAKI/BIDIR",
    "CHINADE",
    "BULKACHUWA/DAGARO",
    "YAYU",
    "MADACHI/GANGAI"
    ],

    "KIRFI": [
    "BADARA",
    "BARA",
    "BENI 'A'",
    "BENI 'B'",
    "DEWU CENTRAL",
    "DEWU EAST",
    "KIRFI",
    "SHANGO",
    "TUBULE",
    "WANKA"
    ],

    "MISAU": [
    "ZADAWA",
    "BETI",
    "JARKASA",
    "KUKADI/GUNDARI",
    "AJILIN/GUGULIN",
    "TOFU",
    "HARDAWA",
    "SARMA/AKUYAM",
    "SIRKO",
    "GWARAM"
    ],

    "NINGI": [
    "NINGI",
    "DINGIS",
    "NASARU",
    "JANGU",
    "BALMA",
    "KUDU / YAMMA",
    "TIFFI / GUDA",
    "BURRA / KYATA",
    "SAMA",
    "BASHE",
    "KURMI"
    ],

    "SHIRA": [
    "ANDUBUN",
    "SAMBUWAL",
    "BUKUL/BANGIRE",
    "DISINA",
    "FAGGO",
    "BELI/GAGIDABA",
    "KILBORI",
    "SHIRA",
    "TSAFI",
    "TUMFAFI",
    "ZUBO"
    ],

    "TAFAWA BALEWA": [
    "KARDAM 'A'",
    "KARDAM 'B'",
    "LERE NORTH",
    "LERE SOUTH",
    "TAPSHIN",
    "WAI",
    "BALL",
    "BULA",
    "DAJIN",
    "DULL",
    "BUNUNU"
    ],

    "TORO": [
    "TORO/TULAI",
    "TILDEN FULANI",
    "RIBINA",
    "MARA/PALAMA",
    "RAUTA/GEJI",
    "JAMA'A/ZARANDA",
    "LAME",
    "WONU",
    "ZALAU/RISHI",
    "TAMA",
    "RAHAMA"
    ],

    "WARJI": [
    "BAIMA NORTH / WEST",
    "BAIMA SOUTH/EAST",
    "DAGU EAST",
    "DAGU WEST",
    "GABANGA",
    "KATANGA",
    "RANGA",
    "TIYIN",
    "TUDUN WADA EAST",
    "TUDUN WADA WEST"
    ],

    "ZAKI": [
    "BURSALI",
    "KATAGUM",
    "TASHENA / GADAI",
    "MAKAWA",
    "SAKWA",
    "GUMAI",
    "MURMUR NORTH",
    "MURMUR SOUTH",
    "ALANGAWARI / KAFIN / LARABAWA",
    "MAIWA",
    "MAINAKO"
    ],

    "BRASS": [
    "BRASS 1",
    "BRASS WARD II",
    "EWOAMA/FANTUO",
    "OKPOAMA",
    "ODIOMA/DIEMA",
    "CAPE FARMOSA",
    "KONGHO",
    "OGINIBIRI",
    "MINIBIE",
    "SANGANA"
    ],

    "EKEREMOR": [
    "TARAKIRI",
    "OYIAKIRI I",
    "OYIAKIRI II",
    "OYIAKIRI III",
    "OYIAKIRI IV",
    "OPOROMOR I",
    "OPOROMOR II",
    "OPOROMOR III",
    "OPOROMOR IV",
    "OPOROMOR V",
    "EDUWINI I",
    "EDUWINI II"
    ],

    "KOLOKUMA/OPOKUMA": [
    "ODI (NORTH) I",
    "ODI (CENTRAL) II",
    "ODI (SOUTH) III",
    "KAIAMA",
    "KAIAMA/OLOBIRI",
    "SAMPOU/KALAMA",
    "OPOKUMA NORTH",
    "OPOKUMASOUTH",
    "SEIBOKOROGHA (SABAGREIA) 1",
    "OKOLOBA (SABAGREIA) II",
    "IGBEDI"
    ],

    "NEMBE": [
    "OGBOLOMABIRI 1",
    "OGBOLOMABIRI 11",
    "OGBOLOMABIRI 111",
    "BASSAMBIRI 1",
    "BASSAMBIRI 11",
    "BASSAMBIRI 111",
    "BASSAMBIRI 1V",
    "IGBETA-EWOAMA/FANTUO",
    "OKOROMA 1",
    "OKOROMA 11",
    "MINI",
    "IKENSI",
    "OLUASIRI"
    ],

    "OGBIA": [
    "OGBIA",
    "OTUOKPOTI",
    "OLOGI",
    "ANYAMA",
    "OKODI",
    "OTUASEGA",
    "EMEYAL",
    "IMIRINGI",
    "KOLO",
    "OLOIBIRI",
    "OPUME",
    "OTAKEME",
    "OTUABULA"
    ],

    "SAGBAMA": [
    "AGBERE",
    "ANGALABIRI",
    "OFONI I",
    "OFONI II",
    "EBEDEBIRI",
    "OSSIAMA",
    "ASAMABIRI",
    "ODONI",
    "TROFANI",
    "SAGBAMA",
    "AGORO",
    "TORU-EBENI",
    "ADAGBABIRI",
    "OSEKWENIKE"
    ],

    "SOUTHERN IJAW": [
    "OPOROMA 1",
    "OPOROMA II",
    "OLODIAMA I",
    "OLODIAMA II",
    "OTUAN",
    "WEST BOMO",
    "CENTRAL BOMO I",
    "CENTRAL BOMO II",
    "AMASSOMA I",
    "AMASSOMA II",
    "AMASSOMA III",
    "EAST BOMO I",
    "EAST BOMO II",
    "FOROPA",
    "APOI",
    "UKUBIE",
    "KOLUAMA"
    ],

    "YENAGOA": [
    "ATTISSA I",
    "ATTISSA II",
    "ATTISSA III",
    "EPIE I",
    "EPIE 11",
    "EPIE III",
    "GBARAIN I",
    "GBARAIN II",
    "GBARAIN III",
    "EKPETIAMA I",
    "EKPETIAMA 11",
    "BISENI 1",
    "BISENI 11",
    "OKORDIA",
    "ZARAMA"
    ],

    "ADO": [
    "AKPOGE/OGBILOLO",
    "APA",
    "EKILE",
    "IGUMALE I",
    "IGUMALE II",
    "IJIGBAN",
    "OGEGE",
    "ROYONGO",
    "UKWONYO",
    "ULAYI"
    ],

    "AGATU": [
    "EGBA",
    "ENUNGBA",
    "OBAGAJI",
    "ODUGBEHO",
    "OGBAULU",
    "OGWULE-KADUNA",
    "OGWULE OGBAULU",
    "OKOKOLO",
    "OSHIGBUDU",
    "USHA"
    ],

    "APA": [
    "AKPETE/OJANTELLE",
    "AUKE",
    "EDIKWU I",
    "EDIKWU II",
    "IGAH-OKPAYA",
    "IGORO",
    "IKOBI",
    "OBA",
    "OFOKE",
    "OIJI",
    "UGBOKPO"
    ],

    "BURUKU": [
    "BINEV",
    "ETULO",
    "MBAADE",
    "MBAAKURA",
    "MBAAPEN",
    "MBAATIRKYAA",
    "MBAAZAGEE",
    "MBAIKYONGO/NYIFON",
    "MBAITYOUGH",
    "MBAKYAAN",
    "MBAYA",
    "MBAYAKA",
    "SHOROV"
    ],

    "GBOKO": [
    "GBK/CENTRAL MARKET",
    "GBOKO EAST",
    "GBOKO NORTH WEST",
    "GBOKO SOUTH",
    "IGYOROV",
    "MBAANKU",
    "MBAA VARAKAA",
    "MBADAM",
    "MBADIM",
    "MBAKPER",
    "MBAKWEN",
    "MBATAN",
    "MBATSER",
    "MBATYU",
    "UKPEKPE",
    "YANDEV NORTH",
    "YANDEV SOUTH"
    ],

    "GUMA": [
    "ABINSI",
    "KAAMBE",
    "MBABAI",
    "MBADWEM",
    "MBAWA",
    "MBAYER/YANDEV",
    "NYIEV",
    "NZOROV",
    "SAGHEV",
    "UVIR"
    ],

    "GWER EAST": [
    "AKPACH'AYI",
    "ALIADE TOWN",
    "GBEMACHA",
    "IKYOGBAJIR",
    "IKYONOV",
    "MBABUR",
    "MBAIASE",
    "MBAIKYU",
    "MBAIKYAAN",
    "MBALOM",
    "MBASOMBO",
    "MBAYOM",
    "SHOUGH",
    "UGEE"
    ],

    "GWER WEST": [
    "AVIHIJIME",
    "GAAMBE - USHIN",
    "GBAANGE/TONGOV",
    "IKYAGHEV",
    "ITYOUGHATEE/INJAHA",
    "MBABUANDE",
    "MBACHOHON",
    "MBANYAMSHI",
    "MBAPA",
    "MERKYEN",
    "SAGHEV/UKUSU",
    "SENGEV",
    "SENGEV/YENGEV",
    "TIJIME",
    "TSAMBE/MBASEV"
    ],

    "KATSINA-ALA": [
    "IKURAV TIEV I",
    "IKURAV TIEV II",
    "IWAR(TONGOV I)",
    "KATSINA-ALA TOWN",
    "MBACHER",
    "MBAJIR",
    "MBATULA/MBEREV",
    "MBAYONGO",
    "MICHIHE",
    "TIIR(TONGOV II)",
    "UTANGE",
    "YOOYO"
    ],

    "KONSHISHA": [
    "IKYURAV/MBATWER",
    "MBAGUSA/MBATSER",
    "MBAIKYASE",
    "MBAIWARNYAM",
    "MBAKE",
    "MBANOR",
    "MBATSEN",
    "MBAVAA",
    "MBAWAR",
    "MBAYEGH/MBAIKYER",
    "TSE-AGBERAGBA"
    ],

    "KWANDE": [
    "ADIKPO METROPOLIS",
    "LIEV I",
    "LIEV II",
    "MBADURA",
    "MBAGBA/MBAIKYAN",
    "MBAIKYOR",
    "MBAKETSA",
    "MENEV",
    "TONDOV I",
    "TONDOV II",
    "USAR",
    "MBAYOO",
    "YAAV",
    "MOON",
    "KUMAKWAGH"
    ],

    "LOGO": [
    "MBADYUL",
    "MBAGBER",
    "MBATER",
    "MBAYAM",
    "MBAVUUR",
    "NENZEV",
    "TOMBO",
    "TURAN",
    "UKEMBERGYA/ISWAREV",
    "YONOV"
    ],

    "MAKURDI": [
    "AGAN",
    "ANKPA/WADATA",
    "BAR",
    "CENTRAL/SOUTH MISSION",
    "CLERKS/MARKET",
    "FIIDI",
    "MBALAGH",
    "MODERN MARKET",
    "NORTH BANK I",
    "NORTH BANK II",
    "WAILOMAYO"
    ],

    "OBI": [
    "ADIKO",
    "ADUM WEST",
    "IKWOKWU",
    "IRABI",
    "ITOGO",
    "OBARIKE",
    "OBEKO",
    "ODIAPA",
    "OGORE",
    "OKPOKWU",
    "OKWUTUNGBE",
    "ORIHI"
    ],

    "OGBADIBO": [
    "AI-OODO I",
    "AI-OODO II",
    "AI-OONO I",
    "AI-OONO II",
    "AI-OONO III",
    "EHAJE I",
    "EHAJE II",
    "ITABONO I",
    "ITABONO II",
    "OLACHAGBAHA",
    "OROKAM I",
    "OROKAM II",
    "OROKAM III"
    ],

    "OJU": [
    "ADOKPA",
    "AINU",
    "IBILLA",
    "IDELLE",
    "IYECHE",
    "OBORU/OYE",
    "OJU",
    "OKPOKPO",
    "OKWUDU",
    "OWO",
    "UKPA/AINU ETTE"
    ],

    "OHIMINI": [
    "AGADAGBA",
    "AWUME EHAJE",
    "AWUME ICHO",
    "EHATOKPE",
    "IDEKPA",
    "OCHOBO",
    "OGLEWU EHAJE",
    "OGLEWU ICHO",
    "ONYAGEDE-EHAJE (ALLE)",
    "ONYAGEDE ICHO (OGOLI)"
    ],

    "OKPOKWU": [
    "AMEJO",
    "EKE",
    "ICHAMA II",
    "OJIGO",
    "OJOGA",
    "OKONOBO",
    "OKPAILE/INGLE",
    "OKPOGA CENTRAL",
    "OKPOGA NORTH",
    "OKPOGA SOUTH",
    "OKPOGA WEST",
    "UGBOKOLO"
    ],

    "OTUKPO": [
    "ADOKA-ICHO",
    "ADOKA-HAJE",
    "ALLAN",
    "ENTEKPA",
    "EWULO",
    "OKETE",
    "OTOBI",
    "OTUKPO TOWN CENTRAL",
    "OTUKPO TOWN EAST",
    "OTUKPO TOWN WEST",
    "UGBOJU-EHAJE",
    "UGBOJU-ICHO",
    "UGBOJU-OTAHE"
    ],

    "TARKA": [
    "MBAAJIR AKAA",
    "MBAAYO",
    "MBACHAVER IKYONDO",
    "MBAIGBA",
    "MBAIKYAA",
    "MBAIKYO/MBAYIA",
    "MBAKWAKEM",
    "MBANYAGBER",
    "SHITILE",
    "TONGOV"
    ],

    "UKUM": [
    "ATERAYANGE",
    "AZENDESHI",
    "BORIKYO",
    "ITYULUV",
    "KENDEV",
    "KUNDAV",
    "LUMBUV",
    "MBATIAN",
    "MBAYENGE",
    "MBAZUN",
    "TSAAV",
    "UGBAAM",
    "UYAM"
    ],

    "USHONGO": [
    "ATIRKYESE",
    "IKOV",
    "LESSEL",
    "MBAAKA",
    "MBAANYAM",
    "MBAAWE",
    "MBAGBA",
    "MBAGWAZA",
    "MBAKUHA",
    "MBAYEGH",
    "UTANGE"
    ],

    "VANDEIKYA": [
    "MBADEDE",
    "MBATYOUGH",
    "MBAGBAM",
    "MBAGBERA",
    "MBAJOR",
    "MBAKAANGE",
    "MBAKYAHA",
    "MBAYONGO",
    "NINGEV",
    "TSAMBE",
    "VANDEIKYA TOWNSHIP",
    "MBANYUMANGBAGH"
    ],

    "ABADAM": [
    "AREGE",
    "BANOWA",
    "FUGUWA",
    "JABULLAM",
    "KUDOKURGU",
    "MALLAMFATORI KESSA",
    "MALAM KAUNARI",
    "YAU",
    "YAWA KURA",
    "YITUWA"
    ],

    "ASKIRA/UBA": [
    "ASKIRA EAST",
    "CHUL / RUMIRGO",
    "DILLE / HUYUM",
    "HUSARA / TAMPUL",
    "KOPA / MULTHAFU",
    "LASSA",
    "MUSSA",
    "NGOHI",
    "NGULDE",
    "UBA",
    "UDA / UVU",
    "WAMDEO / GIWI",
    "ZADAWA / HAUSARI"
    ],

    "BAMA": [
    "ANDARA / AJIRI /WULBA",
    "BUDUWA / BULA CHIRABE",
    "DIPCHARI / JERE / DAR-JAMAL / KOTEMBE",
    "GULUMBA / JUKKURI / BATRA",
    "KASUGULA",
    "KUMSHE /NDUGUNO",
    "LAWANTI / MALAM / MASTARI / ABBARAM",
    "MARKA / MALGE / AMCHAKA",
    "MBULIYA / GONIRI / SIRAJA",
    "SABSABWA / SOYE/ BULONGU",
    "SHEHURI / HAUSARI / MAIRI",
    "WULBARI/NDINE/CHACHILE",
    "YABIRI KURA/YABIRI GANA/CHONGOLO",
    "ZANGERI/KASH KASH"
    ],

    "BAYO": [
    "BALBAYA",
    "BRIYEL",
    "FIKAYEL",
    "GAMADADI",
    "JARA GOL",
    "JARA DALI",
    "LIMANTI",
    "TELI",
    "WUYO",
    "ZARA"
    ],

    "BIU": [
    "BURATAI",
    "DADIN KOWA",
    "DUGJA",
    "GARUBULA",
    "GUR",
    "KENKEN",
    "MANDARA GIRAU",
    "MIRINGA",
    "SULUMTHLA",
    "YAWI",
    "ZARAWUYAKU"
    ],

    "CHIBOK": [
    "CHIBOK GARU",
    "CHIBOK LIKAMA",
    "CHIBOK WUNTAKU",
    "GATAMARWA",
    "KAUTIKARI",
    "KORONGILIM",
    "KUBURMBULA",
    "MBALALA",
    "MBOA KURA",
    "SHIKARKIR",
    "PEMI"
    ],

    "DAMBOA": [
    "AJIGN (A)",
    "AJIGN (B)",
    "AZUR/MULTE/FORFOR",
    "BEGO/YERWA/NGURNA",
    "DAMBOA",
    "GUMSURI/MISAKURBUDU",
    "KAFA / MAFI",
    "MULGWAI / KOPCHI",
    "NGUDA / WUYARAM",
    "WAWA / KOREDE"
    ],

    "DIKWA": [
    "BOBOSHE",
    "DIKWA",
    "GAJIBO",
    "UFAYE / GUJILE",
    "MULIYE / JEMURI",
    "MUDU / KAZA",
    "MALLAM MAJA",
    "NGUDORAM",
    "MAGARTA / SHEFFRI",
    "SOGOMA / AFUYE"
    ],

    "GUBIO": [
    "ARDIMINI",
    "DABIRA",
    "FELO",
    "GAMOWO",
    "GAZABURE",
    "GUBIO TOWN I",
    "GUBIO TOWN II",
    "KINGOWA",
    "NGETRA",
    "ZOWO"
    ],

    "GUZAMALA": [
    "ADUWA",
    "GUDUMBALI EAST",
    "GUDUMBALI WEST",
    "GUWORAM",
    "GUZAMALA EAST",
    "GUZAMALA WEST",
    "KINGARWA",
    "MAIRARI",
    "MODURI",
    "WAMIRI"
    ],

    "GWOZA": [
    "ASHIGASHIYA",
    "BITA / IZGE",
    "DURE / WALA / WARABE",
    "GAVVA / AGAPALWA",
    "GUDUF NAGADIYO",
    "GWOZA TOWN GADAMAYO",
    "GWOZA WAKANE / BULABULIN",
    "HAMBAGDA/ LIMAN KARA/ NEW SETTLEMENT",
    "JOHODE/CHIKIDE/KUGHUM",
    "KIRAWA/JIMINI",
    "KURANA BASSA/NGOSHE - SAMA'A",
    "NGOSHE",
    "PULKA/BOKKO"
    ],

    "HAWUL": [
    "BILINGWI",
    "DZAR/ VINADUM/ BIRNI/ DLANDI",
    "GWANZANG PUSDA",
    "HIZHI",
    "KIDA",
    "KWAJAFFA/HANG",
    "KWAYA-BUR/TANGA RUMTA",
    "MARAMA/KIDANG",
    "PAMA/WHITAMBAYA",
    "PUBA/VIDAU/LOKOJA",
    "SAKWA/HEMA",
    "SHAFFA"
    ],

    "JERE": [
    "ALAU",
    "BALE GALTIMARI",
    "DALA LAWANTI",
    "DUSUMAN",
    "GONGULONG",
    "MAIMUSARI",
    "MASHAMARI",
    "NGUDAA/ADDAMARI",
    "OLD MAIDUGURI",
    "TUBA",
    "MAIRI",
    "GOMARI"
    ],

    "KAGA": [
    "AFA/DIG/MAUDORI",
    "BENISHEIKH",
    "BORGOZO",
    "DOGOMA / JALORI",
    "DONGO",
    "GALANGI",
    "GUWO",
    "KARAGAWARU",
    "MAINOK",
    "MARGUBA",
    "NGAMDU",
    "SHETTIMARI",
    "TOBOLO",
    "WAJIRO / BURGUMMA",
    "WASSARAM"
    ],

    "KALA BALGE": [
    "MOHOLO",
    "JILBE 'A'",
    "JILBE B KOMA KAUDI",
    "JARAWA/SANGAYA",
    "KALA",
    "KUMAGA",
    "RANN 'A'",
    "RANN B DAIMA",
    "MADA",
    "SIGAL/KARCHE"
    ],

    "KONDUGA": [
    "AUNO / CHABBOL",
    "DALORI / WANORI",
    "DAWA EAST / MALARI / KANGAMARI",
    "JEWU / LAMBOA",
    "KAWURI",
    "KELUMIRI / NGALBI AMARI / YALE",
    "KONDUGA",
    "MAIRAMRI / YELERI / BAZAMRI",
    "MASBA / DALWA WEST",
    "NYALERI/SANDIA/YEJIWA",
    "SOJIRI/ NGURO - NGURO"
    ],

    "KUKAWA": [
    "ALAGARNO",
    "BAGA",
    "BUNDUR",
    "DOGOSHI",
    "DORO / DUGURI",
    "KAUWA",
    "KEKENO",
    "KUKAWA",
    "MODUARI / BARWARI",
    "YOYO"
    ],

    "KWAYA/KUSAR": [
    "GONDI",
    "GUSI / BILLA",
    "GUWAL",
    "KUBUKU",
    "KURBA",
    "KWAYA KUSAR",
    "PETA",
    "WADA",
    "WAWA",
    "YIMIRTHALANG"
    ],

    "MAFA": [
    "ABBARI",
    "ANADUA",
    "GAWA",
    "KOSHEBE",
    "LAJE",
    "LIMANTI",
    "LOSKURI",
    "MA'AFA",
    "MAFA",
    "MASU",
    "MUJIGINE",
    "TAMSU NGAMDUA"
    ],

    "MAGUMERI": [
    "ARDO RAM",
    "AYI / YASKU",
    "BORNO YESU",
    "FURRAM",
    "GAJI GANNA I",
    "GAJI GANNA II",
    "HOYO / CHIN GOWA",
    "KALIZORAM / BANORAM",
    "KARERAM",
    "KUBTI",
    "MAGUMERI",
    "NGAMMA",
    "NGUBALA"
    ],

    "MAIDUGURI M.C.": [
    "BOLORI I",
    "BOLORI II",
    "BULABLIN",
    "FEZZAN",
    "GAMBORU LIBERTY",
    "GWANGE I",
    "GWANGE II",
    "GWANGE III",
    "HAUSARI/ZANGO",
    "LAMISULA/JABBA MARI",
    "LIMANTI",
    "MAFONI",
    "MAISANDARI",
    "SHEHURI NORTH",
    "SHEHURI SOUTH"
    ],

    "MARTE": [
    "ALA",
    "ALLA LAWANTI",
    "BORSORI",
    "GUMNA",
    "KABULAWA",
    "KIRENOWA",
    "KULLI",
    "MARTE",
    "MAWULLI",
    "MUSUNE",
    "NGELEIWA",
    "NJINE",
    "ZAGA"
    ],

    "MOBBAR": [
    "ASAGA",
    "BOGUM",
    "CHAMBA",
    "DAMASAK",
    "DUJI",
    "GASHAGAR",
    "KARETO",
    "LAYI",
    "ZANNA UMORTI",
    "ZARI"
    ],

    "MONGUNO": [
    "DAMAKULI",
    "KAGURAM",
    "KUMALIA",
    "MOFIO",
    "MANDALA",
    "MINTAR",
    "MONGUNO",
    "NGURNO",
    "SURE",
    "WULO",
    "YELE",
    "ZULUM"
    ],

    "NGALA": [
    "SAGIR",
    "FUYE",
    "GAMBORU 'B'",
    "GAMBORU 'C'",
    "LOGUMANE",
    "NDUFU",
    "NGALA WARD",
    "OLD GAMBORU 'A'",
    "TUNOKALIA",
    "WARSHELE",
    "WULGO",
    "WURGE"
    ],

    "NGANZAI": [
    "ALARGE",
    "BADU",
    "DAMARAM",
    "GAJIRAM",
    "GADAI",
    "JIGALTA",
    "KUDA",
    "KURNAWA",
    "MAIWA",
    "MIYE",
    "SABSABUWA",
    "SUGUNDURE"
    ],

    "SHANI": [
    "BARGU / BURASHIKA",
    "BUMA",
    "GASI / SALIFAWA",
    "GORA",
    "GWALASHO",
    "GWASKARA",
    "KOMBO",
    "KUBO",
    "KWABA",
    "SHANI",
    "WALAMA"
    ],

    "ABI": [
    "ADADAMA",
    "AFAFANYI/IGONIGONI",
    "EBOM",
    "EDIBA",
    "EKUREKU I",
    "EKUREKU II",
    "IMABANA I",
    "IMABANA II",
    "ITIGIDI",
    "USUMUTONG"
    ],

    "AKAMKPA": [
    "AKAMKPA URBAN",
    "AWI",
    "EKU",
    "IKO",
    "IKPAI",
    "MBARAKOM",
    "OBAN",
    "OJUK NORTH",
    "OJUK SOUTH",
    "UYANGA"
    ],

    "AKPABUYO": [
    "ATIMBO EAST",
    "ATIMBO WEST",
    "ENEYO",
    "IDUNDU/ANYANGANSE",
    "IKANG CENTRAL",
    "IKANG NORTH",
    "IKANG SOUTH",
    "IKOT EDEM ODO",
    "IKOT EYO",
    "IKOT NAKANDA"
    ],

    "BAKASSI": [
    "ABANA",
    "AKPANKANYA",
    "AKWA",
    "AMBAI EKPA",
    "AMOTO",
    "ARCHIBONG",
    "ATAI EMA",
    "EFUT INWANG",
    "EKPOT ABIA",
    "ODIONG"
    ],

    "BEKWARRA": [
    "ABUOCHICHE",
    "AFRIKE OCHAGBE",
    "AFRIKE OKPECHE",
    "BETEN",
    "GAKEM",
    "IBIARAGIDI",
    "NYANYA",
    "OTUKPURU",
    "UGBORO",
    "UKPAH"
    ],

    "BIASE": [
    "ABAYONG",
    "ADIM",
    "AGWAGUNE/OKURIKE",
    "AKPET/ABINI",
    "BIAKPAN",
    "EHOM",
    "EREI NORTH",
    "EREI SOUTH",
    "IKUN/ETONO",
    "UMON NORTH",
    "UMON SOUTH"
    ],

    "BOKI": [
    "ABO",
    "ALANKWU",
    "BEEBO/BUMAJI",
    "BOJE",
    "BUDA",
    "BUENTSEBE",
    "BUNYIA/OKUBUCHI",
    "EKPASHI",
    "KAKWAGOM/BAWOP",
    "OGEP/OSOKOM",
    "OKU/BORUM/NJUA"
    ],

    "CALABAR MUNICIPALITY": [
    "ONE",
    "TWO",
    "THREE",
    "FOUR",
    "FIVE",
    "SIX",
    "SEVEN",
    "EIGTH",
    "NINE",
    "TEN"
    ],

    "CALABAR SOUTH": [
    "ONE (1)",
    "TWO (2)",
    "THREE (3)",
    "FOUR (4)",
    "FIVE (5)",
    "SIX (6)",
    "SEVEN (7)",
    "EIGHT (8)",
    "NINE (9)",
    "TEN (10)",
    "ELEVEN (11)",
    "TWELVE (12)"
    ],

    "ETUNG": [
    "ABIA",
    "ABIJANG",
    "AGBOKIM",
    "AJASSOR",
    "BENDEGHE EKIEM",
    "EFFRAYA",
    "ETOMI",
    "ITAKA",
    "MKPOT/AYUK ABA",
    "NSOFANG"
    ],

    "IKOM": [
    "ABANYUM",
    "AKPARABONG",
    "IKOM URBAN I",
    "IKOM URBAN II",
    "NDE",
    "NNAM",
    "NTA/NSELLE",
    "OFUTOP I",
    "OFUTOP II",
    "OLULUMO",
    "YALA/NKUM"
    ],

    "OBANLIKU": [
    "BASANG",
    "BEBI",
    "BECHEVE",
    "BENDI I",
    "BENDI II",
    "BISHIRI NORTH",
    "BISHIRI SOUTH",
    "BISU",
    "BUSI",
    "UTANGA"
    ],

    "OBUBRA": [
    "ABABENE",
    "APIAPUM",
    "IYAMOYONG",
    "OBUBRA URBAN",
    "OCHON",
    "OFAT",
    "OFODUA",
    "OFUMBONGHA/YALA",
    "OSOPONG I",
    "OSOPONG II",
    "OVONUM"
    ],

    "OBUDU": [
    "ALEGE/UBANG",
    "ANGIABA / BEGIAKA",
    "BEGIADING",
    "IPONG",
    "OBUDU URBAN I",
    "OBUDU URBAN II",
    "UKPE",
    "UTUGWANG CENTRAL",
    "UTUGWANG NORTH",
    "UTUGWANG SOUTH"
    ],

    "ODUKPANI": [
    "ADIABO/EFUT",
    "AKAMKPA",
    "CREEK TOWN I",
    "CREEK TOWN II",
    "EKORI/ANAKU",
    "ENIONG",
    "EKI",
    "OBOMITIAT/MBIABO/EDIONG",
    "ODOT",
    "ODUKPANI CENTRAL",
    "ONIMAN-KIONG",
    "IKONETO",
    "ITO/IDERE/UKWA"
    ],

    "OGOJA": [
    "EKAJUK I",
    "EKAJUK II",
    "MBUBE EAST I",
    "MBUBE EAST II",
    "MBUBE WEST I",
    "MBUBE WEST II",
    "NKUM IBORR",
    "NKUM IREDE",
    "OGOJA URBAN I",
    "OGOJA URBAN II"
    ],

    "YAKURR": [
    "AFREKPE/EKPENTI",
    "AJERE",
    "ASSIGA",
    "BIKO BIKO",
    "IDOMI",
    "IJIMAN",
    "IJOM",
    "IKPAKAPIT",
    "INYIMA",
    "MKPANI/AGOI",
    "ABANAKPAI",
    "NKPOLO/UKPAWEN",
    "NTAN"
    ],

    "YALA": [
    "ECHUMOFANA",
    "GABU",
    "IJIRAGA",
    "NTRIGOM/MFUMA",
    "OKPOMA",
    "OKUKU",
    "WANIHEM",
    "WANIKADE",
    "WANAKOM",
    "YACHE",
    "YAHE"
    ],

    "ANIOCHA NORTH": [
    "OBIOR",
    "ONICHA UGBO",
    "OBOMKPA",
    "ONICHA - OLONA",
    "ISSELE - AZAGBA",
    "ISSELE UKU I",
    "ISSELE UKU II",
    "IDUMUJE - UNOR",
    "UKWU - NZU",
    "EZI"
    ],

    "ANIOCHA SOUTH": [
    "OGWASHI - UKU VILLAGE",
    "OGWASHI - UKU I",
    "OGWASHI - UKU II",
    "UBULU - UKU I",
    "UBULU - UKU II",
    "UBULU - UNOR",
    "NSUKWA",
    "EJEME",
    "ISHEAGU-EWULU",
    "ABA - UNOR",
    "UBULU OKITI"
    ],

    "BOMADI": [
    "BOMADI",
    "KPAKIAMA",
    "ESANMA",
    "OGRIAGBENE",
    "KOLAFIOGBENE/EKAMETAGBENE",
    "OGBEINAMA/OKOLOBA",
    "AKUGBENE I",
    "AKUGBENE II",
    "AKUGBENE III",
    "OGO - EZE"
    ],

    "BURUTU": [
    "TORUGBENE",
    "TAMIGBE",
    "TUOMO",
    "SEIMBIRI",
    "OJOBO",
    "BULOU - NDORO",
    "NGBILEBIRI I",
    "NGBILEBIRI II",
    "OGBOLUBIRI",
    "OBOTEBE",
    "OGULAGHA"
    ],

    "ETHIOPE EAST": [
    "ABRAKA I",
    "ABRAKA II",
    "ABRAKA III",
    "AGBON I",
    "AGBON II",
    "AGBON III",
    "AGBON IV",
    "AGBON V",
    "AGBON VI",
    "AGBON VII",
    "AGBON VIII"
    ],

    "ETHIOPE WEST": [
    "MOSOGAR I",
    "MOSOGAR II",
    "JESSE I",
    "JESSE II",
    "JESSE III",
    "JESSE IV",
    "OGHARA I",
    "OGHARA II",
    "OGHARA III",
    "OGHARA IV",
    "OGHARA V"
    ],

    "IKA NORTH-EAST": [
    "OWA I",
    "OWA II",
    "OWA III",
    "OWA IV",
    "OWA V",
    "OWA VI",
    "AKUMAZI",
    "IGBODO",
    "UTE - OKPU",
    "IDUMUESAH",
    "UMUNEDE",
    "MBIRI",
    "UTE - OGBEJE",
    "OTOLOKPO"
    ],

    "IKA SOUTH": [
    "AGBOR TOWN I",
    "AGBOR TOWN II",
    "IHUOZOMOR (OZANOGOGO ALISIMIE)",
    "IHIUIYASE I",
    "EKUKU - AGBOR",
    "IHUIYASE II",
    "BOJI - BOJI I",
    "BOJI - BOJI II",
    "BOJI - BOJI III",
    "ABAVO I",
    "ABAVO II",
    "ABAVO III"
    ],

    "ISOKO NORTH": [
    "IYEDE I",
    "IYEDE II",
    "ELLU/RADHEO/OVRODE",
    "OFAGBE",
    "ILUELOGBO",
    "OWHE/AKIEHWE",
    "EMEVOR",
    "OKPE - ISOKO",
    "OZORO I",
    "OZORO II",
    "OZORO III",
    "OYEDE",
    "OTIBIO"
    ],

    "ISOKO SOUTH": [
    "OLEH I",
    "OLEH II",
    "AVIARA",
    "UZERE",
    "EMEDE",
    "OLOMORO",
    "IGBIDE",
    "EROWA/UMEH",
    "ENHWE/OKPOLO",
    "IRRI I",
    "IRRI II"
    ],

    "NDOKWA EAST": [
    "OSSISSA",
    "AFOR/OBIKWELE",
    "ABARRA/INYI/ONUABOH",
    "OKPAI/UTCHI/BENEKU",
    "ABOH/AKARRAI",
    "ONYIA/ADIAI/OTUOKU/UMUOLU",
    "ASE",
    "IBEDENI",
    "IBREDE/IGBUKU / ONOGBOKOR",
    "ASHAKA"
    ],

    "NDOKWA WEST": [
    "UTAGBA OGBE",
    "UTAGBA UNO I",
    "UTAGBA UNO II",
    "UTAGBA UNO III",
    "ONICHA - UKWANI",
    "OGUME I",
    "OGUME II",
    "ABBI I",
    "ABBI II",
    "EMU"
    ],

    "OKPE": [
    "OREROKPE",
    "OVIRI - OKPE",
    "OHA I",
    "OHA II",
    "AGHALOKPE",
    "ARAGBA TOWN",
    "MEREJE I",
    "MEREJE II",
    "MEREJE III",
    "UGHOTON"
    ],

    "OSHIMILI NORTH": [
    "AKWUKWU",
    "EBU",
    "ILLAH",
    "IBUSA I",
    "IBUSA II",
    "IBUSA III",
    "IBUSA IV",
    "IBUSA V",
    "OKPANAM",
    "UKALA"
    ],

    "OSHIMILI SOUTH": [
    "OGBELE/AKPAKO",
    "ANALA-AMAKOM",
    "OKWE",
    "UMUEZEI",
    "UMUAJI",
    "UMUONAJE",
    "AGU",
    "UGBOMANTA QUARTERS",
    "WEST END",
    "CABLE POINT I",
    "CABLE POINT II"
    ],

    "PATANI": [
    "ABARI",
    "PATANI I",
    "PATANI II",
    "PATANI III",
    "TAWARE/KOLOWARA AVEN",
    "AGOLOMA",
    "TORU-ANGIAMA",
    "BOLOU - ANGIAMA",
    "UDUOPHORI",
    "ODORUBU/ADOBU/BOLOU APELEBRI"
    ],

    "SAPELE": [
    "SAPELE URBAN I",
    "SAPELE URBAN II",
    "SAPELE URBAN III",
    "SAPELE URBAN IV",
    "SAPELE URBAN V",
    "SAPELE URBAN VI",
    "SAPELE URBAN VII",
    "SAPELE URBAN VIII",
    "AMUOKPE",
    "ELUME",
    "OKOKPORO/UGBORHEN"
    ],

    "UDU": [
    "UDU I",
    "UDU II",
    "UDU III",
    "UDU IV",
    "OPETE/ASSAGBA/EDJOPHE",
    "EKETE",
    "OVWIAN I",
    "OVWIAN II",
    "ORHUWHURUN",
    "ALADJA"
    ],

    "UGHELLI NORTH": [
    "AGBARHA",
    "OGOR",
    "OROGUN I",
    "OROGUN II",
    "UGHELLI I",
    "UGHELLI II",
    "UGHELLI III",
    "EVWRENI",
    "UWHERU",
    "AGBARHO I",
    "AGBARHO II"
    ],

    "UGHELLI SOUTH": [
    "EWU I",
    "EWU II",
    "EWU III",
    "OLOMU I",
    "OLOMU II",
    "EFFURUN - OTOR",
    "EKAKPAMRE",
    "JEREMI I",
    "JEREMI II",
    "JEREMI III",
    "JEREMI IV"
    ],

    "UKWUANI": [
    "UMUTU",
    "AKOKU",
    "EBEDEI",
    "UMUKWATA",
    "EZIOKPOR",
    "AMAI",
    "EZIONUM",
    "UMUEBU",
    "OBIARUKU I",
    "OBIARUKU II"
    ],

    "UVWIE": [
    "EFFURUN I",
    "EFFURUN II",
    "ENERHEN I",
    "ENERHEN II",
    "UGBORIKOKO",
    "UGBOROKE",
    "UGBOMRO/UGBOLOKPOSO",
    "ARMY BARRACKS AREA",
    "EKPAN I",
    "EKPAN II"
    ],

    "WARRI NORTH": [
    "OGHEYE",
    "GBOKODA",
    "EBROHIMI",
    "EGHORO",
    "KOKO I",
    "KOKO II",
    "OPUAMA (EGBEMA I)",
    "TSEKELEWU (EGBEMA II)",
    "OGBINBIRI (EGBEMA III)",
    "OGBUDUGBUDU (EGBEMA IV)"
    ],

    "WARRI SOUTH": [
    "OBODO/OMADINO",
    "ODE-ITSEKIRI",
    "OGUNU/EKUREDE-URHOBO",
    "UGBUWANGUE/EKUREDE-ITSEKIRI",
    "G.R.A.",
    "BOWEN",
    "PESSU",
    "OKERE",
    "IGBUDU",
    "EDJEBA",
    "OKUMAGBA I",
    "OKUMAGBA II"
    ],

    "WARRI SOUTH-WEST": [
    "OGBE - IJOH",
    "ISABA",
    "OPOROZA",
    "GBARAMATU",
    "UGBORODO",
    "AKPIKPA",
    "MADANGHO",
    "ORERE",
    "AJA - UDAIBO",
    "OGIDIGBEN"
    ],

    "ABAKALIKI": [
    "ABAKPA",
    "AMACHI (NDEBO)",
    "AMACHI (NDEGU)",
    "AMAGU / ENYIGBA",
    "AMAGU UNUHU",
    "AZUMINI/AZUGWU",
    "AZUIYIOKWU LAYOUT",
    "AZUIYI UDENE",
    "EDDA",
    "IZZI UNUHU",
    "NDIAGU",
    "OKPOITUMO NDEBOR",
    "OKPOITUMO NDIEGU",
    "TIMBER SHED"
    ],

    "AFIKPO NORTH": [
    "AMATA-AKPOHA",
    "AMOGU AKPOHA",
    "EZEKE AMASIRI",
    "POPERI AMASIRI",
    "ITIM AFIKPO",
    "IBII/OZIZA AFIKPO",
    "NKPOGHORO AFIKPO",
    "OHAISU AFIKPO A",
    "OHAISU AFIKPO B",
    "UGWUEGU AFIKPO",
    "UWANA AFIKPO 1",
    "UWANA AFIKPO II"
    ],

    "AFIKPO SOUTH": [
    "AMAEKE EKOLI",
    "AMAIGBO",
    "AMANGWU",
    "AMIRI EKOLI",
    "AMOSO",
    "EBUNWANA",
    "NDIOKE EKOLI",
    "NGUZU",
    "OGBU (AMATO)",
    "OSO",
    "OWUTU"
    ],

    "EBONYI": [
    "ABAKPA",
    "ABOFIA",
    "AGALEGU",
    "ECHIABA",
    "EGWUDINAGU",
    "ENYIBICHIRI I",
    "ENYIBICHIRI II",
    "KPIRIKPIRI",
    "MBEKE",
    "NDIAGU",
    "NDIEBOR",
    "ONUENYIM",
    "URBAN NEW LAYOUT"
    ],

    "EZZA NORTH": [
    "AMUDA / AMAWULA",
    "EKKA",
    "INYERE",
    "NDIAGUAZU - UMUOGHARA",
    "NKOMORO",
    "OGBOJI",
    "OKPOSI UMUOGHARA",
    "OMEGE UMUEZEOKOHA",
    "ORIUZOR",
    "OSHIEGBE UMUEZEOKOHA",
    "UMUEZEOKA"
    ],

    "EZZA SOUTH": [
    "AMAEZEKWE",
    "AMAGU/NSOKKARA",
    "AMANA",
    "AMEKA",
    "AMUDO/OKOFFIA",
    "AMUZU",
    "ECHARA",
    "EZZAMA",
    "IKWUATOR/IDEMBIA",
    "ONUEKE URBAN",
    "UMUNWAGU / IDEMBIA"
    ],

    "IKWO": [
    "AMA INYIMA",
    "ECHIALIKE",
    "EKA AWOKE",
    "EKPANWUDELE",
    "EKPELU",
    "ENYIBICHIRI",
    "ETAM",
    "IGBUDU I",
    "IGBUDU II",
    "INYIMAGU I",
    "INYIMAGU II",
    "NDIAGU AMAGU I",
    "NDIAGU AMAGU II",
    "NDIAGU ECHARA I",
    "NDIAGU ECHARA II",
    "NDUFU AMAGU I",
    "NDUFU AMAGU II",
    "NDUFU ALIKE",
    "NDUFU ECHARA",
    "NOYE ALIKE"
    ],

    "ISHIELU": [
    "AGBA",
    "AMAEZU",
    "AZUINYABA 'A'",
    "AZUINYABA 'B'",
    "EZILLO I",
    "EZILLO II",
    "EZZAGU I (OGBOJI)",
    "EZZAGU II (NKOMORO)",
    "IYONU",
    "NKALAGU",
    "NKALAHA",
    "NTEZI",
    "OBEAGU",
    "OHOFIA",
    "OKPOTO",
    "UMUHUALI"
    ],

    "IVO": [
    "AKAEZE UKWU",
    "AMAEZE ISHIAGU",
    "AMAGU",
    "AMONYE",
    "IHENTA OGIDI",
    "IYIOJI AKAEZE",
    "NDIOKORO UKWU",
    "NGWOGWO",
    "OBINAGU",
    "OKUE",
    "UMOBO"
    ],

    "IZZI": [
    "AGBAJA MGBO",
    "AGBAJA ANYANWUIGWE",
    "AGBAJA OFFIA ONWE",
    "EZZA INYIMAGU IGBUHU",
    "EZZA INYIMAGU - IGWELEDOHA",
    "EZZA INYIMAGU - IZIOGO",
    "EZZA INYIMAGU NDIAGU",
    "IGBEAGU NDUOGBU",
    "IGBEAGU NDI ETTAH",
    "IGBEAGU III",
    "MGBALAUKWU INYIMAGU I",
    "MGBALAUKWU INYIMAGU II",
    "NDIEZE INYIMAGU MGBABELUZOR",
    "NDIEZE INYIMAGU II NDIABOR ISHIAGU"
    ],

    "OHAOZARA": [
    "AMAECHI OKPOSI",
    "ENE- NA - EZERAKU",
    "MGBOM OKPOSI",
    "OBIOZARA",
    "OKPOSI ACHARA",
    "OKPOSI OKWU",
    "UGBOGOLOGO UGWULANGWU",
    "UHUOTARU UGWULANGWU",
    "UMUCHIMA",
    "UMUNAGA",
    "UMUOBUNA"
    ],

    "OHAUKWU": [
    "EFFIUM I",
    "EFFIUM II",
    "EZZAMGBO",
    "ISHI NGBO I",
    "ISHI NGBO II",
    "NGBO",
    "OKPOSHI I",
    "OKPOSHI II",
    "UMUAGARA / AMECHI",
    "UMU OGUDU OSHIA",
    "UMU OGUDU AKPU I",
    "UMU OGUDU AKPU II",
    "WIGBEKE I",
    "WIGBEKE II",
    "WIGBEKE III"
    ],

    "ONICHA": [
    "ABAOMEGE",
    "AGBABOR-ISU",
    "AMANATOR-ISU",
    "EBIA OSHIRI",
    "ENUAGU-ONICHA",
    "ISINKWO-UKAWU",
    "ISI-ONICHA",
    "OBEAGU-ISU",
    "OGUDUOKWO OSHIRI",
    "OKUZU-UKAWU",
    "UGWU-OSHIRI",
    "UMUDOMI-ONICHA"
    ],

    "AKOKO EDO": [
    "IGARRA I",
    "IGARRA II",
    "IMOGA/ LAMPESE/ BEKUMA/ EKPE",
    "IBILLO/ EKPESA/ EKOR/ IKIRAN-ILE/ OKE",
    "MAKEKE/ OJAH/ DANGBALA/ OJIRAMI/ ANYAWOZA",
    "OLOMA/ OKPE/ IJAJA/ KAKUMA/ ANYARA",
    "SOMORIKA / OGBE / SASARO / ONUMU / ESHAWA / OGUGU IGBOSHI-AFE / IGBOSHI - ELE / AIYEGUNLE",
    "ENWAN/ATTE/IKPESHI/EGBIGELE",
    "UNEME-NEKHUA/AKPAMA/ AIYETORO/ EKPEDO/ ERHURUN/ UNEME OSU",
    "OSOSO"
    ],

    "EGOR": [
    "OTUBU",
    "OLIHA",
    "OGIDA/USE",
    "EGOR",
    "UWELU",
    "EVBAREKE",
    "USELU I",
    "USELU II",
    "OKHORO",
    "UGBOWO"
    ],

    "ESAN CENTRAL": [
    "UNEAH",
    "UWESSAN I",
    "UWESSAN II",
    "IKEKATO",
    "OTORUWO I",
    "OTORUWO II",
    "EWU I",
    "EWU II",
    "OPOJI",
    "UGBEGUN"
    ],

    "ESAN NORTH-EAST": [
    "EGBELE",
    "OBEIDU",
    "ARUE",
    "UELEN/ OKUGBE",
    "IDUMU-OKOJIE",
    "UZEA",
    "AMEDOKHIAN",
    "EFANDION",
    "UWALOR",
    "EWOYI",
    "UBIERUMU"
    ],

    "ESAN SOUTH-EAST": [
    "EWOHIMI I",
    "EWOHIMI II",
    "EWATTO",
    "OHORDUA",
    "EMU",
    "UBIAJA I",
    "UBIAJA II",
    "ILLUSHI I",
    "ILLUSHI II",
    "UGBOHA"
    ],

    "ESAN WEST": [
    "OGWA",
    "UJIOGBA",
    "EGORO/IDOA/UKHUN",
    "EMAUDO/ EGUARE/ EKPOMA",
    "IHUNMUDUMU/IDUMEBO/UKE/UJEMEN",
    "IRUEKPEN",
    "EMUHI/ UKPENU/ UJOELEN/IGOR",
    "UROHI",
    "UHIELE",
    "ILLEH/EKO-INE"
    ],

    "ETSAKO CENTRAL": [
    "FUGAR I",
    "FUGAR II",
    "FUGAR III",
    "OGBONA",
    "IRAOKHOR",
    "EKPERI I",
    "EKPERI II",
    "EKPERI III",
    "SOUTH UNEME I",
    "SOUTH UNEME II"
    ],

    "ETSAKO EAST": [
    "AGENEBODE",
    "WANNO I",
    "WANNO II",
    "WEPPA",
    "OKPELLA I",
    "OKPELLA II",
    "OKPELLA III",
    "OKPELLA IV",
    "THREE IBIES",
    "OKPEKPE"
    ],

    "ETSAKO WEST": [
    "AUCHI",
    "AUCHI I",
    "AUCHI II",
    "AUCHI III",
    "SOUTH IBIE",
    "JAGBE",
    "AVIELE",
    "ANWAIN",
    "UZAIRUE NORTH WEST",
    "UZAIRUE NORTH EAST",
    "UZAIRUE SOUTH WEST",
    "UZAIRUE SOUTH EAST"
    ],

    "IGUEBEN": [
    "EKEKHEN/IDUMUOGO/EGBIKI",
    "IDIGUN/ IDUMEDO",
    "AFUDA/IDUMUOKA",
    "UHE/IDUMUOGBO/IDUMUEKE",
    "UDO",
    "OWU/ OKUTA/ EGUARE EBELLE",
    "OKALO/ OKPUJIE",
    "AMAHOR/UGUN",
    "EWOSSA",
    "EKPON"
    ],

    "IKPOBA/OKHA": [
    "IWOGBAN/UTEH",
    "OREGBENI",
    "OGBESON",
    "ADUWAWA / EVBO MODU",
    "ST. SAVIOUR",
    "GORRETTI",
    "UGBEKUN",
    "IDOGBO",
    "OBAYANTOR",
    "OLOGBO"
    ],

    "OREDO": [
    "OGBE",
    "GRA/ETETE",
    "UZEBU",
    "URUBI/EVBIEMWEN/IWEHEN",
    "IHOGBE/ ISEKHERE/ OREOGHENE/ IBIWE/ ICE ROAD",
    "NEW BENIN I",
    "NEW BENIN II",
    "OREDO",
    "IKPEMA/EGUADASE",
    "UNUERU/OGBOKA",
    "OGBELAKA/ NEKPENEKPEN",
    "IBIWE/ IWEGIE/ UGBAGUE"
    ],

    "ORHIONMWON": [
    "AIBIOKUNLA I",
    "AIBIOKUNLA II",
    "UGBEKA",
    "IYOBA",
    "UGBOKO",
    "UKPATO",
    "URHONIGBE NORTH",
    "URHONIGBE SOUTH",
    "UGU",
    "EVBOESI",
    "IGBANKE EAST",
    "IGBANKE WEST"
    ],

    "OVIA NORTH-EAST": [
    "OKADA WEST",
    "OKADA EAST",
    "UHEN",
    "ADOLOR",
    "OFUNMWEGBE",
    "OLUKU",
    "UHIERE",
    "ISIUWA",
    "OKOKHUO",
    "OGHEDE",
    "ODUNA",
    "IGUOSHODIN",
    "UTOKA"
    ],

    "OVIA SOUTH-WEST": [
    "IGUOBAZUWA EAST",
    "IGUOBAZUWA WEST",
    "UMAZA",
    "SILUKO",
    "UDO",
    "ORA",
    "USEN",
    "UGBOGUI",
    "OFUNAMA",
    "NIKOROGHA"
    ],

    "OWAN EAST": [
    "EMAI I",
    "EMAI II",
    "IHIEVBE I",
    "IHIEVBE II",
    "UOKHA/AKE",
    "IGUE/IKAO",
    "IVBIMION",
    "OTUO I",
    "OTUO II",
    "IVBIADAOBI",
    "WARRAKE"
    ],

    "OWAN WEST": [
    "OZALLA",
    "UHONMORA",
    "EME-ORA/OKE",
    "SABONGIDA/ORA/OGBETURU",
    "AVBIOSI",
    "UKHUSE -OSI",
    "ERUERE",
    "OKPUJE",
    "UZEBBA I",
    "UZEBBA II",
    "SOBE"
    ],

    "UHUNMWODE": [
    "EHOR",
    "UHI",
    "IGIEDUMA",
    "IRHUE",
    "UMAGBAE NORTH",
    "UMAGBAE SOUTH",
    "ISI NORTH",
    "ISI SOUTH",
    "OHUAN",
    "EGBEDE"
    ],

    "ADO EKITI": [
    "ADO 'A' IDOFIN",
    "ADO 'B' INISA",
    "ADO 'C' IDOLOFIN",
    "ADO 'D' IJIGBO",
    "ADO 'E' IJOKA",
    "ADO 'F' OKEYINMI",
    "ADO 'G' OKE ILA",
    "ADO 'H' EREGURU",
    "ADO 'I' DALLIMORE",
    "ADO 'J' OKESA",
    "ADO 'K' IRONA",
    "ADO 'L' IGBEHIN",
    "ADO 'M' FARM SETTLEMENT"
    ],

    "EFON": [
    "EFON I",
    "EFON II",
    "EFON III",
    "EFON IV",
    "EFON V",
    "EFON VI",
    "EFON VII",
    "EFON VIII",
    "EFON IX",
    "EFON X"
    ],

    "EKITI EAST": [
    "OMUO - OKE I",
    "OMUO OKE II",
    "ARAROMI OMUO",
    "KOTA I",
    "KOTA II",
    "OBADORE I",
    "OBADORE II",
    "OBADORE III",
    "OBADORE IV",
    "ILASA I",
    "ILASA II / IKUN / ARAROMI",
    "ISINBODE"
    ],

    "EKITI WEST": [
    "ARAMOKO I",
    "ARAMOKO II",
    "ARAMOKO III / ERIO",
    "ERIJIYAN I",
    "ERIJIYAN II",
    "IKOGOSI",
    "IPOLE ILORO",
    "OKEMESI I",
    "OKEMESI II",
    "OKEMESI III",
    "IDO AJINARE"
    ],

    "EKITI SOUTH-WEST": [
    "ILAWE I",
    "ILAWE II",
    "ILAWE III",
    "ILAWE IV",
    "ILAWE V",
    "ILAWE VI",
    "ILAWE VII",
    "IGBARA ODO I",
    "IGBARA ODO II",
    "OGOTUN I",
    "OGOTUN II"
    ],

    "EMURE": [
    "ODO - EMURE I",
    "ODO - EMURE II",
    "ODO - EMURE III",
    "ODO - EMURE IV",
    "OKE EMURE I",
    "OKE EMURE II",
    "IDAMUDU I",
    "IDA MUDU II",
    "OGBONTIORO I",
    "OGBONTIORO II"
    ],

    "GBONYIN": [
    "EGBE / IRO",
    "ODE I",
    "ODE II",
    "ODE III",
    "IMESI",
    "AGBADO",
    "AISEGBA I",
    "AISEGBA II",
    "ILUOMOBA",
    "IJAN"
    ],

    "IDO/OSI": [
    "IDO I",
    "IDO II",
    "IFAKI I",
    "IFAKI II",
    "ILOGBO",
    "USI",
    "OSI",
    "ORIN / ORA",
    "IGBOLE / IFISIN / AAYE",
    "AYETORO I",
    "AYETORO II"
    ],

    "IJERO": [
    "IJERO WARD 'A'",
    "IJERO WARD 'B'",
    "IJERO WARD 'C'",
    "IJERO WARD 'D'",
    "IPOTI WARD 'A'",
    "IPOTI WARD 'B'",
    "ODO OWA",
    "ILORO WARD 'A'",
    "ILORO / IJUNRIN WARD 'B'",
    "IKORO WARD 'A'",
    "EKAMARUN WARD 'A'",
    "EKAMETA"
    ],

    "IKERE": [
    "ATIBA/AAFIN",
    "OKERUKU",
    "UGELE / AROKU",
    "AGBADO / OYO",
    "OGBONJANA",
    "OKE - OSUN",
    "IDEMO",
    "ILAPETU / IJAO",
    "ARE ARAROMI",
    "AFAO / KAJOLA",
    "ODOSE"
    ],

    "IKOLE": [
    "IKOLE WEST I",
    "IKOLE WEST II",
    "IKOLE EAST",
    "IKOLE NORTH",
    "IKOLE SOUTH",
    "ARAROMI / BOLORUNDURO",
    "IJESA ISU",
    "ODO AYEDUN I",
    "ODO AYEDUN / AYEBODE",
    "OKE AYEDUN",
    "IPAO / OKE AKO / IRELE",
    "ITAPAJI / IYEMERO"
    ],

    "ILEJEMEJE": [
    "EWU",
    "EDA ONIYO",
    "OBADA",
    "IYE I",
    "IYE II",
    "IYE III",
    "IJESAMODU",
    "ILUDUN I",
    "ILUDUN II",
    "IPERE"
    ],

    "IREPODUN/IFELODUN": [
    "AFAO",
    "ARE",
    "AWO",
    "IGBEMO",
    "IGEDE I",
    "IGEDE II",
    "IGEDE III",
    "IROPORA / ESURE / EYIO",
    "IWOROKO",
    "IYIN I",
    "IYIN II"
    ],

    "ISE/ORUN": [
    "ODO ISE I",
    "ODO ISE II",
    "ODO ISE III",
    "ERINWA I",
    "ERINWA II",
    "ORAYE I",
    "ORAYE II",
    "ORAYE III",
    "ORUN I",
    "ORUN II"
    ],

    "MOBA": [
    "OTUN I",
    "OTUN II",
    "OTUN III",
    "IGOGO I",
    "IGOGO II",
    "ERINMOPE I",
    "ERINMOPE II",
    "IKUN I",
    "IKUN II",
    "OSUN",
    "OSAN"
    ],

    "OYE": [
    "ITAPA / OSIN",
    "OYE I",
    "OYE II",
    "AYEGBAJU",
    "ILUPEJU I",
    "ILUPEJU II",
    "IRE I",
    "IRE II",
    "AYEDE NORTH",
    "AYEDE SOUTH ITAJI",
    "ISAN / ILAFON / ILEMESO",
    "OMU OKE / OMU ODO / IJELU"
    ],

    "ABUA-ODUAL": [
    "ABUA I",
    "ABUA II",
    "ABUA III",
    "ABUA IV",
    "EMUGHAN I",
    "EMUGHAN II",
    "OTAPHA",
    "OKPEDEN",
    "AGADA",
    "EMELEGO",
    "EMAGO-KUGBO",
    "AKANI",
    "ANYU"
    ],

    "AHOADA EAST": [
    "AHOADA I",
    "AHOADA II",
    "AHOADA III",
    "AHOADA IV",
    "UPPATA I",
    "UPPATA II",
    "UPPATA III",
    "UPPATA IV",
    "UPPATA V",
    "UPPATA VI",
    "AKOH I",
    "AKOH II",
    "AKOH III"
    ],

    "AHOADA WEST": [
    "EDIRO I",
    "EDIRO II",
    "JOINKRAMA",
    "OKARKI",
    "IGBUDUYA I",
    "IGBUDUYA II",
    "IGBUDUYA III",
    "IGBUDUYA IV",
    "UBIE I",
    "UBIE II",
    "UBIE III",
    "UBIE IV"
    ],

    "AKUKU TORU": [
    "MANUEL I",
    "MANUEL II",
    "MANUEL III",
    "BRIGGS I",
    "BRIGGS II",
    "BRIGGS III",
    "GEORGEWILL I",
    "GEORGEWILL II",
    "GEORGWILL III",
    "JACK I",
    "JACK II",
    "JACK III",
    "ALISE GROUP",
    "OBONOMA",
    "KULA I",
    "KULA II",
    "NORTH/SOUTH GROUP"
    ],

    "ANDONI": [
    "NGO TOWN",
    "UNYEN GALA",
    "AGWUT-OBOLO",
    "EKEDE",
    "IKURU TOWN",
    "UNYEADA I",
    "UNYEADA II",
    "SAMANGA",
    "ASARAMA",
    "ATABA I",
    "ATABA II"
    ],

    "ASARI-TORU": [
    "BUGUMA SOUTH",
    "BUGUMA SOUTH EAST",
    "BUGUMA EAST I",
    "BUGUMA EAST II",
    "BUGUMA SOUTH WEST",
    "BUGUMA NORTH EAST",
    "BUGUMA WEST",
    "BUGUMA NORTH WEST I",
    "BUGUMA NORTH WEST II",
    "BUGUMA EAST WEST",
    "ISIA GROUP I",
    "ISIA GROUP II",
    "WEST CENTAL GROUP"
    ],

    "BONNY": [
    "ORO-IGWE",
    "COURT/ ADA ALLISON",
    "OROSIRIRI",
    "NEW LAYOUT",
    "FINIMA",
    "ABALAMABIE",
    "DEMA ABBEY",
    "DAN JUMBO/ BERESIRI",
    "NANABIE",
    "OLOMA AYAMINIMA",
    "PETERSIDE",
    "KALAIBIAMA"
    ],

    "DEGEMA": [
    "BAKANA I",
    "BAKANA II",
    "BAKANA III",
    "BAKANA IV",
    "BAKANA V",
    "BAKANA VI",
    "TOMBIA I",
    "TOMBIA II",
    "TOMBIA III",
    "TOMBIA IV",
    "DEGEMA I",
    "DEGEMA II",
    "DEGEMA III",
    "OBUAMA",
    "BUKUMA",
    "BILLE",
    "KE/OLD BAKANA"
    ],

    "ELEME": [
    "ALESA",
    "ALODE",
    "OGALE",
    "EBUBU",
    "EKPORO",
    "ETEO",
    "ONNE",
    "AGBONCHIA",
    "AKPAJO",
    "ALETO"
    ],

    "EMOHUA": [
    "OGBAKIRI I",
    "OGBAKIRI II",
    "EMOHUA I",
    "EMOHUA II",
    "OBELLE",
    "IBAA",
    "RUNDELE",
    "RUMUEKPE",
    "ELELE ALIMINI",
    "OMUDIOGA/ AKPADU",
    "EGBEDA",
    "UBIMINI",
    "ODEGU I",
    "ODEGU II"
    ],

    "ETCHE": [
    "AFARA",
    "EGBU",
    "IGBO I",
    "IGBO II",
    "IGBO III",
    "EGWI/OPIRO",
    "NIHI",
    "ODUFOR",
    "OBIBI/AKWUKABI",
    "OBITE",
    "OKEHI",
    "OZUZU",
    "ULAKWO",
    "IGBODO",
    "AKWA/ODOGWA",
    "AKPOKU/UMUOYE",
    "NDASHI",
    "MBA",
    "OWU"
    ],

    "GOKANA": [
    "BODO I",
    "BODO II",
    "BODO III",
    "B-DERE",
    "K-DERE I",
    "K-DERE II",
    "BOMU I",
    "BOMU II",
    "KPOR/LEWE/GBE",
    "MOGHO",
    "NWEOL/GIOKO/BARAKO",
    "BIARA I",
    "DERKEN/DEEYOR/NWERIBIARA",
    "YEGHE II",
    "YEGHE I",
    "BERA",
    "BIARA II"
    ],

    "IKWERRE":  [
    "ISIOKPO I",
    "ISIOKPO II",
    "ELELE I",
    "ELELE II",
    "OMERELU",
    "APANI",
    "UMUANWA",
    "UBIMA",
    "OMAGWA",
    "OMADEME/IPO",
    "OZUAHA",
    "IGWURUTA",
    "ALUU"
    ],

    "KHANA":  [
    "BORI",
    "ZAAKPORI",
    "LLUEKU/NYOKURU",
    "SOGHO",
    "OKWALI",
    "KAANI",
    "BARGHA",
    "TAABAA",
    "BEERI",
    "BANE",
    "KONO/KWAWA",
    "WIIYAA KARA",
    "BOUE",
    "UEGWERE",
    "GWARA/KAA/EEKEN",
    "OPUOKO/KALAOKO",
    "SII/BETEM/KBAABBE",
    "BAEN/KPEAN/DUBURO",
    "LORRE/LUEBE/KPAA"
    ],

    "OBIO/AKPOR":  [
    "ORO-IGWE",
    "RUMUODARA",
    "RUMUOKWU (2B)",
    "RUMUODOMAYA (3A)",
    "ELELENWO (3B)",
    "WORJI",
    "RUMUOKORO",
    "RUMUOMASI",
    "RUMUEME (7A)",
    "RUMUEME (7B)",
    "RUMUEME (7C)",
    "RUMUIGBO (8A)",
    "RUMUKWUTA (8B)",
    "RUKPOKU",
    "CHOBA",
    "OZUOBA/OGBOGORO",
    "RUMUOLUMENI"
    ],

    "OGBA/EGBEMA/NDONI":  [
    "OMOKU TOWN I",
    "OMOKU TOWN II",
    "OMUKU TOWN (OBIETI)",
    "OMOKU TOWN IV (USOMINI)",
    "OBRIKOM",
    "USOMINI SOUTH KREIGANI",
    "IGBURU",
    "EGI I",
    "EGI II",
    "EGI III (EREMA)",
    "EGBEMA I",
    "EGBEMA II",
    "NDONI I",
    "NDONI II",
    "NDONI III",
    "OMOKU TOWN V",
    "EGI IV"
    ],

    "OGU/BOLO":  [
    "OGU I",
    "OGU II",
    "OGU III",
    "OGU IV",
    "OGU V",
    "OGU VI",
    "BOLO I",
    "BOLO II",
    "BOLO III",
    "BOLO IV",
    "WAKAMA",
    "ELE"
    ],

    "OKRIKA":  [
    "OKRIKA I",
    "OKRIKA II",
    "OKRIKA III",
    "OKRIKA IV",
    "OKRIKA V",
    "OKRIKA VI",
    "OKRIKA VII",
    "OGAN",
    "KALIO",
    "OGOLOMA I",
    "OGOLOMA II",
    "OGOLOMA III"
    ],

    "OMUMA":  [
    "EBERI-DIKEOMUUO COMMUNITY",
    "ARIRANIIRI/OWU-AHIA COMMUNITY",
    "OBIOHIA COMMUNITY",
    "OHIMOGHO COMMUNITY",
    "OYORO",
    "OFEH COMMUNITY",
    "UMUOGBA I COMMUNITY",
    "UMUOGBA II COMMUNITY",
    "OBIBI/AJULOKE COMMUNITY",
    "UMUAJULOKE COMMUNITY"
    ],

    "OPOBO/NEKORO":  [
    "KALAIBIAMA I",
    "KALAIBIAMA II",
    "DIEPIRI",
    "UKONU",
    "DAPPAYE AMA-KIRI I",
    "DAPPAYE AMA-KIRI II",
    "JAJA",
    "QUEENS TOWN KALAMA",
    "NKORO I",
    "NKORO II",
    "NKORO III"
    ],

    "OYIGBO":  [
    "OKOLOMA",
    "OBEAKPU",
    "EGBURU",
    "UMUAGBAI",
    "AZUOGU",
    "OBETE",
    "ASA",
    "OYIGBO WEST",
    "OYIGBO CENTRAL",
    "KOMKOM"
    ],

    "PORT HARCOURT":  [
    "OROMINEKE/EZIMGBU",
    "OROGBUM",
    "OROABALI",
    "OGBUNABALI",
    "PORT HARCOURT TOWNSHIP",
    "PORT HARCOURT TOWNSHIP VI",
    "PORT HARCOURT VII",
    "OCHIRI/RUMUKALAGBOR",
    "OROWORUKWO",
    "NKPOLU OROWORUKWO",
    "RUMUWOJI (ONE)",
    "RUMUWOJI (TWO)",
    "RUMUWOJI (THREE)",
    "MGBUNDUKWU (ONE)",
    "MGBUNDUKWU (TWO)",
    "RUMUOBIEKWE WARD",
    "DIOBU",
    "NKPOLU OROWORUKWO TWO",
    "ELEKAHIA",
    "ABULOMA/AMADI-AMA"
    ],

    "TAI":  [
    "BOTEM/GBENEO",
    "KPITE",
    "KOROKORO",
    "KOROMA/HORO",
    "KIRA/BOROBARA",
    "GIO/KPORGHOR/GBAM",
    "NONWA",
    "OYIGBO WEST",
    "BUBU/BARA/KANI",
    "BAN-OGOI"
    ],

    "ANINRI":  [
    "ODUMA I",
    "ODUMA II",
    "ODUMA III",
    "ODUMA IV",
    "NNENWE I",
    "NNENWE II",
    "NNENWE III",
    "NDEABO",
    "OKPANKU",
    "MPU"
    ],

    "AWGU":  [
    "AWGU I",
    "AWGU II",
    "MGBOWO",
    "AGBOGUGU",
    "IHE",
    "OBEAGU",
    "MGBIDI/MMAKU",
    "ANIKENANO/UGWUEME",
    "OWELLI/AMOLI/UGBO/OGUGU",
    "ISU AWA/OGUGU/AGBUDU/ITUKU",
    "OGBAKU"
    ],

    "ENUGU EAST":  [
    "ABAKPA I",
    "ABAKPA II",
    "UMUENWENE",
    "AMORJI",
    "MBULUIYIUKWU",
    "MBULUOWEHE",
    "MBULU-NJODO WEST",
    "UMUCHIGBO",
    "TRANS-EKULU",
    "IBAGWA-NIKE/EDEM",
    "UGWUGO NIKE",
    "MBULU-NJODO EAST"
    ],

    "ENUGU NORTH":  [
    "ASATA TOWNSHIP",
    "UMUNEVO",
    "IHEWUZI",
    "ONU-ASATA",
    "OGBETTE EAST",
    "OGBETTE WEST",
    "G.R.A",
    "GUI NEWLAYOUT",
    "CHINA TOWN",
    "NEW HAVEN",
    "INDEPENDENCE LAYOUT",
    "UDI SIDING/IVA VALLEY",
    "OGUI TOWNSHIP"
    ],

    "ENUGU SOUTH":  [
    "AMECHI I",
    "AMECHI II",
    "OBEAGU I",
    "UWANI EAST",
    "UWANI WEST",
    "ACHARA LAYOUT EAST",
    "ACHARA LAYOUT WEST",
    "AWKUNANAW EAST",
    "AWKUNANAW WEST",
    "UGWUAJI",
    "OBEAGU II",
    "AKWUKE",
    "MARYLAND"
    ],

    "EZEAGU":  [
    "OLO/AMAGU UMULOKPA I",
    "AWHA",
    "AGUOBU-OWA 1",
    "AGUOBU-OWA 11",
    "UMANA NDIAGU",
    "AGBA UMANA",
    "AGUOBU (UMUMBA)",
    "UMUMBA NDIUMO",
    "OGHE I",
    "OGHE II",
    "IMEZI OWA I",
    "IMEZI OWA II",
    "MGBAGBU OWA I",
    "MGBAGBU OWA II",
    "MGBAGBU OWA III",
    "IWOLLO",
    "OBINOFIA",
    "OKPOGHO",
    "OBE AGU UMANA",
    "ULO/AMAGU/UMULOKPA II"
    ],

    "IGBO ETITI":  [
    "AKU I",
    "AKU II",
    "AKU III",
    "AKU IV",
    "AKU V (IDUEME)",
    "EKWEGBE I",
    "EKWEGBE II",
    "OHAODO I",
    "OHAODO II",
    "OZALLA I",
    "OZALLA II",
    "DIOGBE/UMUNKO",
    "IKOLO/OHEBE",
    "ONYOHOR/OCHIMA/IDOHA",
    "EJUOHA/UDEME",
    "UKEHE I",
    "UKEHE II",
    "UKEHE III",
    "UKEHE IV",
    "UKEHE V"
    ],

    "IGBO EZE NORTH":  [
    "ESSODO 1",
    "ESSODO 11",
    "ESSODO 111",
    "ETTE 1",
    "ETTE 11",
    "ETTE CENTRAL",
    "UMUITODO I",
    "UMUITODO II",
    "UMUITODO III",
    "EZZODO",
    "UMUOZZI I",
    "UMUOZZI II",
    "UMUOZZI III",
    "UMUOZZI IV",
    "UMUOZZI V",
    "UMUOZZI VI",
    "UMUOZZI VII",
    "UMUOZZI VIII",
    "UMUOZZI IX",
    "UMUOZZI X"
    ],

    "IGBO EZE SOUTH":  [
    "ALOR AGU",
    "AMEBO /HAUSA/ YORUBA",
    "EZEMA WARD",
    "ECHARA",
    "IHEAKA (UGO AKOYI)",
    "IHEAKA (LIKKI/AKUTARA WARD)",
    "IHEAKPU (EZZI NGWU WARD)",
    "IHEAKPU (AJUONA OGBAGU WARD)",
    "ITCHI/UWANI I",
    "ITCHI/UWANI II",
    "NKALAGU OBUKPA",
    "OVOKO (AJUONA WARD)",
    "OVOKO (UMUELO/OVOKO AGU WARD)",
    "OVOKO (UMULOLO WARD)",
    "UHUNOWERRE",
    "UNADU"
    ],

    "ISI UZO":  [
    "IKEM I",
    "IKEM II",
    "NEKE I",
    "NEKE II",
    "MBU I",
    "MBU II",
    "UMUALOR",
    "EHAMUFU I",
    "EHAMUFU II",
    "EHAMUFU III",
    "EHAMUFU IV"
    ],

    "NKANU EAST":  [
    "AMECHI/IDODO/ORUKU",
    "AMANKANU/OGBAHU",
    "AKPOFU/ISIENU/AMAGUNZE",
    "IHUOKPARA",
    "NARA I",
    "NARA II",
    "NKEREFI I",
    "NKEREFI II",
    "OHUANI/ONICHA-AGU",
    "UGBAWKA I",
    "UGBAWKA II",
    "NOMEH",
    "MBURUBU",
    "OWO"
    ],

    "NKANU WEST":  [
    "AGBANI",
    "AMURRI",
    "AKEGBE-UGWU (OKWUO)",
    "AMODU",
    "OBUOFFIA",
    "OBE",
    "OZALLA",
    "OBINAGU UWANI (AKPUGO I)",
    "AKPUGO II",
    "AKPUGO III",
    "AKUGBO IV",
    "UMUEZE",
    "IBITE AKEGBE UGWU",
    "NDIUNO UWANI (AKPUGO I)"
    ],

    "NSUKKA":  [
    "OGBOZALLA/IDI",
    "IBEKU",
    "ALOR-UNO",
    "EDE-UKWU",
    "EDE-NTA",
    "EDEM-ANI",
    "AKPA/OZZI",
    "AGBEMEBE/UMABOR",
    "EHA-UNO",
    "EHA-NDIAGU",
    "IBAGWANI/IBAGWAGA OKPALIGBO",
    "EJUONA/UWANI",
    "AKALITE",
    "MKPUNANO",
    "IHE",
    "NNU",
    "OWERRE/UMUOYO",
    "OBIMO/IKWOKA",
    "OKPUJE/OKUTU/ANUKA",
    "OBUKPA"
    ],

    "OJI-RIVER":  [
    "OJI-RIVER I",
    "OJI-RIVER II",
    "OJI-RIVER III",
    "OJI-RIVER IV",
    "UGWUOBA I",
    "UGWUOBA II",
    "UGWUOBA III",
    "ACHIAGU I",
    "ACHIAGU II",
    "ACHIAGU III",
    "ACHIUNO I",
    "ACHIUNO II",
    "ACHIUNO III",
    "ACHIUNO IV",
    "INYI I",
    "INYI II",
    "INYI III",
    "INYI IV",
    "AWLAW",
    "AKPUGOEZE"
    ],

    "UDENU":  [
    "AMALA",
    "EZIMO",
    "IMILIKE",
    "OBOLLO-AFOR",
    "OBOLLO-ETITI",
    "OBOLLO-EKE",
    "OGBODU-ABA",
    "ORBA I",
    "ORBA II",
    "UDUNEDEM"
    ],

    "UDI":  [
    "UDI/AGBUDU",
    "NSUDE",
    "UMUAGA",
    "UMUABI",
    "OBINAGU",
    "NACHI",
    "ABIA",
    "NGWO ASA",
    "NGWO UNO",
    "EBE",
    "AWHUM/UKANA",
    "EGEDE/UMUOKA",
    "AKPA KWUME/NZE",
    "AFFA/OGHU/IKONO",
    "ABOR",
    "OKPATU",
    "OBIOMA",
    "UMULUMGBE",
    "EKE",
    "AMOKWE"
    ],

    "UZO-UWANI":  [
    "IGGA/ASABA",
    "ADABA-NKUME",
    "UMULOKPA",
    "UKPATA",
    "OJO",
    "ADANI",
    "OGURUGU",
    "ABBI",
    "AKPOGU",
    "UVURU",
    "NIMBO I",
    "NIMBO II",
    "UGBENE I",
    "UGBENE II",
    "NROBO",
    "NKPOLOGU"
    ],

    "ABAJI":  [
    "ABAJI CENTRAL",
    "ABAJI NORTH EAST",
    "ABAJI SOUTH EAST",
    "AGYANA/PANDAGI",
    "RIMBA EBAGI",
    "NUKU",
    "ALU MAMAGI",
    "YABA",
    "GURDI",
    "GAWU"
    ],

    "BWARI":  [
    "BWARI CENTRAL",
    "KUDURU",
    "IGU",
    "SHERE",
    "KAWU",
    "USHAFA",
    "DUTSE ALHAJI",
    "BYAZHIN",
    "KUBWA",
    "USUMA"
    ],

    "GWAGWALADA":  [
    "GWAGWALADA CENTRE",
    "KUTUNKU",
    "STAFF QUARTERS",
    "IBWA",
    "DOBI",
    "PAIKO",
    "TUNGAN MAJE",
    "ZUBA",
    "IKWA",
    "GWAKO"
    ],

    "KUJE":  [
    "KUJE",
    "CHIBIRI",
    "GAUBE",
    "KWAKU",
    "KABI",
    "RUBOCHI",
    "GWARGWADA",
    "GUDUN KARYA",
    "KUJEKWA",
    "YENCHE"
    ],

    "KWALI":  [
    "KWALI WARD",
    "YANGOJI",
    "PAI",
    "KILANKWA",
    "DAFA",
    "KUNDU",
    "ASHARA",
    "GUMBO",
    "WAKO",
    "YEBU"
    ],

    "MUNICIPAL":  [
    "CITY CENTRE",
    "GARKI",
    "KABUSA",
    "WUSE",
    "GWARINPA",
    "JIWA",
    "GUI",
    "KARSHI",
    "OROZO",
    "KARU",
    "NYANYA",
    "GWAGWA"
    ],

    "AKKO":  [
    "AKKO",
    "GARKO",
    "KALSHINGI",
    "KASHERE",
    "KUMO CENTRAL",
    "KUMO EAST",
    "KUMO NORTH",
    "KUMO WEST",
    "PINDIGA",
    "TUMU",
    "TUKULMA"
    ],

    "BALANGA":  [
    "BAMBAM",
    "BANGU",
    "DADIYA",
    "GELENGU / BALANGA",
    "KINDIYO",
    "KULANI / DEGRE /SIKKAM",
    "MWONA",
    "NYUWAR / JESSU",
    "SWA / REF / W. WAJA",
    "TALASSE / DONG / REME"
    ],

    "BILLIRI":  [
    "BAGANJE NORTH",
    "BAGANJE SOUTH",
    "BARE",
    "BILLIRI NORTH",
    "BILLIRI SOUTH",
    "KALMAI",
    "TAL",
    "TANGLANG",
    "TODI",
    "TUDU KWAYA"
    ],

    "DUKKU":  [
    "GOMBE ABBA",
    "HASHIDU",
    "JAMARI",
    "KUNDE",
    "LAFIYA",
    "MALALA",
    "WAZIRI NORTH",
    "WAZIRI SOUTH / CENTRAL",
    "WURO TALE",
    "ZANGE",
    "ZAUNE"
    ],

    "FUNAKAYE":  [
    "ASHAKA / MAGABA",
    "BAGE",
    "BAJOGA WEST",
    "BAJOGA EAST",
    "BODOR / TILDE",
    "JILLAHI",
    "KUPTO",
    "RIBADU",
    "TONGO",
    "WAWA / WAKKULUTU"
    ],

    "GOMBE":  [
    "AJIYA",
    "BAJOGA",
    "BOLARI EAST",
    "BOLARI WEST",
    "DAWAKI",
    "HERWAGANA",
    "JEKA DAFARI",
    "KUMBIYA - KUMBIYA",
    "NASARAWA",
    "PANTAMI",
    "SHAMAKI"
    ],

    "KALTUNGO":  [
    "AWAK",
    "BULE / KALTIN",
    "KALTUNGO WEST",
    "KALTUNGO EAST",
    "KAMO",
    "TULA BAULE",
    "TULA WANGE",
    "TULA - YIRI",
    "TUNGO",
    "TURE"
    ],

    "KWAMI":  [
    "BOJUDE",
    "DABAN FULANI",
    "DOHO",
    "DUKUL",
    "GADAM",
    "JURARA",
    "KOMFULATA",
    "KWAMI",
    "MALAM SIDI",
    "MALLERI"
    ],

    "NAFADA":  [
    "BARWO / NASARAWO",
    "BARWO WINDE",
    "BIRIN BOLEWA",
    "BIRIN FULANI EAST",
    "BIRIN FULANI WEST",
    "GUDUKKU",
    "JIGAWA",
    "NAFADA CENTRAL",
    "NAFADA EAST",
    "NAFADA WEST"
    ],

    "SHONGOM":  [
    "BANGUNJI",
    "BOH",
    "BURAK",
    "FILIYA",
    "GUNDALE",
    "GWANDUM",
    "KULISHIN",
    "KUSHI",
    "LALAIPIDO",
    "LAPAN"
    ],

    "YALMALTU/DEBA":  [
    "DEBA",
    "DIFA / LUBO / KINAFA",
    "GWANI / SHINGA / WADE",
    "HINNA",
    "JAGALI NORTH",
    "JAGALI SOUTH",
    "KANAWA / WAJARI",
    "KURI /LANO / LAMBAM",
    "KWADON / LIJI / KURBA",
    "NONO / KUNWAL / W. BIRDEKA",
    "ZAMBUK / KWALI"
    ],

    "ABOH MBAISE":  [
    "ENYIOGUGU",
    "NGURU-AHIATO",
    "NGURU-NWENKWO",
    "NGURU-NWEKE",
    "MBUTU",
    "UVURU 1",
    "UVURU II",
    "LORJI",
    "AMUZU",
    "UMUHU",
    "LAGWA",
    "IBEKU"
    ],

    "AHIAZU MBAISE":  [
    "OGBE",
    "OTULU/AGUNEZE",
    "ORU-NA-LUDE",
    "NNARAMBIA",
    "OKIRIKA NWENKWO",
    "AMUZI/IHENWORIE",
    "OKIRIKA NWEKE",
    "OBOHIA/EKWEREAZU",
    "MPAM",
    "OPARANADIM",
    "UMUNUMO/UMUCHIEZE",
    "OGBOR/UMUEZE"
    ],

    "EHIME MBANO":  [
    "AGBAJA",
    "UMUKABIA",
    "NSU 'A' IKPE",
    "NSU 'B' IHITTE",
    "NZEREM/IKPEM",
    "UMUALUMAKU/UMUIHIM",
    "UMUNAKANU",
    "UMUNUMO",
    "UMUEZEALA",
    "UMUEZE I",
    "UMUEZE II/UMUELEKE"
    ],

    "EZINIHITTE MBAISE":  [
    "AMUMARA",
    "ITU",
    "OKPOFE / EZEAGBOGU",
    "ONICHA I",
    "ONICHA II",
    "ONICHA III",
    "ONICHA IV",
    "EZIUDO",
    "UDO-NA-OBIZI",
    "IHITTE",
    "OBOAMA/UMUNAMA",
    "CHOKONEZE/AKPODIM/IFE"
    ],

    "IDEATO NORTH":  [
    "AKPULU",
    "ISIOKPO",
    "UMUOKWARA/UMUEZEAGA",
    "AKWU OWERRE",
    "UMUOPIA/UMUKEGWU",
    "NDI IHEME ARONDIZUOGU",
    "IZUOGU I",
    "NDIMOKO",
    "OBODOUKWU",
    "OSINA",
    "UZII/UMUALOMA",
    "EZEMAZU/OZU",
    "OZUAKOKI/UMUAGO",
    "IZUOGU II"
    ],

    "IDEATO SOUTH":  [
    "AMANATOR/UMUESHI",
    "DIKENAFAI",
    "ISIEKENESI I",
    "ISIEKENESI II",
    "NTUEKE",
    "UGBELLE",
    "OBIOHIA",
    "OGBOKO I",
    "OGBOKO II",
    "UMUAKAM/UMUAGO",
    "UMUCHIMA",
    "UMUMA ISIAKU",
    "UMUOBOM"
    ],

    "IHITTE/UBOMA (ISINWEKE)":  [
    "ABUEKE",
    "AMAKOHIA",
    "AMAINYI",
    "ATONERIM",
    "AWUCHINUMO",
    "DIMNEZE",
    "IKPEREJERE",
    "OKATA",
    "UMUEZEGWU",
    "UMUIHI"
    ],

    "IKEDURU (IHO)":  [
    "AKABO",
    "AMATTA",
    "IHO",
    "INYISHI/UMUDIM",
    "AMAIMO",
    "AMAKOHIA",
    "AVUVU",
    "ATTA I",
    "ATTA II",
    "UGIRIKE / OKWU / EZIAMA",
    "NGUGO/IKEMBARA",
    "UZOAGBA"
    ],

    "ISIALA MBANO (UMUELEMAI)":  [
    "AMARAKU",
    "UMUNKWO",
    "OGBOR",
    "IBEME",
    "OSU-OWERRE I",
    "OSU-OWERRE II",
    "OSUAMA/ANARA",
    "UGIRI/OKA",
    "AMAUZARI",
    "OBOLLO",
    "UMUOZU",
    "OSU-ACHARA"
    ],

    "ISU (UMUNDUGBA)":  [
    "AMANDUGBA I",
    "AMANDUGBA II",
    "UMUNDUGBA I",
    "UMUNDUGBA II",
    "AMURIE OMANZE I",
    "AMURIE OMANZE II",
    "EKWE I",
    "EKWE II",
    "ISU-NJABA I",
    "ISU-NJABA II",
    "ISU NJABA III"
    ],

    "MBAITOLI (NWAORIEUBI)":  [
    "AFARA/EZIAMA",
    "OGBAKU",
    "UMUNOHA/AZARA",
    "UBOMIRI",
    "IFAKALA",
    "OGWA I",
    "OGWA II",
    "ORODO 'A'",
    "ORODO 'B'",
    "UMUNWOHA/MBIERI/UMUAWU",
    "AMAIKE MBIERI",
    "EZINIHITIE MBIERI"
    ],

    "NGOR OKPALA (UMUNEKE)":  [
    "AMALA/ALULU/OBURU/OBOKWE/NTU",
    "ELELEM/OBIKE",
    "OZUZU",
    "EZIAMA/OKPALA",
    "IMERIENWE",
    "NGURU/UMUOWA",
    "NGOR/IHITTE/UMUKABIA",
    "OHEKELEM NNORIE",
    "UMUHU",
    "OBIANGWU",
    "LOGARA/UMUOHIAGU"
    ],

    "NJABA (NNENASA)":  [
    "ATTA I",
    "ATTA II",
    "ATTA III",
    "AMUCHA I",
    "AMUCHA II",
    "NKUME",
    "OKWUDOR",
    "UMUAKA I",
    "UMUAKA II",
    "UMUAKA III",
    "UMUAKA IV"
    ],

    "NKWERRE":  [
    "ONUSA",
    "NNANANO (NKWERRE II)",
    "UMUKOR (NKWERRE III)",
    "NKWERRE IV (UMUNUBO/UMUNACHI)",
    "NKWERRE V",
    "OWERRE NKWORJI I",
    "OWERRE NKWORJI II",
    "UMUDI/UMUWALA",
    "AMAOKPARA",
    "EZIAMA OBAIRE"
    ],

    "NWANGELE (ONU-NWANGELE AMAIGBO)":  [
    "AMANO/UMUDURUMBA WARD (AMAIGBO I)",
    "UMUANU COMMUNITY WARD (AMAIGBO II)",
    "AMAJU COMMUNITY WARD (AMAIGBO III)",
    "AMAMNAISI (AMAIGBO IV)",
    "EZEOBOLO/OFEAHIA/DURUOHA/UMUKABIA (AMAIGBO WARD V)",
    "ABBA WARD",
    "UMUOZU WARD",
    "KARA-NA-ORLU",
    "DIM-NA NUME",
    "ABAJAH WARD I",
    "ABAJAH WARD II"
    ],

    "OBOWO (OTOKO)":  [
    "ALIKE",
    "AMANZE/UMUNGWA",
    "AMUZI",
    "AVUTU",
    "EHUME",
    "ODENKUME/UMUOSOCHIE",
    "OKENALOGHO",
    "OKWUOHIA",
    "UMUARIAM/ACHARA",
    "UMUNACHI"
    ],

    "OGUTA (OGUTA)":  [
    "AWA",
    "EGWE/EGBUOMA",
    "IZOMBE",
    "MBALA/UBA",
    "NDEULOUKWU/UMUOWERE",
    "OBUDI/ARO",
    "OGUTA 'A'",
    "OGUTA 'B'",
    "ORU",
    "OSSEMOTOR/ENUIGBO",
    "UWAORIE"
    ],

    "OHAJI/EGBEMA (MMAHU-EGBEMA)":  [
    "ASSA/OBILE",
    "AWARA/IKWEREDE",
    "EGBEMA 'A'",
    "EGBEMA 'B'",
    "EGBEMA 'C'",
    "EGBEMA 'D'",
    "EGBEMA 'E'",
    "EKWUATO",
    "OBITTI/MGBISHI",
    "OHOBA",
    "UMUAGWO",
    "UMUAPU"
    ],

    "OKIGWE (OKIGWE)":  [
    "OKIGWE I",
    "OKIGWE II",
    "IHUBE",
    "AKU",
    "OGII",
    "EZINACHI",
    "UMUALUMUOKE",
    "AMURO",
    "NDIMOKO OFEIMO/IBINTA/OKANACHI/UMUOWA IBU",
    "UMULOLO",
    "AGBOBU"
    ],

    "ONUIMO (OKWE)":  [
    "EZIAMA",
    "UMUCHEKE",
    "OWERRE-OKWE",
    "EZELU",
    "OFEAHIA",
    "ABOH/OKOHIA",
    "OZIMO / UMUANUMEZE",
    "UMUNA",
    "OKWELLE I",
    "OKWELLE II"
    ],

    "ORLU":  [
    "OHAFOR/OKPORO/UMUTANZE",
    "OHAEKE/OKPORO",
    "EBENESE/UMUEZE/NNACHI IHIOMA",
    "OKWUA BALA/IHIOMA",
    "UMUNA",
    "UMUDIOKA",
    "IHITE OWERRE",
    "AMAIFEKE",
    "UMUZIKE/UMUOWA",
    "OWERRI EBEIRI",
    "EZIACHI/AMIKE",
    "OGBERURU/OBIBI",
    "ORLU/MGBEE/ORLU GOVT. STATION"
    ],

    "ORSU (AWO IDEMILI)":  [
    "UMUHU OKABIA",
    "EBENATOR",
    "OKWUFURUAKU",
    "OKWUAMARAIHIE I",
    "OKWUAMARAIHIE II",
    "OKWUETITI",
    "ORSU IHITEUKWA",
    "IHITENANSA",
    "ASSAH UBIRI ELEM",
    "EZIAWA",
    "AMARURU"
    ],

    "ORU EAST":  [
    "AWO-OMAMMA I",
    "AWO-OMAMMA II",
    "AWO-OMAMMA III",
    "AWO-OMAMMA IV",
    "AMIRI I",
    "AMIRI II",
    "AKUMA",
    "AMAGU",
    "AKATA",
    "OMUMA"
    ],

    "ORU WEST (MGBIDI)":  [
    "NEMPI/ELEM",
    "OTULU",
    "AJI",
    "IBIASO EGBE",
    "AMAFUO",
    "OHAKPU",
    "OZARA",
    "UBULU",
    "MGBIDI I",
    "MGBIDI II"
    ],

    "OWERRI MUNICIPAL":  [
    "ALADINMA I",
    "ALADINMA II",
    "IKENEGBU I",
    "IKENEGBU II",
    "AZUZI I",
    "AZUZI II",
    "AZUZI III",
    "AZUZI IV",
    "GRA",
    "NEW OWERRI I",
    "NEW OWERRI II"
    ],

    "OWERRI NORTH (ORIE URATTA)":  [
    "AWAKA/IHITTE-OGADA",
    "NAZE",
    "EGBU",
    "EMII",
    "EMEKUKU I",
    "EMEKUKU II",
    "ORJI",
    "IHITTA-OHA",
    "OBIBI-URATTA I",
    "OBIBI-URATTA II",
    "AGBALA/OBUBE/ULAKWO",
    "OBIBIEZENA"
    ],

    "OWERRI WEST (UMUGUMA)":  [
    "AVU/OFOROLA",
    "UMUGUMA",
    "OKUKU",
    "EMEABIAM/OKOLOCHI",
    "EZIOBODO",
    "IHIAGWA",
    "NEKEDE",
    "OBINZE",
    "AMAKOHIA-UBI/NDEGWU OHII",
    "IRETE/OROGWE"
    ],

    "AUYO":  [
    "AUYO",
    "AUYAKAYI",
    "AYAMA",
    "AYAN",
    "GAMAFOI",
    "GAMSARKA",
    "GATAFA",
    "KAFUR",
    "TSIDIR",
    "UNIK"
    ],

    "BABURA":  [
    "BABURA",
    "BATALI",
    "DORAWA",
    "GARU",
    "GASAKOLI",
    "INSHARUWA",
    "JIGAWA",
    "KANYA",
    "KUZUNZUMI",
    "KYAMBO",
    "TAKWASA"
    ],

    "BIRNIN KUDU":  [
    "BIRNIN KUDU",
    "KANGIRE",
    "KANTOGA",
    "KIYAKO",
    "KWANGWARA",
    "LAFIYA",
    "SUNDIMINA",
    "SURKO",
    "UNGUWARYA",
    "WURNO",
    "YALWAN DAMAI"
    ],

    "BIRNIWA":  [
    "BATU",
    "BIRNIWA",
    "DANGWALERI",
    "DIGINSA",
    "FAGI",
    "KACHALLARI",
    "KARANKA",
    "KAZURA",
    "MACHINAMARI",
    "MATAMU",
    "NGUWA"
    ],

    "BUJI":  [
    "AHOTO",
    "BUJI",
    "CHURBUN",
    "GANTSA",
    "FALAGERI",
    "KAWAYA",
    "KUKUMA",
    "K/LELEN KUDU",
    "MADABE",
    "Y/TUKUR"
    ],

    "DUTSE":  [
    "ABAYA",
    "CHAMO",
    "DUNDUBUS",
    "DURU",
    "JIGAWAR TSADA",
    "KACHI",
    "KARNAYA",
    "KUDAI",
    "LIMAWA",
    "MADOBI",
    "SAKWAYA"
    ],

    "GARKI":  [
    "BUDURU",
    "DOKO",
    "GARKI",
    "GWARZON GARKI",
    "JIRIMA",
    "KANYA",
    "KARGO",
    "KORE",
    "MUKU",
    "RAFIN MARKE",
    "SIYORI"
    ],

    "GAGARAWA":  [
    "GAGARAWA GARI",
    "GAGARAWA TASHA",
    "GARIN CHIROMA",
    "KORE BALATU",
    "MADAKA",
    "MAIADUWA",
    "MAIKILILI",
    "MEDU",
    "YALAWA",
    "ZARADA"
    ],

    "GUMEL":  [
    "BAIKARYA",
    "DANAMA",
    "DANTANOMA",
    "GALAGAMMA",
    "GARIN GAMBO",
    "GARIN ALHAJI BARKA",
    "GUSAU",
    "HAMMADO",
    "KOFAR AREWA",
    "KOFAR YAMMA",
    "ZANGO"
    ],

    "GURI":  [
    "ABUNABO",
    "ADIYANI",
    "DAWA",
    "GARBAGAL",
    "GURI",
    "KADIRA",
    "LAFIYA",
    "MARGADU",
    "MATARA BABA",
    "MUSARI"
    ],

    "GWARAM":  [
    "BASIRKA",
    "DINGAYA",
    "FAGAM",
    "FARIN DUTSE",
    "GWARAM TSOHUWA",
    "KILA",
    "KWANDIKO",
    "MARUTA",
    "SARA",
    "TSANGARWA",
    "ZANDAN NAGOGO"
    ],

    "GWIWA":  [
    "BUNTUSU",
    "DABI",
    "DARINA",
    "F/YAMMA",
    "GUNTAI",
    "GWIWA",
    "KORAYEL",
    "RORAU",
    "SHAFE",
    "YOLA",
    "ZAUMAR SAINAWA"
    ],

    "HADEJIA":  [
    "ATAFI",
    "DUBANTU",
    "GAGULMARI",
    "KASUWAR KUDA",
    "KASUWAR KOFA",
    "MAJEMA",
    "MATSARO",
    "RUMFA",
    "SABON GARU",
    "YANKOLI",
    "YAYARI"
    ],

    "JAHUN":  [
    "AUJARA",
    "GANGAWA",
    "GAUZA TAZARA",
    "GUNKA",
    "HARBO SABUWA",
    "HARBO TSOHUWA",
    "IDANDUNA",
    "JABARNA",
    "JAHUN",
    "KALE",
    "KANWA"
    ],

    "KAFIN HAUSA":  [
    "BALANGU",
    "DUMADUMIN TOKA",
    "GAFAYA",
    "JABO",
    "KAFIN HAUSA",
    "KAZALEWA",
    "MAJAWA",
    "MEZAN",
    "RUBA",
    "SARAWA",
    "ZAGO"
    ],

    "KAUGAMA":  [
    "ARBUS",
    "ASKANDU",
    "DABUWARAN",
    "DAKAIYAWA",
    "HADIN",
    "JA'E",
    "JARKASA",
    "KAUGAMA",
    "MARKE",
    "UNGUWAR JIBRIN",
    "YALO"
    ],

    "KAZAURE":  [
    "BA'AUZINI",
    "DABA",
    "DABAZA",
    "DANDI",
    "GADA",
    "KANTI",
    "MARADAWA",
    "SABARU",
    "UNGUWAR AREWA",
    "UNGUWAR GABAS",
    "UNGUWAR YAMMA"
    ],

    "KIRIKA SAMMA":  [
    "BATURIYA",
    "BULUNCHAI",
    "DOLERI",
    "FANDUM",
    "GAYIN",
    "KIRIKA SAMMA",
    "MADACHI",
    "MARMA",
    "TSHEGUWA",
    "TARABU"
    ],

    "KIYAWA":  [
    "ABALAGO",
    "ANDAZA",
    "FAKI",
    "GARKO",
    "GURUDUBA",
    "KATANGA",
    "KATUKA",
    "KIYAWA",
    "KWANDA",
    "MAJE",
    "TSURMA"
    ],

    "MAIGATARI":  [
    "BALARABE",
    "DANKUMBO",
    "FULATA",
    "GALADI",
    "JAJERI",
    "KUKAYASKU",
    "MADANA",
    "MAIGATARI AREWA",
    "MAIGATARI KUDU",
    "MATOYA",
    "TURBUS"
    ],

    "MALAM MADORI":  [
    "ARKI",
    "DUNARI",
    "FATEKA AKURYA",
    "GARIN GABAS",
    "MAIRA KUMI-BARA MUSA",
    "MAKA DDARI",
    "MALAM MADORI",
    "SHAIYA",
    "TAGWARO",
    "TASHENA",
    "TONIKUTARA"
    ],

    "MIGA":  [
    "DANGYATIN",
    "GARBO",
    "HANTSU",
    "KOYA",
    "MIGA",
    "SABON GARI TAKANEBU",
    "SANSANI",
    "TSAKUWAWA",
    "YANDUNA",
    "ZAREKU"
    ],

    "RINGIM":  [
    "CHAI-CHAI",
    "DABI",
    "KAFIN BABUSHE",
    "KARSHI",
    "KYARAMA",
    "RINGIM",
    "SANKARA",
    "SINTILMAWA",
    "TOFA",
    "YANDUTSE"
    ],

    "RONI":  [
    "AMARYAWA",
    "BARAGUMI",
    "DANSURE",
    "FARA",
    "GORA",
    "KWAITA",
    "RONI",
    "SANKAU",
    "TUNAS",
    "YANZAKI",
    "ZUGAI"
    ],

    "SULE-TANKARKAR":  [
    "ALBASU",
    "AMANGA",
    "DANGWANKI",
    "DANLADI",
    "DANZOMO",
    "JEKE",
    "SHABARU",
    "SULE-TANKARKAR",
    "TAKATSABA",
    "YANDAMO"
    ],

    "TAURA":  [
    "AJAURA",
    "CHAKWAIKWAIWA",
    "CHUKUTO",
    "GUJUNGU",
    "KIRI",
    "KWALAM",
    "MAJE",
    "MAJIYA",
    "S/GARIN YAYA",
    "TAURA"
    ],

    "YANKWASHI":  [
    "ACHILAFIYA",
    "BELAS",
    "DAWAN-GAWO",
    "GWARTA",
    "GURJIYA",
    "KARKARNA",
    "KUDA",
    "RINGIM",
    "YANKWASHI",
    "ZUNGUMBA"
    ],

    "BIRNIN GWARI":  [
    "MAGAJIN GARI I",
    "MAGAJIN GARI II",
    "MAGAJIN GARI III",
    "GAYAM",
    "KUYELO",
    "KAZAGE",
    "KAKANGI",
    "TABANNI",
    "DOGON DAWA",
    "KUTEMESI",
    "RANDAGI"
    ],

    "CHIKUN":  [
    "CHIKUN",
    "GWAGWADA",
    "KAKAU",
    "KUJAMA",
    "KUNAI",
    "KURIGA",
    "NARAYI",
    "NASARAWA",
    "RIDO",
    "SABON TASHA",
    "UNG. YELWA",
    "S/GGARIN AREWA TIRKANIYA"
    ],

    "GIWA":  [
    "GIWA",
    "KAKANGI",
    "GANGARA",
    "SHIKA",
    "DANMAHAWAYI",
    "YAKAWADA",
    "IDASU",
    "KIDANDAN",
    "GALADIMAWA",
    "KADAGE",
    "PAN HAUYA"
    ],

    "IGABI":  [
    "TURUNKU",
    "ZANGON AYA",
    "GWARAJI",
    "BIRNIN YERO",
    "IGABI",
    "RIGACHIKUN",
    "AFAKA",
    "SABON BIRNIN DAJI",
    "KERAWA",
    "KWARAU",
    "GADAN GAYAN",
    "RIGASA"
    ],

    "IKARA":  [
    "IKARA",
    "JANFALA",
    "K/KOGI",
    "SAULAWA",
    "PALA",
    "SAYA-SAYA",
    "AUCHAN",
    "RUMI",
    "PAKI",
    "KUYA"
    ],

    "JABA":  [
    "NDUYAH",
    "SAMBAM",
    "FADA",
    "SABCHEM",
    "SABZURO",
    "DURA/BITARO",
    "DADDU",
    "CHORI",
    "NOK",
    "FAI"
    ],

    "JEMA'A":  [
    "KAFANCHAN 'A'",
    "KAFANCHAN 'B'",
    "MAIGIZO 'A'",
    "KANINKON",
    "JAGINDI",
    "GODOGODO",
    "GIDAN WAYA",
    "ATUKU",
    "ASSO",
    "BEDDE",
    "KAGOMA",
    "TAKAU 'B'"
    ],

    "KACHIA":  [
    "AGUNU",
    "AWON",
    "DOKA",
    "GUMEL",
    "GIDAN TAGWAI",
    "KWATURU",
    "ANKWA",
    "KATARI",
    "BISHINI",
    "KACHIA URBAN",
    "SABON SARKI",
    "KURMIN MUSA"
    ],

    "KADUNA NORTH":  [
    "SHABA",
    "GAJI",
    "UNGUWAN LIMAN",
    "MAIBURJI",
    "KABALA COSTAIN/ DOKI",
    "GABASAWA",
    "UNGUWAN SARKI",
    "BADARAWA",
    "UNGUWAN DOSA",
    "KAWO",
    "HAYIN BANKI",
    "UNGUWAN SHANU"
    ],

    "KADUNA SOUTH":  [
    "MAKERA",
    "BARNAWA",
    "KAKURI GWARI",
    "TELEVISION",
    "KAKURI HAUSA",
    "TUDUN WADA NORTH",
    "TUDUN WADA SOUTH",
    "TUDUN WADA WEST",
    "TUDUN NUWAPA",
    "SABON GARI SOUTH",
    "SABON GARI NORTH",
    "UNG. SANUSI",
    "BADIKO"
    ],

    "KAGARKO":  [
    "KAGARKO NORTH",
    "KAGARKO SOUTH",
    "KUSHE",
    "JERE NORTH",
    "JERE SOUTH",
    "IDDAH",
    "ARIBI",
    "KURMIN JIBRIN",
    "KATUGAL",
    "KUKUI"
    ],

    "KAJURU":  [
    "KAJURU",
    "TANTATU",
    "BUDA",
    "KUFANA",
    "AFOGO",
    "KASUWAN MAGANI",
    "KALLAH",
    "RIMAU",
    "IDON",
    "MARO"
    ],

    "KAURA":  [
    "FADA",
    "KUKUM",
    "KPAK",
    "AGBAN",
    "KADARKO",
    "MALLAGUM",
    "MANCHOK",
    "BONDON",
    "KAURA",
    "ZANKAN"
    ],

    "KAURU":  [
    "KAURU WEST",
    "MAKAMI",
    "DAWAKI",
    "KWASSAM",
    "BITAL",
    "GESHERE",
    "DAMAKASUWA",
    "BADURUM SAMA",
    "KAMARU",
    "PARI",
    "KAURU EAST"
    ],

    "KUBAU":  [
    "KUBAU",
    "DUTSEN WAI",
    "PAMBEGUA",
    "ZUNTU",
    "DAMAU",
    "KARREH",
    "ANCHAU",
    "HASKIYA",
    "KARGI",
    "MAH",
    "ZABI"
    ],

    "KUDAN":  [
    "KUDAN",
    "HUNKUYI",
    "SABON GARI HUNKUYI",
    "GARU",
    "ZABI",
    "DOKA",
    "LIKORO",
    "TABAN SANI",
    "KAURAN WALI NORTH",
    "KAURAN WALI SOUTH"
    ],

    "LERE":  [
    "SABON BIRNIN",
    "YAR KASUWA",
    "GARU",
    "KAYARDA",
    "LERE",
    "RAMIN KURA",
    "SAMINAKA",
    "LAZURU",
    "ABADAWA",
    "DAN ALHAJI",
    "GURE/KAHUGU"
    ],

    "MAKARFI":  [
    "MAKARFI",
    "TUDUN WADA",
    "GAZARA",
    "DANGUZIRI",
    "GIMI",
    "NASSARAWAN DOYA",
    "MAYERE",
    "GUBUCHI",
    "GWANKI",
    "DANDAMISA"
    ],

    "SABON GARI":  [
    "SAMARU",
    "JAMA'A",
    "BOMO",
    "BASAWA",
    "CHIKAJI",
    "MUCHIA",
    "JUSHIN WAJE",
    "HANWA",
    "DOGARAWA",
    "UNGUWAN GABAS",
    "ZABI"
    ],

    "SANGA":  [
    "GWANTU",
    "FADAN KARSHI",
    "AYU",
    "NINZAM NORTH",
    "NINZAM SOUTH",
    "BOKANA",
    "ABORO",
    "NINZAM WEST",
    "WASA STATION",
    "ARAK",
    "NANDU"
    ],

    "SOBA":  [
    "MAIGANA",
    "KINKIBA",
    "GIMBA",
    "KWASSALLO",
    "RICHIFA",
    "GAMAGIRA",
    "DAN WATA",
    "TURAWA",
    "SOBA",
    "GARUN GWANKI",
    "RAHAMA"
    ],

    "ZANGON KATAF":  [
    "GORA",
    "ZONZON",
    "ZAMAN DABO",
    "UNGUWAR GAIYA",
    "ZONKWA",
    "MADAKIYA",
    "UNGUWAR RIMI",
    "GIDAN JATAU",
    "KAMANTAN",
    "KAMURU IKULU NORTH",
    "ZANGO URBAN"
    ],

    "ZARIA":  [
    "KWARBAI 'A'",
    "KWARBAI 'B'",
    "UNG. JUMA",
    "LIMANCIN-KONA",
    "KAURA",
    "TUDUN WADA",
    "GYALLESU",
    "UNG. FATIKA",
    "TUKUR TUKUR",
    "DAMBO",
    "WUCICCIRI",
    "DUTSEN ABBA",
    "KUFENA"
    ],

    "AJINGI":  [
    "AJINGI",
    "BALARE",
    "CHULA",
    "DABIN KANAWA",
    "DUNDUN",
    "GAFASA",
    "GURDUBA",
    "KUNKURAWA",
    "TORANKE",
    "UNGAWAR BAI"
    ],

    "ALBASU":  [
    "ALBASU CENTRAL",
    "BATAIYA",
    "CHAMARANA",
    "DAHO",
    "FANDA",
    "FARAGAI",
    "GAGARAME",
    "HUNGU",
    "SAYA-SAYA",
    "TSANGAYA"
    ],

    "BAGWAI":  [
    "BAGWAI",
    "DANGADA",
    "GADANYA",
    "GOGORI",
    "KIYAWA",
    "KWAJALI",
    "RIMIN DAKO",
    "ROMO",
    "SARE-SARE",
    "WURO BAGGA"
    ],

    "BEBEJI":  [
    "ANADARIYA",
    "BAGUDA",
    "BEBEJI",
    "DAMAU",
    "DURMAWA",
    "GARGAI",
    "GWARMAI",
    "KOFA",
    "KUKI",
    "RAHAMA",
    "RANKA",
    "RANTAN",
    "TARIWA",
    "WAK"
    ],

    "BICHI":  [
    "BADUME",
    "BICHI",
    "DANZABUWA",
    "FAGOLO",
    "KAUKAU",
    "KWAMARAWA",
    "KYALLI",
    "MUNTSIRA",
    "SAYE",
    "WAIRE",
    "YALLAMI"
    ],

    "BUNKURE":  [
    "BARKUM",
    "BUNKURE",
    "BONO",
    "CHIRIN",
    "GAFAN",
    "GURJIYA",
    "GWAMMA",
    "KULLUWA",
    "KUMURYA",
    "SANDA"
    ],

    "DALA":  [
    "ADAKAWA",
    "BAKIN RUWA",
    "DALA",
    "DOGON NAMA",
    "GOBIRAWA",
    "GWAMMAJA",
    "KABUWAYA",
    "KANTUDU",
    "KOFAR MAZUGAL",
    "KOFAR RUWA",
    "MADIGAWA",
    "YALWA"
    ],

    "DAMBATA":  [
    "AJUMAWA",
    "DANBATTA EAST",
    "DANBATTA WEST",
    "FAGWALAWA",
    "GORON MAJE",
    "GWANDA",
    "GWARABJAWA",
    "KORE",
    "SAIDAWA",
    "SANSAN"
    ],

    "DAWAKI KUDU":  [
    "DABAR KWARI",
    "DANBAGIWA",
    "DAWAKI",
    "DAWAKIJI",
    "DOSAN",
    "GANO",
    "GURJIYA",
    "JIDO",
    "TAMBURAWA",
    "TSAKUWA",
    "UNGUWAR DUNIYA",
    "YANBARAU",
    "YANKATSARI",
    "YARGAYA",
    "ZOGARAWA"
    ],

    "DAWAKI TOFA":  [
    "DAWAKI EAST",
    "DAWAKI WEST",
    "DAWANAU",
    "DAN GUGUWA",
    "GANDUJE",
    "GARGARI",
    "JALLI",
    "KWA",
    "MARKE",
    "TATTARAWA",
    "TUMFAFI"
    ],

    "DOGUWA":  [
    "DARIYA",
    "DOGON KAWO",
    "DUGUWA",
    "FALGORE",
    "MARAKU",
    "RAGADA",
    "RIRIWAI",
    "TAGWAYE",
    "UNGUWAR NATSOHUWA",
    "ZAINABI"
    ],

    "FAGGE":  [
    "FAGGE A",
    "FAGGE B",
    "FAGGE C",
    "FAGGE D",
    "FAGGE E",
    "KWACHIRI",
    "RIJIYAR LEMO",
    "SABONGARI EAST",
    "SABONGARI WEST",
    "YAMMATA"
    ],

    "GABASAWA":  [
    "GABASAWA",
    "GARUN DANGA",
    "JODA",
    "KARMAMI",
    "MEKIYA",
    "TARAUNI",
    "YANTAR AREWA",
    "YAUTAR KUDU",
    "YUMBU",
    "ZAKIRAI",
    "ZUGACHI"
    ],

    "GARKO":  [
    "DAL",
    "GARIN ALI",
    "GARKO",
    "GURJIYA",
    "KAFIN MALAMAI",
    "KATUMARI",
    "KWAS",
    "RABA",
    "SARINA",
    "ZAKARAWA"
    ],

    "GARUN MALAM":  [
    "CHIROMAWA",
    "DORAWAR-SALLAU",
    "FANKURUN",
    "GARUN BABBA",
    "GARUN MALAM",
    "JOBAWA",
    "KADAWA",
    "MAKWARO",
    "YAD AKWARI",
    "YALWAN YADAKWARI"
    ],

    "GAYA":  [
    "BALAN",
    "GAYA AREWA",
    "GAYA KUDU",
    "GAMARYA",
    "GAMOJI",
    "KADEMI",
    "KAZURAWA",
    "MAIMAKAWA",
    "SHAGOGO",
    "WUDILAWA"
    ],

    "GEZAWA":  [
    "BABAWA",
    "GAWO",
    "GEZAWA",
    "JOGANA",
    "KETAWA",
    "MESAR-TUDU",
    "SARARIN-GEZAWA",
    "TSAMIYA-BABBA",
    "TUMBAU",
    "WANGARA",
    "ZANGO"
    ],

    "GWALE":  [
    "DANDAGO",
    "DISO",
    "DORAYI",
    "GALADANCHI",
    "GORON DUTSE",
    "GWALE",
    "GYARANYA",
    "KABUGA",
    "MANDAWARI",
    "SANI MAI MAGGE"
    ],

    "GWARZO":  [
    "GETSO",
    "GWARZO",
    "JAMA' A",
    "KARA",
    "KUTAMA",
    "LAKWAYA",
    "MADADI",
    "MAINIKA",
    "SABON BIRNI",
    "UNGUWAR TUDU"
    ],

    "KABO":  [
    "DUGABAU",
    "DURUN",
    "GAMMO",
    "GARO",
    "GODIYA",
    "GUDE",
    "HAUWADE",
    "KABO",
    "KANWA",
    "MASANAWA"
    ],

    "KANO MUNICIPAL":  [
    "CHEDI",
    "DAN'AGUNDI",
    "GANDUN ALBASA",
    "JAKARA",
    "KANKAROFI",
    "SHAHUCHI",
    "SHARADA",
    "SHESHE",
    "TUDUN NUFAWA",
    "TUDUN WAZIRCHI",
    "YAKASAI",
    "ZAITAWA",
    "ZANGO"
    ],

    "KARAYE":  [
    "DAURA",
    "KAFIN DAFGA",
    "KARAYE",
    "KURUGU",
    "KWANYAWA",
    "TUDUN KAYA",
    "TURAWA",
    "UNGUWAR HAJJI",
    "YAMMEDI",
    "YOLA"
    ],

    "KIBIYA":  [
    "DURBA",
    "FAMMAR",
    "FASSI",
    "KADIGAWA",
    "KAHU",
    "KIBIYA I",
    "KIBIYA II",
    "NARIYA",
    "TARAI",
    "UNGUWAR GAI"
    ],

    "KIRU":  [
    "BA'AWA",
    "BADAFI",
    "BARGONI",
    "BAUDA",
    "DANGORA",
    "DANSOHIYA",
    "DASHI",
    "GALADIMAWA",
    "KIRU",
    "KOGO",
    "MARAKU",
    "TSAUDAWA",
    "YAKO",
    "YALWA",
    "ZUWO"
    ],

    "KUMBOTSO":  [
    "CHALLAWA",
    "CHIRANCHI",
    "DANBARE",
    "DANMALIKI",
    "GURINGAWA",
    "KUMBOTSO",
    "KUREKEN SANI",
    "MARIRI",
    "NA'IBAWA",
    "PANSHEKARA",
    "UNGUWAR RIMI"
    ],

    "KUNCHI":  [
    "BUMAI",
    "GARIN SHEME",
    "GWARMAI",
    "KASUWAR KUKA",
    "KUNCHI",
    "MATAN FADA",
    "RIDAWA",
    "SHAMAKAWA",
    "SHUWAKI",
    "YANDADI"
    ],

    "KURA":  [
    "DALILI",
    "DAN HASSAN",
    "DUKAWA",
    "GUNDUTSE",
    "KARFI",
    "KOSAWA",
    "KURA",
    "KURUNSUMAU",
    "RIGAR DUKA",
    "TANAWA"
    ],

    "MADOBI":  [
    "BURJI",
    "CINKOSO",
    "GALINJA",
    "GORA",
    "KAFIN AGUR",
    "KANWA",
    "KAURA MATA",
    "KUBARACI",
    "KWANKWASO",
    "MADOBI",
    "RIKADAWA",
    "YAKUN"
    ],

    "MAKODA":  [
    "BABBAR RIGA",
    "DURMA",
    "JIBGA",
    "KADANDANI",
    "KOGUNA",
    "KOREN TATSO",
    "MAITSIDAU",
    "MAKODA",
    "SATAME",
    "TANGAJI",
    "WAILARE"
    ],

    "MINJIBIR":  [
    "AZORE",
    "GANDURWAWA",
    "KANTAMA",
    "KUNYA",
    "KURU",
    "KWARKIYA",
    "MINJIBIR",
    "SARBI",
    "TSAKIYA",
    "TSAKUWA",
    "WASAI"
    ],

    "NASARAWA":  [
    "DAKATA",
    "GAMA",
    "GAWUNA",
    "GWAGWARWA",
    "GIGINYU",
    "HOTORO NORTH",
    "HOTORO SOUTH",
    "KAWAJI",
    "KAURA GOJE",
    "TUDUN MURTALA",
    "TUDUN WADA"
    ],

    "RANO":  [
    "DAWAKI",
    "LAUSU",
    "MADACHI",
    "RANO",
    "RURUM SABON-GARI",
    "RURUM TSOHON-GARI",
    "SAJI",
    "YALWA",
    "ZINYAU",
    "ZURGU"
    ],

    "RIMIN GADO":  [
    "BUTU-BUTU",
    "DAWAKIN GULU",
    "DOKA DAWA",
    "DUGURAWA",
    "GULU",
    "JILI",
    "KAROFIN YASHI",
    "RIMIN GADO",
    "SAKARATSA",
    "TAMAWA",
    "YALWAN DANZIYAL",
    "ZANGO DAN ABDU"
    ],

    "ROGO":  [
    "BELI",
    "FALGORE",
    "FULATAN",
    "GWANGWAN",
    "JAJAYE",
    "ROGO RUMA",
    "ROGO SABON GARI",
    "RUWAN BAGO",
    "ZAREWA",
    "ZOZA"
    ],

    "SHANONO":  [
    "ALAJAWA",
    "DUTSEN-BAKOSHI",
    "FARURUWA",
    "GORON DUTSE",
    "KADAMU",
    "KOKIYA",
    "LENI",
    "SHAKOGI",
    "SHANONO",
    "TSAURE"
    ],

    "SUMAILA":  [
    "GALA",
    "GANI",
    "GARFA",
    "GEDIYA",
    "KANAWA",
    "MAGAMI",
    "MASU",
    "RIMI",
    "RUMO",
    "SITTI",
    "SUMAILA"
    ],

    "TAKAI":  [
    "BAGWARO",
    "DURBUNDE",
    "FAJEWA",
    "FALALI",
    "FARURUWA",
    "KACHAKO",
    "KARFI",
    "KUKA",
    "TAKAI",
    "ZUGA"
    ],

    "TARAUNI":  [
    "BABBAN GIJI",
    "DARMANAWA",
    "DAURAWA",
    "GYADI-GYADI AREWA",
    "GYADI-GYADI KUDU",
    "HOTORO (NNPC)",
    "KAUYEN ALU",
    "TARAUNI",
    "UNGUWAR GANO",
    "UNGUWA UKU"
    ],

    "TOFA":  [
    "DINDERE",
    "DOKA",
    "GAJIDA",
    "GINSAWA",
    "JAUBEN KUDU",
    "JANGUZA",
    "KWAMI",
    "LAMBU",
    "LANGEL",
    "TOFA",
    "UNGUWAR RIMI",
    "WANGARA",
    "YALWA KARAMA",
    "YANOKO",
    "YARIMAWA"
    ],

    "TSANYAWA":  [
    "DADDARAWA",
    "DUNBULUN",
    "GOZAKI",
    "GURUN",
    "KABAGIWA",
    "TATSAN",
    "TSANYAWA",
    "YANGANAU",
    "YANKAMAYE",
    "ZAROGI"
    ],

    "TUDUN WADA":  [
    "BABURI",
    "BURUMBURUM",
    "DALAWA",
    "JANDUTSE",
    "JITA",
    "KAREFA",
    "NATA'ALA",
    "SABON GARI",
    "TSOHOGARI",
    "SHUWAKI",
    "YARYASA"
    ],

    "UNGOGO":  [
    "BACHIRAWA",
    "GAYAWA",
    "KADAWA",
    "KARO",
    "PANISAU",
    "RANGAZA",
    "RIJIYAR ZAKI",
    "TUDUN FULANI",
    "UNGOGO",
    "YADAKUNYA",
    "ZANGO"
    ],

    "WARAWA":  [
    "AMARAWA",
    "DANLASAN",
    "GARIN DAU",
    "GOGEL",
    "IMAWA",
    "JEMAGU",
    "JIGAWA",
    "J/GALADIMA",
    "KATARKAWA",
    "MADARI MATA",
    "TAMBURAWAR GABAS",
    "TANGAR",
    "WARAWA",
    "YAN DALLA",
    "YANGIZO"
    ],

    "WUDIL":  [
    "ACHIKA",
    "DAGUMAWA",
    "DANKAZA",
    "DARKI",
    "INDABO",
    "KAUSANI",
    "LAJAWA",
    "SABON GARI",
    "UTAI",
    "WUDIL"
    ],

    "BAKORI":  [
    "BAKORI A",
    "BAKORI B",
    "KURAMI/YANKWANI",
    "DAWAN MUSA",
    "TSIGA",
    "KABOMO",
    "BARDE/KWANTAKWARAN",
    "JARGABA",
    "KAKUMI",
    "GUGA",
    "KANDARAWA"
    ],

    "BATAGARAWA":  [
    "BATAGARAWA A",
    "BATAGARAWA B",
    "DANDAGORO",
    "AJIWA",
    "TSANNI",
    "JINO",
    "BARAWA",
    "BAKIYAWA",
    "KAYAUKI",
    "YARGAMJI"
    ],

    "BATSARI":  [
    "BATSARI",
    "MANAWA",
    "WAGINI",
    "ABADAU/KAGARA",
    "YAUYAU/MALLAMAWA",
    "KANDAWA",
    "DAN. ALH/YANGAIYA",
    "RUMA",
    "DARINI/MAGAJI/ABU",
    "KARARE",
    "MADOGARA"
    ],

    "BAURE":  [
    "YANMAULU",
    "BABBAN MUTUM",
    "TARAMNAWA/BARE",
    "MUDURI",
    "BAURE",
    "UNGUWAR RAI",
    "KAGARA/FASKI",
    "GARKI",
    "YANDUNA",
    "MAI BARA",
    "HUI",
    "AGALA"
    ],

    "BINDAWA":  [
    "KAMRI",
    "BINDAWA",
    "TAMA/DAYE",
    "FARU/DALLAJI",
    "SHIBDAWA",
    "JIBAWA/R,BADE",
    "DORO",
    "BAURE",
    "YANGORA",
    "GIREMAWA",
    "GAIWA"
    ],

    "CHARANCHI":  [
    "CHARANCHI",
    "SAFANA",
    "RADDA",
    "MAJEN WAYYA",
    "GANUWA",
    "TSAKATSA",
    "KODA",
    "KURAYE",
    "BANYE",
    "DOKA"
    ],

    "DANDUME":  [
    "MAHUTA A",
    "MAHUTA B",
    "MAHUTA C",
    "DANDUME A",
    "DANDUME B",
    "TUMBURKAI A",
    "TUMBURKAI B",
    "DANTANKARI",
    "NASARAWA",
    "MAGAJI WANDO A",
    "MAGAJI WANDO B"
    ],

    "DANJA":  [
    "JIBA",
    "TANDAMA",
    "DANJA A",
    "DANJA B",
    "TSANGAMAWA",
    "DABAI",
    "YAKAJI A",
    "YAKAJI B",
    "KAHUTU A",
    "KAHUTU B"
    ],

    "DAN MUSA":  [
    "MARA",
    "DAN ALKIMA",
    "DAN MUSA A",
    "DAN MUSA B",
    "DAN ALI",
    "MAI DABINO A",
    "MAI DABINO B",
    "DANDIRE A",
    "DANDIRE 'B'",
    "YAN-TUMAKI A",
    "YAN-TUMAKI B"
    ],

    "DAURA":  [
    "SARKIN YARA A",
    "SARKIN YARA B",
    "UBANDAWAKI A",
    "UNBADAWAKI B",
    "KUSUGU",
    "MAZOJI A",
    "MAZOJI B",
    "TUDUN WADA",
    "SABON GARI",
    "MADOBI A",
    "MADOBI B"
    ],

    "DUTSI":  [
    "DAN AUNAI",
    "RUWANKAYA A",
    "RUWANKAYA B",
    "DUTSI A",
    "DUTSI B",
    "YAMEL A",
    "YAMEL B",
    "SIRIKA A",
    "SIRIKA B",
    "KAYAWA"
    ],

    "DUTSIN-MA":  [
    "DUTSIN-MA A",
    "DUTSIN-MA B",
    "SHEMA",
    "KAROFI A",
    "KAROFI B",
    "KUKI A",
    "KUKI B",
    "KUTAWA",
    "BAGAGADI",
    "MAKERA",
    "DABAWA"
    ],

    "FASKARI":  [
    "YANKARA",
    "SHEME",
    "MAIRUWA",
    "DAUDAWA",
    "TAFOKI",
    "RUWAN GODIYA",
    "YARMALAMAI",
    "FASKARI",
    "SABONLAYI/GALADIMA",
    "MAIGORA"
    ],

    "FUNTUA":  [
    "DANDUTSE",
    "SABON GARI",
    "UNGUWAR RABIU",
    "UNG IBRAHIM",
    "UNGUWAR MUSA",
    "DUKKE",
    "MAKERA",
    "GOYA",
    "MAI GAMJI",
    "MASKA",
    "TUDUN IYA"
    ],

    "INGAWA":  [
    "DUGUL",
    "YANDOMA",
    "INGAWA",
    "AGAYAWA",
    "JOBE/KANDAWA",
    "KURFEJI/YANKAURA",
    "MANOMAWA/KAFI",
    "BIDORE/YAYA",
    "BARERUWA/RURUMA",
    "DAUNAKA/B.KWARI",
    "DARA"
    ],

    "JIBIA":  [
    "FARFARU",
    "YANGAIYA",
    "JIBIA A",
    "JIBIA B",
    "MAZANYA/MAGAMA",
    "G/BAURE/MALLAMAWA",
    "FARU",
    "BUGAJE",
    "GANGARA",
    "RIKO",
    "KUSA"
    ],

    "KAFUR":  [
    "YARTALATA/RIGOJI",
    "DUTSIN KURA/KANYA",
    "KAFUR",
    "MASARI",
    "YARI BORI",
    "SABUWAR KASA",
    "GOZAKI",
    "DANTUTTURE",
    "MAHUTA",
    "GAMZAGO"
    ],

    "KAITA":  [
    "YANDAKI",
    "DANKABA",
    "ABDALLAWA",
    "BAAWA",
    "GAFIYA",
    "GIRKA",
    "KAITA",
    "YANHOHO",
    "DANKAMA",
    "MATSAI"
    ],

    "KANKARA":  [
    "GATAKAWA S/GARI/MABAI",
    "ZANGO/ZABARO",
    "PAUWA A&B",
    "KUKASHEKA",
    "HURYA",
    "KANKARA A&B",
    "GARAGI",
    "BURDUGAU",
    "WAWAR KAZA",
    "DAN MURABU",
    "DAN'MAIDAKI"
    ],

    "KANKIA":  [
    "GALADIMA 'A'",
    "GALADIMA 'B'",
    "KAFINSOLI",
    "TAFASHIYA/NASARAWA",
    "KUNDURU/GYAZA",
    "KAFIN DANGI/FAKUWA",
    "SUKUNTUNI",
    "RIMAYE",
    "GACHI",
    "MAGAM/TSA"
    ],

    "KATSINA":  [
    "WAKILIN GABAS I",
    "WAKILIN GABAS II",
    "KANGIWA",
    "WAKILIN YAMMA 1",
    "WAKILIN YAMMA 1I",
    "WAKILIN KUDU I",
    "WAKILIN KUDU II",
    "WAKILIN AREWA (A)",
    "WAKILIN AREWA (B)",
    "SHINKAFI 'A'",
    "SHINKAFI 'B'",
    "WAKILI KUDU III"
    ],

    "KURFI":  [
    "TSAURI 'A'",
    "TSAURI 'B'",
    "KURFI 'A'",
    "KURFI 'B'",
    "RAWAYAU 'A'",
    "RAWAYAU 'B'",
    "BIRCHI",
    "BARKIYYA",
    "WURMA 'A'",
    "WURMA 'B'"
    ],

    "KUSADA":  [
    "KUSADA",
    "DUDUNNI",
    "KAIKAI",
    "MAWASHI",
    "BOKO",
    "KOFA",
    "BAURANYA 'A'",
    "BAURANYA 'B'",
    "YASHE 'A'",
    "YASHE 'B'"
    ],

    "MAI'ADUA":  [
    "BUMBUM 'A'",
    "BUMBUM 'B'",
    "DANYASHE",
    "MAI'ADUA 'A'",
    "MAI'ADUA 'B'",
    "MAI'ADUA 'C'",
    "MAI KONI 'A'",
    "MAI KONI 'B'",
    "NATSALLE",
    "KOZA"
    ],

    "MALUFASHI":  [
    "MALUM FASHI 'A'",
    "MALUM FASHI 'B'",
    "YABA",
    "RAWAN SANYI",
    "KARFI",
    "BORIN DAWA",
    "YARMAMA",
    "GORAR DANSAKA",
    "DANSARAI",
    "MAKAURACHI",
    "DAYI",
    "NA-ALMA"
    ],

    "MANI":  [
    "BUJAWA/GEWAYAU",
    "DUWAN/MAKAU",
    "MANI",
    "BAGIWA",
    "TSAGEM/TAKUSHEYI",
    "HAMCHETA",
    "KWATTA",
    "MACHIKA",
    "MUDURU",
    "MAGAMI",
    "JANI"
    ],

    "MASHI":  [
    "MASHI",
    "JIGAWA",
    "S/RIJIYA",
    "BAMBLE",
    "GALLU",
    "SONKAYA",
    "DOGURU 'A'",
    "DOGURU 'B'",
    "KARAU",
    "TAMILO 'A'",
    "TAMILO 'B'"
    ],

    "MATAZU":  [
    "MATAZU 'A'",
    "MATAZU 'B'",
    "MAZOJI 'A'",
    "MAZOJI 'B'",
    "SAYAYA",
    "KOGARI",
    "GWARJO",
    "KARADUWA",
    "DISSI",
    "RINJIN IDI"
    ],

    "MUSAWA":  [
    "MUSAWA",
    "JIKAMSHI",
    "DANJANKU/KARACHI",
    "KIRA",
    "YARADAU/TABANNI",
    "TUGE",
    "GINGIN",
    "KURKUJAN 'A'",
    "DANGANI",
    "GARU",
    "KURKUJAN 'B'"
    ],

    "RIMI":  [
    "RIMI",
    "ABUKUR",
    "MAJENGOBIR",
    "REMAWA/IYATAWA",
    "KADANDANI",
    "SABON GARIN/ALARAIN",
    "TSAGERO",
    "FARDAMI",
    "MAKURDA",
    "MASABO/KURABAU"
    ],

    "SABUWA":  [
    "SABUWA 'A'",
    "SABUWA 'B'",
    "GAZARI",
    "MAIBAKKO",
    "DAMARI",
    "MACHIKA",
    "DUGUN MU'AZU",
    "GAMJI",
    "RAFIN IWA",
    "SAYAU"
    ],

    "SAFANA":  [
    "SAFANA",
    "TSASKIYA",
    "RUNKA 'A'",
    "BABBAN DUHU 'A'",
    "BABBAN DUHU 'B'",
    "RUNKA 'B'",
    "ZAKKA 'A'",
    "ZAKKA 'B'",
    "BAURE 'A'",
    "BAURE 'B'"
    ],

    "SANDAMU":  [
    "SANDAMU",
    "KARKARKU",
    "KWASARAWA",
    "DANEJI 'A'",
    "DANEJI 'B'",
    "FAGO 'A'",
    "FAGO 'B'",
    "RADE 'A'",
    "RADE 'B'",
    "KATSAYAL",
    "KAGARE"
    ],

    "ZANGO":  [
    "YARDAJE",
    "KANDA",
    "ZANGO",
    "SARA",
    "DARGAGE",
    "ROGOGO/CIDARI",
    "GARNI",
    "GWAMBA",
    "KAWARIN KUDI",
    "KAWARIN MALAWAMAI"
    ],

    "ALIERO":  [
    "ALIERO DANGALADIMA I",
    "ALIERO DANGALADIMA II",
    "ALIERO S/FADA I",
    "ALIERO S/FADA II",
    "DANWARAI",
    "JIGA BIRNI",
    "JIGA MAKERA",
    "KASHIN ZAMA",
    "RAFIN BAUNA",
    "SABIYAL"
    ],

    "AREWA":  [
    "BUI",
    "CHIBIKE",
    "DAURA/SAKKWABE/JARKUKA",
    "GORUN DIKKO",
    "FALDE",
    "FESKE/JAFFEJI",
    "GUMUMDAI/RAFIN TSAKA",
    "KANGIWA",
    "LAIMA/JANTULLU",
    "SARKA/DANTSOHO",
    "YELDU"
    ],

    "ARGUNGU":  [
    "ALWASA/GOTOMO",
    "DIKKO",
    "FELANDE",
    "GALADIMA",
    "GULMA",
    "GWAZANGE/KISAWA/U GYAGA",
    "KOKANI NORTH",
    "KOKANI SOUTH",
    "LAILABA",
    "SAUWA/KAURAR SANI",
    "TUNGAR ZAZZAGAWA/RUMBUKI/SARKAWA"
    ],

    "AUGIE":  [
    "AUGIE NORTH",
    "AUGIE SOUTH",
    "BAGAYE/MERA",
    "BAYAWA NORTH",
    "BAYAWA SOUTH",
    "BIRNIN TUDU/GUDALE",
    "BUBUCE",
    "DUNDAYE/KWAIDO/ZAGI/ILLELA",
    "TIGGI/AWADE",
    "YOLA"
    ],

    "BAGUDO":  [
    "BAGUDO/TUGA",
    "BAHINDI/BOKI-DOMA",
    "BANI/TSAMIYA/KALI",
    "ILLO/SABON/GARI/YANTAU",
    "KAOJE/GWAMBA",
    "KENDE/KURGU",
    "LAFAGU/GANTE",
    "LOLO/GIRIS",
    "MATSINKA/GEZA",
    "SHARABI/KWANGUWAI",
    "ZAGGA/KWASARA"
    ],

    "BIRNIN KEBBI":  [
    "NASSARAWA - I",
    "NASSARAWA II",
    "BIRNIN KEBBI MARAFA",
    "BIRNIN KEBBI DANGALADIMA",
    "KOLA /TARASA",
    "MAKERA",
    "MAURIDA /KARYO/UNG. MIJIN-NANA",
    "GWADANGAJI",
    "ZAURO",
    "GAWASU",
    "KARDI/YAMAMA",
    "LAGGA",
    "GULUMBE",
    "AMBURSA",
    "UJARIYO"
    ],

    "BUNZA":  [
    "BUNZA MARAFA",
    "BUNZA DANGALADIMA",
    "GWADE",
    "MAIDAHINI",
    "RAHA",
    "SABON BIRNI",
    "SALWAI",
    "TILLI/HILEMA",
    "TUNGA",
    "ZOGRIMA"
    ],

    "DANDI":  [
    "BANI ZUMBU",
    "BUMA",
    "DOLEKAINA",
    "FANA",
    "MAIHAUSAWA",
    "KYANGAKWAI",
    "GEZA",
    "KAMBA / KAMBA",
    "KWAKKWABA",
    "MAIGWAZA",
    "SHIKO"
    ],

    "FAKAI":  [
    "BAJIDA",
    "BANGU/GARINISA",
    "BIRNIN TUDU",
    "MAHUTA",
    "GULBIN KUKA/MAIJARHULA",
    "INGA(BULU) MAIKENDE",
    "KANGI",
    "FAKAI/ZUSSUN",
    "MARAFA",
    "PENIN AMANA/PENIN GABA"
    ],

    "GWANDU":  [
    "CHEBERU/BADA",
    "DALIJAN",
    "DODORU",
    "GULMARE",
    "GWANDU MARAFA",
    "GWANDU SARKIN FAWA",
    "KAMBAZA",
    "MARUDA",
    "MALISA",
    "MASAMA KWASGARA"
    ],

    "JEGA":  [
    "ALELU/GEHURU",
    "DANGAMAJI",
    "DUNBEGU/BAUSARA",
    "GINDI/NASSARAWA/KYARMI/GALBI",
    "JANDUTSI/BIRNIN MALAM",
    "JEGA FIRCHIN",
    "JEGA KOKANI",
    "JEGA MAGAJI 'B'",
    "JEGA MAGAJI 'A'",
    "KATANGA/FAGADA",
    "KIMBA"
    ],

    "KALGO":  [
    "BADARIYA/MAGARZA",
    "DANGOMA/GAYI",
    "DIGGI",
    "ETENE",
    "KALGO",
    "KUKA",
    "MUTUBARI",
    "NAYILWA",
    "WUROGAURI",
    "ZUGURU"
    ],

    "KOKO/BESSE":  [
    "KOKO MAGAJI",
    "ILLELA/S/GARI",
    "KOKO FIRCIN",
    "DADA/ALELU",
    "JADADI",
    "LANI/MANYAN/TAFUKKA/SHIBA",
    "BESSE",
    "TAKWARE",
    "DUTSIN MARI/DULMERU",
    "ZARIYA KALAKALA/AMIRU",
    "MADACCI/FIRINI",
    "MAIKWARI/ KARAMAR/ DAMBA/ BAKOSHI"
    ],

    "MAIYAMA":  [
    "ANDARAI/ KURUNKUDU/ ZUGUN LIBA",
    "GIWA TAZO/ZARA",
    "GUMBIN KURE",
    "KARAYE/DOGONDAJI",
    "KAWARA/S/SARA/YARKAMBA",
    "KUBERI / GIDIGA",
    "LIBA/DANWA/KUKA KOGO",
    "MAIYAMA",
    "MUNGADI/BOTORO",
    "SAMBAWA/MAYALO",
    "SARANDOSA/GUBBA"
    ],

    "NGASKI":  [
    "BIRNIN YAURI",
    "GAFARA MACHUPA",
    "GARIN BAKA/MAKARIN",
    "KWAKWARAN",
    "LIBATA/KWANGIA",
    "KAMBUWA/DANMARAYA",
    "MAKAWA ULEIRA",
    "NGASKI",
    "UTONO/HOGE",
    "WARA"
    ],

    "SAKABA":  [
    "ADAI",
    "DANKOLO",
    "DOKA/BERE",
    "GELWASA",
    "JANBIRNI",
    "MAZA/MAZA",
    "MAKUKU",
    "SAKABA",
    "TUDUN KUKA",
    "FADA"
    ],

    "SHANGA":  [
    "ATUWO",
    "BINUWA/GEBBE/BUKUNJI",
    "DUGU TSOHO/DUGU RAHA",
    "KAWARA/INGU/SARGO",
    "RAFIN KIRYA/TAFKI TARA",
    "SAKACE/GOLONGO/HUNDEJI",
    "SAWASHI",
    "SHANGA",
    "TAKWARE",
    "YARBESSE"
    ],

    "SURU":  [
    "ALJANNARE",
    "BANDAN",
    "BARBAREJO",
    "BAKUWAI",
    "DAKINGARI",
    "DANDANE",
    "DANIYA/SHEMA",
    "GINGA",
    "GIRO",
    "KWAIFA",
    "SURU"
    ],

    "WASAGU/DANKO":  [
    "AYU",
    "BENA",
    "DAN UMARU/MAIRAIRAI",
    "DANKO/MAGA",
    "KANYA",
    "KYABU/KANDU",
    "RIBAH/MACHIKA",
    "WAJE",
    "WASAGU",
    "YALMO/SHINDI",
    "GWANFI/KELE"
    ],

    "YAURI":  [
    "CHULU/KOMA",
    "GUNGUN SARKI",
    "JIJIMA",
    "TONDI",
    "YELWA CENTRAL",
    "YELWA EAST",
    "YELWA NORTH",
    "YELWA SOUTH",
    "YELWA WEST",
    "ZAMARE"
    ],

    "ZURU":  [
    "BEDI",
    "CIROMAN DABAI",
    "ISGOGO /DAGO",
    "MANGA/USHE",
    "RAFIN ZURU",
    "RIKOTO",
    "RUMU/DABEN/SEME",
    "SENCHI",
    "TADURGA",
    "ZODI"
    ],

    "ADAVI":  [
    "OKUNCHI/OZURI/ONIEKA",
    "OGAMINANA",
    "IRUVUCHEBA",
    "IDANUHUA",
    "ADAVI-EBA",
    "KUROKO I",
    "KUROKO II",
    "INO ZIOMI/IPAKU/OSISI",
    "IKARAWORO/IDOBANYERE",
    "NAGAZI FARM CENTRE",
    "EGE/IRUVOCHINOMI"
    ],

    "AJAOKUTA":  [
    "EBIYA NORTH",
    "EBIYA SOUTH",
    "ABODI/PATESI",
    "ICHUWA/UPAJA",
    "BADOKO",
    "OGIGIRI",
    "ADOGO",
    "ACHAGANA",
    "ODONU/UNOSI",
    "OMGBO",
    "ADOGU/APAMIRA/OGODO UHUOVENE",
    "OBANGEDE/OHUNENE/UKOKO INYE'RE",
    "OLD AJAOKUTA",
    "GANAGA/TOWNSHIP"
    ],

    "ANKPA":  [
    "ANKPA TOWNSHIP",
    "ANKPA SUBURB I",
    "ANKPA SUBURB II",
    "ANKPA I",
    "ANKPA II",
    "ENJEMA I",
    "ENJEMA II",
    "ENJEMA III",
    "ENJEMA IV",
    "OJOKU I",
    "OJOKU II",
    "OJOKU III",
    "OJOKU IV"
    ],

    "BASSA":  [
    "AKUBA I",
    "AKUBA II",
    "AYEDE/AKAKANA",
    "OZONGULO/KPANCHE",
    "IKENDE",
    "GBOLOKO",
    "KPATA",
    "EFORO",
    "MOZUM",
    "OZUGBE"
    ],

    "DEKINA":  [
    "DEKINA TOWN",
    "IYALE",
    "EMEWE",
    "ODU I",
    "ODU II",
    "ABOCHO",
    "OGBABEDE",
    "ADUMU EGUME",
    "OJIKPADALA",
    "ANYIGBA",
    "OKURA OLAFIA",
    "OGANE INIGU"
    ],

    "IBAJI":  [
    "ODEKE",
    "UJEH",
    "IYANO",
    "AKPANYO",
    "UNALE",
    "OJILA",
    "EJULE",
    "AYAH",
    "ANALO",
    "ONYEDEGA"
    ],

    "IDAH":  [
    "IGALAOGBA",
    "OWOLI APA",
    "IGECHEBA",
    "UKWAJA",
    "OGEGELE",
    "EDE",
    "SABON GARI",
    "EGA",
    "UGWODA",
    "ICHALA"
    ],

    "IGALAMELA/ODOLU":  [
    "AVRUGO",
    "EKWULOKO",
    "ODOLU",
    "OJI-AJI",
    "AKPANYA",
    "UBELE",
    "AJAKA I",
    "AJAKA II",
    "OFORACHI I",
    "OFORACHI II"
    ],

    "IJUMU":  [
    "AIYEGUNLE",
    "AIYETORO I",
    "AIYETORO II",
    "IYAH/AYEH",
    "ODOKORO",
    "AIYERE/ARIMAH",
    "OGIDI",
    "ILETEJU/ORIGA",
    "OGALE/ADUGE",
    "EGBEDA EGGA/OKEDAYO",
    "IYARA",
    "IFFE/IKOYI/OKEJUMU",
    "IYAMOYE",
    "EKINRIN ADE",
    "IBGOLAYERE/ILAERE"
    ],

    "KABBA/BUNU":  [
    "ASUTA",
    "ODO-AKETE",
    "OKEKOKO",
    "ODOLU",
    "AIYEWA",
    "AIYETEJU",
    "OTU",
    "EGBEDA",
    "OKEDAYO",
    "AKUTUPA-KIRI",
    "AIYETORO-KIRI",
    "ILUKE",
    "OLLE/OKE-OFIN",
    "ODO-APE",
    "OKEBUKUN"
    ],

    "KOGI K.K.":  [
    "UKWO-KOTON KARFE",
    "ODAKI-KOTON KARFE",
    "KOTONKARFE SOUTH EAST",
    "GIRINYA",
    "IRENODU",
    "AKPASU",
    "TAWARI",
    "GEGU-BEKI NORTH",
    "GEGU-BEKI SOUTH",
    "CHIKARA NORTH",
    "CHIKARA SOUTH"
    ],

    "LOKOJA":  [
    "LOKOJA - A",
    "LOKOJA - B",
    "LOKOJA - C",
    "LOKOJA - D",
    "LOKOJA - E",
    "KUPA NORTH EAST",
    "KUPA SOUTH WEST",
    "OWORO",
    "KAKANDA",
    "EGGAN"
    ],

    "MOPA MORO":  [
    "ODOLE - 1",
    "ODOLE - 2",
    "ILETEJU - 1",
    "ILLETEJU - 2",
    "OKEAGI/ILAI",
    "OROKERE",
    "TAKETE IDDE/OTAFUN",
    "AIYEDAYO/AIYEDARO",
    "AGBAFOGUN",
    "AIYEDE/OKAGI"
    ],

    "OFU":  [
    "IGO",
    "ALOMA",
    "EJULE ALLAH",
    "ITOBE/OKOKENYI",
    "UGWOLAWO - I",
    "UGWOLAWO - 2",
    "ALOJI",
    "OFOKE",
    "OCHADAMU",
    "OGBONICHA",
    "IBOKO/EFAKWU"
    ],

    "OGORI MANGOGO":  [
    "ENI",
    "OSHOBANE",
    "OKIBO",
    "OKESI",
    "ILETEJU",
    "AIYEROMI",
    "UGUGU",
    "OBINOYIN",
    "OBATGBEN",
    "OTURU OPOWUROYE"
    ],

    "OKEHI":  [
    "OBEIBA I",
    "OBEIBA II",
    "IKUEHI",
    "OHUETA",
    "OBOROKE EBA",
    "OBOROKE UVETE I",
    "EIKA / OHIZENYI",
    "OKAITO / USUNGWEN",
    "OHUEPE / OMAVI UBORO",
    "OBANGEDE / UHUODO",
    "OBOROKE UVETE - II"
    ],

    "OKENE":  [
    "BARIKI",
    "OBESSA",
    "ONYUKOKO",
    "IDOJI",
    "ORIETESU",
    "OTUTU",
    "OKENE-EBA / AGASSA/ AHACHE",
    "OBEHIRA UVETE",
    "OBEHIRA EBA",
    "ABUGA/OZUJA",
    "UPOGORO/ODENKU"
    ],

    "OLAMABORO":  [
    "IMANE I",
    "IMANE II",
    "OGUGU I",
    "OGUGU II",
    "OGUGU III",
    "OLAMABORO I",
    "OLAMABORO II",
    "OLAMABORO III",
    "OLAMABORO IV",
    "OLAMABORO V"
    ],

    "OMALA":  [
    "ABEJUKOLO I",
    "ABEJUKOLO II",
    "OPODA/OFEJIJI",
    "BAGANA",
    "OKPATALA",
    "AKPACHA",
    "BAGAJI",
    "ICHEKE AJOPACHI",
    "OGODU",
    "OJI-AJI",
    "OLLA"
    ],

    "YAGBA EAST":  [
    "IFE OLUKOTUN I",
    "IFE OLUKOTUN II",
    "PONYAN",
    "ALU/IGBAGUN/ORANRE",
    "EJUKU",
    "JEGE/OKE/AGI OGBOM/ISAO",
    "MAKUTU I",
    "MAKUTU II",
    "ITEDO",
    "ILAFIN/IDOFIN/ODO - OGBA"
    ],

    "YAGBA WEST":  [
    "EJIBA",
    "ODO ERI OKOTO",
    "ODO ERE OKE ERE",
    "ISAULU ESA/OKOLOKE/OKUNRAN",
    "IYAMERIN/IGBARUKU",
    "ODO ARA OMI OGGA",
    "OGBE",
    "OKE EGBE I",
    "OKE EGBE II",
    "OKE EGBE III",
    "OKE EGBE IV",
    "ODO EGBE I",
    "ODO EGBE II",
    "ODO EGBE"
    ],

    "ASA":  [
    "YOWERE/SOSOKI",
    "ADIGBONGBO/AWE/ORIMARO",
    "ELEBUE/AGBONA/FATA",
    "ONIRE/ODEGIWA/ALAPA",
    "YOWERE 11/OKEWERU",
    "GAMBARI/AIYEKALE",
    "EFUE/BERIKODO",
    "OWODE/GBOGUN",
    "BALLAH/OTTE",
    "OGBONDOROKO/REKE",
    "AGO-OJA/OSHIN/SAPATI/LADUBA",
    "AFON",
    "ILA-OJA",
    "OGELE",
    "BUDO-EGBA",
    "OKESHO",
    "ODO-ODE/ABOTO"
    ],

    "BARUTEN":  [
    "BORIYA/SHIYA",
    "GURE/GWASORO",
    "GWEDEBERERU/BABANE",
    "GWANARA",
    "ILESHA",
    "KENU/TABERU",
    "KPAURA/YAKIRU",
    "KIYORU/BWEN",
    "OKUTA",
    "SHINAWU/TUNBUYAN",
    "YASHIKIRA"
    ],

    "EDU":  [
    "LAFIAGI 1",
    "LAFIAGI 11",
    "LAFIAGI 111",
    "LAFIAGI 1V",
    "TSARAGI 1",
    "TSARAGI 11",
    "TSARAGI 111",
    "TSONGA 1",
    "TSONGA 11",
    "TSONGA 111"
    ],

    "EKITI":  [
    "ERUKU",
    "ISAPA",
    "KORO",
    "OBBO-AIYEGGUNLE 1",
    "OBBO-AIYEGGUNLE 11",
    "OBBO-ILE",
    "OSI 1",
    "OSI 11",
    "OPIN",
    "OKE-OPIN/ETAN"
    ],

    "IFELODUN":  [
    "OKE-ODE 1",
    "OKE-ODE 11",
    "OKE-ODE 111",
    "ORA",
    "ILE-IRE",
    "AGUNJIN",
    "ORO-AGO",
    "OMUPO",
    "SHARE 1",
    "SHARE 11",
    "SHARE 111",
    "SHARE 1V",
    "SHARE V",
    "IGBAJA 1",
    "IGBAJA 11",
    "IGBAJA 111",
    "IDOFIAN 1",
    "IDOFIAN 11"
    ],

    "ILORIN EAST":  [
    "AGBEYANGI/GBADAMU/OSIN",
    "GAMBARI 1",
    "BALOGUN GAMBARI 11",
    "IBAGUN",
    "APADO",
    "IPONRIN",
    "MAGAJI ARE 1",
    "MAGAJI ARE 11",
    "MARAFA/PEPELE",
    "MAYA/ILE-APA",
    "OKE OYI/OKE OSE/ALALUBOSA",
    "ZANGO"
    ],

    "ILORIN SOUTH":  [
    "AKANBI -1",
    "AKANBI -11",
    "AKANBI -111",
    "AKANBI -1V",
    "AKANBI -V",
    "BALOGUN-FULANI I",
    "BALOGUN-FULANI 11",
    "BALOGUN-FULANI 111",
    "OKAKA 1",
    "OKAKA 11",
    "OKE-OGUN"
    ],

    "ILORIN WEST":  [
    "ADEWOLE",
    "AJIKOBI",
    "BABOKO",
    "BADARI",
    "BALOGUN ALANAMU CENTRAL",
    "MAGAJI NGERI",
    "OLOJE",
    "OGIDI",
    "OJUEKUN/ZARUMI",
    "OKO-ERIN",
    "UBANDAWAKI",
    "WARRAH/EGBE JILA/OSHIN"
    ],

    "IREPODUN":  [
    "AJASE IPO 1",
    "AJASE IPO 11",
    "ARANDUN",
    "ESIE/IJAN",
    "IPETU/RORE/ARAN-ORIN",
    "OMU-ARAN 1 (ARAN)",
    "OMU-ARAN 11 (IHAYE)",
    "OMU-ARAN 111 (IFAJA)",
    "ORO 1",
    "ORO 11",
    "OKO"
    ],

    "ISIN":  [
    "ALLA",
    "EDIDI",
    "ISANLU 1",
    "ISANLU 11",
    "IJARA",
    "IWO",
    "OWU ISIN",
    "OKE ONIGBIN",
    "SABAJA/PAMO",
    "OKE ABA",
    "OLLA"
    ],

    "KAIAMA":  [
    "ADENA",
    "BANI",
    "GWANABE 1",
    "GWANABE 11",
    "GWARI A (GWARIA)",
    "KAIAMA 1",
    "KAIAMA 11",
    "KAIAMA 111",
    "KEMANJI",
    "WAJIBE"
    ],

    "MORO":  [
    "JEBBA",
    "BODE-SAADU",
    "OKEMI",
    "LANWA",
    "EJIDONGARI",
    "OKUTALA",
    "BABADUDU",
    "OLORU",
    "PAKUNMO",
    "WOMI/AYAKI",
    "ABATI/ALARA",
    "SHAO",
    "LOGUN/JEHUNKUNNU",
    "MALETE/GBUGUDU",
    "AJANAKU",
    "MEGIDA",
    "AROBADI"
    ],

    "OFFA":  [
    "BALOGUN",
    "SHAWO SOUTH WEST",
    "SHAWO CENTRAL",
    "SHAWO SOUTH EAST",
    "ESSA - A",
    "ESSA - B",
    "ESSA - C",
    "OJOMU NORTH/NORTH WEST",
    "OJOMU CENTRAL 1",
    "OJOMU CENTRAL 11",
    "OJOMU SOUTH EAST",
    "IGBOIDUN"
    ],

    "OKE-ERO":  [
    "AIYEDUN",
    "EKAN",
    "IMOJI/ILALE/ERINMOPE",
    "ILOFFA",
    "IMODE/EGOSI",
    "IDOFIN IGBANA 1",
    "IDOFIN IGBANA 11",
    "IDOFIN /ODO-ASHE",
    "ODO-OWA 1",
    "ODO-OWA 11"
    ],

    "OYUN":  [
    "ERIN-ILE SOUTH",
    "ILEMONA",
    "IGBONA",
    "IRRA",
    "INAJA/AHOGBADA",
    "IKOTUN",
    "OJOKU",
    "IJAGBO",
    "IGOSUN",
    "IPEE",
    "ERIN-ILE NORTH"
    ],

    "PATIGI":  [
    "PATIGI 1",
    "PATIGI 11",
    "PATIGI 111",
    "PATIGI 1V",
    "KPADA 1",
    "KPADA 11",
    "KPADA 111",
    "LADE 1",
    "LADE 11",
    "LADE 111"
    ],

    "AGEGE":  [
    "ISALE/IDIMANGORO",
    "ILORO/ONIPETESI",
    "ONIWAYA/PAPA-UKU",
    "AGBOTIKUYO/DOPEMU",
    "OYEWOLE/PAPA ASHAFA",
    "OKEKOTO",
    "KEKE",
    "DAROCHA",
    "TABON TABON/OKO OBA",
    "ORILE AGEGE/OKO OBA",
    "ISALE ODO"
    ],

    "AJEROMI/IFELODUN":  [
    "AGO HAUSA",
    "AWODI-ORA",
    "WILMER",
    "OLODI",
    "TOLU",
    "TEMIDIRE I",
    "OJO ROAD",
    "LAYENI",
    "ALABA ORO",
    "MOSAFEJO",
    "TEMIDIRE II"
    ],

    "ALIMOSHO":  [
    "SHASHA/AKOWONJO",
    "EGBEDA/ALIMOSHO",
    "IDIMU/ISHERI OLOFIN",
    "IKOTUN/IJEGUN",
    "EGBE/AGODO",
    "IGANDO/EGAN",
    "IPAJA NORTH",
    "IPAJA SOUTH",
    "AYOBO/IJON VILLAGE (CAMP DAVID)",
    "PLEASURE/OKE-ODO",
    "ABULE-EGBA/ABORU/MEIRAN/ALAGBADO"
    ],

    "AMUWO-ODOFIN":  [
    "AMUWO-ODOFIN HOUSING ESTATE, MILE 2",
    "FESTAC 1",
    "FESTAC II",
    "KIRIKIRI",
    "AMUWO",
    "IJEGUN",
    "SATELLITE",
    "IREDE",
    "IBESHE",
    "IGBOLOGUN",
    "FESTAC III"
    ],

    "APAPA":  [
    "APAPA I (MARINE RD. & ENVIRONS)",
    "APAPA II (LIVERPOOL RD. AND ENVIRONS)",
    "APAPA III (CREEK RD. TINCAN/SNAKE ISLAND",
    "APAPA IV (PELEWURA CRESCENT AND ENVIRONS)",
    "IJORA-OLOYE",
    "OLODAN ST. OLOJOWOU ST/ALH.DOGO OLATOKUNBO ST. IGANMU",
    "GASKIYA & ENVIRONS",
    "AFOLABI ALASIA STR. AND ENVIRONS",
    "MALU ROAD AND ENVIRONS",
    "SARI AND ENVIRONS"
    ],

    "BADAGRY":  [
    "POSUKOH",
    "AWHANJIGOH",
    "IBEREKO",
    "APA",
    "KETA-EAST",
    "IWORO GBANKO",
    "AJIDO",
    "ILOGBO-ARAROMI",
    "IKOGA",
    "AJARA",
    "IYA-AFIN"
    ],

    "EPE":  [
    "ETITA/EBODE",
    "LAGBADE",
    "POPO-OBA",
    "OKE-BALOGUN",
    "AJAGANABE",
    "ISE/IGBOGUN",
    "ORIBA/LADABA",
    "ABOMITI",
    "AGBOWA",
    "AGBOWA IKOSI",
    "AGO OWU",
    "ORUGBO",
    "ILARA",
    "IBONWON",
    "ODORAGUNSIN",
    "POKA",
    "ODOMOLA",
    "EJIRIN",
    "ITOIKIN"
    ],

    "ETI-OSA":  [
    "VICTORIA ISLAND I",
    "VICTORIA ISLAND II",
    "ILASAN HOUSING ESTATE",
    "LEKKI/IKATE AND ENVIRONS",
    "ILADO/ETI-OSA AND ENVIRONS",
    "AJAH/SANGOTEDO",
    "ADO/LANGBASA/BADORE",
    "IKOYI I",
    "IKOYI II",
    "OBALENDE"
    ],

    "IBEJU/LEKKI":  [
    "IBEJU I",
    "N2, (IBEJU II)",
    "ORIMEDU I",
    "02, (ORIMEDU II)",
    "03, (ORIMEDU III)",
    "P1, (IWEREKUN I)",
    "IWEREKUN II",
    "S1, (LEKKI I)",
    "LEKKI II",
    "S2, (SIRIWON/IGBEKODO I)",
    "S,2A (SIRIWON/IGBEKODO II)"
    ],

    "IFAKO-IJAYE":  [
    "IJAYE",
    "OLD IFAKO/KARAOLE",
    "NEW IFAKO/OYEMEKUN",
    "FAGBA/AKUTE ROAD",
    "IJU ISAGA",
    "IJU-OBAWOLE",
    "PAMADA/ABULE-EGBA",
    "IJAIYE/OJOKORO",
    "IJAIYE/AGBADO/KOLLINGTON",
    "ALAKUKO/KOLLINGTON",
    "AJEGUNLE/AKINDE/ANIMASHAUN"
    ],

    "IKEJA":  [
    "ANIFOWOSHE/IKEJA",
    "OJODU/AGIDINGBI/OMOLE",
    "ALAUSA/OREGUN/OLUSOSUN",
    "AIRPORT/ONIPETESI/ONILEKERE",
    "IPODO/SERIKI ARO",
    "ADEKUNLE VILL./ADENIYI JONES/OGBA",
    "OKE-IRA/AGUDA",
    "ONIGBONGBO/MILITARY CANTONMENT",
    "GRA/POLICE BARRACKS",
    "WASIMI/OPEBI/ALLEN"
    ],

    "IKORODU":  [
    "ISELE I",
    "ISELE II",
    "ISELE III",
    "AGA/IJIMU",
    "IPAKODO",
    "IMOTA 1",
    "IMOTA II",
    "ISIU",
    "IGBOGBO I",
    "IGBOGBO II",
    "BAIYEKU/ORETA",
    "IBESHE",
    "IJEDE J",
    "IJEDE II",
    "AGURA/IPONMI",
    "ODOGUNYAN",
    "ERIKORODU",
    "AGBALA",
    "OLORUNDA/IGBAGA"
    ],

    "KOSOFE":  [
    "OWORONSHOKI",
    "IFAKO/SOLUYI",
    "ANTHONY/AJAO ESTATE/MENDE/MARYLAND",
    "OJOTA/OGUDU",
    "KETU/ALAPERE/AGIDI/ORISIGUN/KOSOFE/AJELOGO/AKANIMODO",
    "IKOSI KETU/MILE 12/AGILITI/MAIDAN",
    "ISHERI-OLOWO-IRA/SHANGISHA/MAGODO PHASE I & II",
    "AGBOYI I",
    "AGBOYI II",
    "OWODE ONIRIN/AJEGUNLE/ODO-OGUN"
    ],

    "LAGOS ISLAND":  [
    "OLOWOGBOWO/ELEGBATA",
    "OLUWOLE",
    "IDUMOTA/OKE",
    "OJU-OTO",
    "OKO-AWO",
    "AGARAWU/OBADINA",
    "IDUNTAFA",
    "ILUPESI",
    "ISALE-AGBEDE",
    "OLOSUN",
    "OLUSHI/KAKAWA",
    "POPO-AGUDA",
    "ANIKANTAMO",
    "OKO-FAJI",
    "EIYEKOLE",
    "ONIKAN",
    "SANDGROUSE",
    "EPETEDO",
    "LAFIAJI/EBUTE"
    ],

    "LAGOS MAINLAND":  [
    "OTTO/IDDO",
    "EPETEDO",
    "OLALEYE VILLAGE",
    "MAROKO/EBUTE METTA",
    "OYINGBO MARKET/EBUTE METTA",
    "GLOVER/EBUTE METTA",
    "OKO-BABA",
    "OYADIRAN ESTATE/ABULE-OJA",
    "ALAGOMEJI",
    "IWAYA",
    "YABA/IGBOBI"
    ],

    "MUSHIN":  [
    "ALAKARA",
    "IDI-ORO/ODI-OLOWU",
    "BABALOSA",
    "OJUWOYE",
    "ILUPEJU",
    "OLATEJU",
    "KAYODE/FADEYI",
    "MUSHIN/ATEWOLARA",
    "PAPA-AJAO",
    "ILASAMAJA",
    "BABALOSA/IDI-ARABA",
    "IDI-ARABA",
    "ITIRE",
    "ILUPEJU INDUSTRIAL ESTATE"
    ],

    "OJO":  [
    "OJO TOWN",
    "OKOKOMAIKO",
    "AJANGBADI",
    "IJANIKIN",
    "IBA",
    "ILOGBO",
    "IREWE",
    "TAFI",
    "ETEGBIN",
    "IDOLUWO",
    "SABO"
    ],

    "OSHODI/ISOLO":  [
    "OSHODI/BOLADE",
    "ORILE-OSHODI",
    "ISOLO",
    "AJAO ESTATE",
    "ILASAMAJA",
    "MAFOLUKU",
    "SOGUNLE",
    "SOGUNLE/ALASIA",
    "OKOTA",
    "ISHAGATEDO",
    "OKE-AFA/EJIGBO"
    ],

    "SOMOLU":  [
    "ONIPANU",
    "PALMGROVE/IJEBUTEDO",
    "ALADE",
    "BAJULAIYE",
    "MAFOWOKU/PEDRO",
    "LAD-LAK/BARIGA",
    "ILAJE/AKOKA",
    "IGBOBI/FADEYI",
    "FOLA AGORO/BAJULAIYE/IGBARI-AKOKA",
    "GBAGADA PHASE I OBANIKORO/PEDRO",
    "GBAGADA PHASE II /BARIGA/APELEHIN",
    "ABULE-OKUTA/ILAJE/BARIGA"
    ],

    "SURULERE":  [
    "ORILE",
    "AGUDA",
    "IJESHATEDO",
    "AKINHANMI/COLE",
    "YABA/OJUELEGBA",
    "IGBAJA/STADIUM",
    "SHITTA/OGUNLANA DRIVE",
    "ADENIRAN/OGUNSANYA",
    "IPONRI HOUSING ESTATE/ERIC MOORE",
    "COKER",
    "ITIRE",
    "IKATE"
    ],

    "AKWANGA":  [
    "AGYAGA",
    "ANCHOBABA",
    "GWANJE.GWANJE",
    "ANCHO NIGHAAN",
    "ANDAHA",
    "NUNKU",
    "GUDI",
    "MOROA",
    "AKWANGA WEST",
    "AKWANGA EAST",
    "NINGO / BOHAR"
    ],

    "AWE":  [
    "TUNGA",
    "MAKWANGIJI",
    "MADAKI",
    "GALADIMA",
    "JANGARU",
    "KANJE/ABUNI",
    "RIBI",
    "AZARA",
    "WUSE",
    "AKIRI"
    ],

    "DOMA":  [
    "ALAGYE",
    "RUKUBI",
    "AGBASHI",
    "DOKA",
    "AKPANAJA",
    "MADAKI",
    "UNGWAN SARKI DAWAKI",
    "MADAUCHI",
    "UNGWAN DAN GALADIMA",
    "SABON GARI"
    ],

    "KARU":  [
    "ASO / KODAPE",
    "AGADA/BAGAJI",
    "KARSHI I",
    "KARSHI II",
    "KAFIN SHANU/BETTI",
    "TATTARA/KONDORO",
    "GITATA",
    "GURKU/KABUSU",
    "UKE",
    "PANDA / KARE",
    "KARU"
    ],

    "KEANA":  [
    "IWAGU",
    "AMIRI",
    "OBENE",
    "OKI",
    "KADARKO",
    "KWARA",
    "ALOSHI",
    "AGAZA",
    "MADAKI",
    "GIZA GALADIMA"
    ],

    "KEFFI":  [
    "ANGWAN IYA I",
    "ANGWAN IYA II",
    "TUDUN KOFA T.V",
    "GANGARE TUDU",
    "KEFFI TOWN EAST / KOFAR GORIYA",
    "YARA",
    "ANG.RIMI",
    "SABON GARI",
    "JIGWADA",
    "LIMAN ABAJI"
    ],

    "KOKONA":  [
    "AGWADA",
    "KOYA / KANA",
    "BASSA",
    "KOKONA",
    "KOFAR GWARI",
    "NINKORO",
    "HADARI",
    "DARI",
    "AMBA",
    "GARAKU",
    "YELWA"
    ],

    "LAFIA":  [
    "ADOGI",
    "AGYARAGUN TOFA",
    "BAKIN RIJIYA/AKURBA/SARKIN PADA",
    "ARIKYA",
    "ASSAKIO",
    "ASHIGIE",
    "CHIROMA",
    "GAYAM",
    "KEFFIN/WAMBAI",
    "MAKAMA",
    "SHABU/KWANDERE",
    "WAKWA",
    "ZANWA"
    ],

    "NASARAWA EGGON":  [
    "NASARAWA EGGON",
    "UBBE",
    "IGGA/BURUMBURUM",
    "UMME",
    "MADA STATION",
    "LIZZIN KEFFI/EZZEN",
    "LAMBAGA/ARIKPA",
    "KAGBU WANA",
    "IKKA WANGIBI",
    "ENDE",
    "WAKAMA",
    "ALOCE/GINDA",
    "ALOGANI",
    "AGUNJI"
    ],

    "TOTO":  [
    "GWARGWADA",
    "GADAGWA",
    "BUGAKARMO",
    "UMAISHA",
    "DAUSU",
    "KANYEHU",
    "UGYA",
    "TOTO",
    "SHAFAN ABAKPA I",
    "SHAFAN KWATTO I",
    "SHEGE I",
    "KATAKPA I"
    ],

    "WAMBA":  [
    "ARUM",
    "MANGAR",
    "GITTA",
    "NAKERE",
    "KONVAH",
    "WAYO",
    "WAMBA WEST",
    "WAMBA EAST",
    "KWARA",
    "JIMIYA"
    ],

    "NASARAWA":  [
    "UDENIN GIDA",
    "AKUM",
    "UDENIN",
    "LOKO",
    "TUNGA/BAKONO",
    "GUTO/AISA",
    "NASARAWA NORTH",
    "NASARAWA EAST",
    "NASARAWA CENTRAL",
    "NASARAWA MAIN TOWN",
    "ARA I",
    "ARA II",
    "LAMINGA",
    "KANAH/ONDO/APAWU",
    "ODU"
    ],

    "OBI":  [
    "AGWATASHI",
    "DEDDERE/RIRI",
    "OBI",
    "TUDUN ADABU",
    "DUDUGURU",
    "GWADENYE",
    "KYAKALE",
    "GIDAN AUSA I",
    "GIDAN AUSA II",
    "ADUDU"
    ],

    "AGAIE":  [
    "BARO",
    "BOKU",
    "EKOBADEGGI",
    "EKOSSA",
    "EKOWUGI",
    "EKOWUNA",
    "ETSUGAIE",
    "DAUACI",
    "KUTIRIKO",
    "MAGAJI",
    "TAGAGI"
    ],

    "AGWARA":  [
    "ADEHE",
    "AGWATA",
    "BUSURU",
    "GALLAH",
    "KASHINI",
    "KOKOLI",
    "MAGO",
    "PAPIRI",
    "ROFIA",
    "SUTEKU"
    ],

    "BIDA":  [
    "BARIKI",
    "CENIYAN",
    "DOKODZA",
    "KYARI",
    "LANDZUN",
    "MASABA I",
    "MASABA II",
    "MASAGA I",
    "MASAGA II",
    "MAYAKI NDAJIYA",
    "NASSARAFU",
    "UMARU/MAJIGI I",
    "UMARU MAJIGI II",
    "WADATA"
    ],

    "BORGU":  [
    "BABANNA",
    "DUGGA",
    "KARABONDE",
    "KONKOSO",
    "MALALE",
    "NEW BUSSA",
    "KABE/PISSA",
    "SHAGUNU",
    "WAWA",
    "RIVERINE"
    ],

    "BOSSO":  [
    "BEJI",
    "BOSSO CENTRAL I",
    "BOSSO CENTRAL II",
    "CHANCHAGA",
    "GARATU",
    "KAMPALA",
    "KODO",
    "MAIKUNKELE",
    "MAITUMBI",
    "SHATA"
    ],

    "CHANCHAGA":  [
    "LIMAWA 'A'",
    "LIMAWA 'B'",
    "MAKERA",
    "MINNA CENTRAL",
    "MINNA SOUTH",
    "NASSARAWA 'A'",
    "NASSARAWA 'B'",
    "NASSARAWA 'C'",
    "SABON GARI",
    "TUDUN WADA NORTH",
    "TUDUN WADA SOUTH"
    ],

    "EDATTI":  [
    "ENAGI",
    "ETSU TASHA",
    "FAZHI",
    "GAZHE I",
    "GAZHE II",
    "GBANGBAN",
    "GONAGI",
    "GUZAN",
    "ROKOTA",
    "SAKPE"
    ],

    "GBAKO":  [
    "BATAGI",
    "BATAKO",
    "EDOKOTA",
    "EDOZHIGI",
    "ETSU AUDU",
    "GBADAFU",
    "GOGATA",
    "LEMU",
    "NUWANKOTA",
    "SAMMAJIKO"
    ],

    "GURARA":  [
    "BONU",
    "DIKO",
    "GAWU",
    "IZOM",
    "KABO",
    "KWAKA",
    "LAMBATA",
    "LEFU",
    "SHAKO",
    "TUFA"
    ],

    "KATCHA":  [
    "BAKEKO",
    "BADEGGI",
    "BISANTI",
    "DZWAFU",
    "EDOTSU",
    "ESSA",
    "GBAKOGI",
    "KATCHA",
    "KATAREGI",
    "SIDI SABA"
    ],

    "KONTAGORA":  [
    "AREWA",
    "CENTRAL",
    "GABAS",
    "KUDU",
    "MAGAJIYA",
    "MASUGA",
    "MADARA",
    "NAGWAMATSE",
    "RAFIN GORA",
    "TUNGANWAWA",
    "TUNGAN KAWO",
    "USALLE",
    "YAMMA"
    ],

    "LAPAI":  [
    "AREWA/YAMMA",
    "BIRNIN MAZA/TASHIBO",
    "EBBO/GBACINKU",
    "EVUTI/KPADA",
    "GULU/ANGUWA VATSA",
    "GUPA/ABUGI",
    "GURDI/ZAGO",
    "KUDU/GABAS",
    "MUYE/EGBA",
    "TAKUTI/SHAKU"
    ],

    "LAVUN":  [
    "BUSU/KUCHI",
    "BATATI",
    "DASSUN",
    "DOKO",
    "DABBAN",
    "EGBAKO",
    "GABA",
    "JIMA",
    "KUTIGI",
    "KUSOTACHI",
    "LAGUN",
    "MAMBE"
    ],

    "MAGAMA":  [
    "AUNA CENTRAL",
    "AUNA EAST CENTRAL",
    "AUNA EAST",
    "AUNA SOUTH EAST",
    "AUNA SOUTH",
    "IBELU CENTRAL",
    "IBELU EAST",
    "IBELU NORTH",
    "IBELU WEST",
    "NASKO",
    "NASSARAWA"
    ],

    "MARIGA":  [
    "BANGI",
    "BERI",
    "BOBI",
    "GULBIN - BOKA",
    "GALMA/WAMBA",
    "INKWAI",
    "IGWAMA",
    "KAKIHUM",
    "KONTOKORO",
    "KUMBASHI",
    "MABURYA"
    ],

    "MASHEGU":  [
    "BABBAN RAMI",
    "DAPANGI/MAKERA",
    "IBBI",
    "KABOJI",
    "KASANGA",
    "KWATACHI",
    "KULHO",
    "MASHEGU",
    "MAZAKUKA/LIKORO",
    "SAHO-RAMI"
    ],

    "MOKWA":  [
    "BOKANI",
    "GBAJIBO/MUWO",
    "GBARA",
    "JA'AGI",
    "JEBBA NORTH",
    "KPAKI/TAKUMA",
    "KUDU",
    "LABOZHI",
    "MOKWA",
    "MUREGI",
    "RABBA/NDAYAKO"
    ],

    "MUNYA":  [
    "BENI",
    "DANDAUDU",
    "DANGUNU",
    "DAZA",
    "FUKA",
    "GINI",
    "GUNI",
    "KABULA",
    "KAZAI",
    "KUCHI",
    "SARKIN PAWA"
    ],

    "PAIKORO":  [
    "ADUNU",
    "CHIMBI",
    "GWAM",
    "ISHAU",
    "JERE",
    "KAFIN KORO",
    "KWAGANA",
    "KWAKUTI",
    "NIKUCHI T/MALLAM",
    "PAIKO CENTRAL",
    "TUTUNGO JEDNA"
    ],

    "RAFI":  [
    "KAGARA GARI",
    "KAKURI",
    "KONGOMA CENTRAL",
    "KONGOMA WEST",
    "KUSHERKI NORTH",
    "KUSHIRKI SOUTH",
    "KUNDU",
    "SABON GARI",
    "TEGINA GARI",
    "TEGINA WEST",
    "YAKILA"
    ],

    "RIJAU":  [
    "DANRANGI",
    "DUGGE",
    "DUKKU",
    "GENU",
    "JAMA'ARE",
    "RIJAU",
    "SHAMBO",
    "T/BUNU",
    "T/MAGAJIYA",
    "USHE",
    "WARARI"
    ],

    "SHIRORO":  [
    "ALLAWA",
    "BANGAJIYA",
    "BASSA/KUKOKI",
    "EGWA/GWADA",
    "ERANA",
    "GALKOGO",
    "GURMANA",
    "GUSSORO",
    "KATO",
    "KUSHAKA/KUREBE",
    "KWAKI/CHUKWUBA",
    "MANTA",
    "PINA",
    "SHE",
    "UBANDOMA"
    ],

    "SULEJA":  [
    "BAGMAMA 'A'",
    "BAGAMA 'B'",
    "HASHIMI 'A'",
    "HASHIMI 'B'",
    "IKU SOUTH I",
    "IKU SOUTH II",
    "KURMIN SARKI",
    "MAGAJIYA",
    "MAJE NORTH",
    "WAMBAI"
    ],

    "TAFA":  [
    "DOGON KURMI",
    "GARAM",
    "IJA GWARI",
    "IJA KORO",
    "IKU",
    "NEW BWARI",
    "WUSE WEST",
    "WUSE EAST",
    "ZUMA EAST",
    "ZUMA WEST"
    ],

    "WUSHISHI":  [
    "AKARE",
    "BARWA",
    "GWARJIKO",
    "KANWURI",
    "KODO",
    "KWATA",
    "LOKOGOMA",
    "MAITO",
    "SABON GARI",
    "TUKUNJI/YAMIGI",
    "ZUNGERU"
    ],

    "ABEOKUTA NORTH":  [
    "IKEREKU",
    "IKIJA",
    "AGO OKO",
    "ELEGA",
    "ILUGUN/IBEREKODO",
    "GBAGURA",
    "IKA",
    "LAFENWA",
    "SABO",
    "OKE-AGO OWU",
    "TOTORO/SOKORI",
    "ITA-OSHIN/OLOMORE",
    "OLORUNDA IJALE",
    "IMALA - IDIEMI",
    "ISAGA ILEWO",
    "IBARA ORILE/ONISASA"
    ],

    "ABEOKUTA SOUTH":  [
    "AKE I",
    "AKE II",
    "AKE III",
    "KEESI/EMERE",
    "IJEMO",
    "ITOKO",
    "IJAYE/IDI-ABA",
    "ERUNBE/OKE IJEUN",
    "AGO-EGUN/IJESA",
    "SODEKE/SALE-IJEUN I",
    "SODEKE/SALE-IJEUN II",
    "IMO/ISABO",
    "IGBORE/AGO OBA",
    "IBARA I",
    "IBARA II"
    ],

    "ADO ODO-OTA":  [
    "OTA I",
    "OTA II",
    "OTA III",
    "SANGO",
    "IJOKO",
    "ATAN",
    "IJU",
    "ILOGBO",
    "ADO ODO I",
    "ADO ODO II",
    "ERE",
    "ALAPOTI",
    "KETU-ADIE-OWE",
    "IGBESA",
    "AGBARA I",
    "AGBARA/EJILA AWORI"
    ],

    "EGBADO NORTH":  [
    "IDO FOI",
    "AYE TORO I",
    "AYE TORO II",
    "SUNWA",
    "IBORO/JOGA",
    "IMASAI",
    "EBUTE IGBOORO",
    "OHUNBE",
    "IGUA",
    "IJOUN",
    "IBESE"
    ],

    "EGBADO SOUTH":  [
    "ILARO I",
    "ILARO II",
    "ILARO III",
    "IWOYE",
    "IDOGO",
    "OKE ODAN",
    "OWODE I",
    "OWODE II",
    "ILOBI/ERINJA",
    "AJILETE"
    ],

    "EWEKORO":  [
    "ABALABI",
    "ASA/YOBO",
    "ARIGBAJO",
    "ITORI",
    "ELERE/ONIGBEDU",
    "PAPALANTO",
    "WASIMI",
    "MOSAN",
    "OWOWO",
    "OBADA-OKO"
    ],

    "IFO":  [
    "IFO I",
    "IFO II",
    "IFO III",
    "AGBADO",
    "ISERI",
    "AJUWON/AKUTE",
    "OKE-ARO/IBARAGUN/ROBIYAN",
    "OSOSUN",
    "SUNREN",
    "COKER",
    "IBOGUN"
    ],

    "IJEBU EAST":  [
    "IJEBU MUSHIN I",
    "IJEBU MUSHIN II",
    "IJEBU IFE I",
    "IJEBU IFE II",
    "OWU",
    "IKIJA",
    "ITELE",
    "OGBERE",
    "IMOBI I",
    "IMOBI II",
    "AJEBANDELE"
    ],

    "IJEBU NORTH":  [
    "ATIKORI",
    "JAPARA/OJOWO",
    "OMEN",
    "OSUN",
    "OKE AGBO",
    "OKE SOPIN",
    "ORU/AWA/ILAPORU",
    "AGO IWOYE I",
    "AGO IWOYE II",
    "AKO-ONIGBAGBO/GELETE",
    "MAMU/ETIRI"
    ],

    "IJEBU NORTH-EAST":  [
    "ATAN/IMUKU",
    "ODOSIMADEGUN/ODOSEBORA",
    "IMEWIRO/ODODEYO/IMOMO",
    "ODESENLU",
    "IGEDE/ITAMARUN",
    "OJU ONA",
    "ISOYIN",
    "ILESE",
    "OKE-ERI/OGBOGBO",
    "ERUNWON"
    ],

    "IJEBU ODE":  [
    "ISOKU/OSOSA",
    "ODO-ESA",
    "ITANTEBO",
    "IJADE / IMEPE I",
    "IJADE / IMEPE II",
    "POROGUN I",
    "POROGUN II",
    "IJASI/ IDEPO",
    "ODO-EGBO/OLIWORO",
    "ISIWO",
    "ITAMAPAKO"
    ],

    "IKENNE":  [
    "IKENNE I",
    "IKENNE II",
    "IPERU I",
    "IPERU II",
    "IPERU III",
    "OGERE I",
    "OGERE II",
    "ILISAN I",
    "ILISAN II",
    "ILISAN/IROLU"
    ],

    "IMEKO/AFON":  [
    "IMEKO",
    "OKE AGBEDE/MORIWI/MATALE",
    "IDOFA",
    "IWOYE/JABATA",
    "ILARA/ALAGBE",
    "AFON",
    "OTAPELE",
    "KAJOLA/AGBERIODO",
    "OLORUNDA/GBOMO",
    "IDI AYIN"
    ],

    "IPOKIA":  [
    "IPOKIA I",
    "IPOKIA II",
    "AGOSASA",
    "IJOFIN/IDOSA",
    "TUBE",
    "AGADA",
    "MAUNI I",
    "MAUNI II",
    "AJEGUNLE",
    "IFONYINTEDO",
    "IDIROKO",
    "IHUNBO/ ILASE"
    ],

    "OBAFEMI/OWODE":  [
    "MOKOLOKI",
    "OFADA",
    "OWODE",
    "AJURA",
    "MOLOKO-ASIPA",
    "ONIDUNDU",
    "OBA",
    "EGBEDA",
    "KAJOLA",
    "OBAFEMI",
    "AJEBO",
    "ALAPAKO-ONI"
    ],

    "ODEDA":  [
    "ODEDA",
    "BALOGUN ITESI",
    "OLODO",
    "ALAGBAGBA",
    "ILUGUN",
    "OSIELE",
    "OBANTOKO",
    "ALABATA",
    "OBETE",
    "OPEJI"
    ],

    "ODOGBOLU":  [
    "IMOSAN",
    "IMODI",
    "OKUN-OWA",
    "ODOGBOLU I",
    "ODOGBOLU II",
    "AIYEPE",
    "OSOSA",
    "IDOWA",
    "IBEFUN",
    "ILADO",
    "OGBO/MORAIKA/ITA-EPO I",
    "ALA/IGBILE",
    "JOBORE/IBIDO/IKISE",
    "OMU",
    "OGBO/MORAIKA/ITA EPO II"
    ],

    "OGUN WATER SIDE":  [
    "IWOPIN",
    "ONI",
    "IBIADE",
    "LUKOGBE/ILUSIN",
    "ABIGI",
    "EFIRE",
    "AYEDE/LOMIRO",
    "AYILA/ITEBU",
    "MAKUN/IROKUN",
    "ODE-OMI"
    ],

    "REMO NORTH":  [
    "AYEGBAMI",
    "IGAN/AJINA",
    "MOBORODE/OKE-OLA",
    "ODOFIN/IMAGBO/PETEKUN/DAWARA",
    "ILARA",
    "AKAKA",
    "IPARA",
    "ORILE-OKO",
    "ODE I",
    "ODE II"
    ],

    "SAGAMU":  [
    "OKO/EPE/ITULA I",
    "OKO/EPE/ITULA II",
    "AYEGBAMI/IJOKUN",
    "SABO 1",
    "SABO II",
    "ISOKUN / OYEBAJO",
    "IJAGBA",
    "LATAWA",
    "ODE -LEMO",
    "OGIJO/ LIKOSI",
    "SURULERE",
    "ISOTE",
    "SIMAWA / IWELEPE",
    "AGBOWA",
    "IBIDO/ITUWA/ALARA"
    ],

    "AKOKO NORTH-EAST":  [
    "EDO",
    "EKAN",
    "IKADO I",
    "IKADO II",
    "ILEPA I",
    "ILEPA II",
    "ISOWOPO I",
    "ISOWOPO II",
    "IYOMETA I",
    "IYOMETA II",
    "OORUN I",
    "OORUN II",
    "OYINMO"
    ],

    "AKOKO NORTH-WEST":  [
    "ARIGIDI/IYE I",
    "ARIGIDI II",
    "OKEAGBE",
    "OYIN/OGE",
    "AJOWA/IGASI/ERITI/GEDEGEDE",
    "OGBAGI",
    "OKE-IRUN/SURULERE",
    "ODO-IRUN/OYINMO",
    "ESE/AFIN",
    "ERUSU/KARAMU/IBARAMU"
    ],

    "AKOKO SOUTH-EAST":  [
    "EPINMI I",
    "EPINMI II",
    "IFIRA",
    "IPE I",
    "IPE II",
    "IPESI",
    "ISUA I",
    "ISUA II",
    "ISUA III",
    "ISUA IV",
    "SOSAN"
    ],

    "AKOKO SOUTH-WEST":  [
    "OKA I IBAKA / SABO",
    "OKA II A IKANMU",
    "OKA II B OKIA/KOROWA/SIMERIN/UBA",
    "OKA III A AGBA",
    "OKA III B OWASE /IKESE/IWONRIN/EBINRIN/IDORIN",
    "OKA IV/OWAKE/EBO/AYEGUNLE",
    "OKA V A OWALUSIN/AYEPE",
    "OKA V B OKA ODO/OKELA/BOLORUNDURO",
    "IKUN",
    "SUPARE I",
    "SUPARE II",
    "AKUNGBA I",
    "AKUNGBA II",
    "OBA I",
    "OBA II"
    ],

    "AKURE NORTH":  [
    "AGAMO/OKE-OORE/AKOMOWA",
    "AYEDE/OGBESE",
    "AYETORO",
    "IGBATORO",
    "IGOBA/ISINIGBO",
    "ILUABO/ELEYEWO/BOLORUNDURO",
    "ISIMIJA/IRADO",
    "MOFERERE",
    "OBA-ILE",
    "ODO-OJA/IJIGBO",
    "OKE-AFA/OWODE",
    "OKE IJU"
    ],

    "AKURE SOUTH":  [
    "APONMU",
    "GBOGI/ISIKAN I",
    "GBOGI/ISIKAN II",
    "IJOMU/OBANLA",
    "LISA",
    "ODA",
    "ODOPETU",
    "OKE ARO/URO I",
    "OKE ARO/URO II",
    "OSHODI/ISOLO",
    "OWODE/IMUAGUN"
    ],

    "ESE-ODO":  [
    "APOI I",
    "APOI II",
    "APOI III",
    "APOI IV",
    "APOI V",
    "AROGBO I",
    "AROGBO II",
    "AROGBO III",
    "UKPARAMA I",
    "UKPARAMA II"
    ],

    "IDANRE":  [
    "ALA-ELEFOSAN",
    "ALADE/ATOSIN",
    "IDALE-LEMIKAN",
    "IDALE-LOGBOSERE",
    "IJOMU/ISURIN",
    "IROWO",
    "ISALU EHINPETI",
    "ISALU JIGBOKIN",
    "OFOSU/ONISERE",
    "OWENA/APONMULONA"
    ],

    "IFEDORE":  [
    "ERO/IBUJI/MARIWO",
    "IGBAKA-OKE I",
    "IGBAKA-OKE II",
    "IJARE 1",
    "IJARE 11",
    "OBO/IKOTA/OLO-GBO",
    "ILARA 1",
    "ILARA 11",
    "IPOGUN/IBULE",
    "ISARUN/ ERIGI"
    ],

    "ILAJE":  [
    "AHERI",
    "ETIKAN",
    "MAHIN 1",
    "MAHIN 11",
    "MAHIN 111",
    "MAHIN 1V",
    "UGBO 1",
    "UGBO 11",
    "UGBO 111",
    "UGBO 1V",
    "UGBO V",
    "UGBO V1"
    ],

    "ILEOLUJI/OKEIGBO":  [
    "ILEOLUJI 1",
    "ILEOLUJI 11",
    "ILEOLUJI III",
    "ILEOLUJI 1V",
    "ILEOLUJI V",
    "ILEOLUJI VI",
    "OKE-IGBO 1",
    "OKE-IGBO 11",
    "OKE-IGBO 111",
    "OKE-IGBO 1V"
    ],

    "IRELE":  [
    "AJAGBA 1",
    "AJAGBA 11",
    "AKOTOGBO 1",
    "AKOTOGBO 11",
    "IYANSAN/OMI",
    "IRELE 1",
    "IRELE 11",
    "IRELE III",
    "IRELE 1V",
    "IRELE V"
    ],

    "ODIGBO":  [
    "AGBABU",
    "AGO-ALAYE",
    "AJUE",
    "ARAROMI OBU",
    "AYESAN",
    "EBIJAN",
    "KOSERU",
    "ODIGBO",
    "ONIPARAGA",
    "ORE 1",
    "ORE 11"
    ],

    "OKITIPUPA":  [
    "AYEKA/IGBODIGO",
    "ERINJE",
    "IGBOTAKO 1",
    "IGBOTAKO 11",
    "IJU-ODO/EREKITI",
    "IKOYA/OLOTO",
    "ILUTITUN 1",
    "ILUTITUN II",
    "ILUTITUN 111",
    "ODE AYE 1",
    "AYE 11",
    "OKITIPUPA 1",
    "OKITIPUPA 11"
    ],

    "ONDO EAST":  [
    "ATERU/OTASAN/IGBA",
    "ASANTAN OJA",
    "BOLORUNDURO 1",
    "EPE",
    "FAGBO",
    "OBADA",
    "OBOTO",
    "ORISUNMIBARE",
    "OWENA BRIDGE",
    "TEPO"
    ],

    "ONDO WEST":  [
    "ENUOWA/OBALALU",
    "GBAGHENGHA/GBONGBO/AJAGBA ALAFIA",
    "IFORE/ODOSIDA/LORO",
    "ILUNLA/BAGBE/ODOWO I",
    "ILUNLA/BAGBE/ODOWO II",
    "LITAYE/OBUNKEKERE/IGBINDO",
    "LODASA/IPARUKU/LIJOKA",
    "ODOJOMU/ERINKETA/LEGIRI",
    "OKEAGUNLA OKEROWO/OKEKUTA",
    "OKE-OTUNBA/OKE-DIBA/SOKOTI",
    "OKELISA OKEDOKO/OGBODU",
    "ORISUNMIBARE/ARAROMI"
    ],

    "OSE":  [
    "AFO",
    "IDOANI 1",
    "IDOANI 11",
    "IDOGUN",
    "IFON 1",
    "IFON 11",
    "IJAGBA",
    "IKARO/ELEGBEKA",
    "IMERI",
    "IMORU/ARIMOGIJA",
    "OKELUSE",
    "UTE"
    ],

    "OWO":  [
    "EHINOGBE",
    "IGBOROKO 1",
    "IGBOROKO 11",
    "IJEBU 1",
    "IJEBU 11",
    "ILORO",
    "IPELE",
    "ISAIPEN",
    "ISUADA/IPENMEN/IDASAN/OBASOOTO",
    "IYARE",
    "USO/EMURE ILE"
    ],

    "ATAKUMOSA EAST":  [
    "IWARA",
    "IGANGAN",
    "IPOLE",
    "IPERINDO",
    "ETI-ONI",
    "AYEGUNLE",
    "FOREST RESERVE 1",
    "FOREST RESERVE II",
    "FAFORIJI",
    "AJEBANDELE"
    ],

    "ATAKUMOSA WEST":  [
    "OSU I",
    "OSU II",
    "OSU III",
    "IBODI",
    "IFELODUN",
    "ITAGUNMODI",
    "OKE BODE",
    "ISA OBI",
    "MUROKO",
    "IFEWARA I",
    "IFEWARA II"
    ],

    "AYEDAADE":  [
    "BALOGUN",
    "OTUN BALOGUN",
    "OLUFI",
    "OTUN-OLUFI",
    "IJEGBE/OKE-ESO/OKE-OWU IJUGBE",
    "LAGERE/AMOLA",
    "GBONGAN RURAL",
    "ODE-OMU RURAL",
    "OBALUFON",
    "ANLUGBUA",
    "ARAROMI-OWU"
    ],

    "AYEDIRE":  [
    "OLUPONNA 1",
    "OLUPONNA 1I",
    "OLUPONNA 1II",
    "OKE-OSUN",
    "ILEOGBO I",
    "ILEOGBO II",
    "ILEOGBO III",
    "ILEOGBO IV",
    "KUTA I",
    "KUTA II"
    ],

    "BOLUWADURO":  [
    "OKE-OMI OTAN",
    "OKE ODE OTAN",
    "OKE-OTAN",
    "GBELERU OBAALA I",
    "GBELERU OBAALA II",
    "OBALA ILORO",
    "ERIPA",
    "OKE-IRUN",
    "IRESI I",
    "IRESI II"
    ],

    "BORIPE":  [
    "OLOTI IRAGBIJI",
    "OJA - OBA",
    "COLLEGE/EGBADA ROAD",
    "ISALE-OYO",
    "AGBA",
    "ORORUWO",
    "ADA I",
    "ADA II",
    "ISALE ASA IREE",
    "OKE ESA/OKE OGI",
    "OKE AREE"
    ],

    "EDE NORTH":  [
    "OLABA/ATAPARA",
    "ABOGUNDE/SAGBA",
    "OLOGUN/AGBAAKIN",
    "OLUSOKUN",
    "ALUSEKERE",
    "SABO/AGBONGBE I",
    "SABO/AGBONGBE II",
    "ISIBO/BUARI-ISOLA",
    "APASO",
    "ASUNMO",
    "BARA EJEMU"
    ],

    "EDE SOUTH":  [
    "BABANLA/AGATE",
    "KUYE",
    "JAGUN/JAGUN",
    "ALAJUE I",
    "ALAJUE II",
    "OLODAN",
    "BABASANYA",
    "SEKONA",
    "OLOKI/AKODA",
    "LOOGUN"
    ],

    "EGBEDORE":  [
    "AWO/ABUDO",
    "ARA I",
    "ARA II",
    "IDO-OSUN",
    "IRA GBERI I",
    "IRA GBERI II",
    "IWOYE/IDOO/ORIGO",
    "IKOTUN",
    "OJO/ARO",
    "OKINNI/OLORUNSOGO/OFATEDO"
    ],

    "EJIGBO":  [
    "ELEJIGBO 'A'",
    "ELEJIGBO 'B'/OSOLO",
    "ELEJIGBO 'C'/MAPO",
    "ELEJIGBO 'D'/EJEMU",
    "ELEJIGBO/AYEGBOGBO",
    "OLA/AYE/AGURODO",
    "IFEODAN 'A'/OWU-ILE",
    "IFEODAN 'B'/MASIFA",
    "ILAWO/ISOKO/ISUNDUNRIN",
    "INISA I/AATO/IGBON",
    "INISA II/AFAAKE/AYEGUNLE"
    ],

    "IFE CENTRAL":  [
    "ILARE I",
    "ILARE II",
    "ILARE III",
    "ILARE IV",
    "IREMO/AJEBANDELE",
    "IREMO II (ELEYELE)",
    "IREMO III",
    "IREMO IV",
    "IREMO V",
    "AKARABATA",
    "MOORE OJAJA"
    ],

    "IFEDAYO":  [
    "OYI",
    "AYETORO",
    "ISINMI",
    "BALOGUN",
    "OBAALE",
    "AWORO/OKE-ILA RURAL",
    "ASAONI",
    "CO-OPERATIVE",
    "AKESIN",
    "TEMIDIRE"
    ],

    "IFE EAST":  [
    "MOORE",
    "ILODE I",
    "ILODE II",
    "OKEREWE I",
    "OKEREWE II",
    "OKEREWE III",
    "YEKEMI",
    "MODAKEKE I",
    "MODAKEKE II",
    "MODAKEKE III"
    ],

    "IFE NORTH":  [
    "ASIPA/AKINLALU",
    "EDUNABON I",
    "EDUNABON II",
    "FAMIA",
    "YAKOYO",
    "IPETUMODU I",
    "IPETUMODU II",
    "MORO",
    "OYERE I",
    "OYERE - II"
    ],

    "IFE SOUTH":  [
    "AYESAN",
    "IKIJA I",
    "IKIJA II",
    "AARE",
    "MEFOWORADE",
    "OKE OWENA",
    "OLODE",
    "OSI",
    "KERE",
    "ABIRI OGUDU",
    "AYE"
    ],

    "ILA":  [
    "EJIGBO I",
    "EJIGBO II",
    "EJIGBO III",
    "ISEDO I",
    "ISEDO II",
    "IPERIN",
    "EYINDI",
    "OKE OLA",
    "OKE EDE",
    "EYINDI/IPERIN",
    "AJABA/EDEMOSI/ABA ORANGUN"
    ],

    "ILESA EAST":  [
    "OKESA",
    "IMO",
    "IFOSAN/OKE-ESO",
    "ITISIN/OGUDU",
    "IJAMO",
    "UPPER & LOWER IJOKA",
    "ILORO/ROYE",
    "ISARE",
    "ILERIN",
    "BOLORUNDURO",
    "BILADU"
    ],

    "ILESA WEST":  [
    "ITAKOGUN/UPPER EGBE-IDI",
    "LOWER EGBE-IDI",
    "UPPER/LOWER IGBOGI",
    "OMOFE/IDASA",
    "ISOKUN",
    "IKOYI / IKOTI ARAROMI",
    "ILAJE",
    "ISIDA/ADETI",
    "EREJA",
    "AYESO"
    ],

    "IREWOLE":  [
    "IKIRE 'A'",
    "IKIRE 'B'",
    "IKIRE 'C'",
    "IKIRE 'D'",
    "IKIRE 'E'",
    "IKIRE 'F'",
    "IKIRE 'G'",
    "IKIRE 'H'",
    "IKIRE 'I'",
    "IKIRE 'J'",
    "IKIRE 'K'"
    ],

    "ISOKAN":  [
    "OLUKOYI (OJA-OSUN)",
    "OSA IKOYI (OLOKE)",
    "ASALU IKOYI",
    "ORANRAN WARD",
    "IDOGUN WARD",
    "ALAPOMU I (ODO-OSUN)",
    "ALAPOMU II",
    "ASALU (MOGIMOGI)",
    "OOSA ADIFA",
    "AWALA I",
    "AWALA II"
    ],

    "IWO":  [
    "ISALE OBA I",
    "ISALE OBA II",
    "ISALE OBA III",
    "ISALE OBA IV",
    "MOLETE I",
    "MOLETE II",
    "MOLETE III",
    "OKE-ADAN I",
    "OKE-ADAN II",
    "OKE-ADAN III",
    "GIDIGBO I",
    "GIDIGBO II",
    "GIDIGBO III",
    "OKE-OBA I",
    "OKE-OBA II"
    ],

    "OBOKUN":  [
    "IBOKUN",
    "IPETU-ILE/ADAOWODE",
    "ILAHUN/IKINYINWA",
    "ILASE/IDOMINASI",
    "EESUN/IDOOKO",
    "IMESI-ILE",
    "ESA-OKE",
    "OTAN-ILE",
    "ESA - ODO",
    "ILARE"
    ],

    "ODO-OTIN":  [
    "OBA OJOMU",
    "BAALE",
    "IGBAYE",
    "FAJI/OPETE",
    "EKOSIN/IYEKU",
    "ORE/AGBEYE",
    "IJABE/ILA ODO",
    "OKUA/EKUSA",
    "ASI/ASABA",
    "OLUNISA",
    "OLUKOTUN",
    "ESA OTUN BAALE ODE",
    "JAGUN OSI BALE ODE",
    "OLOYAN ELEMOSHO / ESA",
    "OSOLO/OPARIN/OLA"
    ],

    "OLA-OLUWA":  [
    "TELEMU",
    "ASAMU/ILEMOWU",
    "OGBAAGBA I",
    "OGBAAGBA II",
    "IKIRE ILE/IWARA",
    "ISERO/IKONIFIN",
    "OBAMORO/ILE OGO",
    "BODE-OSI",
    "AJAGBA/IWOOKE",
    "ASA AJAGUNLASE"
    ],

    "OLORUNDA":  [
    "AGOWANDE",
    "BALOGUN",
    "AKOGUN",
    "ATELEWO",
    "OWOOPE",
    "OWODE I",
    "OWODE II",
    "AYETORO",
    "OBA-ILE",
    "OBA OKE",
    "ILIE"
    ],

    "ORIADE":  [
    "ERIN-OKE",
    "ERIN-IJESA",
    "IJEBU-JESA",
    "IWOYE",
    "IKEJI-ILE",
    "IRA",
    "IKEJI ARAKEJI/OWENA",
    "APOTI /DAGBAJA",
    "IPETU IJESA I",
    "IPETU-IJESA II",
    "IJEDA/ ILOKO",
    "ERINMO/IWARAJA"
    ],

    "OROLU":  [
    "OLUFON OROLU 'A'",
    "OLUFON OROLU 'B'",
    "OLUFON OROLU 'C'",
    "OLUFON OROLU 'D'",
    "OLUFON OROLU 'E'",
    "OLUFON OROLU 'F'",
    "OLUFON OROLU 'G'",
    "OLUFON OROLU 'H'",
    "OLUFON OROLU 'I'",
    "OLUFON OROLU 'J'"
    ],

    "OSOGBO":  [
    "ATAOJA 'A'",
    "ATAOJA 'B'",
    "ATAOJA 'C'",
    "ATAOJA 'D'",
    "ATAOJA 'E'",
    "OTUN JAGUN 'B'",
    "ALAGBA",
    "ARE-AGO",
    "JAGUN 'A'",
    "JAGUN 'B'",
    "BABA KEKERE",
    "OTUN JAGUN 'A'",
    "EKETA",
    "OTUN BALOGUN 'A'",
    "EKERIN"
    ],

    "IREPODUN":  [
    "OLOBU 'A'",
    "OLOBU 'B'",
    "OLOBU 'C'",
    "OLOBU 'D'",
    "BARA 'A'",
    "BARA 'B'",
    "ELERIN 'A'",
    "ELERIN 'B'",
    "ELERIN 'C'",
    "ELERIN 'D'",
    "ELERIN 'E'"
    ],

    "IFELODUN":  [
    "EESA IKIRUN",
    "AMOLA IKIRUN",
    "OWODE IKIRUN",
    "ISALE/OKE AFO",
    "IKIRUN RURAL",
    "STATION ROAD, IKIRUN",
    "OKEBA IKIRUN",
    "OLONDE IKIRUN",
    "IBA I",
    "IBA II",
    "OBAGUN",
    "EKOENDE/EKO AJALA"
    ],

    "AFIJIO":  [
    "ILORA I",
    "ILORA II",
    "ILORA III",
    "FIDITI I",
    "FIDITI II",
    "AWE I",
    "AWE II",
    "AKINMORIN/JOBELE",
    "IWARE",
    "IMINI"
    ],

    "AKINYELE":  [
    "IKEREKU",
    "OLANLA/OBODA/LABODE",
    "ARULOGUN/ENIOSA/ARORO",
    "OLODE/AMOSUN/ONIDUNDU",
    "OJO-EMO/MONIYA",
    "AKINYELE/ISABIYI/IREPODUN",
    "IWOKOTO/TALONTAN/IDI-ORO",
    "OJOO/AJIBODE/LANIBA",
    "IJAYE/OJEDEJI",
    "AJIBADE/ALABATA/ELEKURU",
    "OLORISA-OKO/OKEGBEMI/MELE",
    "IROKO"
    ],

    "ATIBA":  [
    "OKE-AFIN 1",
    "OKE-AFIN II",
    "AREMO",
    "BASHORUN",
    "AGUNPOPO I",
    "AGUNPOPO II",
    "AGUNPOPO III",
    "ASHIPA I",
    "ASHIPA II",
    "ASHIPA III"
    ],

    "ATISBO":  [
    "TEDE I",
    "TEDE II",
    "IRAWO ILE",
    "IRAWO OWODE",
    "OFIKI",
    "ALAGA",
    "AGO ARE I",
    "AGO ARE II",
    "OWO/AGUNREGE/SABE",
    "BAASI"
    ],

    "EGBEDA":  [
    "ERUNMU",
    "AYEDE/ALUGBO/KOLOKO",
    "OWOBAALE/KASUMU",
    "OLODAN/AJIWOGBO",
    "OLODO/KUMAPAYI I",
    "OLODO II",
    "OLODO III",
    "OSEGERE/AWAYE",
    "EGBEDA",
    "OLODE/ALAKIA",
    "OLUBADAN ESTATE"
    ],

    "IBADAN NORTH":  [
    "WARD I N2",
    "WARD II, N3",
    "WARD III, N4",
    "WARD IV, N5A",
    "WARD V, N5B",
    "WARD VI, N6A PART I",
    "WARD VII, N6A PART II",
    "WARD VIII, N6A PART III",
    "WARD IX, (N6B PART I)",
    "WARD X, N6B PART II",
    "WARD XI, NW8",
    "WARD XII, NW8"
    ],

    "IBADAN NORTH-EAST":  [
    "WARD I. EI",
    "WARD 2 NI (PART II)",
    "WARD III. E3",
    "WARD IV E4",
    "WARD V E5A",
    "WARD VI E5B",
    "WARD VII E6",
    "WARD VIII E7 I",
    "WARD IX E7II",
    "WARD X E8",
    "WARD XI E9 I",
    "WARD XII E9 II"
    ],

    "IBADAN NORTH-WEST":  [
    "WARD I NI (PART I)",
    "WARD 2 NI (PART II)",
    "WARD 3 NW1",
    "WARD 4 NW2",
    "WARD 5 NW3 (PART I)",
    "WARD 6 NW3 (PART I)",
    "WARD 7 NW4",
    "WARD 8 NW5",
    "WARD 9 NW6",
    "WARD 10 NW7",
    "WARD 11 NW7"
    ],

    "IBADAN SOUTH-EAST":  [
    "C1",
    "S 1",
    "S 2A",
    "S. 2B",
    "S 3",
    "S 4A",
    "S 4B",
    "S S5",
    "S 6A",
    "S. 6B",
    "S 7A",
    "S 7B"
    ],

    "IBADAN SOUTH-WEST":  [
    "WARD 1 C2",
    "WARD 2 SW1",
    "WARD 3 SW2",
    "WARD 4 SW3A & 3B",
    "WARD 5 SW4",
    "WARD 6 SW5",
    "WARD 7 SW6",
    "WARD 08 SW7",
    "WARD 9 SW8 (1)",
    "WARD 10 SW8 II",
    "WARD 11 SW9(1)",
    "WARD 12 SW9 II"
    ],

    "IBARAPA CENTRAL":  [
    "IDERE I (MOLETE)",
    "IDERE II (OMINIGBO/OKE - OBA)",
    "IDERE III (KOSO/APA)",
    "IBEREKODO I /(PATAOJU)",
    "IBEREKODO/AGBOORO/ITA BAALE",
    "IDOFIN ISAGANUN",
    "IGBOLE/PAKO",
    "ISALE-OBA",
    "OKESERIN I & II",
    "OKE-ODO"
    ],

    "IBARAPA EAST":  [
    "OKE -OBA",
    "ANKO",
    "ISABA",
    "ABORERIN",
    "NEW ERUWA",
    "SANGO",
    "OKE-IMALE",
    "ISALE TOGUN",
    "OKE OTUN",
    "ITABO"
    ],

    "IBARAPA NORTH":  [
    "AYETE I",
    "AYETE II",
    "IGANGAN I",
    "IGANGAN II",
    "IGANGAN III",
    "IGANGAN IV",
    "OFIKI I",
    "OFIKI II",
    "TAPA I",
    "TAPA II"
    ],

    "IDO":  [
    "ABA EMO/ILAJU/ALAKO",
    "AKUFO/IDIGBA/ARAROMI",
    "AKINWARE/AKINDELE",
    "APETE/AYEGUN/AWOTAN",
    "BATAKE/IDI-IYA",
    "ERINWUSI/KOGUO/ODETOLA",
    "FENWA/OGANLA/ELENUSONSO",
    "IDO/ONIKEDE/OKUNA AWO",
    "OMI ADIO/OMI ONIGBAGBO BAKATARI",
    "OGUNDELE/ALAHO/SIBA/IDI-AHUN"
    ],

    "IREPO":  [
    "AGORO",
    "AJAGUNNA",
    "ATIPA",
    "IBA I",
    "IBA II",
    "IBA III",
    "IBA IV",
    "IBA V",
    "IKOLABA",
    "LAHA/AJANA"
    ],

    "ISEYIN":  [
    "ADO-AWAYE",
    "AKINWUMI/OSOOGUN",
    "EKUNLE I",
    "EKUNLE II",
    "FARAMORA",
    "IJEMBA/OKE-OLA/OKE-OJA",
    "ISALU I",
    "ISALU II",
    "KOSO I",
    "KOSO II",
    "LADOGAN/OKE EYIN"
    ],

    "ITESIWAJU":  [
    "BABAODE",
    "IGBOJAIYE",
    "IPAPO",
    "KOMU",
    "OKAKA I",
    "OKAKA II",
    "OKE-AMU",
    "OTU 1",
    "OTU II",
    "OWODE/IPAPO"
    ],

    "IWAJOWA":  [
    "AGBAAKIN I",
    "AGBAAKIN II",
    "IWERE-ILE I",
    "IWERE-ILE II",
    "IWERE-ILE III",
    "IWERE-ILE IV",
    "SABI GANA I",
    "SABI GANA II",
    "SABI GANA III",
    "SABI GANA IV"
    ],

    "KAJOLA":  [
    "AYETORO-OKE I",
    "ELERO",
    "GBELEKALE I & II",
    "IBA-OGAN",
    "IJO",
    "ILAJI OKE/IWERE-OKE",
    "IMOBA/OKE-OGUN",
    "ISEMI-ILE/IMIA/ILUA",
    "ISIA",
    "KAJOLA",
    "OLELE"
    ],

    "LAGELU":  [
    "AJARA/OPEODU",
    "APATERE/KUFFI/OGUNBODE/OGO",
    "ARULOGUN EHIN/KELEBE",
    "EJIOKU/IGBON/ARIKU",
    "LAGELU MARKET/KAJOLA/GBENA",
    "LAGUN",
    "LALUPON I",
    "LALUPON II",
    "LALUPON III",
    "OFA-IGBO",
    "OGUNJANA/OLOWODE/OGBURO",
    "OGUNREMI/OGUNSINA",
    "OYEDEJI/OLODE/KUTAYI",
    "SAGBE/PABIEKUN"
    ],

    "OGBOMOSHO NORTH":  [
    "ABOGUNDE",
    "AAJE/OGUNBADO",
    "AGUODO/ MASIFA",
    "ISALE AFON",
    "ISALE ALAASA",
    "ISALE ORA/SAJA",
    "JAGUN",
    "OKELERIN",
    "OSUPA",
    "SABO/TARA"
    ],

    "OGBOMOSHO SOUTH":  [
    "AKATA",
    "ALAPATA",
    "AROWOMOLE",
    "IBAPON",
    "IJERU I",
    "IJERU II",
    "ILOGBO",
    "ISOKO",
    "LAGBEDU",
    "OKE-OLA/FARM SETTLEMENT"
    ],

    "OGO-OLUWA":  [
    "AJAAWA I",
    "AJAAWA II",
    "AYEDE",
    "AYETORO",
    "IDEWURE",
    "LAGBEDU",
    "MOWOLOWO/IWO-ATE",
    "ODO-OBA",
    "OPETE",
    "OTAMOKUN"
    ],

    "OLORUNSOGO":  [
    "ABOKE (ABOYUN OGUN)",
    "ELERUGBA/ELEHINKE/SAGBO (APERU)",
    "IKOLABA/OBADIMO",
    "ONIGBETI I (IYAMOPO)",
    "ONIGBETI II/SAGBON AGORO (SAGBON)",
    "ONIGBETI III & IV (AGBENI)",
    "OPA/OGUNNIYI",
    "SERIKI I & ABOSINO (OKIN)",
    "SERIKI II (AGBELE)",
    "WARO/APATA-ALAJE"
    ],

    "OLUYOLE":  [
    "AYEGUN",
    "IDI-IROKO/IKEREKU",
    "IDI-OSAN/EGBEDA-ATUBA",
    "MUSLIM/OGBERE",
    "ODO-ONA NLA",
    "OKANHINDE/LATUNDE",
    "OLOMI/OLURINDE",
    "OLONDE/ABA-NLA",
    "ONIPE",
    "ORISUNBARE/OJO-EKUN"
    ],

    "ONA-ARA":  [
    "AKANRAN/OLORUNDA",
    "ARAROMI/APERIN",
    "BADEKU",
    "GBADA EFON",
    "ODI ODEYALE/ODI APERIN",
    "OGBERE",
    "OGBERE TIOYA",
    "OJOKU/AJIA",
    "OLORUNSOGO",
    "OLODE/GBEDUN/OJEBODE",
    "OREMEJI/AGUGU"
    ],

    "OORELOPE":  [
    "ALEPATA",
    "AARE",
    "BONNI",
    "IGBOPE/IYEYE I",
    "IGBOPE/IYEYE II",
    "IGI ISUBU",
    "ONIBODE I",
    "ONIBODE II",
    "ONIBODE III",
    "ONIGBOHO/ALOMO/OKERE"
    ],

    "ORI IRE":  [
    "ORI IRE I",
    "ORI IRE II",
    "ORI IRE III",
    "ORI IRE IV",
    "ORI IRE V",
    "ORI IRE VI",
    "ORI IRE VII",
    "ORI IRE VIII",
    "ORI IRE IX",
    "ORI IRE X"
    ],

    "OYO EAST":  [
    "AGBOYE/MOLETE",
    "AJAGBA",
    "ALAODI/MODEKE",
    "APAARA",
    "APINNI",
    "BALOGUN",
    "JABATA",
    "OKE APO",
    "OLUAJO",
    "OWODE/ARAROMI"
    ],

    "OYO WEST":  [
    "AKEETAN",
    "AJOKIDERO/AKEWUGBERU",
    "FASOLA/SOKU",
    "ISEKE",
    "ISOKUN I",
    "ISOKUN II",
    "IYAJI",
    "OPAPA",
    "OWODE",
    "PAKOYI/IDODE"
    ],

    "SAKI EAST":  [
    "AGBONLE",
    "AGO AMODU I",
    "AGO AMODU II",
    "OGBOORO I",
    "OGBOORO II",
    "OJE OWODE I",
    "OJE OWODE II",
    "SEPETERI I",
    "SEPETERI II",
    "SEPETERI III",
    "SEPETERI IV"
    ],

    "SAKI WEST":  [
    "AGANMU/KOOKO",
    "AJEGUNLE",
    "BAGII",
    "EKOKAN / IMUA",
    "IYA",
    "OGIDIGBO/KINNIKINNI",
    "OKE-ORO",
    "OKERE I",
    "OKERE II",
    "SANGOTE/BOODA/BAABO/ILUA",
    "SEPETERI/BAPON"
    ],

    "SURULERE":  [
    "BAYA-OJE",
    "IGBON/GAMBARI",
    "IRESAAPA",
    "AROLU",
    "IRESAADU",
    "IREGBA",
    "IWOFIN",
    "OKO",
    "ILAJUE",
    "MAYIN"
    ],

    "BARIKIN LADI":  [
    "BARAKIN LADI",
    "GASSA/SHO",
    "GINDIN AKWATI",
    "HEIPANG",
    "KAPWIS",
    "KURRA FALLS",
    "LOBIRING",
    "MARIT/MAZAT",
    "RAFAN",
    "TAFAN",
    "ZABOT"
    ],

    "BOKKOS":  [
    "BOKKOS",
    "BUTURA",
    "DAFFO",
    "KAMWAI",
    "KWATAS",
    "MANGOR",
    "MBAR/MANGAR",
    "MANGUNA",
    "MUSHERE CENTRAL",
    "MUSHERE WEST",
    "RICHA",
    "SHA",
    "TANGUR",
    "TOFF"
    ],

    "JOS EAST":  [
    "FEDERE",
    "FOBUR 'A'",
    "FOBUR 'B'",
    "FURSUM",
    "JARAWAN KOGI",
    "MAI GEMU",
    "MAIJUJU",
    "SHERE EAST",
    "SHERE WEST",
    "ZANDI"
    ],

    "JOS NORTH":  [
    "ABBA NA SHEHU",
    "ALI KAZAURE",
    "GARBA DAHO",
    "GANGARE",
    "IBRAHIM KATSINA",
    "JENTA ADAMU",
    "JENTA APATA",
    "JOS JARAWA",
    "NARAGUTA 'A'",
    "NARAGUTA 'B'",
    "SARKIN ARAB",
    "TAFAWA BALEWA",
    "TUDUN WADA - KABONG",
    "VANDERPUYE"
    ],

    "JOS SOUTH": [
    "BUKURU",
    "DU",
    "GIRING",
    "GYEL 'A'",
    "GYEL 'B'",
    "KURU 'A'",
    "KURU 'B'",
    "SHEN",
    "TURU",
    "VWANG",
    "ZAWAN 'A'",
    "ZAWAN 'B'"
    ],

    "KANAM": [
    "BIRBYANG",
    "DENGI",
    "DUGUB",
    "GAGDI",
    "GARGA",
    "GUMSHER",
    "GWAMLAR",
    "JARMAI",
    "JOM",
    "KANAM",
    "KANTANA",
    "KUNKYAM",
    "MUNBUTBO",
    "NAMARAN"
    ],

    "KANKE":  [
    "AMPANG-EAST",
    "AMPER CHIKA 'A'",
    "AMPER CHIKA 'B'",
    "AMPER SERI",
    "DAWAKI",
    "GARRAM",
    "KABWIR PADA",
    "KABWIR/GYANGYANG",
    "LANGSHI",
    "NEMEL"
    ],

    "LANGTANG NORTH":  [
    "FUNYALANG",
    "JAT",
    "KELLER",
    "KUFFEN",
    "KWALLAK",
    "KWANPE",
    "LIPCHOK",
    "MBAN/ZAMKO",
    "NYER",
    "PAJAT",
    "PIL GANI",
    "PISHE/YASHI",
    "REAK",
    "WAROH"
    ],

    "LANGTANG SOUTH":  [
    "DADIN KOWA",
    "FAJUL",
    "GAMAKAI",
    "LASHEL",
    "MABUDI",
    "MAGAMA",
    "SABON GIDA",
    "TALGWANG",
    "TIMBOL",
    "TURAKI"
    ],

    "MANGU":  [
    "AMPANG WEST",
    "CHANSO",
    "GINDIRI 1",
    "GINDIRI 11",
    "JANNARET",
    "JIPAL/CHAKFEM",
    "KADUNU",
    "KERANG",
    "KOMBUN",
    "LANGAI",
    "MANGU 1",
    "MANGU 11",
    "MANGUN",
    "MANGU HALLE",
    "PAN YAM",
    "PUSHIT"
    ],

    "MIKANG":  [
    "BALTEP",
    "GARKAWA CENTRAL",
    "GARKAWA NORTH",
    "GARKAWA NORTH EAST",
    "KOENOEM 'A'",
    "KOENOEM 'B'",
    "LALIN",
    "PIAPUNG 'A'",
    "PIAPUNG 'B'",
    "TUNKUS"
    ],

    "PANKSHIN":  [
    "CHIP",
    "DOK-PAI",
    "FIER",
    "JIBLIK",
    "KADUNG",
    "KANGSHU",
    "LANKAN",
    "PANKSHIN CENTRAL",
    "PANKSHIN CHIGWONG",
    "PANKSHIN SOUTH (BELNING)",
    "TAL",
    "WOKKOS"
    ],

    "QUA'AN PAN":  [
    "BWALL",
    "DOEMAK-GOECHIM",
    "DOEMAK-KOPLONG",
    "DOKAN KASUWA",
    "KURGWI",
    "KWA",
    "KWALLA MOEDA",
    "KWALLA YITLA'AR",
    "KWANDE",
    "KWANG",
    "NAMU"
    ],

    "RIYOM":  [
    "ATTAKAR",
    "BUM",
    "DANTO",
    "JOL/KWI",
    "RA-HOSS",
    "RIM",
    "RIYOM",
    "SHARUBUTU",
    "SOPP",
    "TA-HOSS"
    ],

    "SHENDAM":  [
    "DERTENG",
    "KALONG",
    "KURUNGBAU (A)",
    "KURUNGBAU (B)",
    "MOEKAT",
    "PANGSHOM",
    "POESHIP",
    "SHENDAM CENTRAL (A)",
    "SHENDAM CENTRAL (B)",
    "SHIMANKAR",
    "YELWA"
    ],

    "WASE":  [
    "BASHAR",
    "DANBIRAM",
    "GUDUS",
    "KADARKO",
    "KUMBONG",
    "KUMBUR",
    "KUYAMBANA",
    "MAVO",
    "NYALUM/KAMPANI",
    "SALUWE",
    "WASE TOFA",
    "YOLA WAKAT"
    ],

    "BASSA":  [
    "BUHIT",
    "BUJI",
    "GABIA",
    "GURUM",
    "JENGRE",
    "KADAMO",
    "KAKKEK",
    "KASURU",
    "KIMAKPA",
    "KISHIKA",
    "MAFARA",
    "RIMI",
    "TA'AGBE",
    "TAHU",
    "ZABOLO",
    "ZOBWO"
    ],

    "BINJI":  [
    "INNAME",
    "SAMAMA",
    "BINJI",
    "JAMMALI",
    "T/KOSE",
    "GAWAZZAI",
    "MAIKULKI",
    "BUNKARI",
    "SORO GABAS",
    "SORO YAMMA"
    ],

    "BODINGA":  [
    "BODINGA/TAUMA",
    "BAGARAWA",
    "MAZAN GARI/JIRGA MIYO",
    "SIFAWA/LUKUYAWA",
    "BADAU/DARHELA",
    "DINGYADI/BADAWA",
    "TULLUWA/KULAFASA",
    "DANCHADI",
    "BANGI/DABAGA",
    "KWACCIYAR LALLE",
    "TAKATUKU/MADORAWA"
    ],

    "DANGE/SHUNI":  [
    "DANGE",
    "WABABE/SALAU",
    "BODAI/JURGA",
    "FAJALDU",
    "SHUNI",
    "RUDU/AMANAWA",
    "TSAFANADE",
    "TUNTUBE/TSEHE",
    "GIERE/GAJARA",
    "RIKINA",
    "RUGGAR GIDADO"
    ],

    "GADA":  [
    "GADA",
    "KADDI",
    "KADADIN BUDA (KADDI)",
    "GILBADI",
    "TSITSE",
    "KAFFE",
    "DUKAMAJE/ILAH",
    "KYADAWA/HOLAI",
    "KIRI",
    "KADASSAKA",
    "KWARMA"
    ],

    "GORONYO":  [
    "GORONYO",
    "GIYAWA",
    "BOYEKA I",
    "KAGARA",
    "KWAKWAZO",
    "KOJIYO",
    "RIMAWA",
    "SHINAKA",
    "S/GARI DOLE/DAN/TASAKKO",
    "BIRJINGO",
    "TAKAKUME"
    ],

    "GUDU":  [
    "KARFEN SARKI",
    "CHILAS/MAKUYA",
    "BACHAKA",
    "GWAZANGE/BOTO",
    "KURDULA",
    "BALLE",
    "AWULKITI",
    "KARFEN CHANA",
    "MARAKEN BORI",
    "TULUN DOYA"
    ],

    "GWADABAWA":  [
    "ASARA KUDU",
    "ASARA AREWA",
    "ATAKWANYO",
    "CHIMMOLA/KUDU",
    "CHIMOLA AREWA",
    "GWADABAWA",
    "GIDAN KAYA",
    "GIGANE",
    "MAMMANDE",
    "SALAME",
    "HUCHI"
    ],

    "ILLELA":  [
    "ILLELA",
    "KALMALO",
    "DAMBA",
    "DARNA/ SABON GARI",
    "DARNE/ TSOLAWO",
    "G/ HAMMA",
    "ARABA",
    "GARU",
    "R. GATI",
    "TOZAI",
    "G/ KATTA"
    ],

    "ISA":  [
    "ISA NORTH",
    "ISA SOUTH",
    "TURBA",
    "TSABREN SARKIN DARAI",
    "TIDIBALE",
    "BARGAJA",
    "YANFAKO",
    "TOZAI",
    "GEBE 'A'",
    "GEBE 'B'"
    ],

    "KWARE":  [
    "KWARE",
    "BANKANU/ R, KADE",
    "BASANSAN",
    "S/BIRNI/ G. KARMA",
    "H/ ALI/ MARABAWA",
    "G/ MODIBBO/ G. AKWARA",
    "G. RUGGA",
    "DURBAWA",
    "TUNGA/ MALLAMAWA",
    "KABANGA",
    "TSAKI/ WALAKE'E"
    ],

    "KEBBE":  [
    "KEBBE EAST",
    "KEBBE WEST",
    "MARGAI - A",
    "MARGAI - B",
    "KUCHI",
    "NASAGUDU",
    "SANGI",
    "FAKKU",
    "UNGUSHI",
    "GIRKAU"
    ],

    "RABAH":  [
    "RABAH",
    "RIJI/MAIKUJERA",
    "RARA",
    "GWADDODI/GIDAN BU WAI",
    "GAWAKUKE",
    "GANDI 'A'",
    "GANDI 'B'",
    "TSAMIYA",
    "YAR TSAKUWA",
    "KURYA",
    "TURSA"
    ],

    "SABON/BIRNI":  [
    "S/BIRNI EAST",
    "S/BIRNI WEST",
    "TSAMAYE",
    "UNGUWAR LALLE",
    "TARA",
    "GATAWA",
    "KURAWA",
    "GANGARA",
    "KALGO",
    "MAKUWANA",
    "LAJINGE"
    ],

    "SHAGARI":  [
    "JAREDI",
    "HORO BIRNI",
    "DANDIN MAHE",
    "KAMBAMA",
    "GANGAM",
    "LAMBARA",
    "SHAGARI",
    "SANYINNAWAL",
    "KAJIJI",
    "MANDERA"
    ],

    "SILAME":  [
    "GANDE EAST",
    "GANDE WEST",
    "KATAMI NORTH",
    "KATAMI SOUTH",
    "KUBODU NORTH",
    "KUBODU SOUTH",
    "LABANI",
    "MARAFA EAST",
    "MARAFA WEST",
    "SILAME"
    ],

    "SOKOTO NORTH":  [
    "WAZIRI 'A'",
    "WAZIRI 'B'",
    "WAZIRI 'C'",
    "MAGAJIN GARI 'A'",
    "MAGAJIN GARI B",
    "S/ADAR/G/IGWAI",
    "MAGAJIN RAFI 'A'",
    "MAGAJIN RAFI 'B'",
    "S/MUSULMI 'A'",
    "S/MUSULMI 'B'",
    "S/ADAR GANDU"
    ],

    "SOKOTO SOUTH":  [
    "R/DOROWA 'A'",
    "R/DOROWA 'B'",
    "T/WADA 'A'",
    "T/WADA 'B'",
    "S/ZAMFARA 'A'",
    "S/ZAMFARA 'B'",
    "GAGI 'A'",
    "GAGI 'B'",
    "GAGI 'C'",
    "SARKIN ADAR KWANNI",
    "S/A/K/ATIKU"
    ],

    "TAMBUWAL":  [
    "ROMON SARKI",
    "BAKAYA/SABON BIRNI",
    "BASHIRE/MAIKADA",
    "BAGIDA/LUKKINGO",
    "TAMBUWAL/SHINFIRI",
    "FAGA/ALASAN",
    "DOGONDAJI/SALA",
    "SANYINNA",
    "BARKEJI/NABAGUDA",
    "JABO/KAGARA",
    "SAIDA/GOSHE"
    ],

    "TANGAZA":  [
    "TANGAZA",
    "RUWA-WURI",
    "RAKA",
    "SALEWA",
    "GIDAN MADI",
    "SUTTI",
    "KWACCE-HURU",
    "KALANJENI",
    "MAGONHO",
    "SAKKWAI"
    ],

    "TURETA":  [
    "DUMA",
    "LOFA",
    "TSAMIYA",
    "BARAYAR GIWA",
    "FURA GIRKE",
    "LAMBAR TURETA",
    "TURETA GARI",
    "KURUWA",
    "GIDAN KARE/BIMASA",
    "KWARARE"
    ],

    "WAMAKKO":  [
    "ARKILLA",
    "BADO/KASARAWA",
    "GUMBI/WAJAKE",
    "KALAMBAINA/GIRABSHI",
    "G/HAMIDU/G/KAYA",
    "G/BUBU/G/YARO",
    "WAMAKKO",
    "DUNDAYE/GUMBURAWA",
    "K/KIMBA/GEDEWA",
    "GWAMATSE",
    "KAMMATA"
    ],

    "WURNO":  [
    "MARAFA",
    "MAGARYA",
    "DANKEMU",
    "DIMBISO",
    "KWARGABA",
    "CHACHO/MARNONA",
    "DINAWA",
    "ACHIDA",
    "ALKAMMU/GYELGYEL",
    "LAHODU/G/BANGO",
    "KWASARE/SISAWA"
    ],

    "YABO":  [
    "FAKKA",
    "BINGAJE",
    "BINJI",
    "TORANKAWA",
    "RUGGAR IYA",
    "YABO 'A'",
    "YABO 'B'",
    "KILGORI",
    "BIRNIRUWA",
    "BAKALE"
    ],

    "ARDO-KOLA":  [
    "ALIM GORA",
    "ARDO KOLA",
    "IWARE",
    "JAURO YINU",
    "LAMIDO BORNO",
    "MAYO RANEWO",
    "SARKIN DUTSE",
    "SUNKANI",
    "TAU",
    "ZONGON KOMBI"
    ],

    "BALI":  [
    "BADAKOSHI",
    "BALI A",
    "BALI B",
    "GANG DOLE",
    "GANGLARI",
    "GANGTIBA",
    "GANG MATA",
    "KAIGAMA",
    "MAIHULA",
    "SUNTAI",
    "TAKALAFIYA"
    ],

    "DONGA":  [
    "AKATE",
    "ASIBITI",
    "FADA",
    "GAYAMA",
    "GINDIN DUTSE",
    "GYATTA AURE",
    "BIKADARKO",
    "MARARRABA",
    "NYITA",
    "SUNTAI"
    ],

    "GASHAKA":  [
    "GALUMJINA",
    "GANGUMI",
    "GARBABI",
    "GASHAKA",
    "GAYAM",
    "JAMTARI",
    "MAI-IDANU",
    "MAYO SELBE",
    "SERTI 'A'",
    "SERTI 'B'"
    ],

    "GASSOL":  [
    "GASSOL",
    "GUNDUMA",
    "MUTUM BIYU I",
    "MUTUM BIYU II",
    "NAM NAI",
    "SABON GIDA",
    "SARKIN SHIRA",
    "SENDIRDE",
    "TUTARE",
    "WUROJAM",
    "WURYO",
    "YARIMA"
    ],

    "IBI":  [
    "DAMPAR I",
    "DAMPAR II",
    "DAMPAR III",
    "IBI NWONYO I",
    "IBI NWONYO II",
    "IBI RIMI UKU I",
    "IBI RIMI UKU II",
    "SARKIN KUDU I",
    "SARKIN KUDU II",
    "SARKIN KUDU III"
    ],

    "JALINGO":  [
    "ABBARE YELWA",
    "BARADE",
    "KACHALLA SEMBE",
    "KONA",
    "MAJIDADI",
    "MAYO GOI",
    "SARKIN DAWAKI",
    "SINTALI",
    "TURAKI 'A'",
    "TURAKI 'B'"
    ],

    "KARIM-LAMIDO":  [
    "AMAR",
    "ANDAMIN",
    "BACHAMA",
    "BIKWIN",
    "DAROFAI",
    "DIDANGO",
    "JEN ARDIDO",
    "JEN KAIGAMA",
    "KARIM 'A'",
    "KARIM 'B'",
    "KWANCHI"
    ],

    "KURMI":  [
    "ABONG",
    "AKWENTO/BOKO",
    "ASHUKU/ENEME",
    "BAISSA",
    "BENTE/GALEA",
    "BISSAULA",
    "DIDAN",
    "NDAFORO/GEANDA",
    "NJUWANDE",
    "NYIDO/TOSSO"
    ],

    "LAU":  [
    "ABBARE I",
    "ABBERE II",
    "DONADDA",
    "GARIN DOGO",
    "GARIN MAGAJI",
    "JIMLARI",
    "KUNINI",
    "LAU I",
    "LAU II",
    "MAYO LOPE"
    ],

    "SARDAUNA":  [
    "GEMBU 'A'",
    "GEMBU 'B'",
    "KABRI",
    "KAKARA",
    "MAGU",
    "MAYO-NDAGA",
    "MBAMNGA",
    "NDUM-YAJI",
    "NGUROJE",
    "TITONG",
    "WARWAR"
    ],

    "TAKUM":  [
    "BETE",
    "CHANCHANJI",
    "DUTSE",
    "FETE",
    "GAHWETON",
    "BIKASHIBILA",
    "MANYA",
    "ROGO",
    "SHIBONG",
    "TIKARI",
    "YUKUBEN"
    ],

    "USSA":  [
    "BIKA",
    "FIKYU",
    "JENUWA",
    "KPAMBO",
    "KPAMBO PURI",
    "KWAMBAI",
    "KWESATI",
    "LISSAM I",
    "LISSAM II",
    "LUMBU",
    "RUFU"
    ],

    "WUKARI":  [
    "AKWANA",
    "AVYI",
    "BANTAJE",
    "CHONKU",
    "HOSPITAL",
    "JIBU",
    "KENTE",
    "PUJE",
    "RAFIN KADA",
    "TSOKUNDI"
    ],

    "YORRO":  [
    "BIKASSA I",
    "BIKASSA II",
    "NYAJA I",
    "NYAJA II",
    "PANTISAWA I",
    "PANTISAWA II",
    "PUPULE I",
    "PUPULE II",
    "PUPULE III",
    "SUMBU I",
    "SUMBU II"
    ],

    "ZING":  [
    "BITAKO",
    "BUBONG",
    "DINDING",
    "LAMMA",
    "MONKIN A",
    "MONKIN B",
    "YAKOKO",
    "ZING AI",
    "ZING AII",
    "ZING B"
    ],

    "BADE":  [
    "DAGONA",
    "GWIO-KURA",
    "KATUZU",
    "LAWAN AUDU/LAWAN AL - WALI",
    "LAWAN FANNAMI",
    "LAWAN MUSA",
    "SARKIN HAUSAWA",
    "TAGALI/SUGUM",
    "USUR/DAWAYO",
    "ZANGON MUSA/ZANGO UMARU"
    ],

    "BURSARI":  [
    "BAYAMARI",
    "DAMNAWA/JULURI",
    "DANANI",
    "DAPCHI",
    "GARUN DOLE / GARIN ALKALI",
    "GUBA",
    "GUJI / METALARI",
    "KALIYARI",
    "KURNAWA",
    "MASABA"
    ],

    "DAMATURU":  [
    "BINDIGARI/PAWARI",
    "DAMAKASU",
    "DAMATURU CENTRAL",
    "GAMBIR/MADURI",
    "KALALLAWA/GABAI",
    "KUKARETA/WARSALA",
    "MAISANDARI/WAZIRI IBRAHIM ESTATE",
    "MURFA KALAM",
    "NAYINAWA",
    "NJIWAJI/GWANGE",
    "SASAWA/KABARU"
    ],

    "FIKA":  [
    "DAYA/CHANA",
    "FIKA/ANZE",
    "GADAKA/SHEMBIRE",
    "GUDI / DOZI / GODO WOLI",
    "JANGA / BOZA / FA. SAWA / T. NANAI",
    "MUBI / FUSAMI / GARIN WAYO",
    "NGALDA/DUMBULWA",
    "SHOYE/GARIN ABA",
    "TURMI / MALURI",
    "ZANGAYA/MAZAWAUN"
    ],

    "FUNE":  [
    "ABAKIRE / NGENLSHENGELE / SHAMKA",
    "ALAGARNO",
    "BORNO KIJI/NGARHO/BEBBENDE",
    "DAMAGUM TOWN",
    "DAURA/BULANYIWA/DUBBUL/BAUWA",
    "DOGON KUKA/GISHIWARI/GUNUNU",
    "FUNE/NGELZARMA/MILBIYAR/LAWAN KALAM",
    "GABA TASHA/AIGADA/DUMBULWA",
    "GUDUGURKA/MARMAR I",
    "JAJERE/BANELLEWA/BABBARE",
    "KAYERI",
    "KOLLERE/KAFAJE",
    "MASHIO"
    ],

    "GEIDAM":  [
    "ASHEIKRI",
    "BALLE/GALLABA/MELERI",
    "DAMAKARWA/KUSUR",
    "DEJINA/FUKURTI",
    "FUTCHIMIRAM",
    "GUMSA",
    "HAUSARI",
    "KAWURI",
    "MA'ANNA/DAGAMBI",
    "SHAME KURA / DILAWA",
    "ZURGU NGILEWA / BORKO"
    ],

    "GUJBA":  [
    "BUNIGARI/LAWANTI",
    "BUNIYADI NORTH / SOUTH",
    "DADINGEL",
    "GONIRI",
    "GOTALA/GOTUMBA",
    "GUJBA",
    "MALLAM DUNARI",
    "MUTAI",
    "NGURBUWA",
    "WAGIR"
    ],

    "GULANI":  [
    "BARA",
    "BORNO KIJI/TETTEBA",
    "BULARAFA",
    "BUMSA",
    "DOKSHI",
    "GABAI",
    "GAGURE",
    "GARIN TUWO",
    "GULANI",
    "KUSHIMAGA",
    "NJIBULWA",
    "RUHU"
    ],

    "JAKUSKO":  [
    "BUDUWA / SAMINAKA",
    "DUMBARI",
    "GIDGID / BAYAM",
    "GOGARAM",
    "JABA",
    "JAKUSKO",
    "JAWUR/KATAMMA",
    "LAFIYA LOI-LOI",
    "MUGURAM",
    "ZABUDUM / DACHIA"
    ],

    "KARASAWA":  [
    "BUKARTI",
    "FAJIGANARI",
    "GARIN GAWO",
    "GASMA",
    "JAJI MAJI",
    "KARASUWA GALU",
    "KARAUSWA GARU GUNA",
    "WACHAKAL",
    "WARO",
    "YAJIRI"
    ],

    "MACHINA":  [
    "BOGO",
    "DAMAI",
    "DOLE",
    "FALIMARAM",
    "KOM-KOMMA",
    "KUKA-YASKU",
    "LAMISU",
    "MACHINA-KWARI",
    "MASKANDARE",
    "TAGANAMA"
    ],

    "NANGERE":  [
    "CHILARIYE",
    "DADISO / CHUKURIWA",
    "DAWASA/G.BABA",
    "DAZIGAU",
    "DEGUBI",
    "KUKURI/CHIROMARI",
    "LANGAWA / DARIN",
    "NANGERE",
    "PAKARAU KARE-KARE/ PAKARAU FULANI",
    "TIKAU",
    "WATINANI"
    ],

    "NGURU":  [
    "BULABULIN",
    "BULANGUWA",
    "DABULE",
    "DUMSAI/DOGON-KUKA",
    "GARBI/BAMBORI",
    "HAUSARI",
    "KANURI",
    "MAJA-KURA",
    "MIRBA-KABIR/MIRBA SAGIR",
    "NGLAIWA"
    ],

    "POTISKUM":  [
    "BARE-BARE/BAUYA/LALAI DUMBULWA",
    "BOLEWA 'A'",
    "BOLEWA 'B'",
    "DANCHUWA/BULA",
    "DOGO NINI",
    "DOGO TEBO",
    "HAUSAWA",
    "MAMUDO",
    "NGOJIN/ALARABA",
    "YERIMARAM/GARIN DAYE/BADEJO/NAHUTA"
    ],

    "TARMUWA":  [
    "BABANGIDA",
    "BARKAMI / BULTURI",
    "BIRIRI/CHUROKUSKO",
    "JUMBAM",
    "KOKA/SUNGUL",
    "KORIYEL",
    "LANTAIWA",
    "MAFA",
    "MANDADAWA",
    "SHEKAU"
    ],

    "YUNUSARI":  [
    "BULTUWA/MAR/YARO",
    "DARATOSHIA",
    "DEGALTURA/NGAMZAI",
    "DEKWA",
    "DILALA/KALGI",
    "MAIRARI",
    "MOZOGUN/KUJARI",
    "NGIRABO",
    "WADI/KAFIYA",
    "ZAJIBIRI / DUMBAL"
    ],

    "YUSUFARI":  [
    "ALANJIRORI",
    "GUMSHI",
    "GUYA",
    "JEBUWA",
    "KAJIMARAM/SUMBAR",
    "KASKA/TULOTULOWA",
    "KUMAGANNAM",
    "MAI-MALARI",
    "MAYORI",
    "YUSUFARI"
    ],

    "ANKA":  [
    "BAGEGA",
    "BARAYAR-ZAKI",
    "DAN GALADIMA",
    "GALADIMA",
    "MAGAJI",
    "MATSERI",
    "SABON BIRINI",
    "WARAMU",
    "WUYA",
    "YAR'SABAYA"
    ],

    "BAKURA":  [
    "BAKURA",
    "BIRNIN TUDU",
    "DAMRI",
    "DANKADU",
    "DAN MANAU",
    "YAR KUFOJI",
    "DAKKO",
    "NASARAWA",
    "RINI",
    "YAR GEDA"
    ],

    "BIRNIN MAGAJI":  [
    "BIRNIN MAGAJI",
    "DANFAMI SABON BIRINI",
    "GORA",
    "GUSAMI GARI",
    "GUSAMI HAYI",
    "KIYAWA",
    "MODOMAWA EAST",
    "MODOMAWA WEST",
    "NASARAWA GODAL EAST",
    "NASARAWA GODAL WEST"
    ],

    "BUKKUYUM":  [
    "ADABKA",
    "BUKKUYUM",
    "GWASHI",
    "KYARAM",
    "MASAMA",
    "NASARAWA",
    "RUWAN JEMA",
    "YASHI",
    "ZARUMMAI",
    "ZAUMA"
    ],

    "BUNGUDU":  [
    "BINGI NORTH",
    "BINGI SOUTH",
    "BUNGUDU",
    "FURFURI/KWAI-KWAI",
    "GADA / KARAKKAI",
    "NAHUCE",
    "KWATARKWASHI",
    "RAWAYYA/ BELA",
    "SAMAWA",
    "SANKALAWA",
    "TOFA"
    ],

    "GUMMI":  [
    "BARDOKI",
    "BIRNIN MAGAJI",
    "BIRNIN TUDU",
    "FALALE",
    "FELFELDU / GAMO",
    "GAYARI",
    "GYALANGE",
    "ILLELAR AWAL",
    "MAGAJIN GARI",
    "SHIYAR RAFI",
    "UBANDAWAKI"
    ],

    "GUSAU":  [
    "GALADIMA",
    "MADAWAKI",
    "MADA",
    "MAGAMI",
    "MAYANA",
    "RIJIYA",
    "RUWAN BORE",
    "SABON GARI",
    "TUDUN WADA",
    "WANKE",
    "WONAKA"
    ],

    "KAURA NAMODA":  [
    "BANGA",
    "DAN - ISA",
    "GABAKE",
    "GALADIMA DAN GALADIMA",
    "KAGARA",
    "KUNGURKI",
    "KURYA MADARO",
    "KYAM BARAWA",
    "SAKAJIKI",
    "S/BAURA/S/MAFARA",
    "YANKABA"
    ],

    "MARADUN":  [
    "BIRNIN KAYA / DOSARA",
    "DAMAGA / DAMAGIWA",
    "FARU / MAGAMI",
    "GORA",
    "GIDAN GOGA",
    "JANBAKO",
    "KAYA",
    "MARADUN NORTH",
    "MARADUN SOUTH",
    "TSIBIRI"
    ],

    "MARU":  [
    "BINDIN",
    "BINGI",
    "DAN GULBI",
    "DAN KURMI",
    "DAN SADAU",
    "KANOMA",
    "KUYAN BANA",
    "MARU",
    "MAYANCHI",
    "RUWAN DORAWA"
    ],

    "SHINKAFI":  [
    "BADARAWA",
    "BIRNIN YARO",
    "GALADI",
    "JANGERU",
    "KATURU",
    "KWARE",
    "KURYA",
    "SHINKAFI NORTH",
    "SHINKAFI SOUTH",
    "SHANAWA"
    ],

    "TALATA MAFARA":  [
    "GARBADU",
    "GWARAM",
    "JANGEBE",
    "KAGARA",
    "MORAI",
    "RUWAN BORE",
    "RUWAN GIZO",
    "SAUNA R/ GORA",
    "SHIYAR GALADIMA",
    "SHIYAR KAYAYE/MATUSGI",
    "TAKE TSABA/MAKERA"
    ],

    "TSAFE":  [
    "KWAREN GANUWA",
    "YAN KUZO 'B'",
    "YANDOTON DAJI",
    "YANKUZO 'A'",
    "DANJIBGA/KUNCHIN - KALGO",
    "DAUKI",
    "KETA/KIZARA",
    "TSAFE",
    "CHEDIYA",
    "YAN WAREN DAJI",
    "BILBIS"
    ],

    "ZURMI":  [
    "BOKO",
    "DAURAN / BIRNIN-TSABA",
    "DOLE",
    "GALADIMA/YANRUWA",
    "KANWA",
    "KWASHBAWA",
    "MASHEM",
    "KUTURU/MAYASA",
    "RUKUDAWA",
    "YAN BUKI/ DUTSI",
    "ZURMI"
    ]
  };
    
  /* return the list of local government areas in nigeria as a javascript object. */    
  return lgaBranchList; 

};
