
// REACT STANDARD LIBRARY CALLS
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import QRCode from 'qrcode';
import ReactToPrint from "react-to-print";
import '../../../../CSS/id_card_stylesheet.css';
// import '../CSS/id_card_stylesheet.css';
// Import the Component, you can call it whatever you like.
// import GoogleFontLoader from 'react-google-font-loader';



/* WEB APP CONFIG FILE. */
import { configuration } from '../../../../Config/Config';



/* IMPORT LIBRARY  */
import { getData, TRIM, __String_To_Chars_Splitter, EXPLODE, STRING_CHARS_COUNT, getCookie, mathRandom, ID_CARD_IMAGE_CONTROLLER, FIRST_FEW_CHARS_IN_STRING_FETCHER } from '../../../../Library/Library.js';


// Import image
// import ID_CARD_FRONT from './Images/FRONT.png';
// import ID_CARD_BACK from './Images/BACK.png';






export default function Print_Member_ID_cards() {

    // lets set the page title. 
    document.getElementById("title").innerHTML = "Print ID Card | "+configuration.COMPANY_NAME;

    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);
    // GET FROM URL THE ID OF THE MEMBER WE WANT TO GENERATE ID CARD FOR
    const { id } = useParams();
    // CREATE AN OBJECT OF REACT USEREF HOOK, TO BE USED IN LATER STAGE
    let componentRef = useRef();

    // USE THIS HOOK TO INVOKE WEBFLOWS SCRIPTS AND ANIMATIONS 
    useEffect(() => {
        const html = document.getElementsByTagName('html')[0];
        html.setAttribute('data-wf-page', '62ab23d8eae8f044a26113f2');
        html.setAttribute('data-wf-site', '62a356d924ac21c4e115b2a0');

        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2').init();
        document.dispatchEvent(new Event('readystatechange'))
    }, []);

    

    // STATE TO BE USED TO STORE DATA'S OF THE MEMBER, WE WANT TO PRINT ID-CARD FOR
    const [MEMBER_DATA, setMEMBER_DATA] = useState({ mbrfname: "", mbrsname: "", mbrno: "", mbr_STATE: "", mbr_LGA: "", mbr_BRANCH: "" });

    const imageUrl = configuration.MEMBER_BINARY_IMAGE_FETCHER_API+id;
    const [imgUrl, setImgUrl] = useState("/DATA/Spin-Preloader-"+mathRandom(1, 2)+".gif");

    // FUNCTION TO FETCH MEMBER IMAGE IN BINARY AND TRANSCODE INTO READABLE IMAGE FORMATE
    const getImg = async () => {
        const response = await fetch(imageUrl, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Accept': 'image/png',
                'Authorization': `Bearer ${session}`,
                'Content-Type': 'image/png'     // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            /*body: JSON.stringify(data)*/ // body data type must match "Content-Type" header
        }).catch(error => {
            history.push(configuration.LOGIN);
        });

        // ensure that we have an image data response and that fetch operation has not failed as a result of network failure before proceeding to this part
        if(response){
            const imageBlob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(imageBlob);
            reader.onloadend = () => {
            const base64data = reader.result;
            setImgUrl(base64data);
            };
        }
    };
    
    


    // USE THIS HOOK TO FETCH MEMBERS DETAILS AND INVOKE THE FUNCTION THAT WILL FETCH US MEMBER IMAGE
    useEffect(() => {
        // ONLY ALLOW API TO RUN IF THE "ALL_MEMBER_DATA" STATE IS EMPTY.
        if(TRIM(id) !== ""){
            // 
            const token = session;
            /* call the api function responsible for user password reset operation. */
            const url_1 = configuration.SORT_MEMBER_BY_ID_API+id;
            /* Call the "postData" function to help us handle submission. */
            getData(url_1, token).then(response => {
                // CONTROL IN HERE MEANS REQUEST WAS A SUCCESS, HENCE WE FETCH DATA AND SEND TO PARENT COMPONENT
                setMEMBER_DATA(response);
            }).catch(error => {
                history.push(configuration.LOGIN);
            });

            // LOAD IMAGE FROM BACKEND AS BINARY, PROCESS AND CONVERT IT TO IMAGE
            getImg();
        }
    }, []);


    // create state be used to store qr images temporal
    const [src, setSrc] = useState('');
    // extract the section of the member id that has numbers, then split those numbers into chars
    const CODEX = (MEMBER_DATA.mbrno) ? __String_To_Chars_Splitter(EXPLODE("-", MEMBER_DATA.mbrno)[2]) : ""; 
    // extract member id from api response only if id is in its correct format, else we generate new formated id
    const Member_ID = (CODEX.length == 8) ? CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7] : EXPLODE("-", MEMBER_DATA.mbrno)[2];
    // generate qr codes
    // QRCode.toDataURL("Fullname: "+(MEMBER_DATA.mbrfname+" "+MEMBER_DATA.mbrsname)+", Membership Number: "+Member_ID)
    // .then(url => {
    //     setSrc(url);
    // });
    QRCode.toDataURL(configuration.MEMBER_VERIFICATION_LINK+MEMBER_DATA.mbrno)
    .then(url => {
        setSrc(url);
    });
       

    return (
        <div className='dash_board_body'>

            <div className="table_bg" style={{ marginLeft: "auto", marginRight: "auto", marginTop: "2%", width: "1215px", minWidth: "1215px", 
                maxWidth: "1215px" }}>

                
                    {/* <a href="#" className="button_outline filled goldenrod id new w-button">Return</a> */}
                    {/* button to trigger printing of target component */}
                    <ReactToPrint
                    trigger={() => {
                        return ( 
                            <div className="div-block-35" style={{ marginBottom: "0%", marginTop: "0%" }}>
                                <h1></h1>
                                <a href="#" className="button_outline filled goldenrod id new w-button">Print ID Card</a> 
                            </div>
                        );
                    }}
                    content={() => componentRef}
                    />
                


                <h1 style={{ textAlign: "center" }}>PRINT ID CARD</h1>
                <ComponentToPrint ref={(el) => (componentRef = el)} MEMBER_DATA={MEMBER_DATA} IMAGE={imgUrl} src={src} />
                <ComponentNotToPrint MEMBER_DATA={MEMBER_DATA} IMAGE={imgUrl} src={src} />

            </div>

        </div>
    );

}








// EXPLODE
const ComponentNotToPrint = ({MEMBER_DATA, IMAGE, src}) => {
    // construct to ensure that, nothing is displayed when api response is yet to load data
    // const Location_ID = (MEMBER_DATA.mbr_STATE) ? (MEMBER_DATA.mbr_STATE+"/"+MEMBER_DATA.mbr_LGA+"/"+FIRST_FEW_CHARS_IN_STRING_FETCHER(MEMBER_DATA.mbr_BRANCH, 3)) : "";
    // const Location_ID = (MEMBER_DATA.mbr_STATE) ? ("FT/"+MEMBER_DATA.mbr_LGA+"/"+FIRST_FEW_CHARS_IN_STRING_FETCHER(MEMBER_DATA.mbr_BRANCH, 3)) : "";
    const UNITS_DATA = (MEMBER_DATA.unit) ? "/"+MEMBER_DATA.unit : "";
    const Location_ID = (MEMBER_DATA.mbr_STATE) ? (MEMBER_DATA.mbr_STATE+"/"+MEMBER_DATA.mbr_LGA+"/"+FIRST_FEW_CHARS_IN_STRING_FETCHER(MEMBER_DATA.mbr_BRANCH, 3)+UNITS_DATA) : "";
    // extract the section of the member id that has numbers, then split those numbers into chars
    const CODEX = (MEMBER_DATA.mbrno) ? __String_To_Chars_Splitter(EXPLODE("-", MEMBER_DATA.mbrno)[2]) : ""; 
    // extract member id from api response only if id is in its correct format, else we generate new formated id
    // const Member_ID = (CODEX.length == 8) ? CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7] : ((Math.floor(1000 + Math.random() * 9000)) +" "+ (Math.floor(1000 + Math.random() * 9000)));
    const Member_ID = CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7];
    // CONSTRUCT THE FULL NAME OF THE MEMBER
    const MEMBER_NAME = (MEMBER_DATA.mbrfname+" "+MEMBER_DATA.mbrsname);
    // alert(MEMBER_DATA.mbrno);
    const CODEC = ID_CARD_IMAGE_CONTROLLER(configuration.APP_DATA_RESOURCE, EXPLODE("-", MEMBER_DATA.mbrno)[0]);
    const FRONT = CODEC[0];
    const BACK = CODEC[1];

    return (
        <>
            {/* Use it! */}
            {/* <GoogleFontLoader
            fonts={[
                {
                font: 'Roboto',
                weights: [100, 100],
                },
                {
                font: 'Roboto Mono',
                weights: [400, 700],
                },
            ]}
            subsets={['cyrillic-ext', 'greek']}
            /> */}

            <div className="example" style={{ display: "block" }}>

                {/* <img className="background-image-1" src={ID_CARD_FRONT} /> */}
                <img className="background-image-1" src={FRONT} />

                <div className="overlay">
                    <div className="image" style={{ top: "128.3px", left: "135px" }}>
                        <img id="newImg" src={IMAGE} alt=""
                        loading="lazy" width="360" height="307" className="task_nav_button_icon" 
                        style={{ filter: "grayscale(100%)", borderRadius: "25px", border: "solid 6px #333" }} /> 
                    </div>

                    <USER_NAME_RESOLUTOR MEMBER_NAME={MEMBER_NAME} />

                    <h2 className="location_ID" style={{ top: "627px", left: "179px", transform: "translate(-21%, -50%)", 
                        fontFamily: "'Montserrat', Times, serif", fontSize: "50px", fontWeight: "500", 
                        letterSpacing: "4px", textAlign: "center", borderRadius: "15px", border: "solid 9px #333", padding: "17px 12px 17px 12px" }}>
                        {Location_ID}
                    </h2>

                    <h2 className="membership_ID" style={{ top: "627px", left: "1580px", letterSpacing: "12px", transform: "translate(-216%, -50%)", 
                        fontFamily: "'Montserrat', Times, serif", fontSize: "50px", width: "37%", textAlign: "center", fontWeight: "500", 
                        borderRadius: "15px", border: "solid 9px #333", padding: "17px 12px 17px 12px" }}>
                        {Member_ID}
                    </h2>
                </div>

                {/* <img className="background-image-2" src={ID_CARD_BACK} /> */}
                <img className="background-image-2" src={BACK} />

                <div className="overlay2">   
                    <div className="image2" style={{ top: "1305px", left: "880px" }}>
                        <img id="newImg2" src={src} alt="Passport" loading="lazy" width="238" height="220" className="task_nav_button_icon" /> 
                    </div>
                </div>

            </div> 
        </> 
    );
    
};











const USER_NAME_RESOLUTOR = ({MEMBER_NAME}) => {
    const data = STRING_CHARS_COUNT(MEMBER_NAME);
    if(data <= 16){
        return( <h3 className="name" style={{ fontFamily: "'Montserrat', Times, serif", fontWeight: "610", 
        fontSize: "30px", letterSpacing: "4px", top: "488px", left: "135px", textAlign: "center", 
        transform: "translate(3%, -50%)" }}>{MEMBER_NAME}</h3> );
    }else if((data > 16) && (data <= 20)){
        return( <h3 className="name" style={{ fontFamily: "'Montserrat', Times, serif", fontWeight: "610", 
        fontSize: "30px", letterSpacing: "4px", top: "488px", left: "127px", textAlign: "center", 
        transform: "translate(-5%, -50%)" }}>{MEMBER_NAME}</h3> );
    }else if((data > 20) && (data <= 28)){
        return( <h3 className="name" style={{ fontFamily: "'Montserrat', Times, serif", fontWeight: "610", 
        fontSize: "30px", letterSpacing: "3px", top: "488px", left: "97px", textAlign: "center", 
        transform: "translate(-4%, -50%)" }}>{MEMBER_NAME}</h3> );
    }else{
        return( <h3 className="name" style={{ fontFamily: "'Montserrat', Times, serif", fontWeight: "610", 
        fontSize: "30px", letterSpacing: "3px", top: "488px", left: "57px", textAlign: "center", 
        transform: "translate(-0%, -50%)" }}>{MEMBER_NAME}</h3> );
    }
}








const USER_NAME_RESOLUTOR_2 = ({MEMBER_NAME}) => {
    const data = STRING_CHARS_COUNT(MEMBER_NAME);
    if(data <= 16){
        return( <h6 className="name" style={{ fontFamily: "'Montserrat', Times, serif", fontWeight: "410", top: "123px", left: "38px", 
        textAlign: "center", transform: "translate(-0%, -50%)", fontSize: "8px", color: "#111" }}>{MEMBER_NAME}</h6> );
    }else if((data > 16) && (data <= 20)){
        return( <h6 className="name" style={{ fontFamily: "'Montserrat', Times, serif", fontWeight: "410", top: "123px", left: "32px", 
        textAlign: "center", transform: "translate(-0%, -50%)", fontSize: "8px", color: "#111" }}>{MEMBER_NAME}</h6> );
    }else{
        return( <h6 className="name" style={{ fontFamily: "'Montserrat', Times, serif", fontWeight: "410", top: "123px", left: "18px", 
        textAlign: "center", transform: "translate(-0%, -50%)", fontSize: "8px", color: "#111" }}>{MEMBER_NAME}</h6> );
    }
}






class ComponentToPrint extends React.Component {

    render() {

        const CODEC = ID_CARD_IMAGE_CONTROLLER(configuration.APP_DATA_RESOURCE, this.props.MEMBER_DATA.mbr_STATE);
        const FRONT = CODEC[0];
        const BACK = CODEC[1];

        // construct to ensure that, nothing is displayed when api response is yet to load data
        // const Location_ID = (this.props.MEMBER_DATA.mbr_STATE) ? (this.props.MEMBER_DATA.mbr_STATE+"/"+this.props.MEMBER_DATA.mbr_LGA)+"/"+FIRST_FEW_CHARS_IN_STRING_FETCHER(this.props.MEMBER_DATA.mbr_BRANCH, 3) : "";
        // const Location_ID = (this.props.MEMBER_DATA.mbr_STATE) ? ("FT/"+this.props.MEMBER_DATA.mbr_LGA)+"/"+FIRST_FEW_CHARS_IN_STRING_FETCHER(this.props.MEMBER_DATA.mbr_BRANCH, 3) : "";
        const UNITS_DATA = (this.props.MEMBER_DATA.unit) ? "/"+this.props.MEMBER_DATA.unit : "";
        const Location_ID = (this.props.MEMBER_DATA.mbr_STATE) ? (this.props.MEMBER_DATA.mbr_STATE+"/"+this.props.MEMBER_DATA.mbr_LGA)+"/"+FIRST_FEW_CHARS_IN_STRING_FETCHER(this.props.MEMBER_DATA.mbr_BRANCH, 3)+UNITS_DATA : "";
        // extract the section of the member id that has numbers, then split those numbers into chars
        const CODEX = (this.props.MEMBER_DATA.mbrno) ? __String_To_Chars_Splitter(EXPLODE("-", this.props.MEMBER_DATA.mbrno)[2]) : ""; 
        // extract member id from api response only if id is in its correct format, else we generate new formated id
        // const Member_ID = (CODEX.length == 8) ? CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7] : ((Math.floor(1000 + Math.random() * 9000)) +" "+ (Math.floor(1000 + Math.random() * 9000)));
        const Member_ID = CODEX[0]+CODEX[1]+CODEX[2]+CODEX[3]+" "+CODEX[4]+CODEX[5]+CODEX[6]+CODEX[7];
        // CONSTRUCT THE FULL NAME OF THE MEMBER
        const MEMBER_NAME = (this.props.MEMBER_DATA.mbrfname+" "+this.props.MEMBER_DATA.mbrsname);

        return (

            <div className="example">

                {/* <img className="background-image-1" width="350" height="203" src={ID_CARD_FRONT} /> */}
                <img className="background-image-1" width="350" height="203" src={FRONT} />

                <div className="overlay">
                    <div className="image" style={{ left: "37px" }}>
                        <img id="newImg" src={this.props.IMAGE} alt=""
                        loading="lazy" width="99" height="80" className="task_nav_button_icon" 
                        style={{ filter: "grayscale(100%)", borderRadius: "7px", 
                        border: "solid 1px #111" }} /> 
                    </div>

                    <USER_NAME_RESOLUTOR_2 MEMBER_NAME={MEMBER_NAME} />
                    {/* <h6 className="name" style={{ left: "35px" }}>
                        {(this.props.MEMBER_DATA.mbrfname+" "+this.props.MEMBER_DATA.mbrsname)}
                    </h6> */}
                    {/* 55.5px */}
                    <h6 className="location_ID" style={{ left: "47.5px", top: "150px", 
                        fontFamily: "'Montserrat', Times, serif", fontSize: "11.5px", 
                        fontWeight: "500", letterSpacing: "3px", textAlign: "center", 
                        color: "#111", borderRadius: "4px", border: "solid 1.5px #333", 
                        padding: "3px 3px 3px 3px" }}>
                        {Location_ID}
                    </h6>

                    <h6 className="membership_ID" style={{ left: "253.7px", top: "150px", 
                        fontFamily: "'Montserrat', Times, serif", fontSize: "11.5px", 
                        letterSpacing: "5px", width: "37%", textAlign: "center", 
                        transform: "translate(-59%, -0%)", fontWeight: "500", 
                        color: "#111", borderRadius: "4px", border: "solid 1.5px #333", 
                        padding: "3px 3px 3px 3px" }}>
                        {Member_ID}
                    </h6>
                </div>


                {/* <img className="background-image-2" width="350" height="203" src={ID_CARD_BACK} /> */}
                <img className="background-image-2" width="350" height="203" src={BACK} />
                {/* 366px */}
                <div className="overlay2">
                    <div className="image2" style={{ top: "366px" }}>
                        <img id="newImg2" src={this.props.src} alt="Passport" loading="lazy" width="63" height="58" className="task_nav_button_icon" /> 
                    </div>
                </div>

            </div>   
        );
    }
};








