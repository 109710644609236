import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';




/* IMPORT LIBRARY  */
import { Delete_cookie } from '../Library/Library.js';






export default function SideBar({DEFAULT_MENU, DEFAULT_MENU_FEEDBACK, DEFAULT_SUB_MENU}){

    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();  



    const LOGOUT = () => {       
        /* Delet session cookie. */
        Delete_cookie("token");
        /* Redirect user back to homepage. */
        history.push(configuration.LOGIN);
    }

        
    // STATE TO BE USED TO CONTROL THE MENU CLICKED AND EFFECT THE RESPECTIVE CHANGES ITS BRINGS TO OTHER PARTS
    const [MenuRegistra, setMenuRegistra] = useState(DEFAULT_MENU);
    // const [SubMenuRegistra, setSubMenuRegistra] = useState(DEFAULT_SUB_MENU);

    const redirectToMap = () => {
        // window.location.replace("https://www.example.com");
        window.open('https://www.example.com', '_blank');
        // window.open('file:///C:/Users/User/Desktop/TRY/index.html', '_blank');
    };

    // WE SET UP THIS COMPONENT ONLOAD FUNCTION TO ENSURE THAT, ONCE THERE IS A CHANGE IN THE TARGET STATE, THIS COMPONENT RELOADS AND HENCE GETS THE
    // LATEST AND MOST ACCURATE STATE DATA FROM PARENT COMPONENT.
    // useEffect(() => {
    //     setMenuRegistra(DEFAULT_MENU);
    //     setSubMenuRegistra(DEFAULT_SUB_MENU);
    // }, [DEFAULT_MENU, DEFAULT_SUB_MENU]);
    useEffect(() => setMenuRegistra(DEFAULT_MENU), [DEFAULT_MENU]);
    
    return (

        <div className={"new-side-menu"}>
            <div className="task_nav">
                <Link to={(configuration.DASHBOARD)}>
                    <img src={configuration.APP_DATA_RESOURCE+"images/full-logo.svg"} loading="lazy" width="128" 
                        alt={configuration.COMPANY_NAME+" Official Logo"} className="image-4" />
                </Link>
                <MENU_ITEM_REVOLVER MENU_INDICATOR={MenuRegistra} SUB_MENU_INDICATOR={DEFAULT_SUB_MENU} />
            </div> 
            
            <div className="module_nav">
                
                <Link to={(configuration.DASHBOARD)} onClick={() => { setMenuRegistra("MAIN_DASHBOARD");   DEFAULT_MENU_FEEDBACK("MAIN_DASHBOARD"); } } 
                    className={"button_menu white w-button "+((MenuRegistra === "MAIN_DASHBOARD") ? "selected w--current" : "")}>Dashboard</Link>

                <Link to={(configuration.AGENT_RECORDS_LINK)} onClick={() => { setMenuRegistra("AGENT_REG");   DEFAULT_MENU_FEEDBACK("AGENT_REG"); } } 
                    className={"button_menu white w-button "+((MenuRegistra === "AGENT_REG") ? "selected w--current" : "")}>Registered Agents</Link>

                <Link to={(configuration.ACTIVE_MEMBERSHIPS_LINK)} onClick={() => { setMenuRegistra("MEMBER_RECORDS");   DEFAULT_MENU_FEEDBACK("MEMBER_RECORDS"); } } 
                    className={"button_menu white w-button "+((MenuRegistra === "MEMBER_RECORDS") ? "selected w--current" : "")}>Membership Data</Link>
                
                <Link to={(configuration.CUSTOMER_CARE_ACTIVE_MEMBERSHIPS_LINK)} onClick={() => { setMenuRegistra("CUSTOMER_CARE");   DEFAULT_MENU_FEEDBACK("CUSTOMER_CARE"); } } 
                    className={"button_menu white w-button "+((MenuRegistra === "CUSTOMER_CARE") ? "selected w--current" : "")}>Customer Care</Link>

                <Link to={configuration.OUTSTANDING_ID_CARDS} onClick={() => { setMenuRegistra("ID_CARD");   DEFAULT_MENU_FEEDBACK("ID_CARD"); } } aria-current="page" 
                    className={"button_menu white w-button "+((MenuRegistra === "ID_CARD") ? "selected w--current" : "")}>ID Card Issuance</Link>

                <Link to={(configuration.GWS_DASHBOARD_LINK)} onClick={() => { setMenuRegistra("GROUP_WELFARE");   DEFAULT_MENU_FEEDBACK("GROUP_WELFARE"); } } 
                    className={"button_menu white w-button "+((MenuRegistra === "GROUP_WELFARE") ? "selected w--current" : "")}>Group Welfare Scheme</Link>

                <Link to="#" onClick={() => { setMenuRegistra("H.M.O");   DEFAULT_MENU_FEEDBACK("H.M.O"); } } 
                    className={"button_menu white w-button "+((MenuRegistra === "H.M.O") ? "selected w--current" : "")}>Vehicle Insurance</Link>
                
                <Link to="#" onClick={() => { setMenuRegistra("H.M.O");   DEFAULT_MENU_FEEDBACK("H.M.O"); } } 
                    className={"button_menu white w-button "+((MenuRegistra === "H.M.O") ? "selected w--current" : "")}>H.M.O</Link>

                <Link to="#" onClick={() => { setMenuRegistra("FINANCE");   DEFAULT_MENU_FEEDBACK("FINANCE"); } } 
                    className={"button_menu white w-button "+((MenuRegistra === "FINANCE") ? "selected w--current" : "")}>Finance</Link>

                <Link to="#" onClick={() => { setMenuRegistra("H.M.O");   DEFAULT_MENU_FEEDBACK("H.M.O"); } } 
                    className={"button_menu white w-button "+((MenuRegistra === "H.M.O") ? "selected w--current" : "")}>Human Resources</Link>

                <Link to="#" onClick={() => { setMenuRegistra("H.M.O");   DEFAULT_MENU_FEEDBACK("H.M.O"); } } 
                    className={"button_menu white w-button "+((MenuRegistra === "H.M.O") ? "selected w--current" : "")}>Business Intelligence</Link>
                
                <Link to="#" onClick={() => { setMenuRegistra("H.M.O");   DEFAULT_MENU_FEEDBACK("H.M.O"); } } 
                    className={"button_menu white w-button "+((MenuRegistra === "H.M.O") ? "selected w--current" : "")}>General Reporting</Link>

                {/* <Link to={(configuration.TRACKING)} onClick={() => { setMenuRegistra("TRACKING");   DEFAULT_MENU_FEEDBACK("TRACKING"); } } 
                    className={"button_menu white w-button "+((MenuRegistra === "TRACKING") ? "selected w--current" : "")}>Tracking</Link> */}
                <a target="_blanck" onClick={redirectToMap} 
                    className={"button_menu white w-button "+((MenuRegistra === "TRACKING") ? "selected w--current" : "")}>Tracking</a>

                <Link to="#" onClick={() => { setMenuRegistra("ADMIN");   DEFAULT_MENU_FEEDBACK("ADMIN"); } } 
                    className={"button_menu white w-button "+((MenuRegistra === "ADMIN") ? "selected w--current" : "")}>System Administration</Link>

                {/* <Link to="#" onClick={() => alert("Coming soon!")} className="button_menu white w-button">Tracking</Link> */}

                <Link to="#" onClick={() => LOGOUT()} className="button_menu white w-button">Sign out</Link>

            </div>

            {/* <div className="logout_nav">
                <Link to={configuration.LOGIN} className="logout w-inline-block">
                    <img src={configuration.APP_DATA_RESOURCE+"images/logout-logo.svg"} loading="lazy" alt="" className="image-6" />
                    <div className="text-block-3">Sign-Out</div>
                </Link>
                <Link onClick={LOGOUT} className="logout w-inline-block">
                    <img src={configuration.APP_DATA_RESOURCE+"images/logout-logo.svg"} loading="lazy" alt="" className="image-6" />
                    <div className="text-block-3">Sign-Out</div>
                </Link>
            </div> */}
        </div>

    );

}












// FUNCTION TO ROTATE BETWEEN MENU-ITEMS DEPENDING THE MENU USER HAS CLICKED.
const MENU_ITEM_REVOLVER = ({MENU_INDICATOR, SUB_MENU_INDICATOR}) => {
    // HERE WE BEGIN OUR MENU-ITEM DETECTION CLAUSE.
    if(MENU_INDICATOR === "MAIN_DASHBOARD"){
        return ( 
            <PERSONNEL_STATISTICS DEFAULT_SUB_MENU={SUB_MENU_INDICATOR} />
        );
    }else if(MENU_INDICATOR === "ID_CARD"){
        return ( 
            <ID_CARD_MENU_ITEMS DEFAULT_SUB_MENU={SUB_MENU_INDICATOR} />
        );
    }else if(MENU_INDICATOR === "FINANCE"){
        return ( 
            <FINANCE_MENU_ITEMS DEFAULT_SUB_MENU={SUB_MENU_INDICATOR} />
        );
    }else if(MENU_INDICATOR === "MEMBER_RECORDS"){
        return ( 
            <MEMBER_RECORDS_MENU_ITEMS DEFAULT_SUB_MENU={SUB_MENU_INDICATOR} />
        );
    }else if(MENU_INDICATOR === "CUSTOMER_CARE"){
        return ( 
            <CUSTOMER_CARE_MENU_ITEMS DEFAULT_SUB_MENU={SUB_MENU_INDICATOR} />
        );
    }else if(MENU_INDICATOR === "AGENT_REG"){
        return ( 
            <AGENT_REGISTRATION_MENU_ITEMS DEFAULT_SUB_MENU={SUB_MENU_INDICATOR} />
        );
    }else if(MENU_INDICATOR === "GROUP_WELFARE"){
        return ( 
            <GROUP_WELFARE_MENU_ITEMS DEFAULT_SUB_MENU={SUB_MENU_INDICATOR} />
        );
    }else if(MENU_INDICATOR === "H.M.O"){
        return ( 
            <H_M_O_MENU_ITEMS DEFAULT_SUB_MENU={SUB_MENU_INDICATOR} />
        );
    }else if(MENU_INDICATOR === "TRACKING"){
        return ( 
            <ADDRESS_TRACKING DEFAULT_SUB_MENU={SUB_MENU_INDICATOR} />
        );
    }else if(MENU_INDICATOR === "ADMIN"){
        return ( 
            <ADMIN_MENU_ITEMS DEFAULT_SUB_MENU={SUB_MENU_INDICATOR} />
        );
    }else{
        return null;
    }
};












// COMPONENT TO DISPLAY MENU ITEMS OF MEMBER ADDRESS-TRACKING MENU
const ADDRESS_TRACKING = ({DEFAULT_SUB_MENU}) => {

    const [ADDRESS_TRACKING, setADDRESS_TRACKING] = useState(" w--current");

    const ADDRESS_TRACKING_onClick = () => {    setADDRESS_TRACKING(" w--current");     };

    // we use this "useEffect" hook to checkout with the first menu in the sub-menu list, to ensure its highlighted when it needs to be
    useEffect(() => setADDRESS_TRACKING( (DEFAULT_SUB_MENU === "ADDRESS_TRACKING") ? " w--current" : " inactive" ), [DEFAULT_SUB_MENU]);

    return (
        <>

            <Link to={(configuration.TRACKING)} onClick={ADDRESS_TRACKING_onClick} aria-current="page" 
                className={"task_nav_button w-inline-block"+ADDRESS_TRACKING}>
                <img src={configuration.APP_DATA_RESOURCE+"images/button-icon-1.svg"} loading="lazy" width="18" className="task_nav_button_icon" />
                {/* <div className="text-block">Personnel Statistics</div> */}
                <div className="text-block">Address Tracking</div>
            </Link>
            
        </>      
    );
};













// COMPONENT TO DISPLAY MENU ITEMS OF THE ID-CARD MENU
const PERSONNEL_STATISTICS = ({DEFAULT_SUB_MENU}) => {

    const [PERSONNEL_STATISTICS, setPERSONNEL_STATISTICS] = useState(" w--current");

    const PERSONNEL_STATISTICS_onClick = () => {    setPERSONNEL_STATISTICS(" w--current");     };

    // we use this "useEffect" hook to checkout with the first menu in the sub-menu list, to ensure its highlighted when it needs to be
    useEffect(() => setPERSONNEL_STATISTICS( (DEFAULT_SUB_MENU === "PERSONNEL_STATISTICS") ? " w--current" : " inactive" ), [DEFAULT_SUB_MENU]);

    return (
        <>

            <Link to={(configuration.DASHBOARD)} onClick={PERSONNEL_STATISTICS_onClick} aria-current="page" 
                className={"task_nav_button w-inline-block"+PERSONNEL_STATISTICS}>
                <img src={configuration.APP_DATA_RESOURCE+"images/button-icon-1.svg"} loading="lazy" width="18" className="task_nav_button_icon" />
                {/* <div className="text-block">Personnel Statistics</div> */}
                <div className="text-block">Dashboard</div>
            </Link>
            
        </>      
    );
};










// COMPONENT TO DISPLAY MENU ITEMS OF THE ID-CARD MENU
const ID_CARD_MENU_ITEMS = ({DEFAULT_SUB_MENU}) => {

    const [OUTSTANDING_ID_CARDS, setOUTSTANDING_ID_CARDS] = useState(" w--current");
    const [ISSUED_ID_CARDS, setISSUED_ID_CARDS] = useState( (DEFAULT_SUB_MENU === "ISSUED_ID_CARDS") ? " w--current" : " inactive" ); 
    const [REG_STAFF, setREG_STAFF] = useState( (DEFAULT_SUB_MENU === "REG_STAFF") ? " w--current" : " inactive" ); 
    const [REG_AGENT, setREG_AGENT] = useState( (DEFAULT_SUB_MENU === "REG_AGENT") ? " w--current" : " inactive" );

    const OUTSTANDING_ID_CARDS_onClick = () => {    setOUTSTANDING_ID_CARDS(" w--current");    setREG_STAFF(" inactive");    setISSUED_ID_CARDS(" inactive");    setREG_AGENT(" inactive");    };
    const REG_STAFF_onClick = () => {    setOUTSTANDING_ID_CARDS(" inactive");    setREG_STAFF(" w--current");    setISSUED_ID_CARDS(" inactive");    setREG_AGENT(" inactive");    };
    const ISSUED_ID_CARDS_onClick = () => {    setOUTSTANDING_ID_CARDS(" inactive");    setREG_STAFF(" inactive");    setISSUED_ID_CARDS(" w--current");    setREG_AGENT(" inactive");    };
    const REG_AGENT_onClick = () => {    setOUTSTANDING_ID_CARDS(" inactive");    setREG_STAFF(" inactive");    setISSUED_ID_CARDS(" inactive");    setREG_AGENT(" w--current");    };

    // we use this "useEffect" hook to checkout with the first menu in the sub-menu list, to ensure its highlighted when it needs to be
    useEffect(() => setOUTSTANDING_ID_CARDS( (DEFAULT_SUB_MENU === "OUTSTANDING_ID_CARDS") ? " w--current" : " inactive" ), [DEFAULT_SUB_MENU]);

    return (
        <>

            <Link to={(configuration.OUTSTANDING_ID_CARDS)} onClick={OUTSTANDING_ID_CARDS_onClick} aria-current="page" 
                className={"task_nav_button w-inline-block"+OUTSTANDING_ID_CARDS}>
                <img src={configuration.APP_DATA_RESOURCE+"images/button-icon-1.svg"} loading="lazy" width="18" className="task_nav_button_icon" />
                <div className="text-block">Outstanding ID Cards</div>
            </Link>

            <Link to={(configuration.ID_CARD_FOR_MEMBERS)} onClick={ISSUED_ID_CARDS_onClick} className={"task_nav_button w-inline-block"+ISSUED_ID_CARDS}>
                <img src={configuration.APP_DATA_RESOURCE+"images/reg-agent-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Issued ID Cards</div>
            </Link>

            <Link to={(configuration.ID_CARD_FOR_AGENT)} onClick={REG_AGENT_onClick} className={"task_nav_button w-inline-block"+REG_AGENT}>
                <img src={configuration.APP_DATA_RESOURCE+"images/reg-agent-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Data Capture Agents</div>
            </Link>

            <Link to={(configuration.ID_CARD_FOR_STAFF)} onClick={REG_STAFF_onClick} className={"task_nav_button w-inline-block"+REG_STAFF}>
                <img src={configuration.APP_DATA_RESOURCE+"images/reg-agent-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Kalyaan Staff</div>
            </Link>
            
        </>      
    );
};










// COMPONENT TO DISPLAY MENU ITEMS OF THE ADMIN MENU
const ADMIN_MENU_ITEMS = ({DEFAULT_SUB_MENU}) => {
    return (
        <>

            <Link to="#" className="task_nav_button w-inline-block">
                <img src={configuration.APP_DATA_RESOURCE+"images/button-icon-1.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Dashboard</div>
            </Link>
            <Link to="#" className="task_nav_button inactive w-inline-block">
                <img src={configuration.APP_DATA_RESOURCE+"images/button-icon-2.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Registered Staff</div>
            </Link>
            <Link to="#" className="task_nav_button inactive w-inline-block">
                <img src={configuration.APP_DATA_RESOURCE+"images/button-icon-4.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Departments</div>
            </Link>
            
        </>      
    );
};










// COMPONENT TO DISPLAY MENU ITEMS OF THE ADMIN MENU
const FINANCE_MENU_ITEMS = ({DEFAULT_SUB_MENU}) => {
    return (
        <>

            <Link to="#" aria-current="page" className="task_nav_button w-inline-block w--current">
                <img src={configuration.APP_DATA_RESOURCE+"images/button-icon-1.svg"} loading="lazy" width="18" className="task_nav_button_icon" />
                <div className="text-block">Dashboard</div>
            </Link>

            <Link to="#" className="task_nav_button inactive w-inline-block">
                <img src={configuration.APP_DATA_RESOURCE+"images/expense-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Expenses</div>
            </Link>

            <Link to="#" className="task_nav_button inactive w-inline-block">
                <img src={configuration.APP_DATA_RESOURCE+"images/revenue-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Revenue</div>
            </Link>

            <Link to="#" className="task_nav_button inactive w-inline-block">
                <img src={configuration.APP_DATA_RESOURCE+"images/list-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Staff Salary</div>
            </Link>

            <Link to="#" className="task_nav_button inactive w-inline-block">
                <img src={configuration.APP_DATA_RESOURCE+"images/list-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Members Subs</div>
            </Link>
            
        </>      
    );
};











// COMPONENT TO DISPLAY MENU ITEMS OF THE membership MENU
const MEMBER_RECORDS_MENU_ITEMS = ({DEFAULT_SUB_MENU}) => {

    const [ACTIVE_MEMBERS, setACTIVE_MEMBERS] = useState(" w--current");
    const [SUSPENDED_MEMBERS, setSUSPENDED_MEMBERS] = useState( (DEFAULT_SUB_MENU === "SUSPENDED_MEMBERS_LIST") ? " w--current" : " inactive" ); 
    const [EXPELLED_MEMBERS, setEXPELLED_MEMBERS] = useState( (DEFAULT_SUB_MENU === "EXPELLED_MEMBERS_LIST") ? " w--current" : " inactive" ); 
    const [DECEASED_MEMBERS, setDECEASED_MEMBERS] = useState( (DEFAULT_SUB_MENU === "DECEASED_MEMBERS_LIST") ? " w--current" : " inactive" );

    const ACTIVE_MEMBERS_onClick = () => {    setACTIVE_MEMBERS(" w--current");    setEXPELLED_MEMBERS(" inactive");    setSUSPENDED_MEMBERS(" inactive");    setDECEASED_MEMBERS(" inactive");    };
    const EXPELLED_MEMBERS_onClick = () => {    setACTIVE_MEMBERS(" inactive");    setEXPELLED_MEMBERS(" w--current");    setSUSPENDED_MEMBERS(" inactive");    setDECEASED_MEMBERS(" inactive");    };
    const SUSPENDED_MEMBERS_onClick = () => {    setACTIVE_MEMBERS(" inactive");    setEXPELLED_MEMBERS(" inactive");    setSUSPENDED_MEMBERS(" w--current");    setDECEASED_MEMBERS(" inactive");    };
    const DECEASED_MEMBERS_onClick = () => {    setACTIVE_MEMBERS(" inactive");    setEXPELLED_MEMBERS(" inactive");    setSUSPENDED_MEMBERS(" inactive");    setDECEASED_MEMBERS(" w--current");    };

    // we use this "useEffect" hook to checkout with the first menu in the sub-menu list, to ensure its highlighted when it needs to be
    useEffect(() => setACTIVE_MEMBERS( (DEFAULT_SUB_MENU === "ACTIVE_MEMBERS_LIST") ? " w--current" : " inactive" ), [DEFAULT_SUB_MENU]);

    return (
        <>

            <Link to={(configuration.ACTIVE_MEMBERSHIPS_LINK)} onClick={ACTIVE_MEMBERS_onClick} aria-current="page" 
                className={"task_nav_button w-inline-block"+ACTIVE_MEMBERS}>
                <img src={configuration.APP_DATA_RESOURCE+"images/button-icon-1.svg"} loading="lazy" width="18" className="task_nav_button_icon" />
                <div className="text-block">Active</div>
            </Link>

            <Link to={(configuration.SUSPENDED_MEMBERSHIPS_LINK)} onClick={SUSPENDED_MEMBERS_onClick} className={"task_nav_button w-inline-block"+SUSPENDED_MEMBERS}>
                <img src={configuration.APP_DATA_RESOURCE+"images/reg-agent-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Suspended</div>
            </Link>

            <Link to={(configuration.EXPELLED_MEMBERSHIPS_LINK)} onClick={DECEASED_MEMBERS_onClick} className={"task_nav_button w-inline-block"+DECEASED_MEMBERS}>
                <img src={configuration.APP_DATA_RESOURCE+"images/reg-agent-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Expelled</div>
            </Link>

            <Link to={(configuration.DECEASED_MEMBERSHIPS_LINK)} onClick={EXPELLED_MEMBERS_onClick} className={"task_nav_button w-inline-block"+EXPELLED_MEMBERS}>
                <img src={configuration.APP_DATA_RESOURCE+"images/reg-agent-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Deceased</div>
            </Link>
            
        </>      
    );
};










// COMPONENT TO DISPLAY MENU ITEMS OF THE membership MENU
const CUSTOMER_CARE_MENU_ITEMS = ({DEFAULT_SUB_MENU}) => {

    const [ACTIVE_MEMBERS, setACTIVE_MEMBERS] = useState(" w--current");

    const ACTIVE_MEMBERS_onClick = () => {    setACTIVE_MEMBERS(" w--current");    };

    // we use this "useEffect" hook to checkout with the first menu in the sub-menu list, to ensure its highlighted when it needs to be
    useEffect(() => setACTIVE_MEMBERS( (DEFAULT_SUB_MENU === "CUSTOMER_CARE_MEMBERS_LIST") ? " w--current" : " inactive" ), [DEFAULT_SUB_MENU]);

    return (
        <>

            <Link to={(configuration.CUSTOMER_CARE_ACTIVE_MEMBERSHIPS_LINK)} onClick={ACTIVE_MEMBERS_onClick} aria-current="page" 
                className={"task_nav_button w-inline-block"+ACTIVE_MEMBERS}>
                <img src={configuration.APP_DATA_RESOURCE+"images/button-icon-1.svg"} loading="lazy" width="18" className="task_nav_button_icon" />
                <div className="text-block">Active Members</div>
            </Link>
            
        </>      
    );
};









// COMPONENT TO DISPLAY MENU ITEMS OF THE AGENT REGISTRATION MENU
const AGENT_REGISTRATION_MENU_ITEMS = ({DEFAULT_SUB_MENU}) => {

    const [AGENT_LIST, setAGENT_LIST] = useState(" w--current");
    const [REGISTER_AGENT, setREGISTER_AGENT] = useState( (DEFAULT_SUB_MENU === "REGISTER_AGENT") ? " w--current" : " inactive" ); 

    const AGENT_LIST_onClick = () => {             setAGENT_LIST(" w--current");             setREGISTER_AGENT(" inactive");         };
    const REGISTER_AGENT_onClick = () => {         setREGISTER_AGENT(" w--current");         setAGENT_LIST(" inactive");         };

    // we use this "useEffect" hook to checkout with the first menu in the sub-menu list, to ensure its highlighted when it needs to be
    useEffect(() => setAGENT_LIST( (DEFAULT_SUB_MENU === "AGENT_LIST_DASHBOARD") ? " w--current" : " inactive" ), [DEFAULT_SUB_MENU]);

    return (
        <>

            <Link to={(configuration.AGENT_RECORDS_LINK)} onClick={AGENT_LIST_onClick} className={"task_nav_button w-inline-block"+AGENT_LIST}>
                <img src={configuration.APP_DATA_RESOURCE+"images/button-icon-1.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Agents List</div>
            </Link>
            {/* <Link to={configuration.ADD_AGENT_LINK} onClick={REGISTER_AGENT_onClick} className={"task_nav_button w-inline-block"+REGISTER_AGENT}>
                <img src={configuration.APP_DATA_RESOURCE+"images/reg-agent-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Register Agent</div>
            </Link> */}
            
        </>      
    );
};










// COMPONENT TO DISPLAY MENU ITEMS OF THE ADMIN MENU
const GROUP_WELFARE_MENU_ITEMS = ({DEFAULT_SUB_MENU}) => {
    const [GWS_DASHBOARD, setGWS_DASHBOARD] = useState(" w--current");
    const [COMPANY_REG, setCOMPANY_REG] = useState( (DEFAULT_SUB_MENU === "COMPANY_REG") ? " w--current" : " inactive" ); 
    const [G_W_S_APPLICATION, setG_W_S_APPLICATION] = useState( (DEFAULT_SUB_MENU === "G_W_S_APPLICATION") ? " w--current" : " inactive" ); 
    const [APPROVAL_AND_POLICY, setAPPROVAL_AND_POLICY] = useState( (DEFAULT_SUB_MENU === "APPROVAL_AND_POLICY") ? " w--current" : " inactive" ); 
    const [CLAIMS_PROCESSING, setCLAIMS_PROCESSING] = useState( (DEFAULT_SUB_MENU === "CLAIMS_PROCESSING") ? " w--current" : " inactive" ); 

    const GWS_DASHBOARD_onClick = () => {    setGWS_DASHBOARD(" w--current");    setCOMPANY_REG(" inactive");    setG_W_S_APPLICATION(" inactive");    setAPPROVAL_AND_POLICY(" inactive");    setCLAIMS_PROCESSING(" inactive");   };
    const COMPANY_REG_onClick = () => {    setGWS_DASHBOARD(" inactive");    setCOMPANY_REG(" w--current");    setG_W_S_APPLICATION(" inactive");    setAPPROVAL_AND_POLICY(" inactive");    setCLAIMS_PROCESSING(" inactive");   };
    const G_W_S_APPLICATION_onClick = () => {    setGWS_DASHBOARD(" inactive");    setCOMPANY_REG(" inactive");    setG_W_S_APPLICATION(" w--current");    setAPPROVAL_AND_POLICY(" inactive");    setCLAIMS_PROCESSING(" inactive");   };
    const APPROVAL_AND_POLICY_onClick = () => {    setGWS_DASHBOARD(" inactive");    setCOMPANY_REG(" inactive");    setG_W_S_APPLICATION(" inactive");    setAPPROVAL_AND_POLICY(" w--current");    setCLAIMS_PROCESSING(" inactive");   };
    const CLAIMS_PROCESSING_onClick = () => {    setGWS_DASHBOARD(" inactive");    setCOMPANY_REG(" inactive");    setG_W_S_APPLICATION(" inactive");    setAPPROVAL_AND_POLICY(" inactive");    setCLAIMS_PROCESSING(" w--current");   };

    // we use this "useEffect" hook to checkout with the first menu in the sub-menu list, to ensure its highlighted when it needs to be
    useEffect(() => setGWS_DASHBOARD( (DEFAULT_SUB_MENU === "GWS_DASHBOARD") ? " w--current" : " inactive" ), [DEFAULT_SUB_MENU]);

    return (
        <>

            <Link to={(configuration.GWS_DASHBOARD_LINK)} onClick={GWS_DASHBOARD_onClick} className={"task_nav_button w-inline-block"+GWS_DASHBOARD}>
                <img src={configuration.APP_DATA_RESOURCE+"images/button-icon-1.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Statistics</div>
            </Link>
            <Link to={configuration.COMPANY_REG_LINK} onClick={COMPANY_REG_onClick} className={"task_nav_button w-inline-block"+COMPANY_REG}>
                <img src={configuration.APP_DATA_RESOURCE+"images/company-add.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Company Reg</div>
            </Link>
            <Link to={(configuration.G_W_S_APPLICATION_LINK)} onClick={G_W_S_APPLICATION_onClick} className={"task_nav_button w-inline-block"+G_W_S_APPLICATION}>
                <img src={configuration.APP_DATA_RESOURCE+"images/list-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">G.W.S. Application</div>
            </Link>
            <Link to={configuration.APPROVAL_AND_POLICY_LINK} onClick={APPROVAL_AND_POLICY_onClick} className={"task_nav_button w-inline-block"+APPROVAL_AND_POLICY}>
                <img src={configuration.APP_DATA_RESOURCE+"images/list-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Approval &amp; Policy</div>
            </Link>
            <Link to={configuration.CLAIMS_PROCESSING_LINK} onClick={CLAIMS_PROCESSING_onClick} className={"task_nav_button w-inline-block"+CLAIMS_PROCESSING}>
                <img src={configuration.APP_DATA_RESOURCE+"images/list-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                <div className="text-block">Claims Processing</div>
            </Link>
            
        </>      
    );
};










// COMPONENT TO DISPLAY MENU ITEMS OF THE ADMIN MENU
const H_M_O_MENU_ITEMS = ({DEFAULT_SUB_MENU}) => {
    return null;
};