
// REACT STANDARD LIBRARY CALLS
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';

/* IMPORT COMPONENTS. */
import NIGERIA_STATES_DROPDOWN from '../../../Components/Nigerian_States';

/* IMPORT LIBRARY  */
import { PUBLIC_FORMDATA_POSTDATA, FIRST_FEW_CHARS_IN_STRING_FETCHER, REACT_DATEPICKER_DATE_FORMATTER, CAPITALIZE_FIRST_LETTERS, TRIM } from '../../../Library/Library.js';
import { NIGERIA_STATES_LOCAL_GOVERNMENT_AREA } from '../../../Library/STATES_LGA.js';
import { NIGERIA_STATES_LOCAL_GOVERNMENT_AREA_BRANCHES } from '../../../Library/LGA_WARD_OR_BRANCH_SCRIPT.js';
import { AGENT_REGISTRATION_DATA_AUTHENTICATOR } from '../../../Library/REG_LIBRARY.js';






// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };
const licenseCustomStyles = { content: { top: '52%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






export default function ADD_VETERAN({}) {

    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }
    


    // CREATE STATE TO BE USED TO CONTROL BUTTON LOADING ANIMATION, FOR THIS COMPONENT.
    const [BUTTON_LOADING_ANIM, setBUTTON_LOADING_ANIM] = useState(false);
    // CREATE STATE TO BE USED TO STORE IMAGE
    const [imgUrl, setImgUrl] = useState("/DATA/blank_avater.jpg");
    // CREATE FORM STATES HERE
    const [FIRSTNAME, setFIRSTNAME] = useState("");
    const [LASTNAME, setLASTNAME] = useState("");
    // const [DATE_OF_BIRTH, setDATE_OF_BIRTH] = useState(new Date());
    const [DATE_OF_BIRTH, setDATE_OF_BIRTH] = useState(null);
    const [PASSWORD, setPASSWORD] = useState("");
    const [RETYPED_PASSWORD, setRETYPED_PASSWORD] = useState("");
    const [PHONE_1, setPHONE_1] = useState("");
    const [PHONE_2, setPHONE_2] = useState("");
    const [GENDER, setGENDER] = useState("");
    const [ADDRESS_1, setADDRESS_1] = useState("");
    const [ADDRESS_2, setADDRESS_2] = useState("");
    const [PHONE_SERIAL, setPHONE_SERIAL] = useState("");
    const [CITY, setCITY] = useState("");
    const [STATE, setSTATE] = useState("");
    const [LGA, setLGA] = useState([{ value: "", label: "Select LGA" }]);
    const [BRANCH, setBRANCH] = useState([{ value: "", label: "Select Branch" }]);
    const [STATE_OF_ORIGIN, setSTATE_OF_ORIGIN] = useState("");
    const [LGA_OF_ORIGIN, setLGA_OF_ORIGIN] = useState([{ value: "", label: "Select LGA" }]);
    const [TOWN, setTOWN] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [USERNAME, setUSERNAME] = useState(((FIRSTNAME) && (LGA[0])) ? FIRSTNAME+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(STATE, 2)+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(LGA[0].value, 3) : "");
    
    // UNCHANGED FUNCTIONS FOR THE STATES ABOVE
    const FIRSTNAME_onChange = (event) => {         
        setFIRSTNAME( TRIM(event.target.value) );
        setUSERNAME(((FIRSTNAME) && (LGA[0])) ? FIRSTNAME+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(STATE, 2)+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(LGA[0].value, 3) : "");      
    };
    const LASTNAME_onChange = (event) => {         setLASTNAME( TRIM(event.target.value) );         };
    const DATE_OF_BIRTH_onChange = (date) => {        setDATE_OF_BIRTH(date);         };
    // const USERNAME_onChange = (event) => {         setUSERNAME(event.target.value);         };
    const PASSWORD_onChange = (event) => {         setPASSWORD( TRIM(event.target.value) );         };
    const RETYPED_PASSWORD_onChange = (event) => {         setRETYPED_PASSWORD( TRIM(event.target.value) );         };
    const PHONE_1_onChange = (event) => {         setPHONE_1( TRIM(event.target.value) );         };
    const PHONE_2_onChange = (event) => {         setPHONE_2( TRIM(event.target.value) );         };
    const GENDER_onChange = (event) => {         setGENDER( TRIM(event.target.value) );         };
    const ADDRESS_1_onChange = (event) => {         setADDRESS_1( TRIM(event.target.value) );         };
    const ADDRESS_2_onChange = (event) => {         setADDRESS_2( TRIM(event.target.value) );         };
    const PHONE_SERIAL_onChange = (event) => {         setPHONE_SERIAL( TRIM(event.target.value) );         };
    const CITY_onChange = (event) => {         setCITY( TRIM(event.target.value) );         };
    const TOWN_onChange = (event) => {         setTOWN( TRIM(event.target.value) );         };
    const selectedFile_onChange = (e) => {  
        // extract selected image file and store as an objcet
        const FILE_OBJCET = e.target.files[0];  
        // update the "selectedFile" state, which will inform our form submission that, user has selected an image. 
        setSelectedFile(FILE_OBJCET);
        // Also convert the selected image to base64 formate so we can display it on the preview, just immediately after image selection.
        setImgUrl(URL.createObjectURL(FILE_OBJCET));
    };

    // CREATE THE ONCHANGE METHOD, FOR THE STATE DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM STATE DROPDOWN
    const STATE_onChange = (event) => {
        /* Here we collect the selected state data and store it inside the "new_home_state" variable.  */
        let new_home_state = event.target.value;
        /* We update the "STATE" of the new selection. */
        setSTATE( new_home_state ); 
        /* AFTER GETTING THE STATE SELECTED FROM THE STATE DROPDOWN MENU, HERE WE NOW CALL ON THE "STATE_LOCAL_GOVERMENT_OPERATOR" FUNCTION TO HELP 
         * US GET ALL THE LOCAL GOVERNMENTS UNDER THIS STATE AND THEN POPULATE OUR LGA DROPDOWN WITH CORRESPONDING DATAS EACH TIME A NEW STATE IS 
         * SELECTED FROM THE STATE DROPDOWN LIST.  */
        STATE_LOCAL_GOVERMENT_OPERATOR(new_home_state);
        // also update the username field of the new state code
        setUSERNAME(((FIRSTNAME) && (LGA[0])) ? FIRSTNAME+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(STATE, 2)+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(LGA[0].value, 3) : "");
    };

    // CREATE THE ONCHANGE METHOD, FOR THE LGA DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM LGA DROPDOWN
    const LGA_onChange = (event) => {
        /* Here we collect the selected lga data and store it inside the "new_lga" variable.  */
        let new_lga = event.target.value;
        /* We update the "LGA" state of the new selection. We do this update in such a way that any data already in this state stays. */
        // if(LGA.length > 1) setLGA( [{ value: new_lga, label: new_lga }].concat(LGA) );
        // We update the "LGA" state of the new selection.
        setLGA( [{ value: new_lga, label: new_lga }] );
        /* AFTER GETTING THE LGA SELECTED FROM THE LGA DROPDOWN MENU, HERE WE NOW CALL ON THE "LGA_BRANCH_OPERATOR" FUNCTION TO HELP 
         * US GET ALL THE BRANCHES UNDER THIS LGA AND THEN POPULATE OUR BRANCH DROPDOWN WITH CORRESPONDING DATAS EACH TIME A NEW LGA IS 
         * SELECTED FROM THE LGA DROPDOWN LIST.  */
        LGA_BRANCH_OPERATOR(new_lga);
        // also update the username field of the new lga code
        setUSERNAME(((FIRSTNAME) && (LGA[0])) ? FIRSTNAME+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(STATE, 2)+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(LGA[0].value, 3) : "");
    };

    // CREATE THE ONCHANGE METHOD, FOR THE BRANCH DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM BRANCH DROPDOWN
    const BRANCH_onChange = (event) => {   
        /* Here we collect the selected BRANCH data and store it inside the "BRANCH" variable.  */
        let BRANCH_data = event.target.value;
        // UPDATE BRANCH STATE. We do this update in such a way that any data already in this state stays.
        // if(BRANCH.length > 1) setBRANCH( [{ value: BRANCH_data, label: BRANCH_data }].concat(BRANCH) ); 
        //UPDATE BRANCH STATE.
        setBRANCH( [{ value: BRANCH_data, label: BRANCH_data }] );    
    };
    


    /* WE USE TO FUNCTION TO CONTROL WHAT SHOULD HAPPEN WHEN USER SELECTS A STATE. THIS FUNCTION TAKES IN A PARAMETER WHICH IS THE STATE THAT WAS 
     * SELECTED TO HELP IT FETCH THE CORRESPONDING LOCAL GOVERNMENT AREAS FOR THAT STATE */
    const STATE_LOCAL_GOVERMENT_OPERATOR = (SECLECTED_STATE) => {
        /* Now we create a temporary initially empty arrays, the aim of creating this array is so that we get to use it as temporary container to 
         * store the local government areas of the selected state.  */
        let dataset = [];
        // set the default value for our LGA dropdown each time the state dropdown is tampered with.
        dataset[0] = { value: "", label: "Select LGA" };
        /* Also since we shall be storing datas inside array soon using a loop, its important that we create an integer variable to be used to 
         * properly formate the indexes or keys of our new array containers as we store datas in them, right inside the loop.  */
        let countez = 1;
        /* Here we use the "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA" function to create and fetch an object of all the states in nigeria and their 
         * respective local government areas, then we store them inside the "lgaList" variable. */
        const lgaList = NIGERIA_STATES_LOCAL_GOVERNMENT_AREA();
        /* Here, firstly we convert into a loopable array the object that was recently fetched from the "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA" function 
         * before we then create the loop to be used to purge out every "nigerian state" datas present inside the array. note that the nigeria state 
         * data is in this array primary dimension while their respective local government area datas are in the second dimension, check the 
         * "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA" function to understand this data structure.  */
        Object.keys(lgaList).forEach(key => {
            /* on purging out every nigeria state datas away from the primary dimension of the array, we test each of this nigeria state data per 
             * loop cycle at this junction, we check it against the selected state from the dropdown form, if they are thesame this signifies that we 
             * have reached the point in the array looping that is parallel to the user selected state, therefore, we can proceed to the point of 
             * extracting correct and precise local government area for the selected state. */
            if(SECLECTED_STATE === key){
                /* In a bid to extract the local government area for the selected state, we need to create another loop inside the first loop, only that this second loop shall be
                 * strategically used to extract all local government area datas. */
                lgaList[key].forEach(data => {
                    /* Now we extract the local government area data, and use it to create an array that we shall be storing in our temporary arrays, we use the "countez" variable to 
                     * align the indexes of the temporary arrays.  */
                    dataset[countez] = { value: data, label: data };
                    /* At the end of each loop cycle of the second loop, its important we increment this "countez" variable so that the job of properly formatting the indexes of our
                     * two temporary arrays can be smooth. */
                    countez++;
                });
            }
        });

        /* now that we have the local government area data of user selected state, its time we store this "compiled in array" datas inside a react state, but first we empty this react
         * state first at this junction.  */
        setLGA([]);
        /* after emptying the react state responsible for holding the list datas of user first and second prefered locations, we now store the array dataset innside the "dataset" 
         * vaiable in them so they can take a live effect of the location dropdown. Note that the state carrying the selected data of this two location dropdown form is different from
         * this state we updating here, because this state only store user first and second location dropdown datas.  */
        setLGA(dataset);
    };



    /* WE USE TO FUNCTION TO CONTROL WHAT SHOULD HAPPEN WHEN USER SELECTS LGA. THIS FUNCTION TAKES IN A PARAMETER WHICH IS THE LGA THAT WAS 
     * SELECTED TO HELP IT FETCH THE CORRESPONDING BRANCHES FOR THAT LGA */
    const LGA_BRANCH_OPERATOR = (SECLECTED_LGA) => {
        /* Now we create a temporary initially empty arrays, the aim of creating this array is so that we get to use it as temporary container to 
         * store the branches of the selected lga.  */
        let dataset = [];
        // set the default value for our Branch dropdown each time the LGA dropdown is tampered with.
        dataset[0] = { value: "", label: "Select Branch" };
        /* Also since we shall be storing datas inside array soon using a loop, its important that we create an integer variable to be used to 
         * properly formate the indexes or keys of our new array containers as we store datas in them, right inside the loop.  */
        let countez = 1;
        /* Here we use the "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA_BRANCHES" function to create and fetch an object of all the lga's in nigeria and 
         * their respective branches, then we store them inside the "branchList" variable. */
        const branchList = NIGERIA_STATES_LOCAL_GOVERNMENT_AREA_BRANCHES();
        /* Here, firstly we convert into a loopable array the object that was recently fetched from the "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA_BRANCHES" 
         * function before we then create the loop to be used to purge out every "nigerian lga" datas present inside the array. note that the nigeria 
         * lga data is in this array primary dimension while their respective branche datas are in the second dimension, check the 
         * "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA_BRANCHES" function to understand this data structure.  */
        Object.keys(branchList).forEach(key => {
            /* on purging out every nigeria lga datas away from the primary dimension of the array, we test each of this nigeria lga data per loop 
             * cycle at this junction, we check it against the selected lga from the dropdown form, if they are thesame this signifies that we have 
             * reached the point in the array looping that is parallel to the user selected lga, therefore, we can proceed to the point of extracting 
             * correct and precise branches for the selected lga. */
            if(SECLECTED_LGA === key){
                /* In a bid to extract the branch for the selected lga, we need to create another loop inside the first loop, only that this second 
                 * loop shall be strategically used to extract all branch datas. */
                branchList[key].forEach(data => {
                    /* Now we extract the lbranch data, and use it to create an array that we shall be storing in our temporary arrays, we use the 
                     * "countez" variable to align the indexes of the temporary arrays.  */
                    dataset[countez] = { value: data, label: data };
                    /* At the end of each loop cycle of the second loop, its important we increment this "countez" variable so that the job of 
                     * properly formatting the indexes of our two temporary arrays can be smooth. */
                    countez++;
                });
            }
        });

        /* now that we have the branch data of user selected lga, its time we store this "compiled in array" datas inside a react state, but first we 
         * empty this react state first at this junction.  */
        setBRANCH([]);
        /* after emptying the react state responsible for holding the list datas, we now store the array dataset innside the "dataset" vaiable in them 
         * so they can take a live effect on the dropdown.  */
        setBRANCH(dataset);
    };








    // CREATE THE ONCHANGE METHOD, FOR THE STATE DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM STATE DROPDOWN
    const STATE_OF_ORIGIN_onChange = (event) => {
        /* Here we collect the selected state data and store it inside the "new_home_state" variable.  */
        let new_home_state = event.target.value;
        /* We update the "STATE" of the new selection. */
        setSTATE_OF_ORIGIN( new_home_state ); 
        /* AFTER GETTING THE STATE SELECTED FROM THE STATE DROPDOWN MENU, HERE WE NOW CALL ON THE "STATE_LOCAL_GOVERMENT_OPERATOR_____2" FUNCTION TO HELP 
         * US GET ALL THE LOCAL GOVERNMENTS UNDER THIS STATE AND THEN POPULATE OUR LGA DROPDOWN WITH CORRESPONDING DATAS EACH TIME A NEW STATE IS 
         * SELECTED FROM THE STATE DROPDOWN LIST.  */
        STATE_LOCAL_GOVERMENT_OPERATOR_____2(new_home_state);
    };

    // CREATE THE ONCHANGE METHOD, FOR THE LGA DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM LGA DROPDOWN
    const LGA_OF_ORIGIN_onChange = (event) => {
        /* Here we collect the selected lga data and store it inside the "new_lga" variable.  */
        let new_lga = event.target.value;
        /* We update the "LGA" state of the new selection. We do this update in such a way that any data already in this state stays. */
        // if(LGA.length > 1) setLGA( [{ value: new_lga, label: new_lga }].concat(LGA) );
        // We update the "LGA" state of the new selection.
        setLGA_OF_ORIGIN( [{ value: new_lga, label: new_lga }] );
    };

    /* WE USE TO FUNCTION TO CONTROL WHAT SHOULD HAPPEN WHEN USER SELECTS A STATE. THIS FUNCTION TAKES IN A PARAMETER WHICH IS THE STATE THAT WAS 
     * SELECTED TO HELP IT FETCH THE CORRESPONDING LOCAL GOVERNMENT AREAS FOR THAT STATE */
    const STATE_LOCAL_GOVERMENT_OPERATOR_____2 = (SECLECTED_STATE) => {
        /* Now we create a temporary initially empty arrays, the aim of creating this array is so that we get to use it as temporary container to 
         * store the local government areas of the selected state.  */
        let dataset = [];
        // set the default value for our LGA dropdown each time the state dropdown is tampered with.
        dataset[0] = { value: "", label: "Select LGA" };
        /* Also since we shall be storing datas inside array soon using a loop, its important that we create an integer variable to be used to 
         * properly formate the indexes or keys of our new array containers as we store datas in them, right inside the loop.  */
        let countez = 1;
        /* Here we use the "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA" function to create and fetch an object of all the states in nigeria and their 
         * respective local government areas, then we store them inside the "lgaList" variable. */
        const lgaList = NIGERIA_STATES_LOCAL_GOVERNMENT_AREA();
        /* Here, firstly we convert into a loopable array the object that was recently fetched from the "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA" function 
         * before we then create the loop to be used to purge out every "nigerian state" datas present inside the array. note that the nigeria state 
         * data is in this array primary dimension while their respective local government area datas are in the second dimension, check the 
         * "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA" function to understand this data structure.  */
        Object.keys(lgaList).forEach(key => {
            /* on purging out every nigeria state datas away from the primary dimension of the array, we test each of this nigeria state data per 
             * loop cycle at this junction, we check it against the selected state from the dropdown form, if they are thesame this signifies that we 
             * have reached the point in the array looping that is parallel to the user selected state, therefore, we can proceed to the point of 
             * extracting correct and precise local government area for the selected state. */
            if(SECLECTED_STATE === key){
                /* In a bid to extract the local government area for the selected state, we need to create another loop inside the first loop, only that this second loop shall be
                 * strategically used to extract all local government area datas. */
                lgaList[key].forEach(data => {
                    /* Now we extract the local government area data, and use it to create an array that we shall be storing in our temporary arrays, we use the "countez" variable to 
                     * align the indexes of the temporary arrays.  */
                    dataset[countez] = { value: data, label: data };
                    /* At the end of each loop cycle of the second loop, its important we increment this "countez" variable so that the job of properly formatting the indexes of our
                     * two temporary arrays can be smooth. */
                    countez++;
                });
            }
        });

        /* now that we have the local government area data of user selected state, its time we store this "compiled in array" datas inside a react state, but first we empty this react
         * state first at this junction.  */
        setLGA_OF_ORIGIN([]);
        /* after emptying the react state responsible for holding the list datas of user first and second prefered locations, we now store the array dataset innside the "dataset" 
         * vaiable in them so they can take a live effect of the location dropdown. Note that the state carrying the selected data of this two location dropdown form is different from
         * this state we updating here, because this state only store user first and second location dropdown datas.  */
        setLGA_OF_ORIGIN(dataset);
    };



    
    
    // FUNCTION TO CREATE A NEW AGENT
    const ADD_AGENT = (e) => {
        /* line of code to ensure that html form default submit process is disabled, so that form submission can be handled by javascript in the REACT way.  */
        e.preventDefault();

        // process the inpute date of brith data, into a reliable format
        const processed_date = REACT_DATEPICKER_DATE_FORMATTER(DATE_OF_BIRTH);
        /* collect all data from their official states storage and compile them into an object, in a bid to prepare the datas for validation 
         * before we can proceed to submitting data. */
        const RAW_DATA = {
            DCAGMT_SNAME: LASTNAME, DCAGMT_FNAME: FIRSTNAME, DCAGMT_DOB: processed_date, 
            DCAGMTUSRNAME: ((FIRSTNAME) && (LGA[0])) ? FIRSTNAME+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(STATE, 2)+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(LGA[0].value, 3) : "", 
            DCAGMTPASSWRD: PASSWORD, DCAGMT_GENDER: GENDER, DCAGMT_TEL_NO1: PHONE_1, DCAGMT_TEL_NO2: PHONE_2, DCAGMT_ADDR_LN1: ADDRESS_1, 
            DCAGMT_ADDR_LN2: TRIM(ADDRESS_2+"#@#"+PHONE_SERIAL),  
            DCAGMT_CITY: CITY, DCAGMT_STATE: STATE, DCAGMT_LGA: LGA[0].value, DCAGMT_BRANCH: BRANCH[0].value, IMAGE_FILE_OBJECT: selectedFile, 
            DOCUMENT_FILE_OBJECT: selectedDocument, RETYPED_PASSWORD: RETYPED_PASSWORD, PHONE_SERIAL: PHONE_SERIAL, 
            STATE_OF_ORIGIN: STATE_OF_ORIGIN, LGA_OF_ORIGIN: LGA_OF_ORIGIN[0].value, TOWN: TOWN
        };
        /* Here we launch the "RAW_DATA_AUTHENTICATOR" function to help us validate the authenticity of all the datas user has provided. */
        const AUTHENTICATOR_DATA = AGENT_REGISTRATION_DATA_AUTHENTICATOR(RAW_DATA);
        /* Before any major registration operations can be permitted to begin, firstly the "REGISTRATION_DATA_AUTHENTICATOR_2" function must first give clear indications that the data
         * provided by user is valid. So at this junction we test to see if we have clearance to register this user.  */
        if(AUTHENTICATOR_DATA === true){
            /* Here we signal the button state change react-state, to indicate that button is clicked and request sent. */
            setBUTTON_LOADING_ANIM(true);
            // Now that we are certain form data meets the right format after data verification tests has been passsed, we go ahead to Build formData 
            // object, which we shall be using to send multipart data to the java backend.
            let formData = new FormData();
            /* collect all data from their official states storage and compile them into an object, in a bid to prepare the datas for form submission. */
            formData.append('DCAGMT_SNAME', LASTNAME);
            formData.append('DCAGMT_FNAME', FIRSTNAME);
            formData.append('DCAGMT_DOB', processed_date);
            formData.append('DCAGMTUSRNAME', ((FIRSTNAME) && (LGA[0])) ? FIRSTNAME+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(STATE, 2)+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(LGA[0].value, 3) : "");
            formData.append('DCAGMTPASSWRD', PASSWORD);
            formData.append('DCAGMT_GENDER', GENDER);
            formData.append('DCAGMT_TEL_NO1', PHONE_1);
            formData.append('DCAGMT_TEL_NO2', PHONE_2);
            formData.append('DCAGMT_ADDR_LN1', ADDRESS_1);
            formData.append('DCAGMT_ADDR_LN2', TRIM(ADDRESS_2+"#@#"+PHONE_SERIAL));
            formData.append('DCAGMT_CITY', CITY);
            formData.append('DCAGMT_STATE', FIRST_FEW_CHARS_IN_STRING_FETCHER(STATE, 2));
            formData.append('DCAGMT_LGA', FIRST_FEW_CHARS_IN_STRING_FETCHER(LGA[0].value, 3));
            formData.append('DCAGMT_BRANCH', BRANCH[0].value);
            formData.append('stateOfOrigin', STATE_OF_ORIGIN);
            formData.append('localGovtOfOrigin', LGA_OF_ORIGIN[0].value);
            formData.append('hometown', TOWN);
            // AUTOFILL THE REST OF THE FORMS FROM HERE
            formData.append('DCAGMT_NEXT_KIN_SNAME', "OOOOO");
            formData.append('DCAGMT_NEXT_KIN_FNAME', "OOOOO");
            formData.append('DCAGMT_NEXT_KIN_REL', "OOOOO");
            formData.append('DCAGMT_NEXT_KIN_TEL_NO', "08077777777");
            formData.append('DCAGMT_NEXT_KIN_ADDR', "OOOOO OOOOO OOOOO");
            formData.append('ROLENAME', "AGENT");
            formData.append("DCAGMT_PHOTO", selectedFile);
            // formData.append("DCAGMT_PHOTO1", selectedDocument);
            
            const TOKS = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImV4cCI6MTY2MjE0ODU5OSwiaWF0IjoxNjYyMTI2OTk5fQ.b7dk5KzbpMGL7d0Dei_JWL4hKREsL6nljaJm0Y_K_Xr7eGU-a2gTliu4p884kgMzd4P_cu07VQLFolDC6_CoWw";
            /* call the api function responsible for handling the profile updating job. */
            const url = configuration.ADD_AGENT_API;
            /* Call the "putData" function to help us handle submission. */
            PUBLIC_FORMDATA_POSTDATA(url, formData, TOKS, 'POST').then(response => {
                // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
                if(response.status === 200){
                    // display success message
                    openModal("Agent added successfully.");
                    // Simulate a mouse click, to revert user back to an empty form page
                    window.location.href = configuration.ADD_AGENT_LINK;
                    // revert user back to member page
                    // history.push(configuration.ADD_AGENT_LINK);
                }else{
                    // display server error message
                    openModal("System encountered an internal error while trying to process your data.");
                }

                // Here we signal the button state change react-state, to indicate that request has been completed. 
                setBUTTON_LOADING_ANIM(false);
            }).catch(error => {
                // Here we signal the button state change react-state, to indicate that request has been completed. 
                setBUTTON_LOADING_ANIM(false);
                // At this junction we are certain that request submission has failed because of a network error, therefore we send out network 
                // failure message
                openModal("Network Error, when attempting to save data. Please ensure you have a good internet connection.");
            });
            
        }else{
            /* control getting in here signifies that the authentication of user supplied profile data has failed for one reason or the other, further meaning user did 
             * not fill one or more form field well, therefore we initialize our alert container to tell user what he or she is doing wrong. */
            openModal(AUTHENTICATOR_DATA);
        }
    };





    const [documentUploadButtonName, setdocumentUploadButtonName] = useState("Upload ID Card");
    const selectedDocument_onChange = (e) => {  
        // extract selected image file and store as an objcet
        const FILE_OBJCET = e.target.files[0];  
        // update the "selectedDocument" state, which will inform our form submission that, user has selected an document. 
        setSelectedDocument(FILE_OBJCET);
        // change the document upload button trigger, change its text
        setdocumentUploadButtonName(FILE_OBJCET.name);
    };




    

    

    const [undertaken_checker, setundertaken_checker] = useState(false);
    const [attestation_checker, setattestation_checker] = useState(false);




    const [LICENSE_MODAL_TOGGLE, setLICENSE_MODAL_TOGGLE] = useState(false);
    function openLicenceModal(){      setLICENSE_MODAL_TOGGLE(true);     }
    function closeLicenceModal(){      setLICENSE_MODAL_TOGGLE(false);     setattestation_checker(true);     }
    const [agree, setAgree] = useState(false);

    const License_checkboxHandler = () => {
        // if agree === true, it will be set to false
        // if agree === false, it will be set to true
        setAgree(!agree);
        // Don't miss the exclamation mark
        openLicenceModal();
    }

    const [UNDERTAKEN_MODAL_TOGGLE, setUNDERTAKEN_MODAL_TOGGLE] = useState(false);
    function openUndertakenModal(){      setUNDERTAKEN_MODAL_TOGGLE(true);     }
    function closeUndertakenModal(){      setUNDERTAKEN_MODAL_TOGGLE(false);    setundertaken_checker(true);    }
    const [Undertaken_agree, setUndertakenAgree] = useState(false);

    const Undertaken_checkboxHandler = () => {
        // if agree === true, it will be set to false
        // if agree === false, it will be set to true
        setUndertakenAgree(!Undertaken_agree);
        // Don't miss the exclamation mark
        openUndertakenModal();
    }

    // console.log(REACT_DATEPICKER_DATE_FORMATTER(DATE_OF_BIRTH));
    // console.log(DATE_OF_BIRTH);

    return (
        
        <>
            {/* <div className="div-block-35" style={{ marginBottom: "0%", marginTop: "2%" }}>
                <h1></h1>
                <Link to={configuration.AGENT_RECORDS_LINK} className="button_outline filled goldenrod id new w-button">Back</Link>
            </div> */}

            <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
            </Modal>

            <Modal isOpen={LICENSE_MODAL_TOGGLE} onRequestClose={closeLicenceModal} style={licenseCustomStyles}>
                <div>
                    <LICENSE />
                    <div className="div-block-35" style={{ display: "flex", marginTop: "7%" }}>
                        <a href={configuration.ADD_AGENT_LINK} className="button_outline filled goldenrod id new w-button" style={{ visibility: "hidden" }}>Decline</a>
                        <button onClick={closeLicenceModal} className="button_outline filled goldenrod id new w-button">Accept</button>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={UNDERTAKEN_MODAL_TOGGLE} onRequestClose={closeUndertakenModal} style={licenseCustomStyles}>
                <div>
                    <UNDERTAKEN />
                    <div className="div-block-35" style={{ display: "flex", marginTop: "7%" }}>
                        <a href={configuration.ADD_AGENT_LINK} className="button_outline filled goldenrod id new w-button" style={{ visibility: "hidden" }}>Decline</a>
                        <button onClick={closeUndertakenModal} className="button_outline filled goldenrod id new w-button">Accept</button>
                    </div>
                </div>
            </Modal>






            
            
            <div className="register_agent" style={{ marginBottom: "1%", height: "3000px", width: "1200px" }}>
                <br />
                

                    

                <form onSubmit={ADD_AGENT} method="post">
                    <h3 style={{ display: "flex", justifyContent: "center", marginLeft: "0%" }}>
                        VETERANS REGISTRATION PORTAL</h3>
                    <br />

                    <div style={{ display: "flex", justifyContent: "center", marginLeft: "0%", marginBottom: "20px" }}>
                        <img src={imgUrl} loading="lazy" onClick={() => document.getElementById('agentImageFile').click()} width="200" 
                            height="200" alt="Agent Display Picture" style={{ borderRadius: "50%" }} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginLeft: "0%" }}>
                        <button onClick={() => document.getElementById('agentImageFile').click()} className="realbuttons w-button" 
                            style={{ marginRight: "1%" }}>Upload Photo</button>
                        {/* <input type="button" onClick={() => document.getElementById('agentLegalDocumentFile').click()} 
                            className="realbuttons w-button" value={documentUploadButtonName} /> */}
                    </div>
                    <input type='file' id="agentImageFile" onChange={selectedFile_onChange} style={{ display: "none" }} />
                    {/* <input type='file' id="agentLegalDocumentFile" onChange={selectedDocument_onChange} style={{ display: "none" }} /> */}

                    <div className="form_holder">
                        <div className="w-form">

                            <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-8" 
                                style={{ maxHeight: "920px" }}>
                                <div>
                                    <label style={{ color: "#777" }}> Surname </label>
                                    <input onChange={LASTNAME_onChange} type="text" className="my-text-field w-input" minlength="3" maxlength="40" 
                                        name="name-2" data-name="Name 2" placeholder="Surname" id="name-2" required />
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> First Name </label>
                                    <input onChange={FIRSTNAME_onChange} type="text" className="my-text-field w-input" minlength="3" maxlength="40" 
                                        name="name-2" data-name="Name 2" placeholder="First Name" id="name-2" required />
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> Middle Name </label>
                                    <input onChange={FIRSTNAME_onChange} type="text" className="my-text-field w-input" minlength="3" maxlength="40" 
                                        name="name-2" data-name="Name 2" placeholder="Middle Name" id="name-2" required />
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> Gender </label>
                                    <select onChange={GENDER_onChange} id="field-3" name="field-3" data-name="Field 3" minlength="4" maxlength="6" 
                                        required className="my-text-field w-node-_299c5399-09e0-f426-3764-79f53c81c717-17d2ff40 w-select">
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>

                                {/* <div>
                                    <label style={{ color: "#777" }}> Date of Birth </label>
                                    <DatePicker className="my-text-field w-input" selected={DATE_OF_BIRTH} 
                                    dateFormat="dd-MMM-yyyy" onChange={DATE_OF_BIRTH_onChange} placeholder="" />
                                </div> */}
                                <div>
                                    <label style={{ color: "#777" }}> Date of Birth </label>
                                    <input type="date" id="myDate" name="myDate" className="my-text-field w-input" value="1970-10-10"
                                        selected={DATE_OF_BIRTH} dateFormat="dd-MMM-yyyy" onChange={DATE_OF_BIRTH_onChange} />
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> Phone </label>
                                    <input onChange={PHONE_1_onChange} type="tel" placeholder="0000-000-0000" className="my-text-field w-input" 
                                        name="name-2" data-name="Name 2" id="name-2" minlength="3" maxlength="13" required />
                                </div>

                                

                            </div>

                                
                            <div style={{ marginTop: "-70%" }}>
                                <label style={{ color: "#777" }}> Email  <small style={{ color: "#999" }}>(optional)</small> </label>
                                <input onChange={ADDRESS_1_onChange} type="email" className="my-text-field w-input" name="name-2" 
                                    data-name="Name 2" placeholder="Enter Email Address" id="name-2" minlength="3" maxlength="40" 
                                    style={{ width: "720px" }} required />
                            </div>


                            <div style={{ marginTop: "0%" }}>
                                <label style={{ color: "#777" }}> Address Line 1 </label>
                                <input onChange={ADDRESS_1_onChange} type="text" className="my-text-field w-input" name="name-2" 
                                    data-name="Name 2" placeholder="Address Line 1" id="name-2" minlength="3" maxlength="40" 
                                    style={{ width: "720px" }} required />
                            </div>


                            <div style={{ marginTop: "0%" }}>
                                <label style={{ color: "#777" }}> Address Line 2  <small style={{ color: "#999" }}>(optional)</small> </label>
                                <input onChange={ADDRESS_1_onChange} type="text" className="my-text-field w-input" name="name-2" 
                                    data-name="Name 2" placeholder="Address Line 2" id="name-2" minlength="3" maxlength="40" 
                                    style={{ width: "720px" }} required />
                            </div>

                            {/* <div style={{ marginTop: "0%" }}>
                                <label style={{ color: "#777" }}> Address Line 2  <small style={{ color: "#999" }}>(optional)</small> </label>
                                <input onChange={ADDRESS_1_onChange} type="text" className="my-text-field w-input" name="name-2" 
                                    data-name="Name 2" placeholder="Address Line 2" id="name-2" minlength="3" maxlength="40" 
                                    style={{ width: "720px" }} required />
                            </div> */}

                            {/* <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-8" 
                                style={{ maxHeight: "320px" }}>

                            </div> */}

                            <div style={{ marginTop: "0%" }}>
                                <div>
                                    <label style={{ color: "#777" }}> State of Origin </label>
                                    <select id="field-3" name="field-3" data-name="Field 3" onChange={STATE_OF_ORIGIN_onChange} 
                                        required className="my-text-field w-input" style={{ marginTop: "0px", width: "420px" }}>
                                        <option value="">State of Origin</option>
                                        <NIGERIA_STATES_DROPDOWN />
                                    </select>
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> LGA of Origin </label>
                                    <select id="field-3" name="field-3" data-name="Field 3" onChange={LGA_OF_ORIGIN_onChange} minlength="3" maxlength="40" 
                                        required className="my-text-field w-input" style={{ width: "420px", marginTop: "0px" }}>
                                        {LGA_OF_ORIGIN.map((infos, index) => {
                                            return <option value={infos.value}>{CAPITALIZE_FIRST_LETTERS(infos.label)}</option>;
                                        })}
                                    </select>
                                </div>
                                
                                <div>
                                    <label style={{ color: "#777" }}> Hometown </label>
                                    <input onChange={TOWN_onChange} type="text" className="my-text-field w-input" name="name-2" data-name="Name 2" 
                                        placeholder="Town" id="name-2" minlength="3" maxlength="40" style={{ marginTop: "0px" }} required 
                                            style={{ width: "720px" }} />
                                </div>
                            </div>


                            <div style={{ marginTop: "0%" }}>
                                <label style={{ color: "#777" }}> Next of Kin </label>
                                <input onChange={ADDRESS_1_onChange} type="text" className="my-text-field w-input" name="name-2" 
                                    data-name="Name 2" placeholder="Full Name of Next of Kin" id="name-2" minlength="3" maxlength="40" 
                                    style={{ width: "720px" }} required />
                            </div>

                            

                            <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-8" 
                                style={{ marginTop: "-50", maxHeight: "320px" }}>
                                
                                {/* <div>
                                    <label style={{ color: "#777" }}> Next of Kin </label>
                                    <input onChange={FIRSTNAME_onChange} type="text" className="my-text-field w-input" minlength="3" maxlength="40" 
                                        name="name-2" data-name="Name 2" placeholder="Full Name" id="name-2" required />
                                </div> */}

                                <div>
                                    <label style={{ color: "#777" }}> Relationship to Next of Kin </label>
                                    <select onChange={GENDER_onChange} id="field-3" name="field-3" data-name="Field 3" minlength="4" maxlength="6" 
                                        required className="my-text-field w-node-_299c5399-09e0-f426-3764-79f53c81c717-17d2ff40 w-select">
                                        <option value="">Select Relationship</option>
                                        <option value="Father">Father</option>
                                        <option value="Mother">Mother</option>
                                        <option value="Husband">Husband</option>
                                        <option value="Wife">Wife</option>
                                        <option value="Grandfather">Grandfather</option>
                                        <option value="Grandmother">Grandmother</option>
                                        <option value="Uncle">Uncle</option>
                                        <option value="Auntie">Auntie</option>
                                        <option value="Son">Son</option>
                                        <option value="Daughter">Daughter</option>
                                        <option value="Nephew">Nephew</option>
                                        <option value="Niece">Niece</option>
                                        <option value="Friend">Friend</option>
                                        <option value="Brother">Brother</option>
                                        <option value="Sister">Sister</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> Type of Pensioners </label>
                                    <select onChange={GENDER_onChange} id="field-3" name="field-3" data-name="Field 3" minlength="4" maxlength="6" 
                                        required className="my-text-field w-node-_299c5399-09e0-f426-3764-79f53c81c717-17d2ff40 w-select">
                                        <option value="">Select Pensioners Type</option>
                                        <option value="Primary teachers">Primary teachers</option>
                                        <option value="Civil">Civil</option>
                                        <option value="Local Government">Local Government</option>
                                        <option value="Tertiary Institution">Tertiary Institution</option>
                                        <option value="Other">Others</option>
                                    </select>
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> Next of Kin: Phone 1 </label>
                                    <input onChange={PHONE_1_onChange} type="tel" placeholder="0000-000-0000" className="my-text-field w-input" 
                                        name="name-2" data-name="Name 2" id="name-2" minlength="3" maxlength="13" required />
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> Next of Kin: Phone 2 </label>
                                    <input onChange={PHONE_1_onChange} type="tel" placeholder="0000-000-0000" className="my-text-field w-input" 
                                        name="name-2" data-name="Name 2" id="name-2" minlength="3" maxlength="13" required />
                                </div>
                            </div>



                            <div style={{ marginTop: "-21%" }}>
                                <label style={{ color: "#777" }}> Next of Kin: Email  <small style={{ color: "#999" }}>(optional)</small> </label>
                                <input onChange={ADDRESS_1_onChange} type="email" className="my-text-field w-input" name="name-2" 
                                    data-name="Name 2" placeholder="Enter Email Address" id="name-2" minlength="3" maxlength="40" 
                                    style={{ width: "720px" }} required />
                            </div>


                            <div style={{ marginTop: "0%" }}>
                                <label style={{ color: "#777" }}> Next of Kin: Address Line 1 </label>
                                <input onChange={ADDRESS_1_onChange} type="text" className="my-text-field w-input" name="name-2" 
                                    data-name="Name 2" placeholder="Address Line 1" id="name-2" minlength="3" maxlength="40" 
                                    style={{ width: "720px" }} required />
                            </div>


                            <div style={{ marginTop: "0%" }}>
                                <label style={{ color: "#777" }}> Next of Kin: Address Line 2  <small style={{ color: "#999" }}>(optional)</small> </label>
                                <input onChange={ADDRESS_1_onChange} type="text" className="my-text-field w-input" name="name-2" 
                                    data-name="Name 2" placeholder="Address Line 2" id="name-2" minlength="3" maxlength="40" 
                                    style={{ width: "720px" }} required />
                            </div>









                            <div className="div-block-21" style={{ marginRight: "0", paddingBottom: "5px", marginTop: "10%" }}>
                                <h2 className="title_header" style={{ display: "flex", justifyContent: "center", marginLeft: "0%", textTransform: "uppercase", fontSize: "25px", marginBottom: "0"  }}>
                                    PRIMARY BENEFICIARY 1
                                </h2>
                            </div>

                            <div style={{ marginTop: "0%" }}>
                                <label style={{ color: "#777" }}> Beneficiary 1: Full Name </label>
                                <input onChange={ADDRESS_1_onChange} type="text" className="my-text-field w-input" name="name-2" 
                                    data-name="Name 2" placeholder="Full Name" id="name-2" minlength="3" maxlength="40" 
                                    style={{ width: "720px" }} required />
                            </div>

                            <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-8" 
                                style={{ marginTop: "-50", maxHeight: "330px" }}>
                                
                                {/* <div>
                                    <label style={{ color: "#777" }}> Beneficiary 1: Full Name </label>
                                    <input onChange={FIRSTNAME_onChange} type="text" className="my-text-field w-input" minlength="3" maxlength="40" 
                                        name="name-2" data-name="Name 2" placeholder="Full Name" id="name-2" required />
                                </div> */}

                                {/* <div>
                                    <label style={{ color: "#777" }}> Beneficiary 1: Date of Birth </label>
                                    <DatePicker className="my-text-field w-input" selected={DATE_OF_BIRTH} 
                                    dateFormat="dd-MMM-yyyy" onChange={DATE_OF_BIRTH_onChange} placeholder="" />
                                </div> */}
                                <div>
                                    <label style={{ color: "#777" }}> Date of Birth </label>
                                    <input type="date" id="myDate" name="myDate" className="my-text-field w-input" value="2000-10-10"
                                        selected={DATE_OF_BIRTH} dateFormat="dd-MMM-yyyy" onChange={DATE_OF_BIRTH_onChange} />
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> Beneficiary 1: Relationship </label>
                                    <select onChange={GENDER_onChange} id="field-3" name="field-3" data-name="Field 3" minlength="4" maxlength="6" 
                                        required className="my-text-field w-node-_299c5399-09e0-f426-3764-79f53c81c717-17d2ff40 w-select">
                                        <option value="">Select Relationship</option>
                                        <option value="Father">Father</option>
                                        <option value="Mother">Mother</option>
                                        <option value="Husband">Husband</option>
                                        <option value="Wife">Wife</option>
                                        <option value="Grandfather">Grandfather</option>
                                        <option value="Grandmother">Grandmother</option>
                                        <option value="Uncle">Uncle</option>
                                        <option value="Auntie">Auntie</option>
                                        <option value="Son">Son</option>
                                        <option value="Daughter">Daughter</option>
                                        <option value="Nephew">Nephew</option>
                                        <option value="Niece">Niece</option>
                                        <option value="Friend">Friend</option>
                                        <option value="Brother">Brother</option>
                                        <option value="Sister">Sister</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> Beneficiary 1: Gender </label>
                                    <select onChange={GENDER_onChange} id="field-3" name="field-3" data-name="Field 3" minlength="4" maxlength="6" 
                                        required className="my-text-field w-node-_299c5399-09e0-f426-3764-79f53c81c717-17d2ff40 w-select">
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> Beneficiary 1: Address </label>
                                    <input onChange={FIRSTNAME_onChange} type="text" className="my-text-field w-input" minlength="3" maxlength="40" 
                                        name="name-2" data-name="Name 2" placeholder="Address" id="name-2" required />
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> Beneficiary 1: Phone </label>
                                    <input onChange={PHONE_1_onChange} type="tel" placeholder="0000-000-0000" className="my-text-field w-input" 
                                        name="name-2" data-name="Name 2" id="name-2" minlength="3" maxlength="13" required />
                                </div>

                            </div>








                            <div className="div-block-21" style={{ marginRight: "0", paddingBottom: "5px", marginTop: "3%" }}>
                                <h2 className="title_header" style={{ display: "flex", justifyContent: "center", marginLeft: "0%", textTransform: "uppercase", fontSize: "25px", marginBottom: "0"  }}>
                                    PRIMARY BENEFICIARY 2 <i><small style={{ color: "#999" }}>(optional)</small></i>
                                </h2>
                            </div>

                            <div style={{ marginTop: "0%" }}>
                                <label style={{ color: "#777" }}> Beneficiary 2: Full Name </label>
                                <input onChange={ADDRESS_1_onChange} type="text" className="my-text-field w-input" name="name-2" 
                                    data-name="Name 2" placeholder="Full Name" id="name-2" minlength="3" maxlength="40" 
                                    style={{ width: "720px" }} required />
                            </div>


                            <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-8" 
                                style={{ marginTop: "-50", maxHeight: "260px" }}>
                                
                                {/* <div>
                                    <label style={{ color: "#777" }}> Beneficiary 2: Full Name </label>
                                    <input onChange={FIRSTNAME_onChange} type="text" className="my-text-field w-input" minlength="3" maxlength="40" 
                                        name="name-2" data-name="Name 2" placeholder="Full Name" id="name-2" required />
                                </div> */}

                                {/* <div>
                                    <label style={{ color: "#777" }}> Beneficiary 2: Date of Birth </label>
                                    <DatePicker className="my-text-field w-input" selected={DATE_OF_BIRTH} 
                                    dateFormat="dd-MMM-yyyy" onChange={DATE_OF_BIRTH_onChange} placeholder="" />
                                </div> */}
                                <div>
                                    <label style={{ color: "#777" }}> Date of Birth </label>
                                    <input type="date" id="myDate" name="myDate" className="my-text-field w-input" value="2000-10-10"
                                        selected={DATE_OF_BIRTH} dateFormat="dd-MMM-yyyy" onChange={DATE_OF_BIRTH_onChange} />
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> Beneficiary 2: Relationship </label>
                                    <select onChange={GENDER_onChange} id="field-3" name="field-3" data-name="Field 3" minlength="4" maxlength="6" 
                                        required className="my-text-field w-node-_299c5399-09e0-f426-3764-79f53c81c717-17d2ff40 w-select">
                                        <option value="">Select Relationship</option>
                                        <option value="Father">Father</option>
                                        <option value="Mother">Mother</option>
                                        <option value="Husband">Husband</option>
                                        <option value="Wife">Wife</option>
                                        <option value="Grandfather">Grandfather</option>
                                        <option value="Grandmother">Grandmother</option>
                                        <option value="Uncle">Uncle</option>
                                        <option value="Auntie">Auntie</option>
                                        <option value="Son">Son</option>
                                        <option value="Daughter">Daughter</option>
                                        <option value="Nephew">Nephew</option>
                                        <option value="Niece">Niece</option>
                                        <option value="Friend">Friend</option>
                                        <option value="Brother">Brother</option>
                                        <option value="Sister">Sister</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> Beneficiary 2: Gender </label>
                                    <select onChange={GENDER_onChange} id="field-3" name="field-3" data-name="Field 3" minlength="4" maxlength="6" 
                                        required className="my-text-field w-node-_299c5399-09e0-f426-3764-79f53c81c717-17d2ff40 w-select">
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> Beneficiary 2: Address </label>
                                    <input onChange={FIRSTNAME_onChange} type="text" className="my-text-field w-input" minlength="3" maxlength="40" 
                                        name="name-2" data-name="Name 2" placeholder="Address" id="name-2" required />
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> Beneficiary 2: Phone </label>
                                    <input onChange={PHONE_1_onChange} type="tel" placeholder="0000-000-0000" className="my-text-field w-input" 
                                        name="name-2" data-name="Name 2" id="name-2" minlength="3" maxlength="13" required />
                                </div>


                                <div style={{ visibility: "hidden" }}>
                                    <label style={{ color: "#777" }}> Beneficiary 2: Phone </label>
                                    <input onChange={PHONE_1_onChange} type="tel" placeholder="0000-000-0000" className="my-text-field w-input" 
                                        name="name-2" data-name="Name 2" id="name-2" minlength="3" maxlength="13" required />
                                </div>


                                <div style={{  display: "flex", width: "100%", marginTop: "13px" }}>
                                    <input type="checkbox" id="agree" onChange={License_checkboxHandler} style={{  marginRight: "1%" }} 
                                    checked={attestation_checker} required />
                                    <label htmlFor="agree"> Kalyaan Data Protection Policy </label>
                                </div>
                                <div style={{  display: "flex", width: "100%", marginTop: "-20px" }}></div>
                                <div style={{  display: "flex", width: "130%", marginTop: "-20px", marginLeft: "30%" }}>
                                    <input type="checkbox" id="undertaken" onChange={Undertaken_checkboxHandler} 
                                        style={{  marginRight: "1%", marginTop: "-7%"}} checked={undertaken_checker} required />
                                    <label htmlFor="undertaken"> Undertaking against acts of criminality and violence </label>
                                </div>

                            </div>


                        </div>
                    </div>



                    <div className="footer norm" style={{ paddingBottom: "50px", marginTop: "165%" }}>
                        {/* <a href={configuration.ADD_AGENT_LINK} className="button_menu white w-button selected w--current" 
                            style={{ backgroundColor: "#ffc03d", color: "#432344", visibility: "hidden" }}>
                            <p className="button-text small">Cancel</p>
                        </a> */}
                        {(BUTTON_LOADING_ANIM === true) ? (
                            <button type="button" className="button_menu w-button selected w--current">
                                <p className="button-text small">Submitting........</p>
                            </button>
                        ) : (
                            <button type="submit" className="button_menu white w-button selected w--current">
                                <p className="button-text small">SUBMIT</p>
                            </button>
                        )}
                        <a href={configuration.ADD_AGENT_LINK} className="button_menu white w-button selected w--current" 
                            style={{ backgroundColor: "#ffc03d", color: "#432344" }}>
                            <p className="button-text small">CANCEL</p>
                        </a>
                    </div>



                </form>
            </div>

        </>
    );


}
















const UNDERTAKEN = ({}) => {
    return (
        // <div id="full_description" style={{ width: "700px", height: "500px", overflow: "auto" }}>
        //     <h2 style={{ textAlign: "center" }}><strong>UNDERSTAKING AGAINST ACT OF CRIMINALITY AND VIOLENCE</strong></h2>
        //     <p>&nbsp;</p>
            
        //     <p>I______________________________ Nigerian, male/female, of ___________________________________________ acting as a data capture agent of the National Commercial Tricycle Motorcycle Owners and Riders Association of Nigeria, working in association with, and on behalf of Kalyaan Welfare Consulting Limited, hereby undertake to be bound as follows: </p>

        //     <p>&nbsp;</p>

        //     <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- To utilise the data captured solely for the purpose of the digital registration of the members of the National Commercial Tricycle Motorcycle Owners and Riders Association of Nigeria;</span></p>
        //     <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Not to commit any breach of the data protection policy of Kalyaan Welfare Consulting Limited having read and understood same;</span></p>
        //     <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Not to commit any act of data fraud with the records and data acquired by me; </span></p>
        //     <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Not to work with or in association with any person or group of persons or syndicate to aid and abet the commission of any crime or offence during the data capture process or with the data captured by me;</span></p>
        //     <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- That I will submit myself willfully to the Nigerian Department of State Services, the Nigerian Police Force, the Economic and Financial Crime Commission and any other government security agency regarding any investigations for any fraud or criminal activity undertaking with the data capture device registered to and operated me;</span></p>
        //     <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- That I will always act honestly and diligently with the data capture device registered and handed to me, and solely for the purpose earlier stated.</span></p>

        // </div>

        <div id="full_description" style={{ width: "700px", height: "500px", overflow: "auto", textAlign: "justify", paddingRight: "10px" }}>
            <h2 style={{ textAlign: "center" }}><strong>UNDERTAKING AGAINST ACTS OF CRIMINALITY AND VIOLENCE</strong></h2>
            <p>&nbsp;</p>

            I, acting as a data capture agent of the National Commercial Tricycle Motorcycle Owners and Riders Association of Nigeria, working in 
            association with, and on behalf of Kalyaan Welfare Consulting Limited, hereby undertake to be bound as follows:

            <p>&nbsp;</p> 

            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" 
            style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- To utilise the data captured solely for the purpose of the digital 
            registration of the members of the National Commercial Tricycle Motorcycle Owners and Riders Association of Nigeria; </span></p>

            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" 
            style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Not to commit any act of data fraud with the records and 
            data acquired by me; </span></p>

            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" 
            style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Not to commit any act of data fraud with the records and data 
            acquired by me; </span></p>

            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" 
            style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Not to commit any act of data fraud with the records and 
            data acquired by me; </span></p>

            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" 
            style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- That I will submit myself willfully to the Nigerian Department of State 
            Services, the Nigerian Police Force, the Economic and Financial Crime Commission and any other government security agency regarding 
            any investigations for any fraud or criminal activity undertaking with the data capture device registered to and operated me; </span></p>

            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" 
            style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- That I will always act honestly and diligently with the data capture device 
            registered and handed to me, and solely for the purpose earlier stated.; </span></p>

            <p>&nbsp;</p> 

            <p>That I firmly make this declaration understanding the dire consequences of any and all illegal use of the data captured by the device 
                registered and handed over to me. </p>

            <p>&nbsp;</p> 

            <div style={{  display: "flex", width: "99%", marginTop: "-12px", marginLeft: "1%" }}>
                <input type="checkbox" id="Undertaking" checked readOnly style={{  marginRight: "1%", marginTop: "-1%"}} />
                <label htmlFor="Undertaking"> I acknowledge herein that I have read, understood and here undertake the obligations <br />stated in this 
                Data Capture Agents Undertaking </label>
            </div>
            {/* <p> I acknowledge herein that I have read, understood and here undertake the obligations stated in this Data Capture Agents Undertaking</p> */}
        </div>
    );
}














const LICENSE = ({}) => {
    return (
        <div id="full_description" style={{ width: "700px", height: "500px", overflow: "auto", textAlign: "justify", paddingRight: "10px" }}>
            <h2 style={{ textAlign: "center" }}><strong>DATA PROTECTION POLICY</strong></h2>
            <p>&nbsp;</p>
            <h3><strong>Aim of the Data Protection Policy</strong></h3>
            <p>Kalyaan Welfare Consulting Limited (hereafter referred to as &lsquo;Kalyaan Welfare&rsquo;) &nbsp;acknowledges that information technology should be at the service of every citizen.</p>
            <p>Kalyaan Welfare acknowledges that information technology shall not violate human identity, human rights, privacy, or individual or public liberties.</p>
            <p>Kalyaan Welfare is committed to maintaining globally accepted standards and principles of General Data Protection Regulation &ldquo;GDPR&rdquo;.</p>
            <p>Kalyaan Welfare acknowledges that data protection is the foundation of trustworthy relationships and the reputation of Kalyaan Welfare as a credible organisation.</p>
            <p>Kalyaan Welfare Data Protection Policy is meant to be a practical and easy to understand document to which all Kalyaan Welfare&rsquo;s client, departments, agencies, stakeholders and partners can refer to.</p>
            <h3><strong>Scope of the Data Protection Policy</strong></h3>
            <p>This policy applies to all personal data processed by &nbsp;Kalyaan Welfare.</p>
            <p>This Data Protection Policy applies to all entities of Kalyaan Welfare, including networks and branch offices in all countries of operation.</p>
            <p>The policy applies to all Kalyaan Welfare directors and staff.</p>
            <p>The provision of this policy may also be applied to any person employed by an entity that carries out activities for and on behalf of Kalyaan Welfare.</p>
            <p>In particular, this policy applies to implementing partners, suppliers, sub-grantees, stakeholders and other associated entities.</p>
            <p>Kalyaan Welfare&rsquo;s Data Protection Policy applies to all personal data that Kalyaan Welfare holds relating to identifiable individuals, meaning any information relating to an identified or identifiable individual.</p>
            <p>Any person operating a device that deals with data shall take responsibility for Kalyaan Welfare&rsquo;s ongoing compliance with this policy.</p>
            <h3><strong>Data Protection Principles</strong></h3>
            <ol>
            <li>Fairness and Lawfulness:</li>
            </ol>
            <ul>
            <li>When processing personal data, the individual rights of the data subjects must be protected. Personal data must be collected and processed in a legal and fair manner.</li>
            <li>Collected data shall be adequate, relevant and not excessive in relation to the purposes for which they are obtained and their further processing.</li>
            <li>Individual data can be processed upon voluntary consent of the person concerned.</li>
            </ul>
            <ol start="2">
            <li>Restriction to a specific purpose:</li>
            </ol>
            <ul>
            <li>Personal data can be processed only for the purpose that was defined before the data was collected. Personal data shall be obtained for specified, explicit and legitimate purposes, and shall not subsequently be processed in a manner that is incompatible with those purposes. Subsequent changes to the purpose are only possible to a limited extent and require justification.</li>
            <li>However, further data processing for statistical, scientific and historical purposes shall be considered compatible with the initial purposes of the data collection, if it is not used to take decisions with respect to the data subjects.</li>
            </ul>
            <ol start="3">
            <li>Transparency:</li>
            </ol>
            <ul>
            <li>The data subject must be informed of how his/her data is being handled. In general, personal data must be collected directly from the individual concerned. When the data is collected, the data subject must either be made aware of, or informed of:</li>
            </ul>
            <p>&nbsp;&nbsp; - &nbsp;The purpose of data processing;</p>
            <p>- Categories of third parties to whom the data might be transmitted;</p>
            <p>Processing of personal data must have received the consent of the data subject or must meet one of the following conditions: compliance with any legal obligation to which Kalyaan Welfare is subject; the performance of a public service mission entrusted to Kalyaan Welfare.</p>
            <ol start="4">
            <li>Confidentiality and Data Security:</li>
            </ol>
            <ul>
            <li>Personal data is subject to data secrecy. It must be treated as confidential on a personal level and secured with suitable organisational and technical measures to prevent unauthorised access, illegal processing or distribution, as well as accidental loss, modification or destruction.</li>
            </ul>
            <ol start="5">
            <li>Deletion:</li>
            </ol>
            <ul>
            <li>Personal data shall be retained in a form that allows the identification of the data subjects for a period no longer than is necessary for the purposes for which they are obtained and processed. There may be an indication of interests that merit protection or historical significance of this data in individual cases. If so, the data must remain on file until the interests that merit protection have been clarified legally, or the corporate archive has evaluated the data to determine whether it must be retained for historical purposes.</li>
            </ul>
            <ol start="6">
            <li>Factual Accuracy and Up-to-datedness of Data:</li>
            </ol>
            <ul>
            <li>Personal data on file must be correct, complete, and &ndash; if necessary &ndash; kept up to date. Suitable steps must be taken to ensure that inaccurate or incomplete data are deleted, corrected, supplemented or updated.</li>
            </ul>
            <h3><strong>Kalyaan Welfare Set of Data</strong></h3>
            <p>Set of Data herein referred to, means any information relating to a natural person who is or can be identified, directly or indirectly, by reference to an identification number or to one or more factors specific to his physical, physiological, mental, economic, cultural or social identity.</p>
            <p class="Body" style={{ marginBottom: "0in" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", lineHeight: "120%", fontFamily: "Montserrat" }} >This can include in particular:</span></p>
            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Names of individuals</span></p>
            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Living addresses</span></p>
            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Vehicle Registration numbers </span></p>
            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Telephone numbers</span></p>
            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Names of individuals next of kin</span></p>
            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Living addresses of next of kin</span></p>
            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Names of individuals family members </span></p>
            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Living addresses of members </span></p>
            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Identity card and passport</span></p>
            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Date and place of birth</span></p>
            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Identify of relatives</span></p>
            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Fingerprints</span></p>
            <p class="Body" style={{ marginBottom: "0in", lineHeight: "normal" }} ><span lang="EN-GB" style={{ fontSize: "10.0pt", fontFamily: "Montserrat" }} >- Riders ID numbers </span></p>
            <h3><strong>Processing of Data</strong></h3>
            <p>Processing of personal data means any operation or set of operations in relation to such data, whatever the mechanism used, especially the obtaining, recording, organisation, retention, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, blocking, deletion or destruction, such data being stored at Amazon Web Services in the United States of America, the consent hereby granted by the person whose data is being collected.</p>
            <h3><strong>Breach</strong></h3>
            <p>In the event of a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal data, Kalyaan Welfare shall promptly assess the risk to people&rsquo;s rights and freedoms and if necessary and appropriate report this breach to the relevant authorities or institutions.</p>
        </div>
    );
}



