
export const KalyaanNigerianStates = {

    "AB": "Abia",
    "AD": "Adamawa",
    "AK": "Akwa-Ibom",
    "AN": "Anambra",
    "BA": "Bauchi",
    "BY": "Bayelsa",
    "BN": "Benue",
    "BO": "Borno",
    "CR": "Cross River",
    "DT": "Delta",
    "EB": "Ebonyi",
    "ED": "Edo",
    "EK": "Ekiti",
    "EN": "Enugu",
    "FT": "FCT",
    "GB": "Gombe",
    "IM": "Imo",
    "JG": "Jigawa",
    "KD": "Kaduna",
    "KN": "Kano",
    "KT": "Katsina",
    "KB": "Kebbi",
    "KG": "Kogi",
    "KW": "Kwara",
    "LA": "Lagos",
    "NS": "Nasarawa",
    "NG": "Niger",
    "OG": "Ogun",
    "OD": "Ondo",
    "OS": "Osun",
    "OY": "Oyo",
    "PL": "Plateau",
    "RV": "Rivers",
    "SO": "Sokoto",
    "TB": "Taraba",
    "YB": "Yobe",
    "ZA": "Zamfara"

}
