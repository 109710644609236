
// REACT STANDARD LIBRARY CALLS
import React, {useState, useEffect} from 'react';
import { Link, useHistory } from "react-router-dom";
import Modal from 'react-modal';

/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';

/* IMPORT COMPONENTS. */
import { KalyaanNigerianStates } from '../../../Library/KalyaanNigerianStates';
import { KalyaanNigerianStateLGA } from '../../../Library/KalyaanNigerianStateLGA';
import { KalyaanNigerianStateLGABranch } from '../../../Library/KalyaanNigerianStateLGABranch';
import { KalyaanBranchUnits } from '../../../Library/KalyaanBranchUnits';

/* IMPORT LIBRARY  */
import { NIGERIAN_NUMBER_FORMATTER, ALL_LETTERS_CAPITALIZED, CAPITALIZE_FIRST_LETTERS, getData, isEmpty, getCookie, scrollToTop, OBJECT_TO_ARRAY } 
from '../../../Library/Library.js';





// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






export default function ACTIVE_MEMBERS({MenuController, DEFAULT_MENU_FEEDBACK, SEARCHED_MEMBER_DATA, PAGE_TITLE_DATAFEEDBACK, 
    DEFAULT_SUB_MENU, DEFAULT_SUB_MENU_FEEDBACK}) {

    // SET THE PAGE TITLE FOR THIS DASHBOAD SEMI-PAGE
    PAGE_TITLE_DATAFEEDBACK("Active Members | "+configuration.COMPANY_NAME);
    // STATE TO BE USED TO STORE DATA'S OF ALL MEMBERS
    const [ALL_MEMBER_DATA, setALL_MEMBER_DATA] = useState({});
    const [TOTAL_PAGE_NUMBER, setTOTAL_PAGE_NUMBER] = useState("");
    // CREATE A STATE TO BE USED TO CONTROL PAGINATION PAGES
    const [PAGINATION, setPAGINATION] = useState(1);

    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }


    // LOAD MEMBER DATAS AS COMPONENT LOADS UP
    useEffect(() => {
        // ONLY ALLOW API TO RUN IF THE "ALL_MEMBER_DATA" STATE IS EMPTY.
        if(isEmpty(ALL_MEMBER_DATA) === true){
            /* call the api function responsible for user password reset operation. */
            const url = configuration.MEMBER_LIST_API+PAGINATION+"?pageSize="+configuration.ITEM_TO_CALL_AT_ONCE+"&sortField=id&sortDir=desc&name=";
            /* Call the "postData" function to help us handle submission. */
            getData(url, session).then(response => {
                // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
                if(response.content){
                    // CONTROL IN HERE MEANS REQUEST WAS A SUCCESS, HENCE WE FETCH DATA AND SEND TO PARENT COMPONENT
                    setALL_MEMBER_DATA(response.content);
                    setTOTAL_PAGE_NUMBER(response.totalPages);
                }else{
                    // display server error message
                    openModal("System encountered an internal error while trying to load data.");
                }
            }).catch(error => {
                history.push(configuration.LOGIN);
            });
        }
    }, []);





    // FUNCTION TO PAGINATE FORWARD
    const ADD = () => {
        const newPageNumber = (PAGINATION + 1);
        FLIP_PAGE(newPageNumber);
    }
    // FUNCTION TO PAGINATE BACKWARDS
    const SUBTRACT = () => {
        const newPageNumber = (PAGINATION - 1);
        FLIP_PAGE(newPageNumber);
    }
    // ACTUAL PAGINATION FUNCTION. THIS FUNCTION IS RESPONSIBLE FOR NEW PAGE FLIPING/LOADING
    const FLIP_PAGE = (newPageNumber) => {
        // update the pagination state of new flipping
        setPAGINATION(newPageNumber);
        /* call the api function responsible for user password reset operation. */
        const url = configuration.MEMBER_LIST_API+newPageNumber+"?pageSize="+configuration.ITEM_TO_CALL_AT_ONCE+"&sortField=id&sortDir=desc&name=";
        /* Call the "postData" function to help us handle submission. */
        getData(url, session).then(response => {
            // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
            if(response.content){
                // CONTROL IN HERE MEANS REQUEST WAS A SUCCESS, HENCE WE FETCH DATA AND SEND TO PARENT COMPONENT
                setALL_MEMBER_DATA(response.content);
            }else{
                // display server error message
                openModal("System encountered an internal error while trying to load data.");
            }
        }).catch(error => {
            history.push(configuration.LOGIN);
        });

        // SCROLL PAGE TO THE TOP AFTER FETCHING AND UPDATING DATA
        scrollToTop();
    }






    // CREATE FORM STATES HERE
    const [STATE, setSTATE] = useState("");
    const [LGA, setLGA] = useState([{ value: "", label: "Filter by LGA" }]);
    const [BRANCH, setBRANCH] = useState([{ value: "", label: "Filter by Branch" }]);
    const [UNITS, setUNITS] = useState([{ value: "", label: "Filter by Unit" }]);
    // CREATE THE ONCHANGE METHOD, FOR THE STATE DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM STATE DROPDOWN
    const STATE_onChange = (event) => {
        /* Here we collect the selected state data and store it inside the "new_home_state" variable.  */
        let new_home_state = event.target.value;
        /* We update the "STATE" of the new selection. */
        setSTATE( new_home_state ); 
        /* AFTER GETTING THE STATE SELECTED FROM THE STATE DROPDOWN MENU, HERE WE NOW CALL ON THE "STATE_LOCAL_GOVERMENT_OPERATOR" FUNCTION TO HELP 
         * US GET ALL THE LOCAL GOVERNMENTS UNDER THIS STATE AND THEN POPULATE OUR LGA DROPDOWN WITH CORRESPONDING DATAS EACH TIME A NEW STATE IS 
         * SELECTED FROM THE STATE DROPDOWN LIST.  */
        STATE_LOCAL_GOVERMENT_OPERATOR(new_home_state);
    };

    // CREATE THE ONCHANGE METHOD, FOR THE LGA DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM LGA DROPDOWN
    const LGA_onChange = (event) => {
        /* Here we collect the selected lga data and store it inside the "new_lga" variable.  */
        let new_lga = event.target.value;
        // extract the label of the selected lga from the dropdown
        let lga_label = event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text
        // We update the "LGA" state of the new selection.
        setLGA( [{ value: new_lga, label: lga_label }] );

        /* AFTER GETTING THE LGA SELECTED FROM THE LGA DROPDOWN MENU, HERE WE NOW CALL ON THE "LGA_BRANCH_OPERATOR" FUNCTION TO HELP 
         * US GET ALL THE BRANCHES UNDER THIS LGA AND THEN POPULATE OUR BRANCH DROPDOWN WITH CORRESPONDING DATAS EACH TIME A NEW LGA IS 
         * SELECTED FROM THE LGA DROPDOWN LIST.  */
        LGA_BRANCH_OPERATOR(lga_label);
    };

    // CREATE THE ONCHANGE METHOD, FOR THE BRANCH DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM BRANCH DROPDOWN
    const BRANCH_onChange = (event) => {   
        /* Here we collect the selected BRANCH data and store it inside the "BRANCH" variable.  */
        let BRANCH_data = event.target.value;
        // extract the label of the selected branch from the dropdown
        let branch_label = event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text
        //UPDATE BRANCH STATE.
        setBRANCH( [{ value: BRANCH_data, label: branch_label }] );

        /* AFTER GETTING THE BRANCH SELECTED FROM THE BRANCH DROPDOWN MENU, HERE WE NOW CALL ON THE "BRANCH_TO_UNIT_OPERATOR" FUNCTION TO HELP 
         * US GET ALL THE UNITS UNDER THIS BRANCH AND THEN POPULATE OUR UNIT DROPDOWN WITH CORRESPONDING DATAS EACH TIME A NEW BRANCH IS 
         * SELECTED FROM THE BRANCH DROPDOWN LIST.  */
        BRANCH_TO_UNIT_OPERATOR(branch_label); 
    };

    // CREATE THE ONCHANGE METHOD, FOR THE BRANCH DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM BRANCH DROPDOWN
    const UNITS_onChange = (event) => {   
        /* Here we collect the selected UNIT data and store it inside the "UNIT_data" variable.  */
        let UNIT_data = event.target.value;  
        // extract the label of the selected unit from the dropdown
        let unit_label = event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text
        // UPDATE UNIT. 
        setUNITS( [{ value: UNIT_data, label: unit_label }] );
    };








    /* WE USE TO FUNCTION TO CONTROL WHAT SHOULD HAPPEN WHEN USER SELECTS A STATE. THIS FUNCTION TAKES IN A PARAMETER WHICH IS THE STATE THAT WAS 
     * SELECTED TO HELP IT FETCH THE CORRESPONDING LOCAL GOVERNMENT AREAS FOR THAT STATE */
    const STATE_LOCAL_GOVERMENT_OPERATOR = (SECLECTED_STATE) => {
        /* Now we create a temporary initially empty arrays, the aim of creating this array is so that we get to use it as temporary container to 
         * store the local government areas of the selected state.  */
        let dataset = [];
        // set the default value for our LGA dropdown each time the state dropdown is tampered with.
        dataset[0] = { value: "", label: "Filter by LGA" };
        /* Also since we shall be storing datas inside array soon using a loop, its important that we create an integer variable to be used to 
         * properly formate the indexes or keys of our new array containers as we store datas in them, right inside the loop.  */
        let countez = 1;
        /* Here we use the "KalyaanNigerianStateLGA" function to create and fetch an object of all the states in nigeria and their 
         * respective local government areas, then we store them inside the "lgaList" variable. */
        const KalyaanLgaList = KalyaanNigerianStateLGA;

        // get the Code of the selected state and use it against the object of local government area, in a bid to get the corresponding lga's of 
        // current selected state.
        Object.entries(KalyaanLgaList[SECLECTED_STATE]).forEach(entries => {
            /* Now we extract the local government area data, and use it to create an array that we shall be storing in our temporary arrays, we use the "countez" variable to 
             * align the indexes of the temporary arrays.  */
            dataset[countez] = { value: entries[1], label: entries[0] };
            /* At the end of each loop cycle of the second loop, its important we increment this "countez" variable so that the job of properly formatting the indexes of our
             * two temporary arrays can be smooth. */
            countez++;
        });

        /* now that we have the local government area data of user selected state, its time we store this "compiled in array" datas inside a react state, but first we empty this react
         * state first at this junction.  */
        setLGA([]);
        setBRANCH([{ value: "", label: "Filter by Branch" }]);
        setUNITS([{ value: "", label: "Filter by Unit" }]);
        /* after emptying the react state responsible for holding the list datas of user first and second prefered locations, we now store the array dataset innside the "dataset" 
         * vaiable in them so they can take a live effect of the location dropdown. Note that the state carrying the selected data of this two location dropdown form is different from
         * this state we updating here, because this state only store user first and second location dropdown datas.  */
        setLGA(dataset);
    };



    
    const LGA_BRANCH_OPERATOR = (SECLECTED_LGA) => {
        /* Now we create a temporary initially empty arrays, the aim of creating this array is so that we get to use it as temporary container to 
         * store the branches of the selected lga.  */
        let dataset = [];
        // set the default value for our Branch dropdown each time the LGA dropdown is tampered with.
        dataset[0] = { value: "", label: "Filter by Branch" };
        /* Also since we shall be storing datas inside array soon using a loop, its important that we create an integer variable to be used to 
         * properly formate the indexes or keys of our new array containers as we store datas in them, right inside the loop.  */
        let countez = 1;
        /* Here we use the "KalyaanNigerianStateLGABranch" function to create and fetch an object of all the lga's in nigeria and 
         * their respective branches, then we store them inside the "branchList" variable. */
        const branchList = KalyaanNigerianStateLGABranch;
        
        // just incase some datas in the branch object does not correlate with that of user selected lga, we run this clause to avert future errors.
        if(branchList[SECLECTED_LGA] !== undefined){
            // get the Code of the selected state and use it against the object of local government area, in a bid to get the corresponding lga's of 
            // current selected state.
            Object.entries(branchList[SECLECTED_LGA]).forEach(entries => {
                /* Now we extract the local government area data, and use it to create an array that we shall be storing in our temporary arrays, we use the "countez" variable to 
                * align the indexes of the temporary arrays.  */
                dataset[countez] = { value: entries[0], label: entries[1] };
                /* At the end of each loop cycle of the second loop, its important we increment this "countez" variable so that the job of properly formatting the indexes of our
                * two temporary arrays can be smooth. */
                countez++;
            });
        }

        /* now that we have the branch data of user selected lga, its time we store this "compiled in array" datas inside a react state, but first we 
         * empty this react state first at this junction.  */
        setBRANCH([]);
        // Since this page is an update page, meaning default data will be preloaded into forms and dropdowns, and also if this function is activated
        // it only signifies that state dropdown has been tampered with, it only makes sense to set to default empty state, the unit dropdown.
        setUNITS([{ value: "", label: "Filter by Unit" }]);
        /* after emptying the react state responsible for holding the list datas, we now store the array dataset innside the "dataset" vaiable in them 
         * so they can take a live effect on the dropdown.  */
        setBRANCH(dataset);
    };






    /* WE USE TO FUNCTION TO CONTROL WHAT SHOULD HAPPEN WHEN USER SELECTS BRANCH. THIS FUNCTION TAKES IN A PARAMETER WHICH IS THE BRANCH THAT WAS 
     * SELECTED TO HELP IT FETCH THE CORRESPONDING UNITS FOR THAT BRANCH */
    const BRANCH_TO_UNIT_OPERATOR = (SECLECTED_BRANCH) => {
        // Now we create a temporary initially empty arrays, the aim of creating this array is so that we get to use it as temporary container to 
        // store the branches of the selected lga.  
        let dataset = [];
        // set the default value for our Branch dropdown each time the LGA dropdown is tampered with.
        dataset[0] = { value: "", label: "Filter by Unit" };
        // Also since we shall be storing datas inside array soon using a loop, its important that we create an integer variable to be used to 
        // properly formate the indexes or keys of our new array containers as we store datas in them, right inside the loop.
        let countez = 1;
        // Here we use the "KalyaanBranchUnits" function to create and fetch an object of all the Unit's in nigeria and 
        // their respective Unit Codes, then we store them inside the "branchList" variable.
        const unitLists = KalyaanBranchUnits;

        // just incase some datas in the branch object does not correlate with that of user selected lga, we run this clause to avert future errors.
        if(unitLists[SECLECTED_BRANCH] !== undefined){
            // get the Code of the selected state and use it against the object of local government area, in a bid to get the corresponding lga's of 
            // current selected state.
            Object.entries(unitLists[SECLECTED_BRANCH]).forEach(entries => {
                // Now we extract the local government area data, and use it to create an array that we shall be storing in our temporary arrays, we use the "countez" variable to 
                // align the indexes of the temporary arrays.
                dataset[countez] = { value: entries[1], label: entries[0] };
                // At the end of each loop cycle of the second loop, its important we increment this "countez" variable so that the job of properly 
                // formatting the indexes of our two temporary arrays can be smooth. 
                countez++;
            });
        }

        /* now that we have the branch data of user selected lga, its time we store this "compiled in array" datas inside a react state, but first we 
         * empty this react state first at this junction.  */
        setUNITS([]);
        /* after emptying the react state responsible for holding the list datas, we now store the array dataset innside the "dataset" vaiable in them 
         * so they can take a live effect on the dropdown.  */
        setUNITS(dataset);
    };











    // SPECIAL STYLESHEETS
    const first_filter_styles = {borderRadius: "0px", backgroundColor: "#f7f7f7"};
    const filter_styles = {borderRadius: "0px", backgroundColor: "#f7f7f7", borderLeft: "0px solid #fff"};
    const extra_filter_styles = {width: "220px", borderRadius: "0px", backgroundColor: "#f7f7f7", borderLeft: "0px solid #fff"};

    



    // WE UPDATE THE PARENT COMPONENT TO REMIND IT THAT THE "MEMBER_RECORDS" MENU IS WHAT IS CURRENTLY ACTIVE, WE DO THIS JUST SO THAT THE SIDEBAR
    // ACTIVE-MENU-SELECTOR DOES NOT GO OUT OF PLACE EACH TIME THE FULL PAGE IS RELOADED.
    DEFAULT_MENU_FEEDBACK("MEMBER_RECORDS");
    DEFAULT_SUB_MENU_FEEDBACK("ACTIVE_MEMBERS_LIST");

    // if(MenuController === "MEMBER_RECORDS"){

        return (
            <>
                <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                    <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
                </Modal>

                <div className="table_bg" style={{ marginBottom: "100px" }}>
                    <div className="div-block-35" style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                        <h1 className="title_header"><b>ACTIVE MEMBERS</b></h1>
                    </div>

                    <div className="search-div">
                        <div className="form-block w-form" style={{ margin: "auto", marginBottom: "15px" }}>
                            <form id="email-form" name="email-form" data-name="Email Form" className="form-2">
                                <select id="field-3" name="field-3" data-name="Field 3" onChange={STATE_onChange} style={first_filter_styles} 
                                    class="search-text-fields longer w-select">
                                    <option value="">Filter by State</option>
                                    {OBJECT_TO_ARRAY(KalyaanNigerianStates, "BOTH").map((infos, index) => {
                                        return <option value={infos[0]}>{infos[1]}</option>;
                                    })}
                                </select>
                                <select id="field-3" name="field-3" data-name="Field 3" onChange={LGA_onChange} style={filter_styles} 
                                    class="search-text-fields longer w-select">
                                    {LGA.map((infos, index) => {
                                        return <option value={infos.value}>{(infos.label)}</option>;
                                    })}
                                </select>
                                <select id="field-3" name="field-3" data-name="Field 3" onChange={BRANCH_onChange} style={filter_styles} 
                                    class="search-text-fields longer w-select">
                                    {BRANCH.map((infos, index) => {
                                        return <option value={infos.value}>{(infos.label)}</option>;
                                    })}
                                </select>
                                <select id="field-3" name="field-3" data-name="Field 3" onChange={UNITS_onChange} style={extra_filter_styles} 
                                    class="search-text-fields longer w-select">
                                    {UNITS.map((infos, index) => {
                                        return <option value={infos.value}>{(infos.label)}</option>;
                                    })}
                                </select>
                                <button className="link-block w-inline-block">
                                    <img src={configuration.APP_DATA_RESOURCE+"images/search-icon.svg"} loading="lazy" width="17" alt="" />
                                </button>
                            </form>
                        </div>
                    </div>

                    <div className="data_row title">
                        <Link to="#" className="member-data-holder w-inline-block">
                            <div className="new-table-content header">Member No</div>
                            <div className="new-table-content header">Member Name</div>
                            <div className="new-table-content header">Phone Number</div>
                            <div className="new-table-content header">Gender</div>
                        </Link>
                        {/* <div className="div-block-27"><p className="status table title">Status</p></div> */}
                    </div>

                    
                    <TABLE_ITEMS_PRINTER SEARCHED_MEMBER_DATA={SEARCHED_MEMBER_DATA} ALL_MEMBER_DATA={ALL_MEMBER_DATA} />

                    <div className="pagenation-container" style={{ marginTop: "50px", marginBottom: "20px" }}>
                        {(PAGINATION === 1) ? null : (<div className="pagination-holder">
                            <button onClick={SUBTRACT}><p className="pagination">&lt;</p></button>
                        </div>)}
                        <div className="pagination-holder">
                            <p className="pagination">Page {PAGINATION} of {TOTAL_PAGE_NUMBER}</p>
                        </div>
                        {(PAGINATION === TOTAL_PAGE_NUMBER) ? null : (<div className="pagination-holder">
                            <button onClick={ADD}><p className="pagination">&gt;</p></button>
                        </div>)}
                    </div>


                </div>
            </>
        );

    // }else{
    //     return null;
    // }

}










const TABLE_ITEMS_PRINTER = ({SEARCHED_MEMBER_DATA, ALL_MEMBER_DATA}) => {
    //
    if(isEmpty(SEARCHED_MEMBER_DATA) === false){
        //
        return [SEARCHED_MEMBER_DATA].map((infos, index) => {
            // print out our view
            return (
                
                <div className="data_row" style={{ paddingBottom: "17px" }}>
                    <Link to={configuration.EDIT_MEMBER_RECORDS_LINK+infos.mbrno} className="member-data-holder w-inline-block">
                        <div className="new-table-content" style={{ textTransform: "uppercase"  }}> {infos.mbrno} </div>
                        <div className="new-table-content">{(ALL_LETTERS_CAPITALIZED(infos.mbrsname)+" "+CAPITALIZE_FIRST_LETTERS(infos.mbrfname))}</div>
                        <div className="new-table-content">{NIGERIAN_NUMBER_FORMATTER(infos.mbr_TEL_NO1)}
                        {(infos.mbr_TEL_NO2) ? ", "+NIGERIAN_NUMBER_FORMATTER(infos.mbr_TEL_NO2) : ""}</div>
                        <div className="new-table-content">{CAPITALIZE_FIRST_LETTERS(infos.mbr_GENDER)}</div>
                    </Link>
                    {/* <div className="div-block-27">
                        <p className={"status table"+((infos.mbr_STATUS === "alive") ? " active" : "")}>
                            {CAPITALIZE_FIRST_LETTERS( ((infos.mbr_STATUS === "alive") ? "Active" : "Inactive") )}
                        </p>
                    </div> */}
                </div>

            );
        });

    }else if(isEmpty(ALL_MEMBER_DATA) === false){
        //
        return ALL_MEMBER_DATA.map((infos, index) => {
            // print out our view
            return (
                
                <div className="data_row" style={{ paddingBottom: "17px" }}>
                    <Link to={configuration.MEMBER_PROFILE_LINK+infos.mbrno} className="member-data-holder w-inline-block">
                        <div className="new-table-content" style={{ textTransform: "uppercase"  }}> {infos.mbrno} </div>
                        <div className="new-table-content">{(ALL_LETTERS_CAPITALIZED(infos.mbrsname)+" "+CAPITALIZE_FIRST_LETTERS(infos.mbrfname))}</div>
                        <div className="new-table-content">{NIGERIAN_NUMBER_FORMATTER(infos.mbr_TEL_NO1)}</div>
                        <div className="new-table-content">{CAPITALIZE_FIRST_LETTERS(infos.mbr_GENDER)}</div>
                    </Link>
                    {/* <div className="div-block-27">
                        <p className={"status table"+((infos.mbr_STATUS === "alive") ? " active" : "")}>
                        {CAPITALIZE_FIRST_LETTERS( ((infos.mbr_STATUS === "alive") ? "Active" : "Inactive") )}
                        </p>
                    </div> */}
                </div>

            );
        });
    }else{
        return (
        
            <div className="data_row">
                <p>No Member records available.</p>
            </div>

        );
    }
};