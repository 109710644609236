
import React from 'react';





export default function NIGERIA_STATES_DROPDOWN(){

    return (
        <> 

            <option value="ABIA">Abia</option>
            <option value="ADAMAWA">Adamawa</option>
            <option value="AKWA IBOM">Akwa ibom</option>
            <option value="ANAMBRA">Anambra</option>
            <option value="BAUCHI">Bauchi</option>
            <option value="BAYELSA">Bayelsa</option>
            <option value="BENUE">Benue</option>
            <option value="BORNO">Borno</option>
            <option value="CROSS RIVER">Cross river</option>
            <option value="DELTA">Delta</option>
            <option value="EBONYI">Ebonyi</option>
            <option value="EDO">Edo</option>
            <option value="EKITI">EKITI</option>
            <option value="ENUGU">Enugu</option>
            <option value="ABUJA">Fct abuja</option>
            <option value="GOMBE">Gombe</option>
            <option value="IMO">Imo</option>
            <option value="JIGAWA">Jigawa</option>
            <option value="KADUNA">Kaduna</option>
            <option value="KANO">Kano</option>
            <option value="KATSINA">Katsina</option>
            <option value="KEBBI">Kebbi</option>
            <option value="KOGI">Kogi</option>
            <option value="KWARA">Kwara</option>
            <option value="LAGOS">Lagos</option>
            <option value="NASARAWA">Nasarawa</option>
            <option value="NIGER">Niger</option>
            <option value="OGUN">Ogun</option>
            <option value="ONDO">Ondo</option>
            <option value="OSUN">Osun</option>
            <option value="OYO">Oyo</option>
            <option value="PLATEAU">Plateau</option>
            <option value="RIVERS">Rivers</option>
            <option value="SOKOTO">Sokoto</option>
            <option value="TARABA">Taraba</option>
            <option value="YOBE">Yobe</option>
            <option value="ZAMFARA">Zamfara</option>

            {/* <option value="ABIA">ABIA</option>
            <option value="ADAMAWA">ADAMAWA</option>
            <option value="AKWA IBOM">AKWA IBOM</option>
            <option value="ANAMBRA">ANAMBRA</option>
            <option value="BAUCHI">BAUCHI</option>
            <option value="BAYELSA">BAYELSA</option>
            <option value="BENUE">BENUE</option>
            <option value="BORNO">BORNO</option>
            <option value="CROSS RIVER">CROSS RIVER</option>
            <option value="DELTA">DELTA</option>
            <option value="EBONYI">EBONYI</option>
            <option value="EDO">EDO</option>
            <option value="EKITI">EKITI</option>
            <option value="ENUGU">ENUGU</option>
            <option value="ABUJA">FCT ABUJA</option>
            <option value="GOMBE">GOMBE</option>
            <option value="IMO">IMO</option>
            <option value="JIGAWA">JIGAWA</option>
            <option value="KADUNA">KADUNA</option>
            <option value="KANO">KANO</option>
            <option value="KATSINA">KATSINA</option>
            <option value="KEBBI">KEBBI</option>
            <option value="KOGI">KOGI</option>
            <option value="KWARA">KWARA</option>
            <option value="LAGOS">LAGOS</option>
            <option value="NASARAWA">NASARAWA</option>
            <option value="NIGER">NIGER</option>
            <option value="OGUN">OGUN</option>
            <option value="ONDO">ONDO</option>
            <option value="OSUN">OSUN</option>
            <option value="OYO">OYO</option>
            <option value="PLATEAU">PLATEAU</option>
            <option value="RIVERS">RIVERS</option>
            <option value="SOKOTO">SOKOTO</option>
            <option value="TARABA">TARABA</option>
            <option value="YOBE">YOBE</option>
            <option value="ZAMFARA">ZAMFARA</option> */}
            
        </>      
    );

}

