
export const KalyaanNigerianStateLGA = {

    "AB" : {
        "Aba North" : "EZA",
        "Aba South" : "ABA",
        "Arochukwu" : "ACH",
        "Bende" : "BND",
        "Ikwuano" : "KWU",
        "Isiala-Ngwa North" : "KPU",
        "Isiala-Ngwa South" : "MBA",
        "Isuikwuato" : "MBL",
        "Obioma Ngwa" : "NGK",
        "Ohafia" : "HAF",
        "Osisioma" : "SSM",
        "Ugwunagbo" : "GWB",
        "Ukwa West" : "KKE",
        "Ukwa East" : "KEK",
        "Umuahia North" : "UMA",
        "Umuahia South" : "APR",
        "Umu-Nneochi" : "UNC",
    },

    "AD" : {
        "Demsa" : "DSA",
        "Fufore" : "FUR",
        "Ganye" : "GAN",
        "Girei" : "GRE",
        "Gombi" : "GMB",
        "Guyuk" : "GUY",
        "Hong" : "HNG",
        "Jada" : "JAD",
        "Lamurde" : "LMR",
        "Madagali" : "MDG",
        "Maiha" : "MAH",
        "Mayo-Belwa" : "MWA",
        "Michika" : "MCH",
        "Mubi North" : "MUB",
        "Mubi South" : "GYL",
        "Numan" : "NUM",
        "Shelleng" : "SHG",
        "Song" : "SNG",
        "Toungo" : "TNG",
        "Yola North" : "YLA",
        "Yola South" : "JMT"
    },

    "AK" : {
        "Abak" : "ABK",
        "Eastern-Obolo" : "KRT",
        "Eket" : "KET",
        "Esit Eket" : "KST",
        "Essien Udim" : "AFH",
        "Etim Ekpo" : "AEE",
        "Etinan" : "ETN",
        "Ibeno" : "PNG",
        "Ibesikpo Asutan" : "NGD",
        "Ibiono Ibom" : "BMT",
        "Ika" : "NYA",
        "Ikono" : "KKN",
        "Ikot Abasi" : "KTS",
        "Ikot Ekpene" : "KTE",
        "Ini" : "DRK",
        "Itu" : "TTU",
        "Mbo" : "ENW",
        "Mkpat Enin" : "MKP",
        "Nsit Atai" : "TAI",
        "Nsit Ibom" : "AFG",
        "Nsit Ubium" : "KTD",
        "Obot Akara" : "NTE",
        "Okobo" : "KPD",
        "Onna" : "ABT",
        "Oron" : "RNN",
        "Oruk Anam" : "KTM",
        "Udung Uko" : "EYF",
        "Ukanafun" : "KPK",
        "Uruan" : "DUU",
        "Urue-Offong/Oruko " : "UFG",
        "Uyo" : "UYY"
    },

    "AN" : {
        "Aguata" : "AGU",
        "Anambra East" : "AAH",
        "Anambra West" : "NZM",
        "Anaocha" : "NEN",
        "Awka North" : "ACA",
        "Awka South" : "AWK",
        "Ayamelum" : "NKK",
        "Dunukofia" : "KPP",
        "Ekwusigo" : "ZBL",
        "Idemili North" : "GDD",
        "Idemili south" : "JJT",
        "Ihiala" : "HAL",
        "Njikoka" : "ABN",
        "Nnewi North" : "NNE",
        "Nnewi South" : "UKP",
        "Ogbaru" : "ATN",
        "Onitsha North" : "NSH",
        "Onitsha South" : "FGG",
        "Orumba North" : "AJL",
        "Orumba South" : "UMZ",
        "Oyi" : "HTE"
    },

    "BA" : {
        "Alkaleri" : "ALK",
        "Bauchi" : "BAU",
        "Bogoro" : "BGR",
        "Damban" : "DBM",
        "Darazo" : "DRZ",
        "Dass" : "DAS",
        "Gamawa" : "GAM",
        "Ganjuwa" : "GJW",
        "Giade" : "GYD",
        "Itas/Gadau" : "TSG",
        "Jama Are" : "JMA",
        "Katagum" : "KTG",
        "Kirfi" : "KRF",
        "Misau" : "MSA",
        "Ningi" : "NNG",
        "Shira" : "SHR",
        "Tafawa-Balewa" : "TFB",
        "Toro" : "TRR",
        "Warji" : "WRJ",
        "Zaki" : "ZAK"
    },

    "BY" : {
        "Brass" : "BRS",
        "Ekeremor" : "KMR",
        "Kolokuma/Opokuma" : "KMK",
        "Nembe" : "NEM",
        "Ogbia" : "GBB",
        "Sagbama" : "SAG",
        "Southern Ijaw" : "SPR",
        "Yenegoa" : "YEN"
    },

    "BN" : {
        "Ado" : "GMU",
        "Agatu" : "GTU",
        "Apa" : "GKP",
        "Buruku" : "BKB",
        "Gboko" : "GBK",
        "Guma" : "YGJ",
        "Gwer East" : "ALD",
        "Gwer West" : "NAK",
        "Katsina-Ala" : "KAL",
        "Konshisha" : "TSE",
        "Kwande" : "WDP",
        "Logo" : "GBG",
        "Makurdi" : "MKD",
        "Obi" : "BRT",
        "Ogbadibo" : "BGT",
        "Ohimini" : "DKP",
        "Oju" : "JUX",
        "Okpokwu" : "PKG",
        "Otukpo" : "TKP",
        "Tarka" : "WNN",
        "Ukum" : "UKM",
        "Ushongo" : "SEL",
        "Vandeikya" : "VDY"
    },

    "BO" : {
        "Abadam" : "ADM",
        "Askira/Uba" : "ASU",
        "Bama" : "BAM",
        "Bayo" : "BAY",
        "Biu" : "BBU",
        "Chibok" : "CBK",
        "Damboa" : "DAM",
        "Dikwa" : "DKW",
        "Gubio" : "GUB",
        "Guzamala" : "GZM",
        "Gwoza" : "GZA",
        "Hawul" : "HWL",
        "Jere" : "JRE",
        "Kaga" : "KGG",
        "Kala/Balge" : "KBG",
        "Konduga" : "KDG",
        "Kukawa" : "KWA",
        "Kwaya Kusar" : "KWY",
        "Mafa" : "MAF",
        "Magumeri" : "MGM",
        "Maiduguri" : "MAG",
        "Marte" : "MAR",
        "Mobbar" : "MBR",
        "Monguno" : "MNG",
        "Ngala" : "NGL",
        "Nganzai" : "NGZ",
        "Shani" : "SHN"
    },

    "CR" : {
        "Abi" : "TGD",
        "Akamkpa" : "RAM",
        "Akpabuyo" : "KTA",
        "Bakassi" : "BKS",
        "Bekwarra" : "ABE",
        "Biase" : "AKP",
        "Boki" : "BJE",
        "Calabar Municipality" : "CAL",
        "Calabar South" : "ANA",
        "Etung" : "EFE",
        "Ikom" : "KMM",
        "Obanliku" : "BNS",
        "Obubra" : "BRA",
        "Obudu" : "UDU",
        "Odukpani" : "DUK",
        "Ogoja" : "GGJ",
        "Yakurr" : "GEP",
        "Yala" : "CKK"
    },

    "DT" : {
        "Aniocha North" : "SLK",
        "Aniocha South" : "GWK",
        "Bomadi" : "BMA",
        "Burutu" : "BUR",
        "Ethiope East" : "SKL",
        "Ethiope West" : "GRA",
        "Ika North" : "AGB",
        "Ika South" : "AYB",
        "Isoko North" : "DSZ",
        "Isoko South" : "LEH",
        "Ndokwa East" : "ABH",
        "Ndokwa West" : "KWC",
        "Okpe" : "KPE",
        "Oshimili North" : "AKU",
        "Oshimili South" : "ASB",
        "Patani" : "PTN",
        "Sapele" : "SAP",
        "Udu" : "ALA",
        "Ughelli North" : "UGH",
        "Ughelli South" : "JRT",
        "Ukwuani" : "BKW",
        "Uvwie" : "EFR",
        "Warri Central" : "GBJ",
        "Warri North" : "KLK",
        "Warri South" : "WWR"
    },

    "EB" : {
        "Abakaliki" : "AKL",
        "Afikpo North" : "AFK",
        "Afikpo South" : "EDA",
        "Ebonyi" : "UGB",
        "Ezza North" : "EBJ",
        "Ezza South" : "NKE",
        "Ikwo" : "CHR",
        "Ishielu" : "ZLL",
        "Ivo" : "SKA",
        "Izzi" : "BKL",
        "Ohaozara" : "HKW",
        "Ohaukwu" : "BZR",
        "Onicha" : "NCA"
    },

    "ED" : {
        "Akoko Edo" : "GAR",
        "Egor" : "USL",
        "Esan Central" : "RRU",
        "Esan North-East" : "URM",
        "Esan South-East" : "UBJ",
        "Esan West" : "EKP",
        "Etsako Central" : "FUG",
        "Etsako East" : "AGD",
        "Etsako West" : "AUC",
        "Igueben" : "GUE",
        "Ikpoba Okha" : "DGE",
        "Oredo" : "BEN",
        "Orhionmwon" : "ABD",
        "Ovia North-East" : "AKA",
        "Ovia South-West" : "GBZ",
        "Owan East" : "AFZ",
        "Owan West" : "SGD",
        "Uhunmwonde" : "HER"
    },

    "EK" : {
        "Ado Ekiti" : "ADK",
        "Efon" : "EFY",
        "Ekiti South-West" : "MUE",
        "Ekiti-East" : "LAW",
        "Ekiti-West" : "AMK",
        "Emure" : "EMR",
        "Ise/Orun" : "SSE",
        "Gbonyin" : "DEA",
        "Ido/Osi" : "DEK",
        "Ijero" : "JER",
        "Ikere Ekiti" : "KER",
        "Ikole" : "RLE",
        "Ilejemeje" : "YEK",
        "Irepodun/Ifelodun" : "GED",
        "Moba" : "TUN",
        "Oye" : "YEE"
    },

    "EN" : {
        "Aninri" : "DBR",
        "Awgu" : "AWG",
        "Enugu East" : "NKW",
        "Enugu North" : "ENU",
        "Enugu South" : "UWN",
        "Ezeagu" : "AGW",
        "Igbo-Etiti" : "GBD",
        "Igbo-Eze North" : "ENZ",
        "Igbo-Eze South" : "BBG",
        "Isi-Uzo" : "KEM",
        "Nkanu East" : "MGL",
        "Nkanu West" : "AGN",
        "Nsukka" : "NSK",
        "Oji-River" : "JRV",
        "Udenu" : "BLF",
        "Udi" : "UDD",
        "Uzo-Uwani" : "UMU"
    },

    "GB" : {
        "Akko" : "AKK",
        "Balanga" : "BLG",
        "Billiri" : "BLR",
        "Dukku" : "DKU",
        "Funakaye" : "FKY",
        "Gombe" : "GME",
        "Kaltungo" : "KLT",
        "Kwami" : "KWM",
        "Nafada/Bajoga" : "NFD",
        "Shomgom" : "SHM",
        "Yamaltu/Deba" : "YDB"
    },

    "IM" : {
        "Aboh-Mbaise" : "ABB",
        "Ahiazu-Mbaise" : "AFR",
        "Ehime-Mbano" : "EHM",
        "Ezinihitte" : "ETU",
        "Ideato North" : "URU",
        "Ideato South" : "DFB",
        "Ihitte/Uboma" : "EKE",
        "Ikeduru" : "KED",
        "Isiala Mbano" : "UML",
        "Isu" : "UMD",
        "Mbaitoli" : "NWA",
        "Ngor-Okpala" : "NGN",
        "Njaba" : "UMK",
        "Nkwerre" : "NKR",
        "Nwangele" : "AMG",
        "Obowo" : "TTK",
        "Oguta" : "GUA",
        "Ohaji/Egbema" : "EBM",
        "Okigwe" : "KGE",
        "Onuimo" : "KWE",
        "Orlu" : "RLU",
        "Orsu" : "AWT",
        "Oru East" : "MMA",
        "Oru West" : "NGB",
        "Owerri Municipal" : "WER",
        "Owerri North" : "RRT",
        "Owerri West" : "UMG"
    },

    "JG" : {
        "Auyo" : "AUY",
        "Babura" : "BBR",
        "Biriniwa" : "BNW",
        "Birnin Kudu" : "BKD",
        "Buji" : "BUJ",
        "Dutse" : "DUT",
        "Gagarawa" : "GGW",
        "Garki" : "GRK",
        "Gumel" : "GML",
        "Guri" : "GRR",
        "Gwaram" : "GRM",
        "Gwiwa" : "GWW",
        "Hadejia" : "HJA",
        "Jahun" : "JHN",
        "Kafin Hausa" : "KHS",
        "Kaugama" : "KGM",
        "Kazaure" : "KZR",
        "Kiri Kasamma" : "KKM",
        "Kiyawa" : "KYW",
        "Maigatari" : "MGR",
        "Malam Madori" : "MMR",
        "Miga" : "MGA",
        "Ringim" : "RNG",
        "Roni" : "RRN",
        "Sule-Tankarkar" : "STK",
        "Taura" : "TAR",
        "Yankwashi" : "YKS"
    },

    "KD" : {
        "Birnin-Gwari" : "BNG",
        "Chikun" : "KJM",
        "Giwa" : "GKW",
        "Igabi" : "TRK",
        "Ikara" : "KAR",
        "Jaba" : "KWB",
        "Jema'a" : "KAF",
        "Kachia" : "KCH",
        "Kaduna North" : "DKA",
        "Kaduna South" : "MKA",
        "Kagarko" : "KGK",
        "Kajuru" : "KJR",
        "Kaura" : "KRA",
        "Kauru" : "KRU",
        "Kubau" : "ANC",
        "Kudan" : "HKY",
        "Lere" : "SNK",
        "Makarfi" : "MKR",
        "Sabon-Gari" : "SBG",
        "Sanga" : "GWT",
        "Soba" : "MGN",
        "Zango-Kataf" : "ZKW",
        "Zaria" : "ZAR"
    },

    "KN" : {
        "Ajingi" : "AJG",
        "Albasu" : "ABS",
        "Bagwai" : "BGW",
        "Bebeji" : "BBJ",
        "Bichi" : "BCH",
        "Bunkure" : "BNK",
        "Dala" : "DAL",
        "Dambatta" : "DBT",
        "Dawaki Kudu" : "DKD",
        "Dawaki Tofa" : "DTF",
        "Doguwa" : "DGW",
        "Fagge" : "FGE",
        "Gabasawa" : "DSW",
        "Garko" : "GAK",
        "Garum Mallam" : "GNM",
        "Gaya" : "GYA",
        "Gezawa" : "GZW",
        "Gwale" : "GWL",
        "Gwarzo" : "GRZ",
        "Kabo" : "KBK",
        "Kano Municipal" : "KMC",
        "Karaye" : "KRY",
        "Kibiya" : "KBY",
        "Kiru" : "KKU",
        "Kumbotso" : "KBT",
        "Kunchi" : "KNC",
        "Kura" : "KUR",
        "Madobi" : "MDB",
        "Makoda" : "MKK",
        "Minjibir" : "MJB",
        "Nasarawa" : "NSR",
        "Rano" : "RAN",
        "Rimin Gado" : "RMG",
        "Rogo" : "RGG",
        "Shanono" : "SNN",
        "Sumaila" : "SML",
        "Takai" : "TAK",
        "Tarauni" : "TRN",
        "Tofa" : "TEA",
        "Tsanyawa" : "TYW",
        "Tudun Wada" : "TWD",
        "Ungogo" : "UGG",
        "Warawa" : "WRA",
        "Wudil" : "WDL"
    },

    "KT" : {
        "Bakori" : "BKR",
        "Batagarawa" : "BAT",
        "Batsari" : "BTR",
        "Baure" : "BRE",
        "Bindawa" : "BDW",
        "Charanchi" : "CRC",
        "Dan Musa" : "DMS",
        "Dandume" : "DDM",
        "Danja" : "DJA",
        "Daura" : "DRA",
        "Dutsi" : "DTS",
        "Dutsin-Ma" : "DTM",
        "Faskari" : "FSK",
        "Funtua" : "FTA",
        "Ingawa" : "NGW",
        "Jibia" : "JBY",
        "Kafur" : "KFR",
        "Kaita" : "KAT",
        "Kankara" : "KKR",
        "Kankia" : "KNK",
        "Katsina" : "KTN",
        "Kurfi" : "KUF",
        "Kusada" : "KSD",
        "Mai-Adua" : "MDW",
        "Malumfashi" : "MNF",
        "Mani" : "MAN",
        "Mashi" : "MSH",
        "Matazu" : "MTZ",
        "Musawa" : "MSW",
        "Rimi" : "RMY",
        "Sabuwa" : "SBA",
        "Safana" : "SFN",
        "Sandamu" : "SDM",
        "Zango" : "ZNG"
    },

    "KB" : {
        "Aleiro" : "ALR",
        "Arewa Dandi" : "KGW",
        "Argungu" : "ARG",
        "Augie" : "AUG",
        "Bagudo" : "BGD",
        "Birnin Kebbi" : "BRK",
        "Bunza" : "BNZ",
        "Dandi" : "KMB",
        "Danko/Wasagu" : "RBH",
        "Fakai" : "MHT",
        "Gwandu" : "GWN",
        "Jega" : "JEG",
        "Kalgo" : "KLG",
        "Koko/Besse" : "BES",
        "Maiyama" : "MYM",
        "Ngaski" : "WRR",
        "Sakaba" : "DRD",
        "Shanga" : "SNA",
        "Suru" : "DKG",
        "Yauri" : "YLW",
        "Zuru" : "ZUR"
    },

    "KG" : {
        "Adavi" : "DAV",
        "Ajaokuta" : "AJA",
        "Ankpa" : "KPA",
        "Bassa" : "BAS",
        "Dekina" : "KNA",
        "Ibaji" : "NDG",
        "Idah" : "DAH",
        "Igbalamela-Odolu" : "AJK",
        "Ijumu" : "JMU",
        "Kabba/Bunu" : "KAB",
        "Kogi" : "KKF",
        "Lokoja" : "LKJ",
        "Mopa-Muro" : "MPA",
        "Ofu" : "KFU",
        "Ogori/Magongo" : "KPF",
        "Okehi" : "KKH",
        "Okene" : "KNE",
        "Olamabolo" : "LAM",
        "Omala" : "BJK",
        "Yagba East" : "ERE",
        "Yagba West" : "SAN"
    },

    "KW" : {
        "Asa" : "AFN",
        "Baruteen" : "KSB",
        "Edu" : "LAF",
        "Ekiti" : "ARP",
        "Ifelodun" : "SHA",
        "Ilorin East" : "KEY",
        "Ilorin South" : "FUF",
        "Ilorin West" : "LRN",
        "Irepodun" : "MUN",
        "Isin" : "WSN",
        "Kaiama" : "KMA",
        "Moro" : "BDU",
        "Offa" : "FFA",
        "Oke-Ero" : "LFF",
        "Oyun" : "LEM",
        "Patigi" : "PTG"
    },

    "LA" : {
        "Agege" : "GGE",
        "Ajeromi-Ifelodun" : "AGL",
        "Alimosho" : "KTU",
        "Amuwo-Odofin" : "FST",
        "Apapa" : "APP",
        "Badagry" : "BDG",
        "Epe" : "EPE",
        "Eti-Osa" : "EKY",
        "Ibeju/Lekki" : "AKD",
        "Ifako-Ijaye" : "FKJ",
        "Ikeja" : "KJA",
        "Ikorodu" : "KRD",
        "Kosofe" : "KSF",
        "Lagos Island" : "AAA",
        "Lagos Mainland" : "LND",
        "Mushin" : "MUS",
        "Ojo" : "JJJ",
        "Oshodi-Isolo" : "LSD",
        "Shomolu" : "SMK",
        "Surulere" : "LSR"
    },

    "NS" : {
        "Akwanga" : "AKW",
        "Awe" : "AWE",
        "Doma" : "DMA",
        "Karu" : "KRV",
        "Keana" : "KEN",
        "Keffi" : "KEF",
        "Kokona" : "GRU",
        "Lafia" : "LFA",
        "Nasarawa" : "NSW",
        "Nasarawa-Eggon" : "NEG",
        "Obi" : "NBB",
        "Toto" : "NTT",
        "Wamba" : "WAM"
    },

    "NG" : {
        "Agaie" : "AGA",
        "Agwara" : "AGR",
        "Bida" : "BDA",
        "Borgu" : "NBS",
        "Bosso" : "MAK",
        "Chanchaga" : "MNA",
        "Edati" : "ENG",
        "Gbako" : "LMU",
        "Gurara" : "GWU",
        "Katcha" : "KHA",
        "Kontagora" : "KNT",
        "Lapai" : "LAP",
        "Lavun" : "KUG",
        "Magama" : "NAS",
        "Mariga" : "BMG",
        "Mashegu" : "MSG",
        "Mokwa" : "MKW",
        "Muya" : "SRP",
        "Paikoro" : "PAK",
        "Rafi" : "KAG",
        "Rijau" : "RJA",
        "Shiroro" : "KUT",
        "Suleja" : "SUL",
        "Tafa" : "WSE",
        "Wushishi" : "WSU"
    },

    "OG" : {
        "Abeokuta North" : "AKM",
        "Abeokuta South" : "AAB",
        "Ado-Odo/Ota" : "OTA",
        "Egbado North" : "AYE",
        "Egbado South" : "LAR",
        "Ewekoro" : "TRE",
        "Ifo" : "FFF",
        "Ijebu East" : "GBE",
        "Ijebu North" : "JGB",
        "Ijebu North East" : "JNE",
        "Ijebu Ode" : "JBD",
        "Ikenne" : "KNN",
        "Imeko-Afon" : "MEK",
        "Ipokia" : "PKA",
        "Obafemi-Owode" : "WDE",
        "Odeda" : "DED",
        "Odogbolu" : "DGB",
        "Ogun Waterside" : "ABG",
        "Remo North" : "JRM",
        "Shagamu" : "SMG"
    },

    "OD" : {
        "Akoko North East" : "KAK",
        "Akoko North West" : "ANG",
        "Akoko South East" : "KAA",
        "Akoko South West" : "SUA",
        "Akure North" : "AKR",
        "Akure South" : "JTA",
        "Ese-Odo" : "GKB",
        "Idanre" : "WEN",
        "Ifedore" : "FGB",
        "Ilaje" : "GBA",
        "Ile-Oluji/Okeigbo" : "LEL",
        "Irele" : "REL",
        "Odigbo" : "REF",
        "Okitipupa" : "KTP",
        "Ondo East" : "BDR",
        "Ondo West" : "NND",
        "Ose" : "FFN",
        "Owo" : "WWW"
    },

    "OS" : {
        "Atakumosa East" : "PRN",
        "Atakumosa West" : "SSU",
        "Ayedaade" : "GBN",
        "Ayedire" : "LGB",
        "Boluwaduro" : "TAN",
        "Boripe" : "RGB",
        "Ede North" : "EDT",
        "Ede South" : "EDE",
        "Egbedore" : "AAW",
        "Ejigbo" : "EJG",
        "Ife Central" : "FFE",
        "Ife East" : "FEE",
        "Ife North" : "PMD",
        "Ife South" : "FTD",
        "Ifedayo" : "FDY",
        "Ifelodun" : "KNR",
        "Ila" : "LRG",
        "Ilesha East" : "LES",
        "Ilesha West" : "LEW",
        "Irepodun" : "RLG",
        "Irewole" : "KRE",
        "Isokan" : "APM",
        "Iwo" : "WWD",
        "Obokun" : "BKN",
        "Odo-Otin" : "DTN",
        "Ola-Oluwa" : "BDS",
        "Olorunda" : "GNN",
        "Oriade" : "JJS",
        "Orolu" : "FNN",
        "Osogbo" : "SGB"
    },

    "OY" : {
        "Afijio" : "JBL",
        "Akinyele" : "MNY",
        "Atiba" : "FMT",
        "Atigbo" : "TDE",
        "Egbeda" : "EGB",
        "Ibadan North" : "BDJ",
        "Ibadan North East" : "AGG",
        "Ibadan North West" : "MRK",
        "Ibadan South East" : "MAP",
        "Ibadan South West" : "LUY",
        "Ibarapa Central" : "RUW",
        "Ibarapa East" : "AYT",
        "Ibarapa North" : "IRP",
        "Ido" : "DDA",
        "Irepo" : "KSH",
        "Iseyin" : "SEY",
        "Itesiwaju" : "TUT",
        "Iwajowa" : "WEL",
        "Kajola" : "KEH",
        "Lagelu" : "YNF",
        "Ogbomosho North" : "KNH",
        "Ogbomosho South" : "AME",
        "Ogo Oluwa" : "AJW",
        "Olorunsogo" : "GBY",
        "Oluyole" : "YRE",
        "Ona-Ara" : "AKN",
        "Orelope" : "GBH",
        "Ori Ire" : "KKY",
        "Oyo East" : "YYY",
        "Oyo West" : "JND",
        "Saki East" : "GMD",
        "Saki West" : "SHK",
        "Surulere" : "RSD"
    },

    "PL" : {
        "Barkin Ladi" : "BLD",
        "Bassa" : "BSA",
        "Bokkos" : "BKK",
        "Jos East" : "ANW",
        "Jos North" : "JJN",
        "Jos South" : "BUU",
        "Kanam" : "DNG",
        "Kanke" : "KWK",
        "Langtang North" : "LGT",
        "Langtang South" : "MBD",
        "Mangu" : "MGU",
        "Mikang" : "TNK",
        "Pankshin" : "PKN",
        "Qua'an Pan" : "QAP",
        "Riyom" : "RYM",
        "Shendam" : "SHD",
        "Wase" : "WAS"
    },

    "RV" : {
        "Abua/Odual" : "ABU",
        "Ahoada East" : "AHD",
        "Ahoada West" : "KNM",
        "Akuku Toru" : "ABM",
        "Andoni" : "NDN",
        "Asari-Toru" : "BGM",
        "Bonny" : "BNY",
        "Degema" : "DEG",
        "Eleme" : "NCH",
        "Emohua" : "MHA",
        "Etche" : "KHE",
        "Gokana" : "KPR",
        "Ikwerre" : "SKP",
        "Khana" : "BRR",
        "Obio/Akpor" : "RUM",
        "Ogba/Egbema/Ndoni" : "RGM",
        "Ogu/Bolo" : "GGU",
        "Okrika" : "KRK",
        "Omuma" : "BER",
        "Opobo/Nkoro" : "PBT",
        "Oyigbo" : "AFM",
        "Port-Harcourt" : "PHC",
        "Tai" : "SKN"
    },

    "SO" : {
        "Binji" : "BJN",
        "Bodinga" : "DBN",
        "Dange-Shuni" : "DGS",
        "Gada" : "GAD",
        "Goronyo" : "GRY",
        "Gudu" : "BLE",
        "Gwadabawa" : "GWD",
        "Illela" : "LLA",
        "Isa" : "SAA",
        "Kebbe" : "KBE",
        "Kware" : "KWR",
        "Rabah" : "RBA",
        "Sabon Birni" : "SBN",
        "Shagari" : "SGR",
        "Silame" : "SLM",
        "Sokoto North" : "SKK",
        "Sokoto South" : "SRZ",
        "Tambuwal" : "TBW",
        "Tangaza" : "TGZ",
        "Tureta" : "TRT",
        "Wamako" : "WMK",
        "Wurno" : "WRN",
        "Yabo" : "YYB"
    },

    "TB" : {
        "Ardo-Kola" : "ARD",
        "Bali" : "BAL",
        "Donga" : "DGA",
        "Gashaka" : "GKA",
        "Gassol" : "GAS",
        "Ibi" : "BBB",
        "Jalingo" : "JAL",
        "Karim-Lamido" : "KLD",
        "Kurmi" : "KRM",
        "Lau" : "LAU",
        "Sardauna" : "SDA",
        "Takum" : "TTM",
        "Ussa" : "USS",
        "Wukari" : "WKR",
        "Yorro" : "YRR",
        "Zing" : "TZG"
    },

    "YB" : {
        "Bade" : "GSH",
        "Bursari" : "DPH",
        "Damaturu" : "DTR",
        "Fika" : "FKA",
        "Fune" : "FUN",
        "Geidam" : "GDN",
        "Gujba" : "GJB",
        "Gulani" : "GLN",
        "Jakusko" : "JAK",
        "Karasuwa" : "KRS",
        "Machina" : "MCN",
        "Nangere" : "NNR",
        "Nguru" : "NGU",
        "Potiskum" : "PKM",
        "Tarmuwa" : "TMW",
        "Yunusari" : "YUN",
        "Yusufari" : "YSF"
    },

    "ZA" : {
        "Anka" : "ANK",
        "Bakura" : "BKA",
        "Birnin Magaji" : "BMJ",
        "Bukkuyum" : "BKM",
        "Bungudu" : "BUG",
        "Gummi" : "GMM",
        "Gusau" : "GUS",
        "Kaura Namoda" : "KRN",
        "Maradun" : "MRD",
        "Maru" : "MRR",
        "Shinkafi" : "SKF",
        "Talata Mafara" : "TMA",
        "Tsafe" : "TSF",
        "Zurmi" : "ZRM"
    },

    "FT" : {
        "Abaji" : "ABJ",
        "Amac" : "AMC",
        "Bwari" : "BWR",
        "Gwagwalada" : "GWA",
        "Kwali" : "KWL",
        "Kuje" : "KUJ"
    }

};
