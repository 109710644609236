
// REACT STANDARD LIBRARY CALLS
import React, {useState} from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import Modal from 'react-modal';


/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';

/* IMPORT LIBRARY  */
import { getData, getCookie, CAPITALIZE_FIRST_LETTERS, mathRandom } from '../../../Library/Library.js';





// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






export default function GWS_BATCH_APPLICATION({MenuController, DEFAULT_MENU_FEEDBACK, PAGE_TITLE_DATAFEEDBACK, DEFAULT_SUB_MENU, DEFAULT_SUB_MENU_FEEDBACK}) {

    // SET THE PAGE TITLE FOR THIS DASHBOAD SEMI-PAGE
    PAGE_TITLE_DATAFEEDBACK("G.W.S Batch Application | "+configuration.COMPANY_NAME);
    // GET FROM URL THE ID OF THE MEMBER WE WANT TO EDIT HIS/HER DATA
    const { id } = useParams();
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }







    // WE UPDATE THE PARENT COMPONENT TO REMIND IT THAT THE "GROUP_WELFARE" MENU IS WHAT IS CURRENTLY ACTIVE, WE DO THIS JUST SO THAT THE SIDEBAR
    // ACTIVE-MENU-SELECTOR DOES NOT GO OUT OF PLACE EACH TIME THE FULL PAGE IS RELOADED.
    DEFAULT_MENU_FEEDBACK("GROUP_WELFARE");
    DEFAULT_SUB_MENU_FEEDBACK("G_W_S_APPLICATION");
    
    // if(MenuController === "ID_CARD"){

        return (
            
            <>

                <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                    <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
                </Modal>
                    
                <div className="div-block-38">
                    <div className="title-holder"><h1 className="title_header">Batch Application</h1></div>

                    <form method="get">
                        <div className="form-holder" style={{ marginBottom: "1.8%" }}>
                            <div className="reg-form-block w-form">
                                <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="text">
                                    <select id="field-3" name="field-3" data-name="Field 3" className="text-fields long w-select">
                                        <option value="">Select one...</option>
                                        <option value="First">Long Rich Limited</option>
                                        <option value="Second">Johnson &amp; Johnsons</option>
                                        <option value="Third">Health wise Global</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <p className="paragraph-10" style={{ marginBottom: "6%" }}>This batch will be assigned to the selected Insurance Company for processing.</p>

                        <div className="botton-holder">
                            <Link to={configuration.G_W_S_APPLICATION_LINK} className="realbuttons w-button">Back</Link>
                            <Link to={"#"} className="realbuttons w-button">Generate PDF</Link>
                            <button type='submit' className="realbuttons filled w-button">Apply</button>
                        </div>
                    </form>
                </div>

            </>
        );

    // }else{
    //     return null;
    // }

}
