
import React, {useState} from 'react';
import { useHistory } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';



/* IMPORT LIBRARY  */
// import { getData, TRIM, getCookie, Delete_cookie } from '../Library/Library.js';
import { getData, TRIM, getCookie } from '../Library/Library.js';




export default function NavBar({MEMBER_DATAFEEDBACK}){


    // create an object of the history hook for redirection purposes
    let history = useHistory(); 



    // const LOGOUT = () => {       
    //     // Delet session cookie.
    //     Delete_cookie("token");
    //     // Redirect user back to homepage.
    //     history.push(configuration.LOGIN);
    // }


    /* state to be used to store user SEARCH_QUERY data. */
    const [SEARCH_QUERY, setSEARCH_QUERY] = useState("");
    const SEARCH_QUERY_onChange = (event) => {       setSEARCH_QUERY(event.target.value);       };  


    const PUBLISH_SEARCH = (e) => {
        /* line of code to ensure that html form default submit process is disabled, so that form submission can be handled by javascript in the REACT way.  */
        e.preventDefault();

        /* Here we create this clause to intercept what any situation whereby user has attempted to send an empty message accross. */
        if(TRIM(SEARCH_QUERY) !== ""){
            /* call the api function responsible for user password reset operation. */
            const url = configuration.SORT_MEMBER_BY_ID_API+SEARCH_QUERY;
            //
            const token = getCookie("token");
            /* Call the "postData" function to help us handle submission. */
            getData(url, token).then(response => {
                // CONTROL IN HERE MEANS REQUEST WAS A SUCCESS, HENCE WE FETCH DATA AND SEND TO PARENT COMPONENT
                MEMBER_DATAFEEDBACK(response);

                // console.log('response.status: ', response.mbr_TEL_NO1);
            }).catch(error => {
                /* At this junction we are certain that request submission has failed because of a network error, therefore we communicate this info to user. */
                history.push(configuration.LOGIN);
            });
        }else{
            //
            MEMBER_DATAFEEDBACK([]);
            /* Control in here signifies that user has attempted to send an empty message, therefore we display them the appropriate error message. */
            // ERROR_MESSAGE_VIEW_CONTROLLER_onChange("Error, you can not send an empty message.");
        }
    }
        
    
    return (

        <div className="top_nav" style={{ zIndex: "2" }}>
            <div className="div-block-8">
                <img src={configuration.APP_DATA_RESOURCE+"images/logo-purple.svg"} loading="lazy" width="35" alt="" className="image-7" />
                <img src={configuration.APP_DATA_RESOURCE+"images/burger-menu-icon.svg"} loading="lazy" width="30" 
                    data-w-id="372cbde3-1e73-f2c5-6e88-ba7ea8b7fc4b" alt="" className="image-8" />
            </div>

            <div className="search-div">
                <div className="form-block w-form">
                    <form onSubmit={PUBLISH_SEARCH}  id="email-form" name="email-form" data-name="Email Form" className="form-2">
                        <input onChange={SEARCH_QUERY_onChange} type="text" className="text-field widths w-input" maxlength="256" name="name" 
                            data-name="Name" placeholder="Search by Member ID or Name" id="name" />
                        <select id="field-3" name="field-3" data-name="Field 3" class="search-text-fields smaller w-select">
                            <option value="">Choose filter</option>
                            <option value="First">State</option>
                            <option value="Second">L.G.A.</option>
                        </select>
                    </form>
                </div>
                <button onClick={PUBLISH_SEARCH} className="link-block w-inline-block">
                    <img src={configuration.APP_DATA_RESOURCE+"images/search-icon.svg"} loading="lazy" width="17" alt="" />
                </button>
            </div>



            <div className="div-block-4">
                {/* <div data-hover="false" data-delay="0" className="dropdown w-dropdown">
                    <div className="dropdown-toggle w-dropdown-toggle">
                        <div className="icon w-icon-dropdown-toggle"></div>
                        <div className="text-block-7">Adefola Adams</div>
                    </div>
                    <nav className="dropdown-list w-dropdown-list">
                        <a href="#" className="name_drop_link w-dropdown-link">Notification</a>
                        <a href="#" className="name_drop_link w-dropdown-link">Change Password</a>
                        <a onClick={LOGOUT} href="#" className="name_drop_link w-dropdown-link">Log Out</a>
                    </nav>
                </div> */}
                <a href="#" aria-current="page" className="div-block-5 w-inline-block w--current">
                    <img src={configuration.APP_DATA_RESOURCE+"images/dashboard-top-icon.svg"} loading="lazy" width="18" alt="" />
                </a>
                <a data-w-id="fc52364c-20d0-4d45-f0d4-4e5657d9aa46" href="#" className="div-block-5 w-inline-block">
                    <img src={configuration.APP_DATA_RESOURCE+"images/bell.svg"} loading="lazy" width="22" alt="" />
                </a>
                <div className="div-block-6"></div>
            </div>
        </div>

    );

}

