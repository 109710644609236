
export const KalyaanBranchUnits = {

    "Abaji": {
        "A.B Mamman Unit" : "01",
        "Abaji Garage" : "02",
        "Aganya Junction" : "03",
        "Alh Umaru Units" : "04",
        "Central Junction" : "05",
        "Larubawa Junction" : "06",
        "Lokoja Unit" : "07",
        "Market Junction" : "08",
        "Rubouch Units" : "09",
    },
    
    "Apo - Galadimawa": {
        "Galadimawa Bus Stop" : "01",
        "Galadimawa to Gudu" : "02",
        "Galadimawa to Jabi" : "03",
        "Galadimawa to Sunyvale" : "04",
        "Galadimawa Village" : "05",
    },
    
    "Apo - Kabusa Junction": {
        "Apo Dutse " : "01",
        "Apo NEPA" : "02",
        "Apo Primary" : "03",
        "Cedarcrest" : "04",
        "Dan Kogi" : "05",
        "Kabusa Junction " : "06",
        "Kabusa to Waru" : "07",
        "Shoprite" : "08",
        "Waru" : "09",
        "Wassa" : "10",
        "Wumba" : "11",
    },
    
    "Apo - Kabusa Village": {
        "A.P." : "01",
        "Dakwo" : "02",
        "EFAB Estate" : "03",
        "Games Village" : "04",
        "Gudu" : "05",
        "House on the Rock" : "06",
        "Kabusa Junction" : "07",
        "Kabusa Market" : "08",
        "Kabusa to A.P. Primary" : "09",
        "Lokogoma" : "10",
        "Panteka" : "11",
        "Sherriti 1" : "12",
        "Sherriti 2" : "13",
        "Sunycale Estate" : "14",
    },
    
    "Asokoro": {
        "A.Y.A" : "01",
        "Abacha Barracks" : "02",
        "Guzape" : "03",
        "Kunundomu by INEC" : "04",
        "Kunundomu Market" : "05",
        "Kunundomu Police Outpost" : "06",
        "Kunundomu Village" : "07",
        "SARS" : "08",
    },
    
    "Gwarimpa": {
        "Eco Bank" : "01",
        "Efab Estate" : "02",
        "Galadima Gate" : "03",
        "Gwarimpa Tipper Garage" : "04",
        "Jahi" : "05",
        "Kado Kuchi" : "06",
        "Mab Global" : "07",
        "Model City Gate" : "08",
        "Phase 3" : "09",
        "SETRACO" : "10",
    },
    
    "Jikwoyi": {
        "Baba Na Gode Bus Stop" : "01",
        "Customary Court" : "02",
        "FCDA Quarters" : "03",
        "Jikwoyi Junction 1" : "04",
        "Jikwoyi Junction 2" : "05",
        "Jikwoyi Market Junction" : "06",
        "Karshi 1" : "07",
        "Karshi Main" : "08",
        "Karshi New site" : "09",
        "Kurudu Barracks" : "10",
        "Kurudu Police Barracks" : "11",
        "Orozo 1" : "12",
        "Orozo 2" : "13",
    },
    
    "Karmo": {
        "Daki Biyu" : "01",
        "Dei Dei Timber Shade" : "02",
        "Dei Dei Tomato Junction" : "03",
        "Fish Market" : "04",
        "Gwagwa" : "05",
        "Jabi to Airport Junction" : "06",
        "Jabi to Jahi" : "07",
        "Jiwu Junction" : "08",
        "Kado" : "09",
        "Karmo Last Gate" : "10",
        "Karmo Market" : "11",
        "Lifecamp" : "12",
        "New Karmo" : "13",
        "Old Karmo" : "14",
        "Railway Village" : "15",
        "Reke Junction" : "16",
        "Utako" : "17",
    },
    
    "Karu": {
        "Custom Hospital" : "01",
        "District Head Junction" : "02",
        "EFCC Junction" : "03",
        "INEC" : "04",
        "Karu Market" : "05",
        "Karu Park" : "06",
        "Karu Under Bridge" : "07",
    },
    
    "Lugbe Across":{
        "Aleata" : "01",
        "BoreHole " : "02",
        "Car wash Across" : "03",
        "Chika " : "04",
        "Diamond Estate " : "05",
        "Federal Staff Hospital" : "06",
        "Goshen Villa " : "07",
        "Kaama Estate " : "08",
        "Karomajiji" : "09",
        "Kuchigoro" : "10",
        "Lugbe Across" : "11",
        "NUT" : "12",
        "Piwoyi" : "13",
        "Police Signboard Across" : "14",
        "Pyakasa" : "15",
        "Rainbow Estate" : "16",
        "Ruga" : "17",
        "Sabon Lugbe" : "18",
        "VON" : "19",
        "Pindi" : "20",
        "Gido Estate" : "21"
    },
    
    "Lugbe F.H.A 9": {
        "ACO Estate" : "01",
        "Bassa" : "02",
        "F.H.A Carwash" : "03",
        "F.H.A Junction" : "04",
        "Gasa Primary" : "05",
        "Gosa Borehole" : "06",
        "Gosa Market" : "07",
        "Gosa Toge" : "08",
        "Ido" : "09",
        "Jedo Junction" : "10",
        "Lugbe AMAC Market" : "11",
        "New Ido" : "12",
        "Nualege" : "13",
        "Police Signboard" : "14",
        "Sauka" : "15",
        "Tudun Wada" : "16",
    },
    
    "Nyanya": {
        "Gwagwa Lafe 1" : "01",
        "Gwagwa Lafe 2" : "02",
        "Nyanya Check Point" : "03",
        "Nyanya Market" : "04",
        "Nyanya Terminus" : "05",
        "Nyanya Under Bridge 1" : "06",
        "Nyanya Under Bridge 2" : "07",
        "Road Safety" : "08",
    },
    
    "Bwari": {
        "Deeper Life Junction" : "01",
        "Kuduru" : "02",
        "Sabon Gari" : "03",
        "T-Junction" : "04",
        "Bwari Market" : "05",
        "Tipper Garage" : "06",
        "Ushafa" : "07",
    },
    
    "Kubwa": {
        "Across" : "01",
        "Arab Junction" : "02",
        "Byazhin Junction" : "03",
        "Bambara" : "04",
        "Kubwa Market" : "05",
        "FO1" : "06",
        "NNPC Junction" : "07",
        "NYSC Junction" : "08",
        "Phase III Junction " : "09",
        "Phase IV Hospital" : "10",
    },
    
    "Mpape": {
        "Berger Quarry" : "01",
        "Liberty Junction" : "02",
        "Living Faith Junction" : "03",
        "Crush Rocks" : "04",
        "Katampe" : "05",
        "Mpape GRA" : "06",
        "Mpape Mashapha Durumi" : "07",
    },
    
    "Gwagwalada": {
        "Dobi Garage " : "01",
        "Down Gate Market " : "02",
        "GT Bank " : "03",
        "Hajj Camp " : "04",
        "Kuje Road " : "05",
        "Park Road " : "06",
        "S.D.P.1" : "07",
        "Specialist " : "08",
        "University " : "09",
    },
    
    "Kwali": {
        "First Junction" : "01",
        "Gada Biyu" : "02",
        "Kwaita" : "03",
        "Market" : "04",
        "Paiyi Junction" : "05",
        "Yangaiji" : "06",
        "Ankasa" : "07",
        "Checheji" : "08",
        "Dabi" : "09",
        "Gbako" : "10",
        "General Hospital" : "11",
        "Lambata" : "12",
        "Low-Cost" : "13",
        "Shaida" : "14"
    },
    
    "Kuje": {
        "Bamish" : "01",
        "Chibiri" : "02",
        "Dafara" : "03",
        "Fasali" : "04",
        "Fegi" : "05",
        "Forest" : "06",
        "Godaba" : "07",
        "Kayada" : "08",
        "Kuchako" : "09",
        "One Thousand" : "10",
        "Prison Junction" : "11",
        "Shaidadi" : "12",
        "Tipper Garage" : "13",
        "Unguwan Gade" : "14",
        "Unguwan Lado" : "15",
        "Zamfara" : "16"
    }

};
