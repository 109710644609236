import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';



/* WEB APP CONFIG FILE. */
import { configuration } from './Config/Config';



/* IMPORT LIBRARY  */
import { getCookie } from './Library/Library.js';


// PAGES
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import Members_ID_card_batch_printer from './Pages/DashboardPages/ID_Card_Module/ID_CARD_PRINTER/Members_ID_card_batch_printer';
import Print_Member_ID_cards from './Pages/DashboardPages/ID_Card_Module/ID_CARD_PRINTER/Print_Member_ID_cards';
import Print_Agent_ID_cards from './Pages/DashboardPages/ID_Card_Module/ID_CARD_PRINTER/Print_Agent_ID_cards';
import Agent_Registration from './Pages/Agent_Registration';
import VeteransRegistration from './Pages/VeteransRegistration';



function App() {
    return (

        <Router>
            <Switch>

                <PrivateRoute path={configuration.DASHBOARD}>
                    <Dashboard />
                </PrivateRoute>

                <PrivateRoute path={configuration.BATCH_PRINT_MEMBERS_ID_CARDS+"/:pagination_index"}>
                    <Members_ID_card_batch_printer />
                </PrivateRoute>

                <PrivateRoute path={configuration.PRINT_MEMBER_DASHBOARD+"/:id"}>
                    <Print_Member_ID_cards />
                </PrivateRoute>

                <PrivateRoute path={configuration.PRINT_AGENT_DASHBOARD+"/:id"}>
                    <Print_Agent_ID_cards />
                </PrivateRoute>

                {/* <NeutralRoute path={configuration.ADD_AGENT_LINK}>
                    <Agent_Registration />
                </NeutralRoute> */}
                <Route component={Agent_Registration} path={configuration.ADD_AGENT_LINK} />

                <Route component={VeteransRegistration} path={configuration.ADD_VETERAN_LINK} />

                <Switch>

                    <SpecialRoute1 path={configuration.LOGIN}>
                        <Login />
                    </SpecialRoute1>

                    {/* <NeutralRoute path={configuration.ADD_AGENT_LINK}>
                        <Agent_Registration />
                    </NeutralRoute> */}

                    {/* <Route component={Agent_Registration} path={configuration.ADD_AGENT_LINK} /> */}
                    
                    {/* <Route component={Login} path={configuration.LOGIN} /> */}
                    {/* <Route component={Error_404} /> */}

                </Switch>
                
            </Switch>
        </Router>

    );
}

export default App;




















// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                getCookie("token") ? (
                    children
                ):( 
                    <Redirect to={{ pathname: configuration.LOGIN, state: { from: location } }} />
                )
            }
        />
    );
}








/* OUR FIRST SPECIAL ROUTE, JUST TO ENSURE THAT THE LOGIN PAGE CAN ONLY BE ACCESSIBLE WHEN USER IS NOT LOGGED IN, IF USER IS LOGGED IN THIS PAGE REDIRECTS TO DASHBOARD. */
function SpecialRoute1({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                getCookie("token") ? (
                    <Redirect to={{ pathname: configuration.DASHBOARD, state: { from: location } }} />
                ):(
                    children
                )
            }
        />
    );
}








/* OUR FIRST SPECIAL ROUTE, JUST TO ENSURE THAT THE LOGIN PAGE CAN ONLY BE ACCESSIBLE WHEN USER IS NOT LOGGED IN, IF USER IS LOGGED IN THIS PAGE REDIRECTS TO DASHBOARD. */
function NeutralRoute({ children, ...rest }) {
    return (
        <Route {...rest} children />
    );
}


















// function PrivateRoute({ children, ...rest }) {
//     return (
//         <Route
//             {...rest}
//             render={({ location }) =>
//                 children
//             }
//         />
//     );
// }






// function PrivateRoute({ children, ...rest }) {
//     return (
//         <Route
//             {...rest}
//             render={({ location }) =>
//                 getCookie("flatshare_member_hash") ? (
//                     children
//                 ):(
//                     <Redirect to={{ pathname: configuration.LOGIN, state: { from: location } }} />
//                 )
//             }
//         />
//     );
// }
