
// REACT STANDARD LIBRARY CALLS
import React, {useState} from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import Modal from 'react-modal';


/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';

/* IMPORT LIBRARY  */
import { getData, getCookie, CAPITALIZE_FIRST_LETTERS, mathRandom } from '../../../Library/Library.js';





// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






export default function APPROVAL_AND_POLICY({MenuController, DEFAULT_MENU_FEEDBACK, PAGE_TITLE_DATAFEEDBACK, DEFAULT_SUB_MENU, DEFAULT_SUB_MENU_FEEDBACK}) {

    // SET THE PAGE TITLE FOR THIS DASHBOAD SEMI-PAGE
    PAGE_TITLE_DATAFEEDBACK("Approval and Policy | "+configuration.COMPANY_NAME);
    // GET FROM URL THE ID OF THE MEMBER WE WANT TO EDIT HIS/HER DATA
    const { id } = useParams();
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }







    // WE UPDATE THE PARENT COMPONENT TO REMIND IT THAT THE "GROUP_WELFARE" MENU IS WHAT IS CURRENTLY ACTIVE, WE DO THIS JUST SO THAT THE SIDEBAR
    // ACTIVE-MENU-SELECTOR DOES NOT GO OUT OF PLACE EACH TIME THE FULL PAGE IS RELOADED.
    DEFAULT_MENU_FEEDBACK("GROUP_WELFARE");
    DEFAULT_SUB_MENU_FEEDBACK("APPROVAL_AND_POLICY");
    
    // if(MenuController === "ID_CARD"){

        return (
            
            <>

                <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                    <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
                </Modal>
                    
                <div className="table_bg" style={{ marginBottom: "100px" }}>
                    <div className="title-holder"><h1 className="title_header">Insurance Companies</h1></div>

                    <div className="data_row title">
                        <a href="#" className="member-data-holder w-inline-block">
                            <div className="new-table-content header">Company Name</div>
                            <div className="new-table-content header">Phone Number</div>
                            <div className="new-table-content header">Email</div>
                        </a>
                        <div className="div-block-27">
                            <p className="status table title">Policies</p>
                        </div>
                    </div>
                    <div className="data_row">
                        <a href="#" className="member-data-holder w-inline-block">
                            <div className="new-table-content">Long Rich Limited</div>
                            <div className="new-table-content">234 70 436 55757</div>
                            <div className="new-table-content">info@longrich.com</div>
                        </a>
                        <div className="div-block-27">
                            <p className="status table active">Assigned</p>
                        </div>
                    </div>
                    <div className="data_row">
                        <a href="#" className="member-data-holder w-inline-block">
                            <div className="new-table-content">Johnson &amp; Johnsons</div>
                            <div className="new-table-content">234 70 436 55757</div>
                            <div className="new-table-content">info@jandj.com</div>
                        </a>
                        <div className="div-block-27">
                            <p className="status table">Pending</p>
                        </div>
                    </div>
                    <div className="data_row">
                        <a href="#" className="member-data-holder w-inline-block">
                            <div className="new-table-content">Wise Health Global</div>
                            <div className="new-table-content">234 70 436 55757</div>
                            <div className="new-table-content">info@wisehealth.com</div>
                        </a>
                        <div className="div-block-27">
                            <p className="status table active">Assigned</p>
                        </div>
                    </div>

                    <div className="pagenation-container" style={{ marginTop: "50px", marginBottom: "20px" }}>
                        <div className="pagination-holder">
                            <button onClick={""}><p className="pagination">&lt;</p></button>
                        </div>
                        <div className="pagination-holder">
                            <p className="pagination">Page {"1"} of {"10"}</p>
                        </div>
                        <div className="pagination-holder">
                            <button onClick={""}><p className="pagination">&gt;</p></button>
                        </div>
                    </div>
                </div>

            </>
        );

    // }else{
    //     return null;
    // }

}
