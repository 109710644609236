
// REACT STANDARD LIBRARY CALLS
import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';

/* IMPORT COMPONENTS. */
import NIGERIA_STATES_DROPDOWN from '../../../Components/Nigerian_States';

/* IMPORT LIBRARY  */
import { FORMDATA_POSTDATA, FIRST_FEW_CHARS_IN_STRING_FETCHER, getCookie, EXPLODE, TRIM, getData, REACT_DATEPICKER_DATE_FORMATTER, DATE_CONFIRMER, 
    CAPITALIZE_FIRST_LETTERS } from '../../../Library/Library.js';
import { NIGERIA_STATES_LOCAL_GOVERNMENT_AREA } from '../../../Library/STATES_LGA.js';
import { NIGERIA_STATES_LOCAL_GOVERNMENT_AREA_BRANCHES } from '../../../Library/LGA_WARD_OR_BRANCH_SCRIPT.js';
import { AGENT_REGISTRATION_DATA_AUTHENTICATOR_2 } from '../../../Library/REG_LIBRARY.js';






// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal window custom styles
const customStyles = { content: { top: '40%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } };






export default function EDIT_AGENT({MenuController, DEFAULT_MENU_FEEDBACK, PAGE_TITLE_DATAFEEDBACK, DEFAULT_SUB_MENU, DEFAULT_SUB_MENU_FEEDBACK}) {

    // SET THE PAGE TITLE FOR THIS DASHBOAD SEMI-PAGE
    PAGE_TITLE_DATAFEEDBACK("Edit Agent | "+configuration.COMPANY_NAME);
    // GET FROM URL THE ID OF THE MEMBER WE WANT TO EDIT HIS/HER DATA
    const { agent_id } = useParams();
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    // since this page is a private page, hence get login cookie
    const session = getCookie("token");
    // A FUNCTION TO ENSURE THAT USER DOESN'T SEE THIS PAGE AS SOON AS THEIR SESSION/COOKIE EXPIRES
    if(!session)history.push(configuration.LOGIN);


    //         M O D A L     C O N T R O L    O B J E C T S
    // CREATE STATE TO BE USED TO CONTROL MODAL OPENING AND CLOSING, AND ANOTHER TO HOUSE MODAL VIEW AND FINALLY TWO MODAL CONTROLLER FUNCTIONS
    const [MODAL_TOGGLE, setMODAL_TOGGLE] = useState(false);
    const [MODAL_VIEW, setMODAL_VIEW] = useState("");
    function openModal(VIEW){      setMODAL_TOGGLE(true);     setMODAL_VIEW(VIEW);      }
    function closeModal(){      setMODAL_TOGGLE(false);     setMODAL_VIEW("");       }
    


    // CREATE STATE TO BE USED TO CONTROL BUTTON LOADING ANIMATION, FOR THIS COMPONENT.
    const [BUTTON_LOADING_ANIM, setBUTTON_LOADING_ANIM] = useState(false);
    // CREATE STATE TO BE USED TO STORE IMAGE
    // const [imgUrl, setImgUrl] = useState("/DATA/Spin-Preloader-"+mathRandom(1, 2)+".gif");
    const [imgUrl, setImgUrl] = useState("/DATA/Spin-Preloader-2.gif");
    // CREATE FORM STATES HERE
    const [FIRSTNAME, setFIRSTNAME] = useState("");
    const [LASTNAME, setLASTNAME] = useState("");
    // const [DATE_OF_BIRTH, setDATE_OF_BIRTH] = useState("");
    const [DATE_OF_BIRTH, setDATE_OF_BIRTH] = useState(null);
    const [PASSWORD, setPASSWORD] = useState("");
    const [RETYPED_PASSWORD, setRETYPED_PASSWORD] = useState("");
    const [PHONE_1, setPHONE_1] = useState("");
    const [PHONE_2, setPHONE_2] = useState("");
    const [GENDER, setGENDER] = useState("");
    const [ADDRESS_1, setADDRESS_1] = useState("");
    const [ADDRESS_2, setADDRESS_2] = useState("");
    const [PHONE_SERIAL, setPHONE_SERIAL] = useState("");
    const [CITY, setCITY] = useState("");
    const [STATE, setSTATE] = useState("");
    const [LGA, setLGA] = useState([{ value: "", label: "Select LGA" }]);
    const [BRANCH, setBRANCH] = useState([{ value: "", label: "Select Branch" }]);
    const [STATE_OF_ORIGIN, setSTATE_OF_ORIGIN] = useState("");
    const [LGA_OF_ORIGIN, setLGA_OF_ORIGIN] = useState([{ value: "", label: "Select LGA" }]);
    const [TOWN, setTOWN] = useState("");
    const [USERNAME, setUSERNAME] = useState(((FIRSTNAME) && (LGA[0])) ? FIRSTNAME+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(STATE, 2)+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(LGA[0].value, 3) : "");
    const [selectedFile, setSelectedFile] = useState(null);

    // UNCHANGED FUNCTIONS FOR THE STATES ABOVE
    const FIRSTNAME_onChange = (event) => {         
        setFIRSTNAME(event.target.value);
        setUSERNAME(((FIRSTNAME) && (LGA[0])) ? FIRSTNAME+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(STATE, 2)+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(LGA[0].value, 3) : ""); 
    };
    const LASTNAME_onChange = (event) => {         setLASTNAME(event.target.value);         };
    // const DATE_OF_BIRTH_onChange = (event) => {         setDATE_OF_BIRTH(event.target.value);         };
    const DATE_OF_BIRTH_onChange = (date) => {         setDATE_OF_BIRTH(date);         };
    // const USERNAME_onChange = (event) => {         setUSERNAME(event.target.value);         };
    const PASSWORD_onChange = (event) => {         setPASSWORD(event.target.value);         };
    const RETYPED_PASSWORD_onChange = (event) => {         setRETYPED_PASSWORD(event.target.value);         };
    const PHONE_1_onChange = (event) => {         setPHONE_1(event.target.value);         };
    const PHONE_2_onChange = (event) => {         setPHONE_2(event.target.value);         };
    const GENDER_onChange = (event) => {         setGENDER(event.target.value);         };
    const ADDRESS_1_onChange = (event) => {         setADDRESS_1(event.target.value);         };
    const ADDRESS_2_onChange = (event) => {         setADDRESS_2(event.target.value);         };
    const PHONE_SERIAL_onChange = (event) => {         setPHONE_SERIAL(event.target.value);         };
    const CITY_onChange = (event) => {         setCITY(event.target.value);         };
    const TOWN_onChange = (event) => {         setTOWN(event.target.value);         };
    const selectedFile_onChange = (e) => {  
        // extract selected image file and store as an objcet
        const FILE_OBJCET = e.target.files[0];  
        // update the "selectedFile" state, which will inform our form submission that, user has selected an image. 
        setSelectedFile(FILE_OBJCET);
        // Also convert the selected image to base64 formate so we can display it on the preview, just immediately after image selection.
        setImgUrl(URL.createObjectURL(FILE_OBJCET));
    };

    // CREATE THE ONCHANGE METHOD, FOR THE STATE DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM STATE DROPDOWN
    const STATE_onChange = (event) => {
        /* Here we collect the selected state data and store it inside the "new_home_state" variable.  */
        let new_home_state = event.target.value;
        /* We update the "STATE" of the new selection. */
        setSTATE( new_home_state ); 
        /* AFTER GETTING THE STATE SELECTED FROM THE STATE DROPDOWN MENU, HERE WE NOW CALL ON THE "STATE_LOCAL_GOVERMENT_OPERATOR" FUNCTION TO HELP 
         * US GET ALL THE LOCAL GOVERNMENTS UNDER THIS STATE AND THEN POPULATE OUR LGA DROPDOWN WITH CORRESPONDING DATAS EACH TIME A NEW STATE IS 
         * SELECTED FROM THE STATE DROPDOWN LIST.  */
        STATE_LOCAL_GOVERMENT_OPERATOR(new_home_state);
    };

    // CREATE THE ONCHANGE METHOD, FOR THE LGA DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM LGA DROPDOWN
    const LGA_onChange = (event) => {
        /* Here we collect the selected lga data and store it inside the "new_lga" variable.  */
        let new_lga = event.target.value;
        /* We update the "LGA" state of the new selection. We do this update in such a way that any data already in this state stays. */
        // if(LGA.length > 1) setLGA( [{ value: new_lga, label: new_lga }].concat(LGA) );
        // We update the "LGA" state of the new selection.
        setLGA( [{ value: new_lga, label: new_lga }] );
        /* AFTER GETTING THE LGA SELECTED FROM THE LGA DROPDOWN MENU, HERE WE NOW CALL ON THE "LGA_BRANCH_OPERATOR" FUNCTION TO HELP 
         * US GET ALL THE BRANCHES UNDER THIS LGA AND THEN POPULATE OUR BRANCH DROPDOWN WITH CORRESPONDING DATAS EACH TIME A NEW LGA IS 
         * SELECTED FROM THE LGA DROPDOWN LIST.  */
        LGA_BRANCH_OPERATOR(new_lga);
    };

    // CREATE THE ONCHANGE METHOD, FOR THE BRANCH DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM BRANCH DROPDOWN
    const BRANCH_onChange = (event) => {   
        /* Here we collect the selected BRANCH data and store it inside the "BRANCH" variable.  */
        let BRANCH_data = event.target.value;
        // UPDATE BRANCH STATE. We do this update in such a way that any data already in this state stays.
        // if(BRANCH.length > 1) setBRANCH( [{ value: BRANCH_data, label: BRANCH_data }].concat(BRANCH) ); 
        //UPDATE BRANCH STATE.
        setBRANCH( [{ value: BRANCH_data, label: BRANCH_data }] );    
    };
    


    /* WE USE TO FUNCTION TO CONTROL WHAT SHOULD HAPPEN WHEN USER SELECTS A STATE. THIS FUNCTION TAKES IN A PARAMETER WHICH IS THE STATE THAT WAS 
     * SELECTED TO HELP IT FETCH THE CORRESPONDING LOCAL GOVERNMENT AREAS FOR THAT STATE */
    const STATE_LOCAL_GOVERMENT_OPERATOR = (SECLECTED_STATE) => {
        /* Now we create a temporary initially empty arrays, the aim of creating this array is so that we get to use it as temporary container to 
         * store the local government areas of the selected state.  */
        let dataset = [];
        // set the default value for our LGA dropdown each time the state dropdown is tampered with.
        dataset[0] = { value: "", label: "Select LGA" };
        /* Also since we shall be storing datas inside array soon using a loop, its important that we create an integer variable to be used to 
         * properly formate the indexes or keys of our new array containers as we store datas in them, right inside the loop.  */
        let countez = 1;
        /* Here we use the "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA" function to create and fetch an object of all the states in nigeria and their 
         * respective local government areas, then we store them inside the "lgaList" variable. */
        const lgaList = NIGERIA_STATES_LOCAL_GOVERNMENT_AREA();
        /* Here, firstly we convert into a loopable array the object that was recently fetched from the "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA" function 
         * before we then create the loop to be used to purge out every "nigerian state" datas present inside the array. note that the nigeria state 
         * data is in this array primary dimension while their respective local government area datas are in the second dimension, check the 
         * "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA" function to understand this data structure.  */
        Object.keys(lgaList).forEach(key => {
            /* on purging out every nigeria state datas away from the primary dimension of the array, we test each of this nigeria state data per 
             * loop cycle at this junction, we check it against the selected state from the dropdown form, if they are thesame this signifies that we 
             * have reached the point in the array looping that is parallel to the user selected state, therefore, we can proceed to the point of 
             * extracting correct and precise local government area for the selected state. */
            if(SECLECTED_STATE === key){
                /* In a bid to extract the local government area for the selected state, we need to create another loop inside the first loop, only that this second loop shall be
                 * strategically used to extract all local government area datas. */
                lgaList[key].forEach(data => {
                    /* Now we extract the local government area data, and use it to create an array that we shall be storing in our temporary arrays, we use the "countez" variable to 
                     * align the indexes of the temporary arrays.  */
                    dataset[countez] = { value: data, label: data };
                    /* At the end of each loop cycle of the second loop, its important we increment this "countez" variable so that the job of properly formatting the indexes of our
                     * two temporary arrays can be smooth. */
                    countez++;
                });
            }
        });

        /* now that we have the local government area data of user selected state, its time we store this "compiled in array" datas inside a react state, but first we empty this react
         * state first at this junction.  */
        setLGA([]);
        // Since this page is an update page, meaning default data will be preloaded into forms and dropdowns, and also if this function is activated
        // it only signifies that state dropdown has been tampered with, it only makes sense to set to default empty state, the branch dropdown.
        setBRANCH([{ value: "", label: "Select Branch" }]);
        /* after emptying the react state responsible for holding the list datas of user first and second prefered locations, we now store the array dataset innside the "dataset" 
         * vaiable in them so they can take a live effect of the location dropdown. Note that the state carrying the selected data of this two location dropdown form is different from
         * this state we updating here, because this state only store user first and second location dropdown datas.  */
        setLGA(dataset);
    };



    /* WE USE TO FUNCTION TO CONTROL WHAT SHOULD HAPPEN WHEN USER SELECTS LGA. THIS FUNCTION TAKES IN A PARAMETER WHICH IS THE LGA THAT WAS 
     * SELECTED TO HELP IT FETCH THE CORRESPONDING BRANCHES FOR THAT LGA */
    const LGA_BRANCH_OPERATOR = (SECLECTED_LGA) => {
        /* Now we create a temporary initially empty arrays, the aim of creating this array is so that we get to use it as temporary container to 
         * store the branches of the selected lga.  */
        let dataset = [];
        // set the default value for our Branch dropdown each time the LGA dropdown is tampered with.
        dataset[0] = { value: "", label: "Select Branch" };
        /* Also since we shall be storing datas inside array soon using a loop, its important that we create an integer variable to be used to 
         * properly formate the indexes or keys of our new array containers as we store datas in them, right inside the loop.  */
        let countez = 1;
        /* Here we use the "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA_BRANCHES" function to create and fetch an object of all the lga's in nigeria and 
         * their respective branches, then we store them inside the "branchList" variable. */
        const branchList = NIGERIA_STATES_LOCAL_GOVERNMENT_AREA_BRANCHES();
        /* Here, firstly we convert into a loopable array the object that was recently fetched from the "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA_BRANCHES" 
         * function before we then create the loop to be used to purge out every "nigerian lga" datas present inside the array. note that the nigeria 
         * lga data is in this array primary dimension while their respective branche datas are in the second dimension, check the 
         * "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA_BRANCHES" function to understand this data structure.  */
        Object.keys(branchList).forEach(key => {
            /* on purging out every nigeria lga datas away from the primary dimension of the array, we test each of this nigeria lga data per loop 
             * cycle at this junction, we check it against the selected lga from the dropdown form, if they are thesame this signifies that we have 
             * reached the point in the array looping that is parallel to the user selected lga, therefore, we can proceed to the point of extracting 
             * correct and precise branches for the selected lga. */
            if(SECLECTED_LGA === key){
                /* In a bid to extract the branch for the selected lga, we need to create another loop inside the first loop, only that this second 
                 * loop shall be strategically used to extract all branch datas. */
                branchList[key].forEach(data => {
                    /* Now we extract the lbranch data, and use it to create an array that we shall be storing in our temporary arrays, we use the 
                     * "countez" variable to align the indexes of the temporary arrays.  */
                    dataset[countez] = { value: data, label: data };
                    /* At the end of each loop cycle of the second loop, its important we increment this "countez" variable so that the job of 
                     * properly formatting the indexes of our two temporary arrays can be smooth. */
                    countez++;
                });
            }
        });

        /* now that we have the branch data of user selected lga, its time we store this "compiled in array" datas inside a react state, but first we 
         * empty this react state first at this junction.  */
        setBRANCH([]);
        /* after emptying the react state responsible for holding the list datas, we now store the array dataset innside the "dataset" vaiable in them 
         * so they can take a live effect on the dropdown.  */
        setBRANCH(dataset);
    };









    // CREATE THE ONCHANGE METHOD, FOR THE STATE DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM STATE DROPDOWN
    const STATE_OF_ORIGIN_onChange = (event) => {
        /* Here we collect the selected state data and store it inside the "new_home_state" variable.  */
        let new_home_state = event.target.value;
        /* We update the "STATE" of the new selection. */
        setSTATE_OF_ORIGIN( new_home_state ); 
        /* AFTER GETTING THE STATE SELECTED FROM THE STATE DROPDOWN MENU, HERE WE NOW CALL ON THE "STATE_LOCAL_GOVERMENT_OPERATOR_____2" FUNCTION TO HELP 
         * US GET ALL THE LOCAL GOVERNMENTS UNDER THIS STATE AND THEN POPULATE OUR LGA DROPDOWN WITH CORRESPONDING DATAS EACH TIME A NEW STATE IS 
         * SELECTED FROM THE STATE DROPDOWN LIST.  */
        STATE_LOCAL_GOVERMENT_OPERATOR_____2(new_home_state);
    };

    // CREATE THE ONCHANGE METHOD, FOR THE LGA DROPDOWN. THIS FUNCTION DEFINES WHAT MUST HAPPEN WHEN USER SELECT FROM LGA DROPDOWN
    const LGA_OF_ORIGIN_onChange = (event) => {
        /* Here we collect the selected lga data and store it inside the "new_lga" variable.  */
        let new_lga = event.target.value;
        /* We update the "LGA" state of the new selection. We do this update in such a way that any data already in this state stays. */
        // if(LGA.length > 1) setLGA( [{ value: new_lga, label: new_lga }].concat(LGA) );
        // We update the "LGA" state of the new selection.
        setLGA_OF_ORIGIN( [{ value: new_lga, label: new_lga }] );
    };

    /* WE USE TO FUNCTION TO CONTROL WHAT SHOULD HAPPEN WHEN USER SELECTS A STATE. THIS FUNCTION TAKES IN A PARAMETER WHICH IS THE STATE THAT WAS 
     * SELECTED TO HELP IT FETCH THE CORRESPONDING LOCAL GOVERNMENT AREAS FOR THAT STATE */
    const STATE_LOCAL_GOVERMENT_OPERATOR_____2 = (SECLECTED_STATE) => {
        /* Now we create a temporary initially empty arrays, the aim of creating this array is so that we get to use it as temporary container to 
         * store the local government areas of the selected state.  */
        let dataset = [];
        // set the default value for our LGA dropdown each time the state dropdown is tampered with.
        dataset[0] = { value: "", label: "Select LGA" };
        /* Also since we shall be storing datas inside array soon using a loop, its important that we create an integer variable to be used to 
         * properly formate the indexes or keys of our new array containers as we store datas in them, right inside the loop.  */
        let countez = 1;
        /* Here we use the "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA" function to create and fetch an object of all the states in nigeria and their 
         * respective local government areas, then we store them inside the "lgaList" variable. */
        const lgaList = NIGERIA_STATES_LOCAL_GOVERNMENT_AREA();
        /* Here, firstly we convert into a loopable array the object that was recently fetched from the "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA" function 
         * before we then create the loop to be used to purge out every "nigerian state" datas present inside the array. note that the nigeria state 
         * data is in this array primary dimension while their respective local government area datas are in the second dimension, check the 
         * "NIGERIA_STATES_LOCAL_GOVERNMENT_AREA" function to understand this data structure.  */
        Object.keys(lgaList).forEach(key => {
            /* on purging out every nigeria state datas away from the primary dimension of the array, we test each of this nigeria state data per 
             * loop cycle at this junction, we check it against the selected state from the dropdown form, if they are thesame this signifies that we 
             * have reached the point in the array looping that is parallel to the user selected state, therefore, we can proceed to the point of 
             * extracting correct and precise local government area for the selected state. */
            if(SECLECTED_STATE === key){
                /* In a bid to extract the local government area for the selected state, we need to create another loop inside the first loop, only that this second loop shall be
                 * strategically used to extract all local government area datas. */
                lgaList[key].forEach(data => {
                    /* Now we extract the local government area data, and use it to create an array that we shall be storing in our temporary arrays, we use the "countez" variable to 
                     * align the indexes of the temporary arrays.  */
                    dataset[countez] = { value: data, label: data };
                    /* At the end of each loop cycle of the second loop, its important we increment this "countez" variable so that the job of properly formatting the indexes of our
                     * two temporary arrays can be smooth. */
                    countez++;
                });
            }
        });

        /* now that we have the local government area data of user selected state, its time we store this "compiled in array" datas inside a react state, but first we empty this react
         * state first at this junction.  */
        setLGA_OF_ORIGIN([]);
        /* after emptying the react state responsible for holding the list datas of user first and second prefered locations, we now store the array dataset innside the "dataset" 
         * vaiable in them so they can take a live effect of the location dropdown. Note that the state carrying the selected data of this two location dropdown form is different from
         * this state we updating here, because this state only store user first and second location dropdown datas.  */
        setLGA_OF_ORIGIN(dataset);
    };









    // construct api url to load up image
    const imageUrl = configuration.AGENT_BINARY_IMAGE_FETCHER_API+agent_id;
    // FUNCTION TO LOAD IMAGE FROM BACKEND AS BINARY, PROCESS AND CONVERT IT TO IMAGE
    const getImg = async () => {
        const response = await fetch(imageUrl, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Accept': 'image/png',
                'Authorization': `Bearer ${session}`,
                'Content-Type': 'image/png'     // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }).catch(error => {
            // history.push(configuration.LOGIN);
            openModal("Network Error, when attempting to save data. Please ensure you have a good internet connection.");
        });

        // ensure that we have an image data response and that fetch operation has not failed as a result of network failure before proceeding to this part
        if(response){
            const imageBlob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(imageBlob);
            reader.onloadend = () => {
                const base64data = reader.result;
                setImgUrl(base64data);
            };
        }
    };



    

    //
    useEffect(() => {
        // ONLY ALLOW API TO RUN IF THE "ALL_MEMBER_DATA" STATE IS EMPTY.
        if(TRIM(agent_id) !== ""){
            // LOAD IMAGE FROM BACKEND AS BINARY, PROCESS AND CONVERT IT TO IMAGE
            getImg(); 

            /* call the api function responsible for user password reset operation. */
            const url_1 = configuration.SORT_AGENT_BY_ID_API+agent_id;
            /* Call the "postData" function to help us handle submission. */
            getData(url_1, session).then(response => {
                // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
                if(response.id){
                    // console.log(response);
                    const address_line_2_data = EXPLODE("#@#", response.dcagmt_ADDR_LN2);
                    // set states storing user personal datas
                    setFIRSTNAME(response.dcagmt_FNAME);
                    setLASTNAME(response.dcagmt_SNAME);
                    console.log(new Date(DATE_CONFIRMER(response.dcagmt_DOB)));
                    console.log(DATE_CONFIRMER(response.dcagmt_DOB));
                    setDATE_OF_BIRTH(new Date(DATE_CONFIRMER(response.dcagmt_DOB)));
                    setGENDER(response.dcagmt_GENDER);
                    setPHONE_1(response.dcagmt_TEL_NO1);
                    setPHONE_2(response.dcagmt_TEL_NO2);
                    setADDRESS_1(response.dcagmt_ADDR_LN1);
                    setADDRESS_2(  TRIM( (address_line_2_data.length > 1) ? address_line_2_data[0] : response.dcagmt_ADDR_LN2 )  );
                    setPHONE_SERIAL(  TRIM( (address_line_2_data.length > 1) ? address_line_2_data[1] : "" )  );
                    setUSERNAME(response.dcagmtusrname);
                    setCITY(response.dcagmt_CITY);
                    // System of storing state, lga and branch is quite different so we perform that here
                    setSTATE(response.dcagmt_STATE);
                    setLGA([{ value: response.dcagmt_LGA, label: response.dcagmt_LGA }]);
                    setBRANCH([{ value: response.dcagmt_BRANCH, label: response.dcagmt_BRANCH }]);
                    setSTATE_OF_ORIGIN(response.stateOfOrigin);
                    setLGA_OF_ORIGIN([{ value: response.localGovtOfOrigin, label: response.localGovtOfOrigin }]);
                    setTOWN(response.hometown);
                }else{
                    // display server error message
                    openModal("System encountered an internal error while trying to load data.");
                }
            }).catch(error => {
                // At this junction we are certain that request submission has failed because of a network error, therefore we send out network 
                // failure message
                //openModal("Network Error, when attempting to save data. Please ensure you have a good internet connection.");
            });
        }

    }, []);



    
    
    // FUNCTION TO CREATE A NEW AGENT
    const UPDATE_AGENT = (e) => {
        /* line of code to ensure that html form default submit process is disabled, so that form submission can be handled by javascript in the REACT way.  */
        e.preventDefault();

        // process the inpute date of brith data, into a reliable format
        const processed_date = REACT_DATEPICKER_DATE_FORMATTER(DATE_OF_BIRTH);
        /* collect all data from their official states storage and compile them into an object, in a bid to prepare the datas for validation 
         * before we can proceed to submitting data. */
        const RAW_DATA = {
            DCAGMT_SNAME: LASTNAME, DCAGMT_FNAME: FIRSTNAME, DCAGMT_DOB: processed_date, 
            DCAGMTUSRNAME: ((FIRSTNAME) && (LGA[0])) ? FIRSTNAME+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(STATE, 2)+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(LGA[0].value, 3) : "", 
            DCAGMTPASSWRD: PASSWORD, DCAGMT_GENDER: GENDER, DCAGMT_TEL_NO1: PHONE_1, DCAGMT_TEL_NO2: PHONE_2, DCAGMT_ADDR_LN1: ADDRESS_1, 
            DCAGMT_ADDR_LN2: TRIM(ADDRESS_2+"#@#"+PHONE_SERIAL),  
            DCAGMT_CITY: CITY, DCAGMT_STATE: STATE, DCAGMT_LGA: LGA[0].value, DCAGMT_BRANCH: BRANCH[0].value, IMAGE_FILE_OBJECT: selectedFile, 
            RETYPED_PASSWORD: RETYPED_PASSWORD, PHONE_SERIAL: PHONE_SERIAL, STATE_OF_ORIGIN: STATE_OF_ORIGIN, LGA_OF_ORIGIN: LGA_OF_ORIGIN[0].value, 
            TOWN: TOWN
        };
        /* Here we launch the "RAW_DATA_AUTHENTICATOR" function to help us validate the authenticity of all the datas user has provided. */
        const AUTHENTICATOR_DATA = AGENT_REGISTRATION_DATA_AUTHENTICATOR_2(RAW_DATA, "UPDATE");

        /* Before any major registration operations can be permitted to begin, firstly the "REGISTRATION_DATA_AUTHENTICATOR_2" function must first give clear indications that the data
         * provided by user is valid. So at this junction we test to see if we have clearance to register this user.  */
        if(AUTHENTICATOR_DATA === true){
            /* Here we signal the button state change react-state, to indicate that button is clicked and request sent. */
            setBUTTON_LOADING_ANIM(true);
            // Now that we are certain form data meets the right format after data verification tests has been passsed, we go ahead to Build formData 
            // object, which we shall be using to send multipart data to the java backend.
            let formData = new FormData();
            /* collect all data from their official states storage and compile them into an object, in a bid to prepare the datas for form submission. */
            formData.append('DCAGMT_SNAME', LASTNAME);
            formData.append('DCAGMT_FNAME', FIRSTNAME);
            formData.append('DCAGMT_DOB', processed_date);
            // formData.append('DCAGMT_DOB', DATE_OF_BIRTH);
            formData.append('DCAGMTUSRNAME', ((FIRSTNAME) && (LGA[0])) ? FIRSTNAME+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(STATE, 2)+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(LGA[0].value, 3) : "");
            formData.append('DCAGMTPASSWRD', PASSWORD);
            formData.append('DCAGMT_GENDER', GENDER);
            formData.append('DCAGMT_TEL_NO1', PHONE_1);
            formData.append('DCAGMT_TEL_NO2', PHONE_2);
            formData.append('DCAGMT_ADDR_LN1', ADDRESS_1);
            formData.append('DCAGMT_ADDR_LN2', TRIM(ADDRESS_2+"#@#"+PHONE_SERIAL));
            formData.append('DCAGMT_CITY', CITY);
            formData.append('DCAGMT_STATE', FIRST_FEW_CHARS_IN_STRING_FETCHER(STATE, 2));
            formData.append('DCAGMT_LGA', FIRST_FEW_CHARS_IN_STRING_FETCHER(LGA[0].value, 3));
            formData.append('DCAGMT_BRANCH', BRANCH[0].value);
            formData.append('stateOfOrigin', STATE_OF_ORIGIN);
            formData.append('localGovtOfOrigin', LGA_OF_ORIGIN[0].value);
            formData.append('hometown', TOWN);
            // AUTOFILL THE REST OF THE FORMS FROM HERE
            formData.append('DCAGMT_NEXT_KIN_SNAME', "OOOOO");
            formData.append('DCAGMT_NEXT_KIN_FNAME', "OOOOO");
            formData.append('DCAGMT_NEXT_KIN_REL', "OOOOO");
            formData.append('DCAGMT_NEXT_KIN_TEL_NO', "08077777777");
            formData.append('DCAGMT_NEXT_KIN_ADDR', "OOOOO OOOOO OOOOO");
            formData.append('ROLENAME', "AGENT");
            // formData.append("DCAGMT_PHOTO", selectedFile);
            
            /* call the api function responsible for handling the profile updating job. */
            const url = configuration.UPDATE_AGENT_API+agent_id;
            /* Call the "putData" function to help us handle submission. */
            FORMDATA_POSTDATA(url, formData, session, 'PUT').then(response => {
                // check the response code of the response gotten from server after sending in this request. Test to see if all went well or not.
                if(response.status === 200){
                    // display success message
                    openModal("Agent updated successfully.");
                    // revert user back to member page
                    history.push(configuration.AGENT_RECORDS_LINK);
                }else{
                    // display server error message
                    openModal("System encountered an internal error while trying to process your data.");
                }

                // Here we signal the button state change react-state, to indicate that request has been completed. 
                setBUTTON_LOADING_ANIM(false);
            }).catch(error => {
                // Here we signal the button state change react-state, to indicate that request has been completed. 
                setBUTTON_LOADING_ANIM(false);
                // At this junction we are certain that request submission has failed because of a network error, therefore we send out network 
                // failure message
                openModal("Network Error, when attempting to save data. Please ensure you have a good internet connection.");
            });
            
        }else{
            /* control getting in here signifies that the authentication of user supplied profile data has failed for one reason or the other, further meaning user did 
             * not fill one or more form field well, therefore we initialize our alert container to tell user what he or she is doing wrong. */
            openModal(AUTHENTICATOR_DATA);
        }
    };
    







    
    

    // WE UPDATE THE PARENT COMPONENT TO REMIND IT THAT THE "AGENT_REG" MENU IS WHAT IS CURRENTLY ACTIVE, WE DO THIS JUST SO THAT THE SIDEBAR
    // ACTIVE-MENU-SELECTOR DOES NOT GO OUT OF PLACE EACH TIME THE FULL PAGE IS RELOADED.
    DEFAULT_MENU_FEEDBACK("AGENT_REG");
    DEFAULT_SUB_MENU_FEEDBACK("AGENT_LIST_DASHBOARD");
    
    // if(MenuController === "AGENT_REG"){

        return (
            
            <>
                
                <Modal isOpen={MODAL_TOGGLE} onRequestClose={closeModal} style={customStyles}>
                    <div><div id="full_description"><p>{MODAL_VIEW}</p></div><div className="div-block-35" style={{ marginTop: "3%" }}><h1 className="title_header"></h1><button onClick={closeModal} className="button_outline filled goldenrod id new w-button">Close</button></div></div>
                </Modal>

                <div className="div-block-35" style={{ marginBottom: "0%", marginTop: "2%" }}>
                    <h1></h1>
                    <Link to={configuration.AGENT_RECORDS_LINK} className="realbuttons w-button" style={{ marginBottom: "0" }}>Back</Link>
                </div>

                
                <div className="register_agent" style={{ marginBottom: "1%", height: "1500px" }}>
                    <br />
                    <h3 className="profile_pic_container" style={{ justifyContent: "center" }}>EDIT AGENT</h3>
                    <br />

                        
                    {/* <div className="profile_pic_container" style={{ justifyContent: "center", marginBottom: "0px" }}>
                        <p className="paragraph-9">Personal Information</p>
                    </div> */}

                    <form onSubmit={UPDATE_AGENT} method="post">
                    {/* <form onSubmit={UPDATE_AGENT} method="post" enctype="multipart/form-data"> */}
                        <div className="profile_pic_container" style={{ justifyContent: "center", marginBottom: "20px" }}>
                            <img src={imgUrl} loading="lazy" onClick={() => document.getElementById('agentImageFile').click()} width="200" 
                                height="200" alt="" style={{ borderRadius: "50%" }} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", marginLeft: "0%" }}>
                            <button onClick={() => document.getElementById('agentImageFile').click()} className="realbuttons w-button" 
                                style={{ marginRight: "1%" }}>Upload Photo</button>
                        </div>
                        <input type='file' id="agentImageFile" onChange={selectedFile_onChange} style={{ display: "none" }} />

                        <div className="form_holder">
                            <div className="w-form">
                                <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-8">
                                    <div>
                                        <label style={{ color: "#777" }}> First Name </label>
                                        <input onChange={FIRSTNAME_onChange} type="text" className="my-text-field w-input" minlength="3" maxlength="40" 
                                            name="name-2" data-name="Name 2" placeholder="First Name" id="name-2" value={FIRSTNAME} required />
                                    </div>

                                    <div>
                                        <label style={{ color: "#777" }}> Surname </label>
                                        <input onChange={LASTNAME_onChange} type="text" className="my-text-field w-input" minlength="3" maxlength="40" 
                                            name="name-2" data-name="Name 2" placeholder="Surname" id="name-2" value={LASTNAME} required />
                                    </div>

                                    <div>
                                        <label style={{ color: "#777" }}> Gender </label>
                                        <select onChange={GENDER_onChange} id="field-3" name="field-3" data-name="Field 3" minlength="4" maxlength="6" 
                                            required className="my-text-field w-node-_299c5399-09e0-f426-3764-79f53c81c717-17d2ff40 w-select">
                                            <option value={GENDER}>{GENDER}</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label style={{ color: "#777" }}> Date of Birth </label>
                                        {/* <input onChange={DATE_OF_BIRTH_onChange} type="date" className="my-text-field w-input" 
                                            name="name-2" data-name="Name 2" placeholder="dd-mm-yyyy" id="name-2" 
                                            min="1960-01-01" minlength="10" maxlength="10" value={DATE_OF_BIRTH} required /> */}
                                        <DatePicker className="my-text-field w-input" selected={DATE_OF_BIRTH} dateFormat="dd-MMM-yyyy" 
                                            onChange={DATE_OF_BIRTH_onChange} />
                                    </div>

                                    <div>
                                        <label style={{ color: "#777" }}> Phone 1 </label>
                                        <input onChange={PHONE_1_onChange} type="tel" placeholder="0000-000-0000" className="my-text-field w-input" 
                                            name="name-2" data-name="Name 2" id="name-2" minlength="3" maxlength="13" value={PHONE_1} required />
                                    </div>

                                    
                                    <div>
                                        <label style={{ color: "#777" }}> Phone 2 </label>
                                        <input onChange={PHONE_2_onChange} type="tel" placeholder="0000-000-0000" className="my-text-field w-input" 
                                            name="name-2" data-name="Name 2" id="name-2" minlength="3" maxlength="13" value={PHONE_2} />
                                    </div>
                                </div>

                                <div style={{ marginTop: "-70%" }}>
                                    <label style={{ color: "#777" }}> Address Line 1 </label>
                                    <input onChange={ADDRESS_1_onChange} type="text" className="my-text-field w-input" name="name-2" 
                                        data-name="Name 2" placeholder="Address Line 1" id="name-2" minlength="3" maxlength="40" value={ADDRESS_1} 
                                        style={{ width: "720px" }} required />
                                </div>
                                
                                <div>
                                    <label style={{ color: "#777" }}> Address Line 2 </label>
                                    <input onChange={ADDRESS_2_onChange} type="text" className="my-text-field w-input" name="name-2" 
                                        data-name="Name 2" placeholder="Address Line 2" id="name-2" minlength="3" maxlength="40" 
                                        style={{ width: "720px" }} value={ADDRESS_2} />
                                </div>

                                <div>
                                    <label style={{ color: "#777" }}> Phone Serial No </label>
                                    <input onChange={PHONE_SERIAL_onChange} type="text" className="my-text-field w-input" name="name-2" 
                                        data-name="Name 2" placeholder="Phone Serial Number" id="name-2" minlength="3" maxlength="40" 
                                        style={{ width: "720px" }} value={PHONE_SERIAL} required />
                                </div>
                                {/* {console.log(CITY)} */}
                                <div id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form-8">
                                    <div>
                                        <label style={{ color: "#777" }}> City </label>
                                        <input onChange={CITY_onChange}  type="text" className="my-text-field w-input"  name="name-2" 
                                            data-name="Name 2" placeholder="City" id="name-2" minlength="3" maxlength="40" 
                                            value={CITY} />
                                    </div>

                                    <div>
                                        <label style={{ color: "#777" }}> State </label>    
                                        <select id="field-3" name="field-3" data-name="Field 3" onChange={STATE_onChange} minlength="3" maxlength="40" 
                                            required className="my-text-field w-node-_299c5399-09e0-f426-3764-79f53c81c717-17d2ff40 w-select">
                                            <option value={STATE}>{STATE}</option>
                                            <NIGERIA_STATES_DROPDOWN />
                                        </select>
                                    </div>

                                    <div>
                                        <label style={{ color: "#777" }}> Local Government Area </label>
                                        <select id="field-3" name="field-3" data-name="Field 3" onChange={LGA_onChange} minlength="3" maxlength="40" 
                                            required className="my-text-field w-node-_299c5399-09e0-f426-3764-79f53c81c717-17d2ff40 w-select">
                                            {LGA.map((infos, index) => {
                                                return <option value={infos.value}>{CAPITALIZE_FIRST_LETTERS(infos.label)}</option>;
                                            })}
                                        </select>
                                    </div>

                                    <div>
                                        <label style={{ color: "#777" }}> Branch </label>
                                        <select id="field-3" name="field-3" data-name="Field 3" onChange={BRANCH_onChange} minlength="3" maxlength="40" 
                                            required className="my-text-field w-node-_299c5399-09e0-f426-3764-79f53c81c717-17d2ff40 w-select">
                                            {BRANCH.map((infos, index) => {
                                                return <option value={infos.value}>{CAPITALIZE_FIRST_LETTERS(infos.label)}</option>;
                                            })}
                                        </select>
                                    </div>

                                    <div>
                                        <label style={{ color: "#777" }}> State of Origin </label>
                                        <select id="field-3" name="field-3" data-name="Field 3" onChange={STATE_OF_ORIGIN_onChange} minlength="3" maxlength="40" 
                                            required className="my-text-field w-node-_299c5399-09e0-f426-3764-79f53c81c717-17d2ff40 w-select" 
                                            style={{ marginTop: "0px" }}>
                                            <option value={CAPITALIZE_FIRST_LETTERS(STATE_OF_ORIGIN)}>{CAPITALIZE_FIRST_LETTERS(STATE_OF_ORIGIN)}</option>
                                            <NIGERIA_STATES_DROPDOWN />
                                        </select>
                                    </div>

                                    <div>
                                        <label style={{ color: "#777" }}> LGA of Origin </label>
                                        <select id="field-3" name="field-3" data-name="Field 3" onChange={LGA_OF_ORIGIN_onChange} minlength="3" maxlength="40" 
                                            required className="my-text-field w-node-_299c5399-09e0-f426-3764-79f53c81c717-17d2ff40 w-select" 
                                            style={{ marginTop: "0px" }}>
                                            {LGA_OF_ORIGIN.map((infos, index) => {
                                                return <option value={infos.value}>{CAPITALIZE_FIRST_LETTERS(infos.label)}</option>;
                                            })}
                                        </select>
                                    </div>
                                    
                                    <div>
                                        <label style={{ color: "#777" }}> Hometown </label>
                                        <input onChange={TOWN_onChange} type="text" className="my-text-field w-input" name="name-2" data-name="Name 2" 
                                            placeholder="Town" id="name-2" minlength="3" maxlength="40" style={{ marginTop: "0px" }} value={TOWN} 
                                            required />
                                    </div>

                                    <div>
                                        <label style={{ color: "#777" }}> Username </label>
                                        <input type="text" className="my-text-field w-input" name="name-2" data-name="Name 2" 
                                            placeholder="Username" id="name-2" minlength="3" maxlength="40" 
                                            value={((FIRSTNAME) && (STATE) && (LGA[0])) ? FIRSTNAME+"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(STATE, 2)
                                            +"-"+FIRST_FEW_CHARS_IN_STRING_FETCHER(LGA[0].value, 3) : ""} 
                                            style={{ border: "1px solid #eee", background: "#D3D3D3" }} required />
                                    </div>

                                    <div>
                                        <label style={{ color: "#777" }}> Password </label>
                                        <input onChange={PASSWORD_onChange} type="password" className="my-text-field w-input" name="name-2" data-name="Name 2" 
                                            placeholder="Password" id="name-2" minlength="3" maxlength="40" required />
                                    </div>

                                    <div>
                                        <label style={{ color: "#777" }}> Re-type Password </label>
                                        <input onChange={RETYPED_PASSWORD_onChange} type="password" className="my-text-field w-input" name="name-2" data-name="Name 2" 
                                            placeholder="Password" id="name-2" minlength="3" maxlength="40" required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer norm" style={{ paddingBottom: "50px", marginTop: "46%" }}>
                            {/* <Link to={configuration.AGENT_RECORDS_LINK} className="button_menu white w-button selected w--current" 
                                style={{ backgroundColor: "#ffc03d", color: "#432344" }}>
                                <p className="button-text small">Cancel</p>
                            </Link> */}
                            {(BUTTON_LOADING_ANIM === true) ? (
                                <button type="submit" className="button_menu w-button selected w--current">
                                    <p className="button-text small">Updating........</p>
                                </button>
                            ) : (
                                <button type="submit" className="button_menu white w-button selected w--current">
                                    <p className="button-text small">Update Agent</p>
                                </button>
                            )}
                            <Link to={configuration.AGENT_RECORDS_LINK} className="button_menu white w-button selected w--current" 
                                style={{ backgroundColor: "#ffc03d", color: "#432344" }}>
                                <p className="button-text small">Cancel</p>
                            </Link>
                        </div>
                    </form>
                </div>

            </>
        );

    // }else{
    //     return null;
    // }

}





