
// REACT STANDARD LIBRARY CALLS
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';



/* IMPORT LIBRARY  */
import { postData, createCookie, getCookie } from '../Library/Library.js';


// LOAD AGENT MODULE FOR AGENTS REGISTRATION
import ADD_AGENT from './DashboardPages/Agent_Module/ADD_AGENT';




export default function Agent_Registration() {

    // lets set the page title. 
    document.getElementById("title").innerHTML = "Agent Registration | "+configuration.COMPANY_NAME;
    /* we create "useHistory" object to be used for redirecting. */
    // let history = useHistory();   

    // A FUNCTION TO ENSURE YOU DONT SEE THE LOGIN PAGE AS LONG AS YOUR COOKIE IS YET TO EXPIRE
    // if(getCookie("token"))history.push(configuration.DASHBOARD);


    useEffect(() => {
        const html = document.getElementsByTagName('html')[0];
        html.setAttribute('data-wf-page', '62ab23d8eae8f044a26113f2');
        html.setAttribute('data-wf-site', '62a356d924ac21c4e115b2a0');

        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2').init();
        document.dispatchEvent(new Event('readystatechange'))
    }, []);
    




    return (
        <div className='dash_board_body'>
            <div className="new-side-menu">
                <div className="task_nav">
                    <Link to="#">
                        <img src={configuration.APP_DATA_RESOURCE+"images/full-logo.svg"} loading="lazy" width="128" 
                            alt={configuration.COMPANY_NAME+" Official Logo"} className="image-4" />
                    </Link>
                    
                    <Link to="#" className={"task_nav_button w-inline-block w--current"}>
                        <img src={configuration.APP_DATA_RESOURCE+"images/reg-agent-icon.svg"} loading="lazy" className="task_nav_button_icon" />
                        <div className="text-block">Agent Registration</div>
                    </Link>
                </div> 
            </div>


            <div className="main-body">
                {/* <div className="top_nav" style={{ zIndex: "2" }}>
                    <div className="div-block-8">
                        <img src={configuration.APP_DATA_RESOURCE+"images/logo-purple.svg"} loading="lazy" width="35" alt="" className="image-7" />
                        <img src={configuration.APP_DATA_RESOURCE+"images/burger-menu-icon.svg"} loading="lazy" width="30" 
                            data-w-id="372cbde3-1e73-f2c5-6e88-ba7ea8b7fc4b" alt="" className="image-8" />
                    </div>
                    
                    <div className="div-block-4">
                        <div data-hover="false" data-delay="0" className="dropdown w-dropdown">
                            <div className="dropdown-toggle w-dropdown-toggle">
                                <div className="icon w-icon-dropdown-toggle"></div>
                                <div className="text-block-7">Adefola Adams</div>
                            </div>
                            <nav className="dropdown-list w-dropdown-list">
                                <a href="#" className="name_drop_link w-dropdown-link">Notification</a>
                                <a href="#" className="name_drop_link w-dropdown-link">Change Password</a>
                                <a href="#" className="name_drop_link w-dropdown-link">Log Out</a>
                            </nav>
                        </div>
                        <a href="#" aria-current="page" className="div-block-5 w-inline-block w--current">
                            <img src={configuration.APP_DATA_RESOURCE+"images/dashboard-top-icon.svg"} loading="lazy" width="18" alt="" />
                        </a>
                        <a data-w-id="fc52364c-20d0-4d45-f0d4-4e5657d9aa46" href="#" className="div-block-5 w-inline-block">
                            <img src={configuration.APP_DATA_RESOURCE+"images/bell.svg"} loading="lazy" width="22" alt="" />
                        </a>
                        <div className="div-block-6"></div>
                    </div>
                </div> */}


                <ADD_AGENT />
                
                
            </div>

        </div>
    );

}
